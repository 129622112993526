import React from "react";
import {
	FcNeutralTrading,
	FcGenealogy,
	FcPlus,
	FcPositiveDynamic,
	FcViewDetails,
	FcComboChart,
  FcDocument
} from "react-icons/fc";
import Dashboard from "../../componentsBase/tables/Intro"; // Asegúrate de que la ruta sea correcta

const ParentComponent = () => {
	const cards = [
		{
			id: 1,
			icon: <FcNeutralTrading />,
			title: "Crear Estructura",
			description: "Nombra la estructura de tu plan presupuestal",
			to: "/menu/hefestoPresupuesto/crear-estructura", // Ruta correcta
		},
		{
			id: 2,
			icon: <FcGenealogy />,
			title: "Configura Estructura",
			description:
				"Configura los nombres,niveles y números de caracteres de tu estructura ",
			to: "/menu/hefestoPresupuesto/configurar-estructura", // Ruta correcta
		},
		{
			id: 3,
			icon: <FcPlus />,
			title: "Crear plan",
			description:
				"Crea el plan presupuestal, asignando una estructura y año  ",
			to: "/menu/hefestoPresupuesto/crear-planP", // Ruta correcta
		},
		{
			id: 4,
			icon: <FcPositiveDynamic />,
			title: "Proyectar Plan",
			description:
				"Crea, edita o asigna valores a cada cuenta de tu plan presupuestal",
			to: "/menu/hefestoPresupuesto/proyectar-planP", // Ruta correcta
		},
		{
			id: 5,
			icon: <FcViewDetails />,
			title: "Cargar Plan",
			description:
				"Usando la plantilla que genera el sistema carga tu plan de manera ágil",
			to: "/menu/hefestoPresupuesto/planpxPlano", // Ruta correcta
		},
		{
			id: 5,
			icon: <FcViewDetails />,
			title: "Cargar Plan",
			description:
				"Usando la plantilla que genera el sistema carga tu plan de manera ágil",
			to: "/menu/hefestoPresupuesto/planpxPlano", // Ruta correcta
		},
		{
			id: 6,
			icon: <FcComboChart />,
			title: "Deducciones",
			description:
				"Configura las deducciones que aplicarás en tus movimientos presupuestales ",
			to: "/menu/hefestoPresupuesto/conceptos-planP", // Ruta correcta
		},
    {
			id: 7,
			icon: <FcDocument />,
			title: "Conceptos",
			description:
				"Configura los distintos conceptos, que posteriormente utilizarás en tus movimientos presupuestales ",
			to: "/menu/hefestoPresupuesto/conceptosinfo-planP", // Ruta correcta
		}
	];

	return <Dashboard cards={cards} />;
};

export default ParentComponent;
