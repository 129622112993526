import React from "react";
import { useNavigate } from "react-router-dom";
import "./../../../styles/administration/Intro.css";

const Dashboard = ({ cards }) => {
  const navigate = useNavigate(); // Hook para redireccionar

  return (
    <div className="dashboard-container">
      {cards.map((card) => (
        <div
          key={card.id}
          className="card"
          onClick={() => navigate(card.to, { replace: true })} // Asegura navegación limpia
          style={{ cursor: "pointer" }} // Cambia el cursor para indicar que es clicable
        >
          <div className="icon">{card.icon}</div>
          <h3 className="title">{card.title}</h3>
          <p className="description">{card.description}</p>

          {/* Verifica si existen detalles y mapea las tarjetas pequeñas */}
          {card.detalles && (
            <div className="details-container">
              {card.detalles.map((detalle, index) => (
                <div
                  key={index}
                  className="small-card"
                  onClick={(e) => {
                    e.stopPropagation(); // Previene que el click en la tarjeta pequeña redirija a la tarjeta principal
                    navigate(detalle.to, { replace: true });
                  }}
                  style={{ cursor: "pointer" }} // Cambia el cursor para indicar que es clicable
                  data-description={detalle.description} // Agrega la descripción como atributo para el tooltip
                >
                  <div className="small-icon">{detalle.icon}</div>
                  <h4 className="small-title">{detalle.title}</h4>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Dashboard;
