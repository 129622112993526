import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/LogContext";
import { Formik, Form, Field } from "formik";
import Select from "react-select";
import axios from "axios";
import {
	route_person_searchPerson,
	route_contabconf_base,
	route_informesConta_base,
} from "../../../api-routes/RoutesLogin";
import TableIngresos from "../../componentsBase/tables/TableBase1";

const MyForm = () => {
	useEffect(() => {
		loadFiltros();
	}, []);
	const columns = [
		{ Header: "Fecha", accessor: "fecha" },
		{ Header: "Tercero", accessor: "tercero" },
		{ Header: "Comprobante", accessor: "nombreComprobante" },
		{ Header: "Tipo ", accessor: "tipoDetalle" },
		{ Header: "Detalle tipo ", accessor: "categoriaDetalle" },
		{ Header: "Cuenta ", accessor: "cuenta" },
		{ Header: "Categoria ", accessor: "categoriaCuenta" },
		{ Header: "Centro Costo ", accessor: "centroCosto" },
		{ Header: "Débito ", accessor: "debito" },
		{ Header: "Crédito ", accessor: "credito" },
	];
	const sumFields = ["creditos", "debitos"];

	const {
		establishment,
		getToken,
		customStyles,
		inputStyles,
		dateStyles,
		FechaYMDHMS,
	} = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(false);
	const [optionsPersons, setOptionsPersons] = useState([]);
	const [personSelected, setPersonSelected] = useState(null);
	const [banCarga, setBanCarga] = useState(0);
	const [options, setOptions] = useState({});
	const [data, setData] = useState([]);

	const onInputChange = (inputValue) => {
		if (
			typeof inputValue === "string" &&
			inputValue != "" &&
			inputValue.length >= 3
		) {
			loadPersons(inputValue);
		}
	};
	const loadPersons = async (inputValue) => {
		setIsLoading(true);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		try {
			const response = await axios.get(
				route_person_searchPerson +
					establishment.id +
					"," +
					inputValue +
					",true",
				config
			);
			const data = response.data;
			const formattedOptions = data.map((item) => ({
				value: item.id,
				label:
					item.identification +
					" - " +
					item.name1 +
					" " +
					item.name2 +
					" " +
					item.lastName1 +
					" " +
					item.lastName2 +
					" - " +
					item.businessName,
			}));

			setOptionsPersons(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
			setIsLoading(false);
		}
	};

	function customSort(a, b) {
		// Ordenar por 'codigo'
		if (a.codigo < b.codigo) return -1;
		if (a.codigo > b.codigo) return 1;

		// Si 'codigo' es igual, ordenar por 'tipo'
		if (a.tipo < b.tipo) return -1;
		if (a.tipo > b.tipo) return 1;

		// Si 'codigo' y 'tipo' son iguales, ordenar por 'naturaleza'
		if (a.naturaleza < b.naturaleza) return -1;
		if (a.naturaleza > b.naturaleza) return 1;

		return 0;
	}
	const loadFiltros = async (inputValue) => {
		setIsLoading(true);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		try {
			const response = await axios.get(
				route_contabconf_base + "/getOptions/" + establishment.id,
				config
			);
			const data = response.data;
			//let order = response.data.cuentas.sort(customSort);
			//data.cuentas=order;
			data.tiposC = [
				{ value: 1, label: "Ingresos" },
				{ value: 2, label: "Gastos" },
				{ value: 3, label: "Contables" },
			];
			/* data.categoriasC = [
				{ value: 1, label: "Ingresos" },
				{ value: 2, label: "Gastos" },
				{ value: 3, label: "Contables" },
			]; */
			setOptions(data);
			console.log(data);

			//setOptionsPersons(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
			setIsLoading(false);
		}
	};
	const getDetalles = async (values) => {
		setBanCarga(1)
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.post(
			`${route_informesConta_base}/getInformeDetalles`,
			values,
			config
		);
		setData(result.data);
		setBanCarga(0)
	};
	const stylePlan = {
		control: (base) => ({
			...base,
			height: 80,
			minHeight: 35,
			fontSize: "14px",
			height: "45px",
		}),
		option: (base, { data, isDisabled }) => ({
			...base,
			textAlign: `left`,
			backgroundColor: data.tipo === 1 ? "#5fb7eb" : "#3879db",
			color: "white",
		}),
	};
	return (
		<>
			<div className="containerFormUser">
				<h1>Informe Detallado</h1>
				<Formik
					initialValues={{
						idE: establishment.id,
						idTercero: null,
						fechaIni: "",
						fechaFin: "",
						comprobantes: [],
						tiposC: [],
						//categoriasC: [],
						cuentas: [],
						centros: [],
						inicioC: "",
						catCuenta: null,
					}}
					onSubmit={(values) => {
						if (values.fechaIni == "") {
							values.fechaIni = "1900-01-01";
						}
						if (values.fechaFin == "") {
							values.fechaFin = "3000-01-01";
						}
						console.log("Formulario Enviado:", JSON.stringify(values, null, 2));
						getDetalles(values);
					}}
				>
					{({ setFieldValue, values }) => (
						<Form>
							{/* ---------------------- SECCION 1 ------------------------------- */}
							{/* idTercero */}
							<div className="row">
								<div className="col-md-4">
									<Select
										styles={customStyles}
										isSearchable={true}
										placeholder="Búsqueda de terceros"
										isClearable={true}
										isLoading={isLoading}
										value={personSelected}
										onInputChange={onInputChange}
										options={optionsPersons}
										onChange={(e) => {
											setPersonSelected(e);
											setFieldValue("idTercero", e ? e.value : null);
										}}
									/>
								</div>
								{/* Fecha Inicio */}
								<div className="col-md-4  ">
									<div className="form-floating mb-2  ">
										<input
											style={dateStyles}
											type="date"
											name="fechaIni"
											id="fechaIni"
											//value={hasta}
											className="form-control"
											placeholder="Desde "
											onChange={(e) => {
												if (e) {
													setFieldValue("fechaIni", e.target.value);
												}
											}}
										/>
										<label
											style={{ zIndex: "0" }}
											htmlFor="hasta"
											className="form-label"
										>
											Fecha Inicio:
										</label>
									</div>
								</div>
								{/* Fecha Fin */}
								<div className="col-md-4  ">
									<div className="form-floating mb-2  ">
										<input
											style={dateStyles}
											type="date"
											name="hasta"
											id="hasta"
											//value={hasta}
											className="form-control"
											placeholder="Hasta "
											onChange={(e) => {
												if (e) {
													setFieldValue("fechaFin", e.target.value);
												}
											}}
										/>
										<label
											style={{ zIndex: "0" }}
											htmlFor="hasta"
											className="form-label"
										>
											Fecha Fin:
										</label>
									</div>
								</div>
							</div>
							{/* ---------- SECCION 2 ------------------ */}
							<div className="row">
								<div className="col-md-4">
									<Select
										styles={customStyles}
										isMulti
										options={options.comprobantes}
										name="comprobantes"
										placeholder="Comprobantes"
										onChange={(selectedOptions) =>
											setFieldValue(
												"comprobantes",
												selectedOptions
													? selectedOptions.map((o) => o.value)
													: []
											)
										}
										value={
											options.comprobantes
												? options.comprobantes.filter((option) =>
														values.comprobantes.includes(option.value)
												  )
												: [] // Valor predeterminado si no existe options.comprobantes
										}
									/>
								</div>
								<div className="col-md-4">
									<Select
										styles={customStyles}
										isMulti
										options={options.tiposC}
										name="tiposC"
										placeholder="Tipos de comprobante"
										onChange={(selectedOptions) =>
											setFieldValue(
												"tiposC",
												selectedOptions
													? selectedOptions.map((o) => o.value)
													: []
											)
										}
										value={
											options.tiposC
												? options.tiposC.filter((option) =>
														values.tiposC.includes(option.value)
												  )
												: [] // Valor predeterminado si no existe options.tiposC
										}
									/>
								</div>
								<div className="col-md-4">
									<Select
										isMulti
										styles={customStyles}
										options={options.centros}
										placeholder="Centros de costo"
										name="centros"
										onChange={(selectedOptions) =>
											setFieldValue(
												"centros",
												selectedOptions
													? selectedOptions.map((o) => o.value)
													: []
											)
										}
										value={
											options.centros
												? options.centros.filter((option) =>
														values.centros.includes(option.value)
												  )
												: [] // Valor predeterminado si no existe options.centros
										}
									/>
								</div>
							</div>
							{/* ----------SECCION 3 -------------------- */}
							<div className="row mt-2">
								<div className="col-md-4">
									<Select
										styles={stylePlan}
										placeholder="Cuentas"
										isMulti
										options={options.cuentas}
										name="cuentas"
										filterOption={(option, inputValue) => {
											const label = option.label.toLowerCase();
											const input = inputValue.toLowerCase();

											// Primero, prioriza las coincidencias que comienzan con el input
											if (label.startsWith(input)) {
												return true;
											}

											// Como segunda opción, busca cualquier coincidencia
											return label.includes(input);
										}}
										onChange={(selectedOptions) =>
											setFieldValue(
												"cuentas",
												selectedOptions
													? selectedOptions.map((o) => o.value)
													: []
											)
										}
										value={
											options.cuentas
												? options.cuentas.filter((option) =>
														values.cuentas.includes(option.value)
												  )
												: [] // Valor predeterminado si no existe options.cuentas
										}
									/>
								</div>
								<div className="col-md-4">
									<Field
										//value={detSugerido}
										style={inputStyles}
										type="text"
										name="inicioC"
										id="inputStyles"
										className="form-control"
										placeholder="Codigo"
										//onChange={(e) => {setDetSugerido(e.target.value); formik.setFieldValue('detalle',e.target.value)}}
									/>
								</div>
								<div className="col-md-4">
									<Select
										options={options.catCuenta}
										styles={customStyles}
										placeholder="Categoria cuenta"
										name="catCuenta"
										isClearable={true}
										onChange={(selectedOption) =>
											setFieldValue(
												"catCuenta",
												selectedOption ? selectedOption.value : null
											)
										}
										value={
											options.catCuenta
												? options.catCuenta.find(
														(option) => option.value === values.catCuenta
												  )
												: null // Valor predeterminado si no existe options.catCuenta
										}
									/>
								</div>
							</div>

							{/* Submit Button */}
							<div className="mt-2">
								<button className="btn btn-primary" type="submit">
									Buscar
								</button>
							</div>
						</Form>
					)}
				</Formik>
				<div className="table-container-reacTable">
					{banCarga === 0 ? (
						<TableIngresos
							data={data}
							columns={columns}
							encabezado={{
								empresa: establishment.name,
								nit: establishment.identification,
								titulo: "Informe Detallado",
								imagen: establishment.image,
								fechas: "Informacion corte: ",
								generado: "Informe generado: " + FechaYMDHMS,
							}}
							sumFields={sumFields}
						/>
					) : (
						/* spinner carga */
						<button className="btn btn-primary" type="button" disabled>
							<span
								className="spinner-border spinner-border-sm"
								aria-hidden="true"
							></span>
							<span role="status"> Cargando información...</span>
						</button>
						/*--------------- */
					)}
				</div>
			</div>
		</>
	);
};

export default MyForm;
