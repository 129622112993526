import React, { useEffect, useContext, useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../context/LogContext";
import {
	route_establishments_save,
	route_typeid_list,
	route_establishments_getAll,
	route_establishments_license,
} from "../../../api-routes/RoutesLogin";
import { show_alert } from "../../../functions";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
	idType: Yup.string().required("El tipo de identificación es obligatorio"),
	identification: Yup.string()
		.required("La identificación es obligatoria")
		.matches(/^[0-9]*$/, "La identificación solo puede contener números"),
	name: Yup.string().required("El nombre es obligatorio"),
	phone: Yup.string()
		.required("El teléfono es obligatorio")
		.matches(/^[0-9]*$/, "El teléfono solo puede contener números"),
	phone2: Yup.string().matches(
		/^[0-9]*$/,
		"El teléfono secundario solo puede contener números"
	),
	email: Yup.string()
		.email("Ingrese una dirección de correo electrónico válida")
		.required("El correo electrónico es obligatorio"),
	image: Yup.mixed().notRequired(),
	/* password: Yup.string().required("La contraseña es obligatoria"),
	password2: Yup.string()
		.oneOf([Yup.ref("password"), null], "Las contraseñas no coinciden")
		.required("Debe confirmar la contraseña"), */
	address: Yup.string().required("La dirección es obligatoria"),
});
const validationSchemaLicense = Yup.object().shape({
	token: Yup.string().required("Ingrese la licencia recibida"),
});

const FormularioAdmin = () => {
	const { getToken, key, inputStyles, customStyles } = useContext(AuthContext);
	const [typesId, setTypesId] = useState([]);
	const [estabs, setEstabs] = useState([]);
	const [idEstab, setIdEstab] = useState(null);
	const navigate = useNavigate();
	const btnCancel = useRef(null);
	const [btnCarga, setBtnCarga] = useState(0);
	const [institucion, setInstitucion] = useState(false)
	const initialValues = {
		idType: "",
		identification: "",
		name: "",
		phone: "",
		phone2: "",
		email: "",
		image: null,
		password: "",
		password2: "",
		address: "",
		user: localStorage.getItem("idUser"),
	};

	const initialValuesLicense = {
		idEstablishment: "",
		clave: key,
		token: "",
		user: localStorage.getItem("idUser"),
	};

	const registrarEstab = async (json) => {
		const config = {
			headers: {
				//"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		return axios
			.post(route_establishments_save, json, config)
			.then((response) => {
				if (response.data.errorMessage != null) {
					show_alert(response.data.errorMessage, "error");
					return false;
				} else {
					Swal.fire({
						icon: "success",
						title: "Establecimiento creado exitosamente",
						showConfirmButton: true,
						timer: 1500,
					}).then(() => {
						setBtnCarga(0);
						getEstabs();
					});
					//show_alert("Usuario creado exitosamente", "success");
				}
			})
			.catch((error) => {
				console.error(error);
				throw error;
			});
	};

	const handleSubmit = (values) => {
		setBtnCarga(1);
		values.institucion = institucion;
		registrarEstab(values);
	};
	const handleSubmitLicense = async (values) => {
		setBtnCarga(1);
		values.idEstablishment = idEstab;
		values.user = localStorage.getItem("idUser");
		const config = {
			headers: {
				//"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		return axios
			.post(route_establishments_license, values, config)
			.then((response) => {
				if (response.data && response.data.status == true) {
					var text = "Licencia valida hasta: " + response.data.vencimiento;
					Swal.fire({
						icon: "success",
						title: "Licencia valida",
						text: text,
						showConfirmButton: true,
					}).then(() => {
						setBtnCarga(0);
						getEstabs();
						btnCancel.current.click();
					});
				} else {
					Swal.fire({
						icon: "warning",
						title: "Licencia Invalida",
						text: "Comuniquese con soporte",
						showConfirmButton: true,
					}).then(() => {
						setBtnCarga(0);
						getEstabs();
					});
					//show_alert("Usuario creado exitosamente", "success");
				}
			})
			.catch((error) => {
				Swal.fire({
					icon: "warning",
					title: "Licencia Invalida",
					text: "Comuniquese con soporte",
					showConfirmButton: true,
				});
				setBtnCarga(0);
				console.error(error);
				throw error;
			});
	};

	const getEstabs = async () => {
		axios
			.get(route_establishments_getAll, {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			})
			.then((response) => {
				setEstabs(response.data);
			})
			.catch((error) => {
			});
	};
	useEffect(() => {
		getEstabs();
		axios
			.get(route_typeid_list, {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			})
			.then((response) => {
				setTypesId(response.data);
			})
			.catch((error) => {
			});
	}, []);

	return (
		<div className="containerFormUser">
			<h1>Nuevo Establecimiento</h1>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, isSubmitting }) => (
					<Form>
						<div className="row">
							<div className="col-md-12">
								<div className="form-floating mb-2">
									<Field
										style={inputStyles}
										type="text"
										name="name"
										id="name"
										className="form-control"
										placeholder="Nombre"
									/>
									<label htmlFor="name" className="form-label">
										Nombre
									</label>
									{errors.name && touched.name && (
										<ErrorMessage
											name="name"
											component="div"
											className="error"
										/>
									)}
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-floating mb-2">
									<Field
										style={customStyles}
										as="select"
										id="idType"
										name="idType"
										className="form-select"
										aria-label="Floating label select example"
									>
										<option selected>Selecciona </option>
										{typesId.map((rol) => (
											<option key={rol.id} value={rol.id}>
												{rol.name}
											</option>
										))}
									</Field>
									<label htmlFor="idType" className="form-label">
										Tipo de identificacion
									</label>
									{errors.idType && touched.idType && (
										<ErrorMessage
											name="idType"
											component="div"
											className="error"
										/>
									)}
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-floating mb-2">
									<Field
										style={inputStyles}
										type="text"
										name="identification"
										id="identification"
										className="form-control"
										placeholder="Numero de identificacion"
									/>
									<label htmlFor="name" className="form-label">
										Numero de identificacion
									</label>
									{errors.identification && touched.identification && (
										<ErrorMessage
											name="identification"
											component="div"
											className="error"
										/>
									)}
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-floating mb-2">
									<Field
										style={inputStyles}
										type="text"
										name="phone"
										id="phone"
										className="form-control"
										placeholder="Numero de telefono"
									/>
									<label htmlFor="name" className="form-label">
										Numero de telefono
									</label>
									{errors.phone && touched.phone && (
										<ErrorMessage
											name="phone"
											component="div"
											className="error"
										/>
									)}
								</div>
							</div>
							<div className="col-md-3">
								<div className="form-floating mb-2">
									<Field
										style={inputStyles}
										type="text"
										name="phone2"
										id="phone2"
										className="form-control"
										placeholder="Numero de telefono secundario"
									/>
									<label htmlFor="phone2" className="form-label">
										Numero de telefono opcional
									</label>
									{errors.phone2 && touched.phone2 && (
										<ErrorMessage
											name="phone2"
											component="div"
											className="error"
										/>
									)}
								</div>
							</div>
							<div className="col-md-3">
								<div className="form-floating mb-2">
									<Field
										style={inputStyles}
										type="text"
										name="address"
										id="address"
										className="form-control"
										placeholder="Dirección"
									/>
									<label htmlFor="address" className="form-label">
										Dirección
									</label>
									{errors.address && touched.address && (
										<ErrorMessage
											name="address"
											component="div"
											className="error"
										/>
									)}
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-floating mb-2">
									<Field
										style={inputStyles}
										type="email"
										name="email"
										id="email"
										className="form-control"
										placeholder="Email"
									/>
									<label htmlFor="email" className="form-label">
										Email
									</label>
									{errors.email && touched.email && (
										<ErrorMessage
											name="email"
											component="div"
											className="error"
										/>
									)}
								</div>
							</div>
							<div className="col-md-6">
								<div>
									<Field
										style={inputStyles}
										type="file"
										name="image"
										id="image"
										className="form-control form-control-lg"
									/>
								</div>
							</div>
							
							<div className="col-md-6">
								<div class="input-group  mb-3">
									<div class="input-group-text">
										<div className="form-check form-switch">
											<input
												className="form-check-input"
												type="checkbox"
												role="switch"
												//name={module.id}
												//id={module.id}
												onChange={(event) => {setInstitucion(event.target.checked) }}
											></input>
										</div>
									</div>
									<label
										type="text"
										class="form-control"
										aria-label="Text input with checkbox"
									>
										Institución Educativa
									</label>
								</div>
							</div>
							{/* <div className="col-md-6">
								<div className="form-floating mb-2">
									<Field
										type="password"
										name="password"
										id="password"
										className="form-control"
										placeholder="Password"
									/>
									<label htmlFor="password" className="form-label">
										Password
									</label>
									{errors.password && touched.password && (
										<ErrorMessage
											name="password"
											component="div"
											className="error"
										/>
									)}
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-floating mb-2">
									<Field
										type="password"
										name="password2"
										id="password2"
										className="form-control"
										placeholder="Password"
									/>
									<label htmlFor="password" className="form-label">
										Confirme su Password
									</label>
									{errors.password2 && touched.password2 && (
										<ErrorMessage
											name="password2"
											component="div"
											className="error"
										/>
									)}
								</div>
							</div> */}
						</div>
						<>
							{btnCarga === 0 ? (
								<button type="submit" className="btn btn-primary">
									Guardar
								</button>
							) : (
								/* spinner carga */
								<button className="btn btn-primary" type="button" disabled>
									<span
										className="spinner-border spinner-border-sm"
										aria-hidden="true"
									></span>
									<span role="status"> Guardando...</span>
								</button>
								/*--------------- */
							)}
							&nbsp;
							<button type="reset" className="btn btn-danger">
								Cancelar
							</button>
						</>
					</Form>
				)}
			</Formik>

			{/* tabla de establecimientos */}
			<table id="tableUsers" className="table table-striped table-hover">
				<thead>
					<tr>
						<th style={{ textAlign: "center" }}>Identificación</th>
						<th style={{ textAlign: "center" }}>Nombre</th>
						<th>opciones</th>
					</tr>
				</thead>
				<tbody>
					{estabs &&
						estabs.map((rol) => (
							<tr key={rol.id}>
								<td style={{ textAlign: "left" }}>{rol.identification}</td>
								<td style={{ textAlign: "left" }}>{rol.name}</td>
								<td>
									<button
										type="button"
										class="btn btn-primary"
										data-bs-toggle="modal"
										data-bs-target="#exampleModal"
										onClick={() => setIdEstab(rol.id)}
									>
										Actualizar licencia
									</button>
								</td>
							</tr>
						))}
				</tbody>
			</table>
			{/* modal actializar licencia */}
			<div
				class="modal fade"
				id="exampleModal"
				tabindex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<h1 class="modal-title fs-5" id="exampleModalLabel">
								Autorizar
							</h1>
							<button
								type="button"
								class="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div class="modal-body">
							{/* formulario licenciamiento */}
							<Formik
								initialValues={initialValuesLicense}
								validationSchema={validationSchemaLicense}
								onSubmit={handleSubmitLicense}
							>
								{({ errors, touched, isSubmitting }) => (
									<Form>
										<div className="row">
											<div className="col-md-12">
												<div className="form-floating mb-2">
													<Field
														style={{ height: "200px" }}
														as="textarea"
														name="token"
														id="token"
														className="form-control"
														placeholder="Licencia"
													/>
													<label htmlFor="name" className="form-label">
														Licencia
													</label>
													{errors.token && touched.token && (
														<ErrorMessage
															name="token"
															component="div"
															className="error"
														/>
													)}
												</div>
											</div>
										</div>

										<div class="modal-footer">
											{btnCarga === 0 ? (
												<button type="submit" className="btn btn-primary">
													Guardar
												</button>
											) : (
												/* spinner carga */
												<button
													className="btn btn-primary"
													type="button"
													disabled
												>
													<span
														className="spinner-border spinner-border-sm"
														aria-hidden="true"
													></span>
													<span role="status"> Guardando...</span>
												</button>
												/*--------------- */
											)}
											&nbsp;
											<button
												type="reset"
												className="btn btn-danger"
												data-bs-dismiss="modal"
												ref={btnCancel}
											>
												Cancelar
											</button>
										</div>
									</Form>
								)}
							</Formik>
							{/* ------------------------- */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FormularioAdmin;
