import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";

export default function Register() {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirige a la ruta de "intro-persons" al montar el componente
    navigate("/menu/hefestoPersons/intro-persons");
  }, []);

  return (
    <div id="space">
      <div className="container1 container-fluid">
        <div className="menu">
          <div className="divMenuLog">
            <ul>
              {/* Envolvemos el li con un NavLink */}
              <NavLink className="navInfo" to="register-person">
                <li className="navInfoAdmin">
                  <i className="fas fa-user-plus"></i> <span> Registro</span>
                </li>
              </NavLink>

              <NavLink className="navInfo" to="search-person">
                <li className="navInfoAdmin">
                  <i className="fas fa-search"></i> <span> Búsqueda</span>
                </li>
              </NavLink>
            </ul>
          </div>
        </div>
        <div className="workspace">
          {/* Renderiza el contenido dinámico de acuerdo a la ruta */}
          <Outlet />
        </div>
      </div>
    </div>
  );
}
