import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/LogContext";
import axios from "axios";
import Select from "react-select";
import {
	route_person_searchPerson,
	route_persons_registerPersons,
} from "../../../api-routes/RoutesLogin";
import { MdContentPasteSearch } from "react-icons/md";

import TablePersons from "../../componentsBase/tables/TableBase1";

export default function InformacionUsuarios() {
	const [optionsPersons, setOptionsPersons] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [terceroSelect, setTerceroSelect] = useState(0);
	const [estadoSelect, setEstadoSelect] = useState(true);
	const { establishment, getToken,FechaYMDHMS,FechaYMD } = useContext(AuthContext);
	const [dataPersons, setDataPersons] = useState([]);
	const onInputChange = (inputValue) => {
		if (typeof inputValue === "string" && inputValue != "") {
			loadPersons(inputValue);
		}
	};
	const loadPersons = async (inputValue) => {
		if (
			typeof inputValue === "string" &&
			inputValue != "" &&
			inputValue.length >= 3
		) {
			setIsLoading(true);
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};
			try {
				const response = await axios.get(
					route_person_searchPerson + establishment.id+","+ inputValue + ",true",
					config
				);
				const data = response.data;
				const formattedOptions = data.map((item) => ({
					value: item.id,
					label:
						item.identification +
						" - " +
						item.name1 +
						" " +
						item.name2 +
						" " +
						item.lastName1 +
						" " +
						item.lastName2 +
						" - " +
						item.businessName,
				}));

				setOptionsPersons(formattedOptions);
			} catch (error) {
				console.error("Error while fetching options:", error);
			} finally {
				setIsLoading(false);
			}
		}
	};

	const columns = [
		{ Header: "Tipo id", accessor: "tipoIdentificacion" },
		{ Header: "Fecha Expedición", accessor: "fechaExpedicion" },
		{ Header: "País expediciòn", accessor: "nombrePaisExpedicion" },
		{ Header: "Depto expediciòn", accessor: "nombreDepartamentoExpedicion" },
		{ Header: "Mupio expediciòn ", accessor: "nombreMunicipioExpedicion" },
		{ Header: "No. Identificación", accessor: "identification" },
		{ Header: "Sigla", accessor: "sigla" },
		{ Header: "DV", accessor: "dv" },
		{ Header: "Tipo persona", accessor: "tipoPersona" },
		{ Header: "Nombre", accessor: "nombreCompleto" },
		{ Header: "Razón social", accessor: "businessName" },
		{ Header: "Dirección", accessor: "address" },
		{ Header: "Pais ubicación", accessor: "nombrePaisUbicacion" },
		{ Header: "Depto ubicación", accessor: "nombreDepartamentoUbicacion" },
		{ Header: "Mupio ubicación", accessor: "nombreMunicipioUbicacion" },
		{ Header: "Código postal", accessor: "codPostal" },
		{ Header: "Teléfono 1", accessor: "phone1" },
		{ Header: "Teléfono 2", accessor: "phone2" },
		{ Header: "Email", accessor: "email1" },
		{ Header: "Nombre comercial", accessor: "nombreComercial" },
		{ Header: "Actividad comercial 1", accessor: "nombreActividad1" },
		{ Header: "Fecha inicio", accessor: "fechaIniAct1" },
		{ Header: "Actividad comercial 2", accessor: "nombreActividad2" },
		{ Header: "Fecha inicio", accessor: "fechaIniAct2" },
		{ Header: "Actividad comercial 3", accessor: "nombreActividad3" },
		{ Header: "Actividad comercial 4", accessor: "nombreActividad4" },
		{ Header: "Ocupación", accessor: "nombreOcupacion" },

		// ...otras columnas
	];
	const visualizarInforme = async () => {
	
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_persons_registerPersons}/reporteUsuarios1/${establishment.id},${estadoSelect},${terceroSelect}`,
			config
		);

		setDataPersons(result.data);
	};
	return (
		<>
			<div className="containerFormUser">
				<h1>Informe de usuarios</h1>

				{/* seccion de filtros */}
				<div className="row">
					<div className="col-md-6">
						<Select
							isSearchable="true"
							placeholder="Búsqueda de usuarios "
							isClearable="true"
							isLoading={isLoading}
							onInputChange={onInputChange}
							options={optionsPersons}
							onChange={(e) => {
								setTerceroSelect(e ? e.value : 0);
							}}
						></Select>
					</div>
					<div className="col-md-4">
						<Select
							isSearchable={true}
							placeholder="Búsqueda de usuarios"
							isClearable={false}
							isLoading={isLoading}
							onChange={(e) => {
								setEstadoSelect(e ? e.value : null);
							}}
							options={[
								{ value: true, label: "Activos" },
								{ value: false, label: "Eliminados" },
							]}
							defaultValue={{ value: true, label: "Activos" }}
						/>
					</div>
					<div className="col-md-2">
						<button
							className="btn btn-primary"
							title="Visualizar Informe"
							onClick={visualizarInforme}
						>
							<MdContentPasteSearch size={20} />
						</button>
					</div>
				</div>
				<div className="table-container-reacTable">
					<TablePersons
						data={dataPersons}
						columns={columns}
						encabezado={{
							empresa: establishment.name,
							nit: establishment.identification,
							titulo: "Usuarios",
							imagen: establishment.image,
							fechas: "Informacion actualizada hasta: "+FechaYMD,
							generado: "Informe generado: "+FechaYMDHMS

						}}
					/>

				</div>
			</div>
		</>
	);
}
