import React, { useState, useEffect, useContext, useRef } from "react";
import { AuthContext } from "../../context/LogContext";
import axios from "axios";
import {
	route_gym_suscripciones,
	route_person_searchPerson,
	route_configuration_base,
} from "../../../api-routes/RoutesLogin";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import { GiReceiveMoney } from "react-icons/gi";
import { FaSearchDollar } from "react-icons/fa";
import { Formik, Form, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import "moment/locale/es";
import { NumericFormat } from "react-number-format";
import ComprobantePagoPDF from '../pdfs/ComprobantePagoPDF';


const SearchPersons = (props) => {
	const { getToken, establishment } = useContext(AuthContext);
	const [optionsPersons, setOptionsPersons] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [suscripciones, setSuscripciones] = useState([]);
	const [suscripcion, setSuscripcion] = useState(null);
	const [usuario, setUsuario] = useState(null);
	const [idFormaPago, setIdFormaPago] = useState(null);
	const [valorPago, setValorPago] = useState(null);
	const btnCancel = useRef(null);
	const [vrMax, setVrMax] = useState(0);
	const { suscripcionId } = useParams();
	const [listPagos, setListaPagos] = useState([]);
	const [totalPlan, setTotalPlan] = useState(0);
	const [totalPagos, setTotalPagos] = useState(0);
	const [totalSaldo, setTotalSaldo] = useState(0);
	const [listFormasPago, setListFormasPago] = useState([]);
	const [pagoSelected, setPagoSelected] = useState({})
	const [suscripcionSelected, setSuscripcionSelected]=useState({});


	const getSuscripciones = async (idT) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		try {
			const result = await axios.get(
				`${route_gym_suscripciones}/getByUser/${idT}`,
				config
			);
			setSuscripciones(result.data);
			setTotalPlan(
				result.data.reduce((total, sus) => total + sus.valorPlan, 0)
			);
			setTotalPagos(
				result.data.reduce((total, sus) => total + sus.valorPago, 0)
			);
			setTotalSaldo(result.data.reduce((total, sus) => total + sus.saldo, 0));
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getFormasPago();
		if (suscripcionId) {
			getSuscripciones(suscripcionId);
		}
	}, []);

	/* Busqueda de terceros  */
	const onInputChange = (inputValue) => {
		if (typeof inputValue === "string" && inputValue != "" && inputValue.length >= 3) {
			loadPersons(inputValue);
		}
	};

	const loadPersons = async (inputValue) => {
		setIsLoading(true);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		try {
			const response = await axios.get(
				route_person_searchPerson + establishment.id+","+ inputValue + ",true",
				config
			);
			const data = response.data;
			const formattedOptions = data.map((item) => ({
				value: item.id,
				label:
					item.identification +
					" - " +
					item.name1 +
					" " +
					item.name2 +
					" " +
					item.lastName1 +
					" " +
					item.lastName2 +
					" - " +
					item.businessName,
			}));

			setOptionsPersons(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const filterChange = (filterValue) => {
		if (filterValue) {
			setOptionsPersons([]);
			getSuscripciones(filterValue);
			setUsuario(filterValue);
		} else {
			setOptionsPersons([]);
		}
	};

	const handleAnular = async (id) => {
		const { value: text } = await Swal.fire({
			title: "Motivo de la anulación",
			input: "textarea",
			inputPlaceholder: "Justificación",
			inputAttributes: {
				"aria-label": "Justificación",
			},
			confirmButtonText: "Confirmar",
			cancelButtonText: "Cancelar",
			showCancelButton: true,
			cancelButtonColor: "#d9534f",
			inputValidator: (value) => {
				return !value && "Debes ingresar un motivo de anulación";
			},
		});

		if (text) {
			anularPago(id, text);
		}
	};

	const anularPago = async (id, motivo) => {
		let values = {
			id: id,
			motivoAnula: motivo,
			usuAnula: localStorage.getItem("idUser"),
		};
		let dataJson = JSON.stringify(values);
		try {
			const response = await axios.post(
				route_gym_suscripciones + "/anularpagos",
				dataJson,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			Swal.fire({
				icon: "success",
				title: "El pago se ha eliminado",
				text: "Este proceso recalcula el saldo y los pagos realizados",
				showConfirmButton: true,
			}).then(() => {
				getSuscripciones(usuario);
			});
			return response;
		} catch (error) {
			throw error;
		}
	};

	const handleSubmit = (values) => {
	
		savePago(values);
	};

	const savePago = async (json) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			const response = await axios.post(
				route_gym_suscripciones + "/savepagos",
				json,
				config
			);
			const successMessage = "Pago ejecutado exitosamente";

			Swal.fire({
				icon: "success",
				title: successMessage,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
			
				getSuscripciones(usuario);
				btnCancel.current.click();
			});
		} catch (error) {
			if (error.response && error.response.status === 403) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Verifique registros",
					showConfirmButton: true,
				}).then(() => {});
			} else {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error de red, contactese con su proveedor",
					showConfirmButton: true,
				}).then(() => {});
			}
		}
	};

	const customStyles = {
		control: (base) => ({
			...base,
			justifyContent: "Left",
			height: 60,
			minHeight: 35,
		}),
		option: (base) => ({
			...base,
			border: `1px dotted blue`,
		}),
	};

	const validationSchema = Yup.object().shape({
		valor: Yup.number()
			.required("Campo requerido")
			.typeError("Debe ser un número")
			.test(
				"valorMaximo",
				"El valor no puede ser mayor al saldo y mayor a cero",
				function(value) {
					return value > 0 && value <= vrMax;
				}
			),
		formaPago: Yup.number().required("Campo requerido"),
	});

	const initialValues = {
		usuario: localStorage.getItem("idUser"),
		suscripcion: "",
		valor: "",
		formaPago: "",
	};

	const formasPago = [
		{ id: 1, forma: "Efectivo" },
		{ id: 2, forma: "Transferencia" },
		{ id: 3, forma: "Tarjeta" },
	];

	const handleCancel = () => {
		setIdFormaPago([]);
		setValorPago("");
	};

	const handleSelectChange = (options) => {
		setIdFormaPago(options);
	};

	const getFormasPago = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_configuration_base}/getFormas/${establishment.id},true`,
			config
		);
		setListFormasPago(result.data);
	};

	return (
		<>
			<div className="containerFormUser">
				<h1>Gestión de pagos</h1>

				<Select
					isSearchable="true"
					placeholder="Búsqueda de terceros "
					isClearable="true"
					isLoading={isLoading}
					onInputChange={onInputChange}
					options={optionsPersons}
					onChange={(e) => {
						filterChange(e ? e.value : "");
					}}
				></Select>
				<br></br>
				<table
					style={{ width: "100%" }}
					id="tableUsers"
					className="table table-striped table-hover"
				>
					<thead>
						<tr>
							<th>Usuario</th>
							<th>Plan</th>
							<th>Periodo</th>
							<th>Estado</th>
							<th>Valor</th>
							<th>Pagos</th>
							<th>Saldo</th>
							<th style={{ width: "15%", textAlign: "right" }}>Opciones</th>
						</tr>
					</thead>
					<tbody>
						{suscripciones.map((sus) => {
							let estado, colorC, colorS;
							if (sus.estado === 1) {
								estado = "Activo";
								colorC = "green";
							} else if (sus.estado === 2) {
								estado = "Vencido";
								colorC = "red";
							} else if (sus.estado === 3) {
								estado = "Pausa";
								colorC = "blue";
							}

							if (sus.saldo == 0) {
								colorS = "green";
							} else {
								colorS = "red";
							}

							return (
								<tr key={sus.id}>
									<td>
										{sus.terceroObject.name1} {sus.terceroObject.name2}{" "}
										{sus.terceroObject.lastName1} {sus.terceroObject.lastName2}
									</td>
									<td>{sus.planObject.plan}</td>
									<td>
										{sus.fechaIni} / {sus.fechaFin}
									</td>
									<td style={{ color: colorC }}>{estado}</td>
									<td style={{ textAlign: "right" }}>
										$ {sus.valorPlan.toLocaleString(undefined)}
									</td>
									<td style={{ textAlign: "right" }}>
										$ {sus.valorPago.toLocaleString(undefined)}
									</td>
									<td style={{ textAlign: "right", color: colorS }}>
										$ {sus.saldo.toLocaleString(undefined)}
									</td>
									<td style={{ width: "20%", textAlign: "right" }}>
										{sus.saldo > 0 ? (
											<>
												<button
													className="btnSuccess"
													type="button"
													data-bs-toggle="modal"
													data-bs-target="#staticBackdrop2"
													title="Ejecutar pago"
													onClick={() => {
														handleCancel();
														setSuscripcion(sus.id);
														setVrMax(parseFloat(sus.saldo));
														setUsuario(sus.tercero);
													}}
												>
													<GiReceiveMoney size={25} />
												</button>
											</>
										) : (
											<></>
										)}
										&nbsp;&nbsp;
										<button
											className="btnInfo"
											type="button"
											title="Ver pagos"
											data-bs-toggle="modal"
											data-bs-target="#modalDetallePagos"
											onClick={() => {
												setSuscripcionSelected(sus)
												setListaPagos(sus.pagosxSuscripcions);
											}}
										>
											<FaSearchDollar size={25} />
										</button>
									</td>
								</tr>
							);
						})}
						<tr>
							<th colSpan={4}></th>
							<th style={{ textAlign: "right" }}>
								{totalPlan.toLocaleString(undefined)}
							</th>
							<th style={{ textAlign: "right" }}>
								{totalPagos.toLocaleString(undefined)}
							</th>
							<th style={{ textAlign: "right" }}>
								{totalSaldo.toLocaleString(undefined)}
							</th>
						</tr>
					</tbody>
				</table>
			</div>
			{/* Modal de pago   */}
			<div
				className="modal fade"
				id="staticBackdrop2"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabindex="-1"
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="staticBackdropLabel">
								Ejecutar pago
							</h1>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>

						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={handleSubmit}
						>
							{/* -----///////- */}

							{(formik, props) => (
								<>
									<Form>
										<div class="modal-body">
											<div className="row">
												{/* BLOQUE1 */}

												<div className="col-md-12 ">
													<div className="form-floating mb-2  ">
														<Select
															styles={customStyles}
															placeholder="Forma de pago"
															value={idFormaPago}
															onChange={(e) => {
																handleSelectChange();
																formik.setFieldValue(
																	"formaPago",
																	e ? e.value : ""
																);
																formik.setFieldValue(
																	"suscripcion",
																	suscripcion
																);
															}}
															options={listFormasPago.map((rol) => ({
																label: rol.forma,
																value: rol.id,
															}))}
															className="basic-multi-select"
															classNamePrefix="select"
														/>

														<ErrorMessage
															name="formaPago"
															component="div"
															className="error"
														/>
													</div>
												</div>

												<div className="col-md-12  ">
													<div className="form-floating mb-2  ">
														<NumericFormat
															value={valorPago}
															style={{ textAlign: "right" }}
															className="form-control"
															thousandSeparator="."
															decimalSeparator=","
															onChange={(e) => {
																setValorPago(
																	parseFloat(
																		e.currentTarget.value
																			.replace(/\./g, "")
																			.replace(/,/g, ".")
																	)
																);
																formik.setFieldValue(
																	"valor",
																	e
																		? parseFloat(
																				e.currentTarget.value
																					.replace(/\./g, "")
																					.replace(/,/g, ".")
																		  )
																		: ""
																);
															}}
														/>
														<label htmlFor="valoe" className="form-label">
															Valor
														</label>
														<ErrorMessage
															name="valor"
															component="div"
															className="error"
														/>
													</div>
												</div>
											</div>
										</div>
										<div class="modal-footer">
											<button
												ref={btnCancel}
												type="reset"
												onClick={() => {
													handleCancel();
												}}
												data-bs-dismiss="modal"
												className="btn btn-danger"
											>
												Cancelar
											</button>
											<button type="submit" class="btn btn-primary">
												Confirmar
											</button>
										</div>
									</Form>
								</>
							)}

							{/* ------------ */}
						</Formik>
					</div>
				</div>
			</div>
			{/* --------------- */}
			{/* Modal de detalle   */}
			<div
				className="modal fade"
				id="modalDetallePagos"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabindex="-1"
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div class="modal-dialog modal-md modal-dialog-scrollable">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="staticBackdropLabel">
								Detalle de pagos
							</h1>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div class="modal-body">
							<table style={{ width: "100%" }} className="table table-hover ">
								<thead>
									<tr>
										<th style={{ textAlign: "center" }}>Fecha</th>
										<th style={{ textAlign: "center" }}>Valor</th>
										<th style={{ width: "30%", textAlign: "right" }}>
											Opciones
										</th>
									</tr>
								</thead>
								<tbody>
									{listPagos.map((p) => {
										let colorP;
										if (p.active === false) {
											colorP = "red";
										}
										return (
											<tr style={{ color: colorP }} key={p.id}>
												<td> {p.fecha}</td>
												<td style={{ textAlign: "right" }}>
													$ {p.valor.toLocaleString(undefined)}
												</td>

												<td style={{ width: "20%", textAlign: "right" }}>
													<>
														<button
															className="btnDetail"
															type="button"
															data-bs-toggle="modal"
															data-bs-target="#modalPdf"
															onClick={() => {
																setPagoSelected(p);
															}}
														>
															<i class="fas fa-file-pdf"></i>
														</button>
													</>
													{p.active == true ? (
														<>
															&nbsp;&nbsp;
															<button
																className="btnDelete"
																type="button"
																title="Anular pago"
																data-bs-dismiss="modal"
																onClick={() => handleAnular(p.id)}
															>
																<i className="fas fa-trash-alt"></i>
															</button>{" "}
														</>
													) : (
														<></>
													)}
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
							{/* --------------------- */}
						</div>

						<div class="modal-footer">
							<button
								type="button"
								class="btn btn-danger"
								data-bs-dismiss="modal"
							>
								Cerrar
							</button>
						</div>
						{/* ------------ */}
					</div>
				</div>
			</div>
			{/* --------------- */}
			{/* Modal recibo   */}
			<div
				className="modal fade"
				id="modalPdf"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabindex="-1"
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div  class="modal-dialog modal-dialog-scrollable">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="staticBackdropLabel">
								Recibo de pago
							</h1>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>

						<div className="modal-body"><ComprobantePagoPDF style={{width:"100%"}} establishment={establishment} suscripcion={suscripcionSelected}  pago={pagoSelected} /></div>
					</div>
				</div>
			</div>
			{/* --------------- */}
		</>
	);
};

export default SearchPersons;
