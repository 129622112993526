import React, { useState, useContext, useEffect, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
	route_typeid_list,
	route_persons_typesPersons,
	route_persons_registerPersons,
	route_persons_getById,
	route_person_listPais,
	route_person_listDepto,
	route_person_listMupios,
	route_person_listCiiu,
	route_person_listCiuo,
} from "../../../api-routes/RoutesLogin";
import { AuthContext } from "../../context/LogContext";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import axios from "axios";
import * as Yup from "yup";

export default function RegisterPerson(props) {
	const navigate = useNavigate();
	const { establishment, inputStyles, customStyles, dateStyles } =
		useContext(AuthContext);
	const [typesId, setTypesId] = useState([]);
	const [typesPerson, setTypesPerson] = useState([]);
	const [personToEdit, setPersonToEdit] = useState(null);
	const [showForm, setshowForm] = useState(false);
	const [digitoVerificacion, setDigitoVerificacion] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const { getToken } = useContext(AuthContext);
	const { personId } = useParams();

	const [optionsPais, setOptionsPais] = useState([]);
	const [optionsPaisU, setOptionsPaisU] = useState([]);
	const [paisSelected, setPaisSelected] = useState(null);
	const [paisSelectedU, setPaisSelectedU] = useState(null);

	const [optionsDepto, setOptionsDepto] = useState([]);
	const [optionsDeptoU, setOptionsDeptoU] = useState([]);
	const [deptoSelected, setDeptoSelected] = useState(null);
	const [deptoSelectedU, setDeptoSelectedU] = useState(null);
	const selectDpto = useRef(null);
	const selectDptoU = useRef(null);

	const [optionsMupio, setOptionsMupio] = useState([]);
	const [optionsMupioU, setOptionsMupioU] = useState([]);
	const selectMupio = useRef(null);
	const selectMupioU = useRef(null);

	const [optionsCiiu, setOptionsCiiu] = useState([]);
	const [optionsCiuo, setOptionsCiuo] = useState([]);
	const dvRef = useRef(null);
	const clearForm = useRef(null);
	const [btnCarga, setBtnCarga] = useState(0);
	const [banModal, setBanModal] = useState(0);

	const [typeP, setTypeP] = useState(1);

	useEffect(() => {
		props && props.ban ? setBanModal(1) : setBanModal(0);
		axios
			.get(route_typeid_list, {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			})
			.then((response) => {
				setTypesId(response.data);
			})
			.catch((error) => {
	
			});

		axios
			.get(route_persons_typesPersons, {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			})
			.then((response) => {
				setTypesPerson(response.data);
			})
			.catch((error) => {
		
			});

		if (personId) {
			setBtnCarga(1);
			axios
				.get(route_persons_getById + personId, {
					headers: {
						Authorization: `Bearer ${getToken()}`,
					},
				})
				.then((response) => {
					setPersonToEdit(response.data);
					setDigitoVerificacion(response.data.dv);
					setPaisSelected(response.data.paisEx);
					setPaisSelectedU(response.data.pais);
					setDeptoSelected(response.data.depto);
					setDeptoSelectedU(response.data.dptoEx);
					setshowForm(true);
					setBtnCarga(0);
				})
				.catch((error) => {
			
				});
		} else {
			setshowForm(true);
		}
	}, []);

	const handleChange = (event, formik) => {
		const numeroIdentificacion = event.target.value;
		const isNitValid = /^\d+$/.test(numeroIdentificacion);

		// Si es un número se calcula el Dígito de Verificación
		if (isNitValid) {
			const digito = calcularDigitoVerificacion1(numeroIdentificacion);
			setDigitoVerificacion(digito.toString());
			formik.setFieldValue("dv", digito.toString());
		}
		formik.handleChange(event);
	};

	function calcularDigitoVerificacion1(myNit) {
		var vpri, x, y, z;

		// Se limpia el Nit
		myNit = myNit.replace(/\s/g, ""); // Espacios
		myNit = myNit.replace(/,/g, ""); // Comas
		myNit = myNit.replace(/\./g, ""); // Puntos
		myNit = myNit.replace(/-/g, ""); // Guiones

		// Se valida el nit
		if (isNaN(myNit)) {
			return "";
		}

		// Procedimiento
		vpri = new Array(16);
		z = myNit.length;

		vpri[1] = 3;
		vpri[2] = 7;
		vpri[3] = 13;
		vpri[4] = 17;
		vpri[5] = 19;
		vpri[6] = 23;
		vpri[7] = 29;
		vpri[8] = 37;
		vpri[9] = 41;
		vpri[10] = 43;
		vpri[11] = 47;
		vpri[12] = 53;
		vpri[13] = 59;
		vpri[14] = 67;
		vpri[15] = 71;

		x = 0;
		y = 0;
		for (var i = 0; i < z; i++) {
			y = myNit.substr(i, 1);
			x += y * vpri[z - i];
		}

		y = x % 11;

		return y > 1 ? 11 - y : y;
	}
	/*------ Busqueda de paises de expedicion//// */
	const onInputChange = (inputValue) => {
		if (typeof inputValue === "string" && inputValue != "") {
			loadOptionsPais(inputValue, 1);
		}
	};
	/* Busiqeda de paises de ubicacion */
	const onInputChangePU = (inputValue) => {
		if (typeof inputValue === "string" && inputValue != "") {
			loadOptionsPais(inputValue, 2);
		}
	};
	const loadOptionsPais = async (inputValue, tipo) => {
		setIsLoading(true);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		try {
			const response = await axios.get(
				route_person_listPais + inputValue,
				config
			);
			const data = response.data;
			const formattedOptions = data.map((item) => ({
				value: item.id,
				label: item.descripcion,
			}));
			if (tipo == 1) {
				setOptionsPais(formattedOptions);
			} else {
				setOptionsPaisU(formattedOptions);
			}
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
			setIsLoading(false);
		}
	};
	/* Busqueda de deptos de expedicion --------- */
	const onInputDeptoChange = (inputValue) => {
		if (
			typeof inputValue === "string" &&
			inputValue != "" &&
			paisSelected != null
		) {
			loadOptionsDepto(inputValue, 1);
		}
	};
	const onInputDeptoUChange = (inputValue) => {
	
		if (
			typeof inputValue === "string" &&
			inputValue != "" &&
			paisSelectedU != null
		) {
			loadOptionsDepto(inputValue, 2);
		}
	};
	const loadOptionsDepto = async (inputValue, tipo) => {
		setIsLoading(true);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		try {
			if (tipo == 1) {
				var pais = paisSelected;
			} else {
				var pais = paisSelectedU;
			}
	
			const response = await axios.get(
				route_person_listDepto + pais + "," + inputValue,
				config
			);
			const data = response.data;
			const formattedOptions = data.map((item) => ({
				value: item.id,
				label: item.descripcion,
			}));
			if (tipo == 1) {
				setOptionsDepto(formattedOptions);
			} else {
				setOptionsDeptoU(formattedOptions);
			}
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
			setIsLoading(false);
		}
	};
	/* Busqueda mupios de expedicion ------------*/
	const onInputMupioChange = (inputValue) => {
		if (
			typeof inputValue === "string" &&
			inputValue != "" &&
			deptoSelected != null
		) {
			loadOptionsMupio(inputValue, 1);
		}
	};
	const onInputMupioChangeU = (inputValue) => {
		if (
			typeof inputValue === "string" &&
			inputValue != "" &&
			deptoSelectedU != null
		) {
			loadOptionsMupio(inputValue, 2);
		}
	};
	const loadOptionsMupio = async (inputValue, tipo) => {
		setIsLoading(true);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		try {
			if (tipo == 1) {
				var depto = deptoSelected;
			} else {
				var depto = deptoSelectedU;
			}
			const response = await axios.get(
				route_person_listMupios + depto + "," + inputValue,
				config
			);
			const data = response.data;
			const formattedOptions = data.map((item) => ({
				value: item.id,
				label: item.descripcion,
			}));
			if (tipo == 1) {
				setOptionsMupio(formattedOptions);
			} else {
				setOptionsMupioU(formattedOptions);
			}
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
			setIsLoading(false);
		}
	};
	/* Busqueda Ciiu  */
	const onInputCiiuChange = (inputValue) => {
		if (typeof inputValue === "string" && inputValue != "") {
			loadCiiu(inputValue);
		}
	};
	const loadCiiu = async (inputValue) => {
		setIsLoading(true);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		try {
			const response = await axios.get(
				route_person_listCiiu + inputValue,
				config
			);
			const data = response.data;
			const formattedOptions = data.map((item) => ({
				value: item.id,
				label: item.codigo + " - " + item.descripcion,
			}));
			setOptionsCiiu(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
			setIsLoading(false);
		}
	};
	/* Busqueda Ciiu  */
	const onInputCiuoChange = (inputValue) => {
		if (typeof inputValue === "string" && inputValue != "") {
			loadCiuo(inputValue);
		}
	};
	const loadCiuo = async (inputValue) => {
		setIsLoading(true);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		try {
			const response = await axios.get(
				route_person_listCiuo + inputValue,
				config
			);
			const data = response.data;
			const formattedOptions = data.map((item) => ({
				value: item.id,
				label: item.codigo + " - " + item.descripcion,
			}));
			setOptionsCiuo(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
			setIsLoading(false);
		}
	};
	/* --------------- */
	const registerPerson = async (json) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			const response = await axios.post(
				route_persons_registerPersons,
				json,
				config
			);
			const successMessage = personToEdit
				? "Persona actualizada exitosamente"
				: "Persona creada exitosamente";
			Swal.fire({
				icon: "success",
				title: successMessage,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				setBtnCarga(0);
				if (banModal == 0) {
					navigate("/menu/hefestoPersons/search-person");
				} else {
					if (clearForm.current) {
						clearForm.current.click();
					}
					if (props.closeModal.current) {
						props.closeModal.current.click();
					}
					
				}
			});
		} catch (error) {
			if (error.response && error.response.status === 403) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Verifique que el número de identificación no se encuentre registrado",
					showConfirmButton: true,
				}).then(() => {
					setBtnCarga(0);
				});
			} else {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error de red, contactese con su proveedor",
					showConfirmButton: true,
				}).then(() => {
					setBtnCarga(0);
				});
			}
		}
	};

	const onSubmit = (values) => {
		setBtnCarga(1);
		registerPerson(values);
	};
	/* valores para formik */
	const initialValues = {
		id: personToEdit ? personToEdit.id : null,
		identification: personToEdit ? personToEdit.identification : "",
		typeId: personToEdit ? personToEdit.typeId : "",
		typePer: personToEdit ? personToEdit.typePer : "",
		fechaExpedicion: personToEdit ? personToEdit.fechaExpedicionFormateada : "",
		name1: personToEdit ? personToEdit.name1 : "",
		name2: personToEdit ? personToEdit.name2 : "",
		lastName1: personToEdit ? personToEdit.lastName1 : "",
		lastName2: personToEdit ? personToEdit.lastName2 : "",
		businessName: personToEdit ? personToEdit.businessName : "",
		dv: personToEdit ? personToEdit.dv : "",
		paisEx: personToEdit ? personToEdit.paisEx : "",
		dptoEx: personToEdit ? personToEdit.dptoEx : "",
		mupioEx: personToEdit ? personToEdit.mupioEx : "",
		nombreComercial: personToEdit ? personToEdit.nombreComercial : "",
		sigla: personToEdit ? personToEdit.sigla : "",
		pais: personToEdit ? personToEdit.pais : "",
		depto: personToEdit ? personToEdit.depto : "",
		mupio: personToEdit ? personToEdit.mupio : "",
		email1: personToEdit ? personToEdit.email1 : "",
		codPostal: personToEdit ? personToEdit.codPostal : "",
		address: personToEdit ? personToEdit.address : "",
		phone1: personToEdit ? personToEdit.phone1 : "",
		phone2: personToEdit ? personToEdit.phone2 : "",
		codAct1: personToEdit ? personToEdit.codAct1 : "",
		fechaIniAct1: personToEdit ? personToEdit.fechaIniAct1Formatted : "",
		codAct2: personToEdit ? personToEdit.codAct2 : "",
		fechaIniAct2: personToEdit ? personToEdit.fechaIniAct2Formatted : "",
		email2: personToEdit ? personToEdit.email2 : "",
		codAct3: personToEdit ? personToEdit.codAct3 : "",
		codAct4: personToEdit ? personToEdit.codAct4 : "",
		ocupacion: personToEdit ? personToEdit.ocupacion : "",
		numEstabs: personToEdit ? personToEdit.numEstabs : "",
		idEstablishment: establishment.id,
		active: true,
		user: parseInt(localStorage.getItem("idUser")),
	};
	const validationSchema = Yup.object().shape({
		typeId: Yup.string().required("Este campo es requerido"),
		identification: Yup.string().required("Este campo es requerido"),
		typePer: Yup.string().required("Este campo es requerido"),
		name1: Yup.string().test(
			"required-with-typePer",
			"Este campo es requerido",
			function (value) {
				const { typePer } = this.parent;
				if (typePer === "1") {
					return !!value;
				}
				return true;
			}
		),
		name2: Yup.string(),
		lastName1: Yup.string().test(
			"required-with-typePer",
			"Este campo es requerido",
			function (value) {
				const { typePer } = this.parent;
				if (typePer === "1") {
					return !!value;
				}
				return true;
			}
		),
		lastName2: Yup.string(),
		businessName: Yup.string().test(
			"required-with-typePer",
			"Este campo es requerido",
			function (value) {
				const { typePer } = this.parent;
				if (typePer !== "1") {
					return !!value;
				}
				return true;
			}
		),
		address: Yup.string().required("Este campo es requerido"),
		phone1: Yup.string()
			.required("Este campo es requerido")
			.matches(/^[0-9]+$/, "Solo se permiten números"),
		phone2: Yup.string().matches(/^[0-9]+$/, "Solo se permiten números"),
		email1: Yup.string()
			.email("El correo electrónico es inválido")
			.required("Este campo es requerido"),
		pais: Yup.string().required("Este campo es requerido"),
		depto: Yup.string().required("Este campo es requerido"),
		mupio: Yup.string().required("Este campo es requerido"),
	});

	return (
		<>
			<div className="containerFormUser">
				<h1>Registro terceros</h1>

				{showForm && (
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={onSubmit}
					>
						{(formik, props) => (
							<Form>
								<div class="accordion" id="accordionExample">
									<div class="accordion-item">
										<h2 class="accordion-header">
											<button
												class="accordion-button"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapseOne"
												aria-expanded="true"
												aria-controls="collapseOne"
											>
												IDENTIFICACIÓN
											</button>
										</h2>
										<div
											id="collapseOne"
											class="accordion-collapse collapse show"
											data-bs-parent="#accordionExample"
										>
											{/* IDENTIFICACION */}
											<div class="accordion-body">
												<div className="row  ">
													{/* BLOQUE 1 */}
													<div className="col-md-3  ">
														<div>
															<Select
																styles={customStyles}
																defaultValue={
																	personToEdit
																		? {
																				label: personToEdit.typePerson.name,
																				value: personToEdit.typePer,
																		  }
																		: ""
																}
																isSearchable="true"
																placeholder="Tipo de persona"
																isClearable="true"
																options={typesPerson.map((rol) => ({
																	label: rol.name,
																	value: rol.id,
																}))}
																onChange={(e) => {
																	formik.setFieldValue(
																		"typePer",
																		e ? e.value : ""
																	);
																}}
															></Select>
														</div>
														<ErrorMessage
															name="typePer"
															component="div"
															className="error"
														/>
													</div>
													<div className="col-md-3 ">
														<div>
															<Select
																styles={customStyles}
																defaultValue={
																	personToEdit
																		? {
																				label:
																					personToEdit.identificationType.name,
																				value: personToEdit.typeId,
																		  }
																		: ""
																}
																isSearchable="true"
																placeholder="Tipo de identificación"
																isClearable="true"
																options={typesId.map((rol) => ({
																	label: rol.name,
																	value: rol.id,
																}))}
																onChange={(e) => {
																	formik.setFieldValue(
																		"typeId",
																		e ? e.value : ""
																	);
																}}
															></Select>
														</div>
														<ErrorMessage
															name="typeId"
															component="div"
															className="error"
														/>
													</div>
													<div className="col-md-5  ">
														<div className="form-floating mb-2  ">
															<Field
																style={inputStyles}
																type="text"
																name="identification"
																id="identification"
																className="form-control"
																placeholder="Numero de identificacion"
																onBlur={(event) => handleChange(event, formik)}
															/>
															<label
																style={{ zIndex: "0" }}
																htmlFor="name"
																className="form-label"
															>
																Identificación
															</label>

															{formik.errors.identification &&
																formik.touched.identification && (
																	<ErrorMessage
																		name="identification"
																		component="div"
																		className="error"
																	/>
																)}
														</div>
													</div>
													<div className="col-md-1  ">
														<div className="form-floating mb-2  ">
															<Field
																style={inputStyles}
																ref={dvRef}
																type="text"
																name="dv"
																id="dv"
																className="form-control"
																placeholder="dv"
																value={digitoVerificacion}
															/>
															<label
																style={{ zIndex: "0" }}
																htmlFor="name"
																className="form-label"
															>
																DV
															</label>
															{formik.errors.dv && formik.touched.dv && (
																<ErrorMessage
																	name="dv"
																	component="div"
																	className="error"
																	readOnly
																/>
															)}
														</div>
													</div>

													{/* BLOQUE 2 */}
													<div className="col-md-3">
														<div className="form-floating mb-2">
															<Field
																style={dateStyles}
																type="date"
																name="fechaExpedicion"
																id="fechaExpedicion"
																className="form-control"
																placeholder="Fecha de expedicion"
															/>
															<label
																style={{ zIndex: "0" }}
																htmlFor="name"
																className="form-label"
															>
																Fecha de expedición
															</label>
															{formik.errors.fechaExpedicion &&
																formik.touched.fechaExpedicion && (
																	<ErrorMessage
																		name="fechaExpedicion"
																		component="div"
																		className="error"
																	/>
																)}
														</div>
													</div>
													<div className="col-md-3">
														<div className="form-floating mb-2">
															<Select
																styles={customStyles}
																defaultValue={
																	personToEdit && personToEdit.paisExpedicion
																		? {
																				label:
																					personToEdit.paisExpedicion
																						.descripcion,
																				value: personToEdit.paisExpedicion.id,
																		  }
																		: ""
																}
																isSearchable="true"
																placeholder="País de expedición"
																isClearable="true"
																isLoading={isLoading}
																onInputChange={onInputChange}
																options={optionsPais}
																onChange={(e) => {
																	formik.setFieldValue(
																		"paisEx",
																		e ? e.value : ""
																	);
																	setPaisSelected(e ? e.value : "");
																	setOptionsDepto([]);
																	selectDpto.current.clearValue();
																}}
																noOptionsMessage={() => "Digite y Seleccione"}
															/>
														</div>
														<ErrorMessage
															name="paisEx"
															component="div"
															className="error"
														/>
													</div>
													<div className="col-md-3">
														<div className="form-floating mb-2">
															<Select
																ref={selectDpto}
																styles={customStyles}
																defaultValue={
																	personToEdit &&
																	personToEdit.departamentoExpedicion
																		? {
																				label:
																					personToEdit.departamentoExpedicion
																						.descripcion,
																				value:
																					personToEdit.departamentoExpedicion
																						.id,
																		  }
																		: ""
																}
																isSearchable="true"
																placeholder="Depto de expedición"
																isClearable="true"
																isLoading={isLoading}
																onInputChange={onInputDeptoChange}
																options={optionsDepto}
																onChange={(e) => {
																	formik.setFieldValue(
																		"dptoEx",
																		e ? e.value : ""
																	);
																	setDeptoSelected(e ? e.value : "");
																	setOptionsMupio([]);
																	selectMupio.current.clearValue();
																}}
																noOptionsMessage={() => "Digite y Seleccione"}
															/>
														</div>
														<ErrorMessage
															name="dptoEx"
															component="div"
															className="error"
														/>
													</div>
													<div className="col-md-3">
														<div className="form-floating mb-2">
															<Select
																ref={selectMupio}
																defaultValue={
																	personToEdit &&
																	personToEdit.municipioExpedicion
																		? {
																				label:
																					personToEdit.municipioExpedicion
																						.descripcion,
																				value:
																					personToEdit.municipioExpedicion.id,
																		  }
																		: ""
																}
																styles={customStyles}
																isSearchable="true"
																placeholder="Mupio de expedición"
																isClearable="true"
																isLoading={isLoading}
																onInputChange={onInputMupioChange}
																options={optionsMupio}
																onChange={(e) => {
																	formik.setFieldValue(
																		"mupioEx",
																		e ? e.value : ""
																	);
																}}
																noOptionsMessage={() => "Digite y Seleccione"}
															/>
														</div>
														<ErrorMessage
															name="mupioEx"
															component="div"
															className="error"
														/>
													</div>

													{/* BLOQUE 3 */}
													<div className="col-md-3">
														<div className="form-floating mb-2">
															<Field
																style={inputStyles}
																type="text"
																name="lastName1"
																id="lastName1"
																className="form-control"
																placeholder="Primer apellido"
															/>
															<label
																style={{ zIndex: "0" }}
																htmlFor="lastName1"
																className="form-label"
															>
																Primer apellido
															</label>
															<ErrorMessage
																name="lastName1"
																component="div"
																className="error"
															/>
														</div>
													</div>
													<div className="col-md-3">
														<div className="form-floating mb-2">
															<Field
																style={inputStyles}
																type="text"
																name="lastName2"
																id="lastName2"
																className="form-control"
																placeholder="Segundo apellido"
															/>
															<label
																style={{ zIndex: "0" }}
																htmlFor="lastName2"
																className="form-label"
															>
																Segundo apellido
															</label>
															<ErrorMessage
																name="lastName2"
																component="div"
																className="error"
															/>
														</div>
													</div>
													<div className="col-md-3">
														<div className="form-floating mb-2">
															<Field
																style={inputStyles}
																type="text"
																name="name1"
																id="name1"
																className="form-control"
																placeholder="Primer Nombre"
															/>
															<label
																style={{ zIndex: "0" }}
																htmlFor="name1"
																className="form-label"
															>
																Primer nombre
															</label>
															<ErrorMessage
																name="name1"
																component="div"
																className="error"
															/>
														</div>
													</div>
													<div className="col-md-3">
														<div className="form-floating mb-2">
															<Field
																style={inputStyles}
																type="text"
																name="name2"
																id="name2"
																className="form-control"
																placeholder="Segundo Nombre"
															/>
															<label
																style={{ zIndex: "0" }}
																htmlFor="name2"
																className="form-label"
															>
																Otros nombres
															</label>
															<ErrorMessage
																name="name2"
																component="div"
																className="error"
															/>
														</div>
													</div>
													{/* BLOQUE 4 */}
													<div className="col-md-12">
														<div className="form-floating mb-2">
															<Field
																style={inputStyles}
																type="text"
																name="businessName"
																id="businessName"
																className="form-control"
																placeholder="Razón social"
															/>
															<label
																style={{ zIndex: "0" }}
																htmlFor="businessName"
																className="form-label"
															>
																Razón social
															</label>
															<ErrorMessage
																name="businessName"
																component="div"
																className="error"
															/>
														</div>
													</div>
													{/* BLOQUE 5 */}
													<div className="col-md-8">
														<div className="form-floating mb-2">
															<Field
																style={inputStyles}
																type="text"
																name="nombreComercial"
																id="nombreComercial"
																className="form-control"
																placeholder="Nombre comercial"
															/>
															<label
																style={{ zIndex: "0" }}
																htmlFor="nombreComercial"
																className="form-label"
															>
																Nombre Comercial
															</label>
															<ErrorMessage
																name="nombreComercial"
																component="div"
																className="error"
															/>
														</div>
													</div>
													<div className="col-md-4">
														<div className="form-floating mb-2">
															<Field
																style={inputStyles}
																type="text"
																name="sigla"
																id="sigla"
																className="form-control"
																placeholder="Sigla"
															/>
															<label
																style={{ zIndex: "0" }}
																htmlFor="sigla"
																className="form-label"
															>
																Sigla
															</label>
															<ErrorMessage
																name="sigla"
																component="div"
																className="error"
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="accordion-item">
										<h2 class="accordion-header">
											<button
												class="accordion-button show"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapseTwo"
												aria-expanded="false"
												aria-controls="collapseTwo"
											>
												UBICACIÓN
											</button>
										</h2>
										<div
											id="collapseTwo"
											class="accordion-collapse show"
											data-bs-parent="#accordionExample"
										>
											<div class="accordion-body">
												{/* UBICACION */}
												<div className="row">
													{/* BLOQUE 1 */}
													<div className="col-md-4">
														<div className="form-floating mb-2">
															<Select
																styles={customStyles}
																defaultValue={
																	personToEdit && personToEdit.paisUbicacion
																		? {
																				label:
																					personToEdit.paisUbicacion
																						.descripcion,
																				value: personToEdit.paisUbicacion.id,
																		  }
																		: ""
																}
																isSearchable="true"
																placeholder="País de ubicación"
																isClearable="true"
																isLoading={isLoading}
																onInputChange={onInputChangePU}
																options={optionsPaisU}
																onChange={(e) => {
																	formik.setFieldValue(
																		"pais",
																		e ? e.value : ""
																	);
																	setPaisSelectedU(e ? e.value : "");
																	setOptionsDeptoU([]);
																	selectDptoU.current.clearValue();
																}}
																noOptionsMessage={() => "Digite y Seleccione"}
															/>
														</div>
														<ErrorMessage
															name="pais"
															component="div"
															className="error"
														/>
													</div>
													<div className="col-md-4">
														<div className="form-floating mb-2">
															<Select
																ref={selectDptoU}
																defaultValue={
																	personToEdit &&
																	personToEdit.departamentoUbicacion
																		? {
																				label:
																					personToEdit.departamentoUbicacion
																						.descripcion,
																				value:
																					personToEdit.departamentoUbicacion.id,
																		  }
																		: ""
																}
																styles={customStyles}
																isSearchable="true"
																placeholder="Depto de ubicación"
																isClearable="true"
																isLoading={isLoading}
																onInputChange={onInputDeptoUChange}
																options={optionsDeptoU}
																onChange={(e) => {
																	formik.setFieldValue(
																		"depto",
																		e ? e.value : ""
																	);
																	setDeptoSelectedU(e ? e.value : "");
																	setOptionsMupioU([]);
																	selectMupioU.current.clearValue();
																}}
																noOptionsMessage={() => "Digite y Seleccione"}
															/>
														</div>
														<ErrorMessage
															name="depto"
															component="div"
															className="error"
														/>
													</div>
													<div className="col-md-4">
														<div className="form-floating mb-2">
															<Select
																ref={selectMupioU}
																styles={customStyles}
																defaultValue={
																	personToEdit &&
																	personToEdit.municipioUbicacion
																		? {
																				label:
																					personToEdit.municipioUbicacion
																						.descripcion,
																				value:
																					personToEdit.municipioUbicacion.id,
																		  }
																		: ""
																}
																isSearchable="true"
																placeholder="Mupio de ubicación"
																isClearable="true"
																isLoading={isLoading}
																onInputChange={onInputMupioChangeU}
																options={optionsMupioU}
																onChange={(e) => {
																	formik.setFieldValue(
																		"mupio",
																		e ? e.value : ""
																	);
																}}
																noOptionsMessage={() => "Digite y Seleccione"}
															/>
														</div>
														<ErrorMessage
															name="mupio"
															component="div"
															className="error"
														/>
													</div>
													{/* BLOQUE 2 */}
													<div className="col-md-8">
														<div className="form-floating mb-2">
															<Field
																style={inputStyles}
																type="text"
																name="address"
																id="address"
																className="form-control"
																placeholder="Dirección"
															/>
															<label
																style={{ zIndex: "0" }}
																htmlFor="address"
																className="form-label"
															>
																Dirección
															</label>
															<ErrorMessage
																name="address"
																component="div"
																className="error"
															/>
														</div>
													</div>
													{/* BLOQUE 3 */}

													<div className="col-md-4">
														<div className="form-floating mb-2">
															<Field
																style={inputStyles}
																type="text"
																name="codPostal"
																id="codPostal"
																className="form-control"
																placeholder="Código postal"
															/>
															<label
																style={{ zIndex: "0" }}
																htmlFor="codPostal"
																className="form-label"
															>
																Código postal
															</label>
															<ErrorMessage
																name="codPostal"
																component="div"
																className="error"
															/>
														</div>
													</div>
													<div className="col-md-4">
														<div className="form-floating mb-2">
															<Field
																style={inputStyles}
																type="text"
																name="email1"
																id="email1"
																className="form-control"
																placeholder="Correo electrónico"
															/>
															<label
																style={{ zIndex: "0" }}
																htmlFor="email1"
																className="form-label"
															>
																Correo electrónico
															</label>
															<ErrorMessage
																name="email1"
																component="div"
																className="error"
															/>
														</div>
													</div>
													<div className="col-md-4">
														<div className="form-floating mb-2">
															<Field
																style={inputStyles}
																type="text"
																name="phone1"
																id="phone1"
																className="form-control"
																placeholder="Telefono 1"
															/>
															<label
																style={{ zIndex: "0" }}
																htmlFor="phone1"
																className="form-label"
															>
																Telefono 1
															</label>
															<ErrorMessage
																name="phone1"
																component="div"
																className="error"
															/>
														</div>
													</div>
													<div className="col-md-4">
														<div className="form-floating mb-2">
															<Field
																style={inputStyles}
																type="text"
																name="phone2"
																id="phone2"
																className="form-control"
																placeholder="Telefono 2"
															/>
															<label
																style={{ zIndex: "0" }}
																htmlFor="phone2"
																className="form-label"
															>
																Telefono 2
															</label>
															<ErrorMessage
																name="phone2"
																component="div"
																className="error"
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="accordion-item mb-2 ">
										<h2 class="accordion-header">
											<button
												class="accordion-button show"
												type="button"
												data-bs-toggle="collapse"
												data-bs-target="#collapseThree"
												aria-expanded="false"
												aria-controls="collapseThree"
											>
												CLASIFICACIÓN
											</button>
										</h2>
										<div
											id="collapseThree"
											class="accordion-collapse show"
											data-bs-parent="#accordionExample"
										>
											<div class="accordion-body">
												<div className="row">
													<div className="col-md-9">
														<div className="form-floating mb-2">
															<Select
																styles={customStyles}
																defaultValue={
																	personToEdit && personToEdit.actividad1
																		? {
																				label:
																					personToEdit.actividad1.descripcion,
																				value: personToEdit.actividad1.id,
																		  }
																		: ""
																}
																isSearchable="true"
																placeholder="Actividad Economica 1"
																isClearable="true"
																isLoading={isLoading}
																onInputChange={onInputCiiuChange}
																options={optionsCiiu}
																onChange={(e) => {
																	formik.setFieldValue(
																		"codAct1",
																		e ? e.value : ""
																	);
																}}
															/>
														</div>
														<ErrorMessage
															name="codAct1"
															component="div"
															className="error"
														/>
													</div>
													<div className="col-md-3">
														<div className="form-floating mb-2">
															<Field
																style={dateStyles}
																type="date"
																name="fechaIniAct1"
																id="fechaIniAct1"
																className="form-control"
																placeholder="Fecha de inicio"
															/>
															<label
																style={{ zIndex: "0" }}
																htmlFor="fechaIniAct1"
																className="form-label"
															>
																Fecha de inicio
															</label>
															{formik.errors.fechaIniAct1 &&
																formik.touched.fechaIniAct1 && (
																	<ErrorMessage
																		name="fechaIniAct1"
																		component="div"
																		className="error"
																	/>
																)}
														</div>
													</div>
													<div className="col-md-9">
														<div className="form-floating mb-2">
															<Select
																styles={customStyles}
																defaultValue={
																	personToEdit && personToEdit.actividad2
																		? {
																				label:
																					personToEdit.actividad2.descripcion,
																				value: personToEdit.actividad2.id,
																		  }
																		: ""
																}
																isSearchable="true"
																placeholder="Actividad Economica 2"
																isClearable="true"
																isLoading={isLoading}
																onInputChange={onInputCiiuChange}
																options={optionsCiiu}
																onChange={(e) => {
																	formik.setFieldValue(
																		"codAct2",
																		e ? e.value : ""
																	);
																}}
															/>
														</div>
													</div>
													<div className="col-md-3">
														<div className="form-floating mb-2">
															<Field
																style={dateStyles}
																type="date"
																name="fechaIniAct2"
																id="fechaIniAct2"
																className="form-control"
																placeholder="Fecha de inicio"
															/>
															<label
																style={{ zIndex: "0" }}
																htmlFor="fechaIniAct2"
																className="form-label"
															>
																Fecha de inicio
															</label>
															{formik.errors.fechaIniAct2 &&
																formik.touched.fechaIniAct2 && (
																	<ErrorMessage
																		name="fechaIniAct2"
																		component="div"
																		className="error"
																	/>
																)}
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-floating mb-2">
															<Select
																styles={customStyles}
																defaultValue={
																	personToEdit && personToEdit.actividad3
																		? {
																				label:
																					personToEdit.actividad3.descripcion,
																				value: personToEdit.actividad3.id,
																		  }
																		: ""
																}
																isSearchable="true"
																placeholder="Otra Actividad 1"
																isClearable="true"
																isLoading={isLoading}
																onInputChange={onInputCiiuChange}
																options={optionsCiiu}
																onChange={(e) => {
																	formik.setFieldValue(
																		"codAct3",
																		e ? e.value : ""
																	);
																}}
															/>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-floating mb-2">
															<Select
																styles={customStyles}
																defaultValue={
																	personToEdit && personToEdit.actividad4
																		? {
																				label:
																					personToEdit.actividad4.descripcion,
																				value: personToEdit.actividad4.id,
																		  }
																		: ""
																}
																isSearchable="true"
																placeholder="Otra Actividad 2"
																isClearable="true"
																isLoading={isLoading}
																onInputChange={onInputCiiuChange}
																options={optionsCiiu}
																onChange={(e) => {
																	formik.setFieldValue(
																		"codAct4",
																		e ? e.value : ""
																	);
																}}
															/>
														</div>
													</div>
													<div className="col-md-6 mt-2">
														<div className="form-floating mb-2">
															<Select
																styles={customStyles}
																defaultValue={
																	personToEdit && personToEdit.ocupacionTercero
																		? {
																				label:
																					personToEdit.ocupacionTercero
																						.descripcion,
																				value: personToEdit.ocupacionTercero.id,
																		  }
																		: ""
																}
																isSearchable="true"
																placeholder="Ocupación"
																isClearable="true"
																isLoading={isLoading}
																onInputChange={onInputCiuoChange}
																options={optionsCiuo}
																onChange={(e) => {
																	formik.setFieldValue(
																		"ocupacion",
																		e ? e.value : ""
																	);
																}}
															/>
														</div>
													</div>
													<div className="col-md-6 mt-2">
														<div className="form-floating mb-2">
															<Field
																style={inputStyles}
																type="number"
																name="numEstabs"
																id="numEstabs"
																className="form-control"
																placeholder="Número de establecimientos"
															/>
															<label
																style={{ zIndex: "0" }}
																htmlFor="numEstabs"
																className="form-label"
															>
																Número de establecimientos
															</label>
															{formik.errors.numEstabs &&
																formik.touched.numEstabs && (
																	<ErrorMessage
																		name="numEstabs"
																		component="div"
																		className="error"
																	/>
																)}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								{personToEdit ? (
									<>
										{btnCarga === 0 ? (
											<button type="submit" className="btn btn-primary">
												Actualizar
											</button>
										) : (
											/* spinner carga */
											<button
												className="btn btn-primary"
												type="button"
												disabled
											>
												<span
													className="spinner-border spinner-border-sm"
													aria-hidden="true"
												></span>
												<span role="status"> Guardando...</span>
											</button>
											/*--------------- */
										)}
										&nbsp;
										<NavLink
											to={"/menu/hefestoPersons/search-person"}
											className="btn btn-danger"
										>
											Cancelar
										</NavLink>
									</>
								) : (
									<>
										{btnCarga === 0 ? (
											<button type="submit" className="btn btn-primary">
												Guardar
											</button>
										) : (
											/* spinner carga */
											<button
												className="btn btn-primary"
												type="button"
												disabled
											>
												<span
													className="spinner-border spinner-border-sm"
													aria-hidden="true"
												></span>
												<span role="status"> Guardando...</span>
											</button>
											/*--------------- */
										)}
										&nbsp;
										
											<button ref={clearForm} type="reset" className="btn btn-danger">
												Cancelar
											</button>
										
									</>
								)}
							</Form>
						)}
					</Formik>
				)}
			</div>
		</>
	);
}
