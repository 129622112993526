import React, { useState, useEffect, useContext, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import { AuthContext } from "../../context/LogContext";
import { route_confiPresupuesto_base } from "../../../api-routes/RoutesLogin";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Select from "react-select";
import { MdAccountTree } from "react-icons/md";

const ConceptosPago = () => {
	const { establishment, getToken, inputStyles, customStyles } = useContext(AuthContext);
	const [conceptos, setConceptos] = useState([]);
	const btnCancel = useRef(null);
	const formikRef = useRef(null);
	const formikCta = useRef(null);
	const [conceptoSelected, setConceptoSelected] = useState(null);
	const [conceptoFSelected, setConceptoFSelected] = useState(null);
	const [optionsConcepto, setOptionsConcepto] = useState([]);
	const [tipoSelected, setTipoSelected] = useState(null);
	const [estadoSelected, setEstadoSelected] = useState(null);
	const [bancoSelected, setBancoSelected] = useState(null);
	const [tiposCuenta, setTiposCuenta] = useState([
		{ value: 1, label: "Ahorros" },
		{ value: 2, label: "Corriente" },
	]);
	const [cuentas, setCuentas] = useState([]);
	const [btnCarga, setBtnCarga] = useState(0);
	const [banCarga, setBanCarga] = useState(0);

	const handleSubmit = (values) => {
		setBtnCarga(1);
		saveConcepto(values);
	
	};
	const handleSubmitCta = async (json) => {
		json.user = parseInt(localStorage.getItem("idUser"));
		json.banco = bancoSelected;
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			const response = await axios.post(
				route_confiPresupuesto_base + "/saveCuentaxBanco",
				json,
				config
			);
			const successMessage = "Cuenta bancaria creada exitosamente";

			Swal.fire({
				icon: "success",
				title: successMessage,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				if (formikCta.current) {
					formikCta.current.resetForm();
				}
				setTipoSelected(null);
				setEstadoSelected(null);
				getCuentas(bancoSelected);
				setBtnCarga(0);
			});
		} catch (error) {
			if (error.response && error.response.status === 403) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Verifique los datos",
					showConfirmButton: true,
				}).then(() => {});
			} else {
				setBtnCarga(0);
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error de red, contactese con su proveedor",
					showConfirmButton: true,
				}).then(() => {});
			}
		}
	};

	const saveConcepto = async (json) => {
		json.user = parseInt(localStorage.getItem("idUser"));
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			const response = await axios.post(
				route_confiPresupuesto_base + "/saveConceptoInfo",
				json,
				config
			);
			const successMessage = "Concepto informativo creado exitosamente";

			Swal.fire({
				icon: "success",
				title: successMessage,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				setBtnCarga(0);
				btnCancel.current.click();
				getConceptos(json.tipo);
				setConceptoSelected(null);
				//setPorcentajeVal(0);
				//getConceptos();
			});
		} catch (error) {
			if (error.response && error.response.status === 403) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Verifique los datos",
					showConfirmButton: true,
				}).then(() => {});
			} else {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error de red, contactese con su proveedor",
					showConfirmButton: true,
				}).then(() => {});
			}
		}
	};
	const getConceptos = async (tipo) => {
		setBanCarga(1)
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_confiPresupuesto_base}/getConceptosI/${tipo},${establishment.id}`,
			config
		);

		var order = result.data.sort((a, b) => a.codigo.localeCompare(b.codigo));
		setConceptos(order);
		setBanCarga(0)
	};
	const getCuentas = async (banco) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_confiPresupuesto_base}/getCuentaxBanco/${banco}`,
			config
		);

		setCuentas(result.data);
	};

	const handleEditCta = (id) => {
		const CtaEdit = cuentas.find((p) => p.id == id);
		if (formikCta.current) {
			formikCta.current.setValues(CtaEdit);
			var tipoSel = tiposCuenta.find((option) => option.value === CtaEdit.tipo);
			setTipoSelected(tipoSel);
			setEstadoSelected(
				CtaEdit.active == true
					? { value: true, label: "Activa" }
					: { value: false, label: "Inactiva" }
			);
		}
	};
	const handleServiceEdit = (conceptoId) => {
		const conceptoDet = conceptos.find((p) => p.id === conceptoId);
		if (formikRef.current) {
			formikRef.current.setValues(conceptoDet);
			var tipoSel = optionsConcepto.find(
				(option) => option.value === conceptoDet.tipo
			);
			setConceptoSelected(tipoSel);
		}
	};
	const handleDelete = (conceptoId) => {
		Swal.fire({
			title: "Eliminar concepto de información?",
			text: "El concepto no debe estar aplicado a ningun comprobante!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				deleteServicio(conceptoId);
			}
		});
	};
	const deleteServicio = async (servicioId) => {

		try {
			const response = await axios.delete(
				route_confiPresupuesto_base + "/deleteConceptoI/" + servicioId,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			if (response.data == true) {
				Swal.fire({
					icon: "success",
					title: "El concepto se ha eliminado correctamente",
					showConfirmButton: false,
					timer: 1500,
				}).then(() => {
					getConceptos(conceptoFSelected);
				});
			} else {
				Swal.fire({
					icon: "warning",
					title: "Error, Verifique si el concepto no ha sido aplicado",
					showConfirmButton: false,
					timer: 1500,
				});
			}
		} catch (error) {
			throw error;
		}
	};
	const activarCta = async (id) => {
		let values = {
			id: id,
			user: localStorage.getItem("idUser"),
		};
	
		let dataJson = JSON.stringify(values);
		try {
			const response = await axios.post(
				route_confiPresupuesto_base + "/updateBanco",
				dataJson,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			Swal.fire({
				icon: "success",
				title: "",
				showConfirmButton: false,
				timer: 1500,
			});
			getConceptos(1);
		} catch (error) {
			Swal.fire({
				icon: "warning",
				title: "Hubo un error en el proceso",
				showConfirmButton: false,
				timer: 1500,
			});
			getConceptos(1);
		}
	};
	useEffect(() => {
		//getConceptos();
		setOptionsConcepto([
			{ label: "Bancos", value: 1 },
			{ label: "Fuente de recursos", value: 2 },
			{ label: "Fuente de financiación", value: 3 },
			{ label: "Clase de  contrato", value: 4 },
			{ label: "Forma de contratación", value: 5 },
			{ label: "Tipo de pago", value: 6 },
		]);
	}, []);

	const validationSchema = Yup.object().shape({
		nombre: Yup.string().required().typeError("Campo obligatorio"),
		codigo: Yup.string().required().typeError("Campo obligatorio"),
		tipo: Yup.number().required().typeError("Campo obligatorio"),
	});

	const initialValues = {
		id: "",
		nombre: "",
		codigo: "",
		tipo: null,
		active: true,
		establishment:establishment.id
	};

	const validationSchemaCta = Yup.object().shape({
		tipo: Yup.number().required("Campo requerido"),
		numero: Yup.string().required("Campo requerido"),
		denominacion: Yup.string().required("Campo requerido"),
		active: Yup.boolean().required("Campo requerido"),
	});

	const initialValuesCta = {
		tipo: "",
		numero: "",
		denominacion: "",
		active: false,
	};

	return (
		<div className="containerFormUser">
			<h1>Conceptos Información</h1>

			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
				innerRef={formikRef}
			>
				{(formik, props) => (
					<Form>
						<div className="row">
							{/*------ BLOQUE1 ------- */}
							<div className="col-md-3  ">
								<div className="form-floating mb-2  ">
									<Select
										isClearable={true}
										styles={customStyles}
										value={conceptoSelected}
										placeholder="Tipo"
										onChange={(e) => {
											if (e) {
												formik.setFieldValue("tipo", e.value);
												setConceptoSelected(e);
											} else {
												formik.setFieldValue("tipo", null);
												setConceptoSelected(null);
											}
										}}
										options={optionsConcepto}
									/>
									<ErrorMessage name="tipo" component="div" className="error" />
								</div>
							</div>

							<div className="col-md-3  ">
								<div className="form-floating mb-2  ">
									<Field
										style={inputStyles}
										type="text"
										name="nombre"
										id="nombre"
										className="form-control"
										placeholder="Nombre de concepto"
									/>
									<label htmlFor="forma" className="form-label">
										Concepto
									</label>
									<ErrorMessage
										name="nombre"
										component="div"
										className="error"
									/>
								</div>
							</div>
							{/* -------------------- */}
							<div className="col-md-3  ">
								<div className="form-floating mb-2  ">
									<Field
										style={inputStyles}
										type="text"
										name="codigo"
										id="codigo"
										className="form-control"
										placeholder="Código de concepto"
									/>
									<label htmlFor="forma" className="form-label">
										Código
									</label>
									<ErrorMessage
										name="codigo"
										component="div"
										className="error"
									/>
								</div>
							</div>
							<div className="col-md-3  ">
								{btnCarga === 0 ? (
									<button type="submit" className="btn btn-primary">
										Guardar
									</button>
								) : (
									/* spinner carga */
									<button className="btn btn-primary" type="button" disabled>
										<span
											className="spinner-border spinner-border-sm"
											aria-hidden="true"
										></span>
										<span role="status"> Guardando...</span>
									</button>
									/*--------------- */
								)}
								&nbsp;
								<button type="reset" ref={btnCancel} className="btn btn-danger">
									Cancelar
								</button>
							</div>
							{/* ------------------- */}
						</div>
					</Form>
				)}
			</Formik>
			<br></br>
			<h1>Lista de conceptos</h1>

			<div className="row">
				<div className="col-md-12  ">
					<div className="form-floating mb-2  ">
						<Select
							isClearable={true}
							styles={customStyles}
							//value={conceptoSelected}
							placeholder="Tipo"
							onChange={(e) => {
								if (e) {
									getConceptos(e.value);
									setConceptoFSelected(e.value);
								} else {
									setConceptos([]);
									setConceptoFSelected(null);
								}
							}}
							options={optionsConcepto}
						/>
					</div>
				</div>
			</div>
			{banCarga === 0 ? (
				<table
					style={{ width: "100%" }}
					id="tableUsers"
					className="table table-striped table-hover"
				>
					<thead>
						<tr>
							<th style={{ width: "60%", textAlign: "center" }}>Nombre</th>
							<th style={{ width: "20%", textAlign: "right" }}>Código</th>
							<th style={{ width: "20%", textAlign: "right" }}>Opciones</th>
						</tr>
					</thead>
					<tbody>
						{conceptos.map((concepto) => (
							<tr key={concepto.id}>
								<td>{concepto.nombre} </td>
								<td style={{ textAlign: "right" }}>{concepto.codigo}</td>
								<td style={{ width: "20%", textAlign: "right" }}>
									<div className="row">
										<div className="col-4"></div>

										<div className="col-2">
											{concepto.tipo === 1 ? (
												<>
													<div class="form-check form-switch">
														<input
															className="form-check-input"
															type="checkbox"
															role="switch"
															id="flexSwitchCheckDefault"
															checked={concepto.active}
															onChange={() => activarCta(concepto.id)}
														/>
													</div>
												</>
											) : null}
										</div>

										<div className="col-6">
											{concepto.tipo === 1 ? (
												<>
													&nbsp;
													<button
														type="button"
														className="btnInfo"
														data-bs-toggle="modal"
														data-bs-target="#exampleModal"
														title="Configurar cuentas"
														onClick={() => {
															setBancoSelected(concepto.id);
															getCuentas(concepto.id);
														}}
													>
														<MdAccountTree />
													</button>
													&nbsp;
												</>
											) : null}
											<button
												className="btnEdit"
												type="button"
												onClick={(e) => handleServiceEdit(concepto.id)}
											>
												<i className="fas fa-pen"></i>
											</button>
											&nbsp;
											<button
												className="btnDelete"
												type="button"
												onClick={() => handleDelete(concepto.id)}
											>
												<i className="fas fa-trash-alt"></i>
											</button>
										</div>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			) : (
				/* spinner carga */
				<button className="btn btn-primary" type="button" disabled>
					<span
						className="spinner-border spinner-border-sm"
						aria-hidden="true"
					></span>
					<span role="status"> Cargando...</span>
				</button>
				/*--------------- */
			)}
			{/* modal para configurar cunetas por banco */}
			<div
				class="modal fade"
				id="exampleModal"
				tabindex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div class="modal-dialog modal-dialog-scrollable">
					<div class="modal-content">
						<div class="modal-header">
							<h1 class="modal-title fs-5" id="exampleModalLabel">
								Cuentas Bancarias
							</h1>
							<button
								type="button"
								class="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div class="modal-body">
							<Formik
								initialValues={initialValuesCta}
								validationSchema={validationSchemaCta}
								onSubmit={handleSubmitCta}
								innerRef={formikCta}
							>
								{(formik, props) => (
									<Form>
										<div className="row">
											{/*------ BLOQUE1 ------- */}
											<div className="col-md-6  ">
												<div className="form-floating mb-2  ">
													<Select
														isClearable={true}
														styles={customStyles}
														value={tipoSelected}
														placeholder="Tipo"
														onChange={(e) => {
															if (e) {
																formik.setFieldValue("tipo", e.value);
																setTipoSelected(e);
															} else {
																formik.setFieldValue("tipo", null);
																setTipoSelected(null);
															}
														}}
														options={tiposCuenta}
													/>
													<ErrorMessage
														name="tipo"
														component="div"
														className="error"
													/>
												</div>
											</div>
											<div className="col-6 ">
												<div className="form-floating mb-2  ">
													<Select
														styles={customStyles}
														value={estadoSelected}
														placeholder="Estado"
														onChange={(e) => {
															if (e) {
																formik.setFieldValue("active", e.value);
																setEstadoSelected(e);
															} else {
																formik.setFieldValue("active", null);
																setEstadoSelected(null);
															}
														}}
														options={[
															{ value: true, label: "Activa" },
															{ value: false, label: "Inactiva" },
														]}
													/>
													<ErrorMessage
														name="tipo"
														component="div"
														className="error"
													/>
												</div>
											</div>
										</div>

										<div className="row">
											{/*------ BLOQUE1 ------- */}
											<div className="col-6 ">
												<div className="form-floating mb-2  ">
													<Field
														styles={inputStyles}
														type="text"
														name="denominacion"
														id="denominacion"
														className="form-control"
														placeholder="denominacion"
													/>
													<label htmlFor="denominacion" className="form-label">
														Denominación
													</label>
													<ErrorMessage
														name="denominacion"
														component="div"
														className="error"
													/>
												</div>
											</div>
											<div className="col-6  ">
												<div className="form-floating mb-2  ">
													<Field
														styles={inputStyles}
														type="text"
														name="numero"
														id="numero"
														className="form-control"
														placeholder="numero"
													/>
													<label htmlFor="numero" className="form-label">
														Numero
													</label>
													<ErrorMessage
														name="numero"
														component="div"
														className="error"
													/>
												</div>
											</div>
										</div>

										<div class="modal-footer">
											<button
												type="button"
												class="btn btn-danger"
												data-bs-dismiss="modal"
											>
												Cancelar
											</button>
											<button type="submit" class="btn btn-primary">
												Guardar
											</button>
										</div>
									</Form>
								)}
							</Formik>
						</div>
						<div
							class="modal-footer"
							style={{ height: "200px", overflowY: "auto" }}
						>
							<table
								style={{ width: "100%" }}
								id="tableUsers"
								className="table table-striped table-hover"
							>
								<thead>
									<tr>
										<th style={{ textAlign: "center" }}>Denominación</th>
										<th style={{ textAlign: "center" }}>Tipo</th>
										<th style={{ textAlign: "center" }}>Numero</th>
										<th style={{ textAlign: "center" }}>Estado</th>
										<th style={{ width: "20%", textAlign: "right" }}>
											Opciones
										</th>
									</tr>
								</thead>
								<tbody>
									{cuentas.map((concepto) => (
										<tr key={concepto.id}>
											<td>{concepto.denominacion} </td>
											<td>{concepto.tipo == 1 ? "Ahorros" : "Corriente"} </td>
											<td>{concepto.numero} </td>
											<td>{concepto.active == true ? "Activa" : "Inactiva"}</td>
											<td style={{ width: "20%", textAlign: "right" }}>
												<button
													className="btnEdit"
													type="button"
													onClick={(e) => handleEditCta(concepto.id)}
												>
													<i className="fas fa-pen"></i>
												</button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

			{/* --------------------------------------- */}
		</div>
	);
};

export default ConceptosPago;
