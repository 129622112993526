import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { TbReportMoney } from "react-icons/tb";
import { GiReceiveMoney, GiPayMoney } from "react-icons/gi";
import { FaHandsHelping } from "react-icons/fa";

export default function MovimientosPre() {
	const navigate = useNavigate();
	const [usuariosOptionsVisible, setUsuariosOptionsVisible] = useState(false);
	const [financierosOptionsVisible, setFinancierosOptionsVisible] = useState(false);
	const [modificacionesOptionsVisible, setModificacionesOptionsVisible] = useState(false);

	const toggleUsuariosOptions = () => {
		setUsuariosOptionsVisible(!usuariosOptionsVisible);
	};
	const toggleFinancierosOptions = () => {
		setFinancierosOptionsVisible(!financierosOptionsVisible);
	};

	const toggleModificacionesOptions = () => {
		setModificacionesOptionsVisible(!modificacionesOptionsVisible);
	};

	useEffect(() => {
		navigate("/menu/hefestoPresupuesto/intro-movspre");
	}, []);
	return (
		<>
			<div id="space">
				<div className="container1 container-fluid">
					<div className="menu">
						<div className="divMenuLog">
							{/* bloque ingresos */}
							<ul>
								<NavLink className="navInfo" to="#">
									<li className="navInfoAdmin" onClick={toggleUsuariosOptions}>
										<GiReceiveMoney />{" "}
										<span>
											Ingresos&nbsp;
											{usuariosOptionsVisible === false ? (
												<i class="fas fa-chevron-down"></i>
											) : (
												<i class="fas fa-chevron-up"></i>
											)}{" "}
										</span>
									</li>
								</NavLink>
								{usuariosOptionsVisible && (
									<ul>
										<NavLink
											className="navInfo"
											to={{
												pathname:
													"/menu/hefestoPresupuesto/reconocimiento-Presupuesto/1",
											}}
										>
											<li className="navSubmenu">
												<TbReportMoney /> <span> Reconocimiento</span>
											</li>
										</NavLink>

										<NavLink
											className="navInfo"
											to={{
												pathname:
													"/menu/hefestoPresupuesto/reconocimiento-Presupuesto/2",
											}}
										>
											<li className="navSubmenu">
												<TbReportMoney /> <span> Ingreso</span>
											</li>
										</NavLink>
									</ul>
								)}
							</ul>
							{/* Bloque gastoa */}
							<ul>
								<NavLink className="navInfo" to="#">
									<li
										className="navInfoAdmin"
										onClick={toggleFinancierosOptions}
									>
										<GiPayMoney />{" "}
										<span>
											Gastos&nbsp;
											{financierosOptionsVisible === false ? (
												<i class="fas fa-chevron-down"></i>
											) : (
												<i class="fas fa-chevron-up"></i>
											)}
										</span>
									</li>
								</NavLink>
								{financierosOptionsVisible && (
									<ul>
										<NavLink
											className="navInfo"
											to={{
												pathname:
													"/menu/hefestoPresupuesto/reconocimiento-Presupuesto/3",
											}}
										>
											<li className="navSubmenu">
												<TbReportMoney /> <span> Disponibilidad</span>
											</li>
										</NavLink>

										<NavLink
											className="navInfo"
											to={{
												pathname:
													"/menu/hefestoPresupuesto/reconocimiento-Presupuesto/4",
											}}
										>
											<li className="navSubmenu">
												<TbReportMoney /> <span> Registro</span>
											</li>
										</NavLink>
										<NavLink
											className="navInfo"
											to={{
												pathname:
													"/menu/hefestoPresupuesto/reconocimiento-Presupuesto/5",
											}}
										>
											<li className="navSubmenu">
												<TbReportMoney /> <span> Obligación</span>
											</li>
										</NavLink>
										<NavLink
											className="navInfo"
											to={{
												pathname:
													"/menu/hefestoPresupuesto/reconocimiento-Presupuesto/6",
											}}
										>
											<li className="navSubmenu">
												<TbReportMoney /> <span> Comp. de Egreso</span>
											</li>
										</NavLink>
										<NavLink
											className="navInfo"
											to={{
												pathname:
													"/menu/hefestoPresupuesto/reconocimiento-Presupuesto/18",
											}}
										>
											<li className="navSubmenu">
												<TbReportMoney /> <span> Pago sin afectacion p. </span>
											</li>
										</NavLink>
									</ul>
								)}
							</ul>

							{/* Bloque modificaciones */}
							<ul>
								<NavLink className="navInfo" to="#">
									<li
										className="navInfoAdmin"
										onClick={toggleModificacionesOptions}
									>
										<FaHandsHelping />{" "}
										<span>
											Modificaciones&nbsp;
											{modificacionesOptionsVisible === false ? (
												<i class="fas fa-chevron-down"></i>
											) : (
												<i class="fas fa-chevron-up"></i>
											)}
										</span>
									</li>
								</NavLink>
								{modificacionesOptionsVisible && (
									<ul>
										<NavLink
											className="navInfo"
											to={{
												pathname:
													"/menu/hefestoPresupuesto/reconocimiento-Presupuesto/15",
											}}
										>
											<li className="navSubmenu">
												<TbReportMoney /> <span> Adiciones</span>
											</li>
										</NavLink>

										<NavLink
											className="navInfo"
											to={{
												pathname:
													"/menu/hefestoPresupuesto/reconocimiento-Presupuesto/16",
											}}
										>
											<li className="navSubmenu">
												<TbReportMoney /> <span> Reducciones</span>
											</li>
										</NavLink>
										<NavLink
											className="navInfo"
											to={{
												pathname:
													"/menu/hefestoPresupuesto/reconocimiento-Presupuesto/17",
											}}
										>
											<li className="navSubmenu">
												<TbReportMoney /> <span> Traslados</span>
											</li>
										</NavLink>
										
									</ul>
								)}
							</ul>
						</div>
					</div>
					<div className="workspace">
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}
