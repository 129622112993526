import React from "react";
import {
	FcCalendar,FcReading,FcEditImage
} from "react-icons/fc";
import Dashboard from "../../componentsBase/tables/Intro"; // Asegúrate de que la ruta sea correcta

const ParentComponent = () => {
	const cards = [
		{
			id: 1,
			icon: <FcCalendar />,
			title: "Años",
			description: "Gestiona la apertura y cierre de cada año",
			to: "/menu/hefestoContabilidad/anios_planp/5", // Ruta correcta
		},
		{
			id: 2,
			icon: <FcCalendar />,
			title: "Meses",
			description: "Gestiona la apertura y cierre mensual",
			to: "/menu/hefestoContabilidad/meses_planp/5", // Ruta correcta
		},
		
		
	];

	return <Dashboard cards={cards} />;
};

export default ParentComponent;
