import React, { useState, useEffect, useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { NumericFormat } from "react-number-format";
import { AuthContext } from "../../context/LogContext";
import axios from "axios";
import {
	route_person_searchPerson,
	route_confiGym_base,
	route_gym_suscripciones,
} from "../../../api-routes/RoutesLogin";
import Swal from "sweetalert2";
import * as Yup from "yup";

const SuscripcionUsuario = () => {
	const {
		establishment,
		getToken,
		customStyles,
		dateStyles,
		inputStyles,
	} = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(false);
	const [optionsPersons, setOptionsPersons] = useState([]);
	const [planes, setPlanes] = useState([]);
	const [inputValorPlan, setInputValorPlan] = useState(null);
	const [inputValorPago, setInputValorPago] = useState(0);
	const currentDate = new Date().toISOString().split("T")[0];
	const [fechaIni, setFechaIni] = useState(currentDate);
	const [fechaFin, setFechaFin] = useState("");
	const [planDet, setPlanDet] = useState("");
	const [inputImc, setInputImc] = useState("");
	const [instructors, setInstructors] = useState([]);
	const navigate = useNavigate();

	const onInputChange = (inputValue) => {
		if (
			typeof inputValue === "string" &&
			inputValue != "" &&
			inputValue.length >= 3
		) {
			loadPersons(inputValue);
		}
	};

	const loadPersons = async (inputValue) => {
		setIsLoading(true);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		try {
			const response = await axios.get(
				route_person_searchPerson +
					establishment.id +
					"," +
					inputValue +
					",true",
				config
			);
			const data = response.data;
			const formattedOptions = data.map((item) => ({
				value: item.id,
				label:
					item.identification +
					" - " +
					item.name1 +
					" " +
					item.name2 +
					" " +
					item.lastName1 +
					" " +
					item.lastName2 +
					" - " +
					item.businessName,
			}));

			setOptionsPersons(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const getPlanes = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_confiGym_base}/getAllPlanes/${establishment.id}`,
			config
		);
		setPlanes(result.data);
	};

	const registerSuscripcion = async (json) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			const response = await axios.post(route_gym_suscripciones, json, config);
			const successMessage = "Suscripcion realizada exitosamente";

			Swal.fire({
				icon: "success",
				title: successMessage,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				const idT = response.data.tercero;
				navigate("/menu/hefestoGym/register-pagoGym/" + idT);
			});
		} catch (error) {
			if (error.response && error.response.status === 403) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Verifique los datos registrados",
					showConfirmButton: true,
				}).then(() => {});
			} else {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error de red, contactese con su proveedor",
					showConfirmButton: true,
				}).then(() => {});
			}
		}
	};

	const handleSubmit = (values) => {

		registerSuscripcion(values);
	};

	const handleChangePlan = (formik, e) => {
		const planSelect = planes.find((p) => p.id === e.value);
		setPlanDet(planSelect);
		formik.setFieldValue("valorPlan", planSelect.valor);
		formik.setFieldValue("saldo", planSelect.valor);
		//formik.setFieldValue("valorPago", planSelect.valor);
		setInputValorPlan(planSelect.valor);
		//setInputValorPago(planSelect.valor);
		sumDias(planSelect.dias, formik);
	};

	const calcularFechas = (formik) => {
		const fechaSeleccionada = document.getElementById("fechaIni").value;
		if (fechaSeleccionada) {
			const fechaInicial = new Date(fechaSeleccionada);
			setFechaIni(fechaInicial.toISOString().split("T")[0]);
		}
		if (planDet) {
			sumDias(planDet.dias, formik);
		}
	};

	const sumDias = (dias, formik) => {
		const fechaSeleccionada = document.getElementById("fechaIni").value;
		if (fechaSeleccionada) {
			const fechaInicial = new Date(fechaSeleccionada);
			const fechaNueva = new Date(
				fechaInicial.getTime() + dias * 24 * 60 * 60 * 1000
			);
			setFechaFin(fechaNueva.toISOString().split("T")[0]);
			formik.setFieldValue("fechaFin", fechaNueva.toISOString().split("T")[0]);
			formik.setFieldValue(
				"fechaIni",
				fechaInicial.toISOString().split("T")[0]
			);
		}
	};

	const getInstructors = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_confiGym_base}/getAllInstructors/${establishment.id}`,
			config
		);
		setInstructors(result.data);
	};

	const calculateIMC = (formik) => {
		const weightInKg = formik.values["peso"];
		const heightInM = formik.values["estatura"];

		if (weightInKg && heightInM) {
			const imc = parseFloat((weightInKg / (heightInM * heightInM)).toFixed(2));

			formik.setFieldValue("imc", imc);
			setInputImc(imc);
			//setResult(imc.toFixed(2));
		} else {
			setInputImc("");
		}
	};

	const handleCancel = () => {
		navigate("/menu/hefestoGym/");
	};

	useEffect(() => {
		getInstructors();
		getPlanes();
	}, []);

	const validationSchema = Yup.object().shape({
		tercero: Yup.number().required("Este campo es requerido"),
		plan: Yup.number().required("Este campo es requerido"),
		valorPlan: Yup.number().required("Este campo es requerido"),
		fechaIni: Yup.date().required("Este campo es requerido"),
		fechaFin: Yup.date().required("Este campo es requerido"),
		peso: Yup.number(),
		estatura: Yup.number(),
		imc: Yup.number(),
		observaciones: Yup.string(),
		active: Yup.boolean(),
		idEstablishment: Yup.number().required("Este campo es requerido"),
	});

	const initialValues = {
		tercero: "",
		plan: "",
		instructores: [],
		valorPlan: "",
		saldo: "",
		fechaIni: "",
		fechaFin: "",
		peso: "",
		estatura: "",
		imc: "",
		observaciones: "",
		active: true,
		idEstablishment: establishment.id,
		estado: 1,
	};

	return (
		<div className="containerFormUser">
			<h1>Inscripción de usuarios</h1>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{(formik, props) => (
					<Form>
						<div className="row">
							{/* Bloque 1 */}
							<div className="col-md-6  ">
								<div className="form-floating mb-2  ">
									<Select
										styles={customStyles}
										isSearchable="true"
										placeholder="Búsqueda de terceros "
										isClearable="true"
										isLoading={isLoading}
										onInputChange={onInputChange}
										options={optionsPersons}
										onChange={(e) => {
											formik.setFieldValue("tercero", e ? e.value : "");
											setOptionsPersons([]);
										}}
									></Select>
									<ErrorMessage
										name="tercero"
										component="div"
										className="error"
									/>
								</div>
							</div>
							<div className="col-md-6  ">
								<div className="form-floating mb-2  ">
									<Select
										styles={customStyles}
										placeholder="Instructores"
										isMulti
										options={instructors.map((rol) => ({
											label:
												rol.nombre1 +
												" " +
												rol.nombre2 +
												" " +
												rol.apellido1 +
												" " +
												rol.apellido2,
											value: rol.id,
										}))}
										//value={selectedOptions}
										onChange={(e) => {
											formik.setFieldValue("instructores", e ? e : "");
										}}
										className="basic-multi-select"
										classNamePrefix="select"
									/>

									<ErrorMessage
										name="instructores"
										component="div"
										className="error"
									/>
								</div>
							</div>
							{/* Bloque 2 */}
							<div className="col-md-6  ">
								<div className="form-floating mb-2  ">
									<Select
										styles={customStyles}
										placeholder="Plan"
										options={planes.map((plan) => ({
											label: plan.plan,
											value: plan.id,
										}))}
										onChange={(e) => {
											formik.setFieldValue("plan", e ? e.value : "");
											handleChangePlan(formik, e);
										}}
										classNamePrefix="select"
									/>

									<ErrorMessage name="plan" component="div" className="error" />
								</div>
							</div>
							<div className="col-md-6  ">
								<div className="form-floating mb-2  ">
									<NumericFormat
										value={inputValorPlan}
										style={{ ...inputStyles, textAlign: "right" }}
										className="form-control"
										thousandSeparator="."
										decimalSeparator=","
										onChange={(e) => {
											formik.setFieldValue(
												"valorPlan",
												e
													? parseFloat(
															e.currentTarget.value
																.replace(/\./g, "")
																.replace(/,/g, ".")
													  )
													: ""
											);
											formik.setFieldValue(
												"saldo",
												e
													? parseFloat(
															e.currentTarget.value
																.replace(/\./g, "")
																.replace(/,/g, ".")
													  )
													: ""
											);
										}}
									/>
									<label htmlFor="valorPlan" className="form-label">
										Valor Plan
									</label>
									<ErrorMessage
										name="valorPlan"
										component="div"
										className="error"
									/>
								</div>
							</div>
							{/* <div className="col-md-4  ">
								<div className="form-floating mb-2  ">
									<NumericFormat
										value={inputValorPago}
										style={{ textAlign: "right" }}
										className="form-control"
										thousandSeparator="."
										decimalSeparator=","
										onChange={(e) => {
											calcularSaldo(
												formik,
												parseFloat(
													e.currentTarget.value
														.replace(/\./g, "")
														.replace(/,/g, ".")
												)
											);
											formik.setFieldValue(
												"valorPago",
												e
													? parseFloat(
															e.currentTarget.value
																.replace(/\./g, "")
																.replace(/,/g, ".")
													  )
													: ""
											);
										}}
									/>
									<label htmlFor="valorPago" className="form-label">
										Valor Pago
									</label>
									<ErrorMessage
										name="valorPago"
										component="div"
										className="error"
									/>
								</div>
							</div> */}
							{/* bloque 3 */}
							<div className="col-md-6  ">
								<div className="form-floating mb-2  ">
									<Field
										style={dateStyles}
										type="date"
										name="fechaIni"
										id="fechaIni"
										className="form-control"
										placeholder="Fecha inicio"
										onChange={(e) => {
											calcularFechas(formik);
											formik.setFieldValue("fechaIni", e.target.value);
										}}
										value={fechaIni}
									/>
									<label htmlFor="fechaIni" className="form-label">
										Fecha inicio
									</label>
									<ErrorMessage
										name="fechaIni"
										component="div"
										className="error"
									/>
								</div>
							</div>
							<div className="col-md-6  ">
								<div className="form-floating mb-2  ">
									<Field
										style={dateStyles}
										type="date"
										name="fechaFin"
										id="fechaFin"
										className="form-control"
										placeholder="Fecha fin"
										value={fechaFin}
										onChange={(e) => {
											formik.setFieldValue("fechaFin", e.target.value);
											setFechaFin(e.target.value);
										}}
									/>
									<label htmlFor="fechaFin" className="form-label">
										Fecha fin
									</label>
									<ErrorMessage
										name="fechaFin"
										component="div"
										className="error"
									/>
								</div>
							</div>
							{/* Bloque 4 */}
							<div className="col-md-4  ">
								<div className="form-floating mb-2  ">
									<NumericFormat
										//value={inputValorPlan}
										style={{ ...inputStyles, textAlign: "right" }}
										className="form-control"
										thousandSeparator="."
										decimalSeparator=","
										onChange={(e) => {
											calculateIMC(formik);
											formik.setFieldValue(
												"peso",
												e
													? parseFloat(
															e.currentTarget.value
																.replace(/\./g, "")
																.replace(/,/g, ".")
													  )
													: ""
											);
										}}
									/>
									<label htmlFor="peso" className="form-label">
										Peso
									</label>
									<ErrorMessage name="peso" component="div" className="error" />
								</div>
							</div>
							<div className="col-md-4  ">
								<div className="form-floating mb-2  ">
									<NumericFormat
										//value={inputValorPlan}
										style={{ ...inputStyles, textAlign: "right" }}
										className="form-control"
										thousandSeparator="."
										decimalSeparator=","
										onChange={(e) => {
											calculateIMC(formik);
											formik.setFieldValue(
												"estatura",
												e
													? parseFloat(
															e.currentTarget.value
																.replace(/\./g, "")
																.replace(/,/g, ".")
													  )
													: ""
											);
										}}
									/>
									<label htmlFor="estatura" className="form-label">
										Estatura
									</label>
									<ErrorMessage
										name="estatura"
										component="div"
										className="error"
									/>
								</div>
							</div>
							<div className="col-md-4  ">
								<div className="form-floating mb-2  ">
									<NumericFormat
										value={inputImc}
										style={{ ...inputStyles, textAlign: "right" }}
										className="form-control"
										thousandSeparator="."
										decimalSeparator=","
										onChange={(e) => {
											formik.setFieldValue(
												"imc",
												e
													? parseFloat(
															e.currentTarget.value
																.replace(/\./g, "")
																.replace(/,/g, ".")
													  )
													: ""
											);
										}}
									/>
									<label htmlFor="imc" className="form-label">
										IMC
									</label>
									<ErrorMessage name="imc" component="div" className="error" />
								</div>
							</div>
							{/* Bloque 5 */}
							<div className="col-md-12  ">
								<div className="form-floating mb-2  ">
									<Field
										style={dateStyles}
										type="textarea"
										name="observaciones"
										id="observaciones"
										className="form-control"
										placeholder="Observaciones"
									/>
									<label htmlFor="observaciones" className="form-label">
										Observaciones
									</label>
									<ErrorMessage
										name="observaciones"
										component="div"
										className="error"
									/>
								</div>
							</div>
							<Field type="hidden" id="active" name="active" />
							<Field
								type="hidden"
								id="idEstablishment"
								name="idEstablishment"
							/>
							<Field type="hidden" id="saldo" name="saldo" />
						</div>
						<button type="submit" className="btn btn-primary">
							Guardar
						</button>
						&nbsp;
						<button className="btn btn-danger" onClick={handleCancel}>
							Cancelar
						</button>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default SuscripcionUsuario;
