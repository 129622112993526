import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { MdOutlineScreenSearchDesktop } from "react-icons/md";

function SuscripcionesGym() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/menu/hefestoAdmin/intro-infoAdmin");
  }, []);

  return (
    <>
      <div id="space">
        <div className="container1 container-fluid">
          <div className="menu">
            <div className="divMenuLog">
              <ul>
                <NavLink className="navInfo" to="info-logs">
                  <li className="navInfoAdmin">
                    <MdOutlineScreenSearchDesktop /> <span> Logs</span>
                  </li>
                </NavLink>
              </ul>
            </div>
          </div>
          <div className="workspace">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

export default SuscripcionesGym;
