import React from "react";
import { FcOrganization, FcBusinessman, FcAcceptDatabase } from "react-icons/fc";
import Dashboard from "../../componentsBase/tables/Intro"; // Asegúrate de que la ruta sea correcta

const ParentComponent = () => {
  const cards = [
    {
      id: 1,
      icon: <FcOrganization />,
      title: "Establecimientos",
      description: "Administra la información relevante de tu empresa",
      to: "/menu/hefestoAdmin/register-establishment", // Ruta correcta
    },
    {
      id: 2,
      icon: <FcBusinessman />,
      title: "Roles",
      description: "Crea roles personalizados según tus necesidades",
      to: "/menu/hefestoAdmin/register-roles", // Ruta correcta
    },
    {
      id: 3,
      icon: <FcAcceptDatabase />,
      title: "Permisos",
      description:
        "Controla la seguridad de tu información con la asignación de permisos",
      to: "/menu/hefestoAdmin/register-permissions", // Ruta correcta
    },
  ];

  return <Dashboard cards={cards} />;
};

export default ParentComponent;
