import React, { useState, useEffect, useContext, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import { AuthContext } from "../../context/LogContext";
import { route_instituciones_base } from "../../../api-routes/RoutesLogin";
import Swal from "sweetalert2";
import * as Yup from "yup";

const customStyles = {
	control: (base) => ({
		...base,
		justifyContent: "Left",
		height: 60,
		minHeight: 35,
	}),
	option: (base) => ({
		...base,
		border: `1px dotted blue`,
	}),
};

const FormasPago = () => {
	const { getToken, inputStyles } = useContext(AuthContext);
	const { establishment } = useContext(AuthContext);
	const [instituciones, setInstituciones] = useState([]);
	const btnCancel = useRef(null);
	const formikRef = useRef(null);
	const inputServicio = useRef(null);

	const handleSubmit = (values) => {
		saveInstitucion(values);
	};
	const saveInstitucion = async (json) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			const response = await axios.post(
				route_instituciones_base + "/save",
				json,
				config
			);

			//const successMessage = "Forma de pago creada exitosamente";
			Swal.fire({
				icon: "success",
				title: response.data,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				btnCancel.current.click();
				getInstituciones(establishment.id);
			});
		} catch (error) {
			if (error.response && error.response.status === 403) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Verifique los datos",
					showConfirmButton: true,
				}).then(() => {});
			} else {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error de red, contactese con su proveedor",
					showConfirmButton: true,
				}).then(() => {});
			}
		}
	};
	const getInstituciones = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_instituciones_base}/listar/${establishment.id}`,
			config
		);
		setInstituciones(result.data);
	};
	const handleServiceEdit = (serviceId) => {
		const instructorDet = instituciones.find((p) => p.id === serviceId);
		if (formikRef.current) {
			formikRef.current.setValues(instructorDet);
		}
	};
	const handleDelete = (servicioId) => {
		Swal.fire({
			title: "Eliminar institución?",
			text: "La institución no debe tener movimientos!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Eliminando...",
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					didOpen: () => {
						eliminarInstitucion(servicioId)
							.then((response) => {
								Swal.fire({
									icon: "success",
									title: "Institución eliminada",
									showConfirmButton: true,
								});
								getInstituciones(establishment.id);
							})
							.catch((error) => {
								console.error(error);
								Swal.fire({
									icon: "error",
									title: "Hubo un error al eliminar la institución",
									text: error.message,
									showConfirmButton: true,
								});
							});
					},
				});
			}
		});
	};

	/* 	const handleActive = (servicioId) => {
		Swal.fire({
			title: "Reactivar forma de pago?",
			text: "La forma de pago  estará disponible!",
			icon: "success",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Reactivando...",
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					didOpen: () => {
						deleteServicio(servicioId, true)
							.then((response) => {
								Swal.fire({
									icon: "success",
									title: "La forma de pago ha sido reactivada",
									showConfirmButton: false,
									timer: 1500,
								}).then(() => {
									getServicios();
								});
							})
							.catch((error) => {
								console.error(error);
								Swal.fire({
									icon: "error",
									title: "Hubo un error al eliminar la forma de pago",
									text: error.message,
									showConfirmButton: true,
								});
							});
					},
				});
			}
		});
	}; */

	const eliminarInstitucion = async (id) => {
		try {
			const response = await axios.delete(
				route_instituciones_base + "/eliminar/" + id,

				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);

			return response.data;
		} catch (error) {
			throw error;
		}
	};
	useEffect(() => {
		getInstituciones(establishment.id);
		//getServicios();
	}, []);

	const validationSchema = Yup.object().shape({
		nombre: Yup.string().required("Ingrese el nombre de la institución"),
		codigo: Yup.string().required("Ingrese el codigo de la institución"),
	});

	const initialValues = {
		id: "",
		nombre: "",
		codigo: "",
		idEstablishment: establishment.id,
	};
	return (
		<div className="containerFormUser">
			<h1>Instituciones Educativas</h1>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
				innerRef={formikRef}
			>
				{(formik, props) => (
					<Form>
						<div className="row">
							{/* BLOQUE1 */}
							<div className="col-md-6  ">
								<div className="form-floating mb-2  ">
									<Field
										//style={{ ...inputStyles }}
										//innerRef={inputServicio}
										type="text"
										name="nombre"
										id="nombre"
										className="form-control"
										placeholder="Institución"
									/>
									<label htmlFor="forma" className="form-label">
										Institución
									</label>
									<ErrorMessage
										name="nombre"
										component="div"
										className="error"
									/>
								</div>
							</div>
							<div className="col-md-6  ">
								<div className="form-floating mb-2  ">
									<Field
										//style={{ ...inputStyles }}
										//innerRef={inputServicio}
										type="text"
										name="codigo"
										id="codigo"
										className="form-control"
										placeholder="Código"
									/>
									<label htmlFor="forma" className="form-label">
										Código
									</label>
									<ErrorMessage
										name="codigo"
										component="div"
										className="error"
									/>
								</div>
							</div>
						</div>
						<button type="submit" className="btn btn-primary">
							Guardar
						</button>
						&nbsp;
						<button type="reset" ref={btnCancel} className="btn btn-danger">
							Cancelar
						</button>
					</Form>
				)}
			</Formik>
			<br></br>
			<h1>Lista de instituciones</h1>
			<table
				style={{ width: "100%" }}
				id="tableUsers"
				className="table table-striped table-hover"
			>
				<thead>
					<tr>
						<th>Nombre</th>
						<th>Código</th>
						<th style={{ width: "20%", textAlign: "right" }}>Opciones</th>
					</tr>
				</thead>
				<tbody>
					{instituciones.map((inst) => (
						<tr key={inst.id}>
							<td>{inst.nombre}</td>
							<td>{inst.codigo}</td>
							<td style={{ width: "20%", textAlign: "right" }}>
								<>
									<button
										className="btnEdit"
										type="button"
										onClick={(e) => handleServiceEdit(inst.id)}
									>
										<i className="fas fa-pen"></i>
									</button>
									&nbsp;&nbsp;
									<button
										className="btnDelete"
										type="button"
										onClick={() => handleDelete(inst.id)}
									>
										<i className="fas fa-trash-alt"></i>
									</button>
								</>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default FormasPago;
