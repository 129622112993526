import React from "react";
import {
	FcPieChart,FcDataSheet,FcBarChart,FcComboChart,FcFinePrint
} from "react-icons/fc";
import Dashboard from "../../componentsBase/tables/Intro"; // Asegúrate de que la ruta sea correcta

const ParentComponent = () => {
	const cards = [
		{
			id: 1,
			icon: <FcPieChart />,
			title: "DashBoard",
			description: "Revisa tu información contable mas releante de forma ágil",
			to: "/menu/hefestoContabilidad/dashboard-contabilidad", // Ruta correcta
		},
		{
			id: 2,
			icon: <FcDataSheet />,
			title: "Informe de Detalles",
			description: "Consulta toda tu infromación contable, mediante filtros dinámicos",
			to: "/menu/hefestoContabilidad/informe-detalle", // Ruta correcta
		},
		{
			id: 3,
			icon: <FcBarChart />,
			title: "Situación Financiera",
			description: "Consulta la situación financiera de tu compañia a la fecha de corte deseada ",
			to: "/menu/hefestoContabilidad/informe-situacion", // Ruta correcta
		},
		{
			id: 4,
			icon: <FcComboChart />,
			title: "Estado de Resultados",
			description: "Consulta el estado de resultados de tu compañia a la fecha de corte deseada ",
			to: "/menu/hefestoContabilidad/estado-resultados", // Ruta correcta
		},
		{
			id: 5,
			icon: <FcFinePrint />,
			title: "Conciliación Bancaria",
			description: "Concilia la infromación contable con tus extractos bancarios",
			to: "/menu/hefestoContabilidad/conciliaciones-contabilidad", // Ruta correcta
		},
		
		
	];

	return <Dashboard cards={cards} />;
};

export default ParentComponent;
