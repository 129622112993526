import styles from "../../../styles/tables/dashboards.module.css";
import React, { useEffect, useContext, useState, useRef } from "react";
import { Bar, Pie, Line } from "react-chartjs-2";
import { route_informesConta_base } from "../../../api-routes/RoutesLogin";
import axios from "axios";
import { AuthContext } from "../../context/LogContext";

const Dashboard = () => {
	const [dataLoaded, setDataLoaded] = useState(false);
	const [info, setInfo] = useState([]);
	const { establishment, getToken } = useContext(AuthContext);
	const [data1, setData1] = useState([]);
	const [data2, setData2] = useState([]);
	const [data3, setData3] = useState([]);
	const [data4, setData4] = useState([]);
	const [data5, setData5] = useState([]);
	const [categorias,setCategorias]=useState({});


	useEffect(() => {
		loadInfo();
	}, []);

	const loadInfo = async () => {
		try {
			const response = await axios.get(
				route_informesConta_base + "/getInformeDash/" + establishment.id,
				{
					headers: {
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			console.log(response.data.valoresxcategoria);
			setCategorias(response.data.valoresxcategoria)
			setData1({
				labels: [
					"Enero",
					"Febrero",
					"Marzo",
					"Abril",
					"Mayo",
					"Junio",
					"Julio",
					"Agosto",
					"Septiembre",
					"Octubre",
					"Noviembre",
					"Diciembre",
				],
				datasets: [
					{
						label: "Cuentas por cobrar",
						data: response.data.cxc,
						backgroundColor: "rgba(0, 123, 255, 0.6)",
						borderColor: "rgba(0, 123, 255, 1)",
						borderWidth: 1,
					},
					{
						label: "Recibos de caja",
						data: response.data.rc,
						borderColor: "rgba(255, 99, 132, 1)",
						backgroundColor: "rgba(255, 99, 132, 0.2)",
						tension: 0.4,
					},
				],
			});
			setData2({
				labels: [
					"Enero",
					"Febrero",
					"Marzo",
					"Abril",
					"Mayo",
					"Junio",
					"Julio",
					"Agosto",
					"Septiembre",
					"Octubre",
					"Noviembre",
					"Diciembre",
				],
				datasets: [
					{
						label: "Cuentas por pagar",
						data: response.data.cxp,
						backgroundColor: "rgba(0, 123, 255, 0.6)",
						borderColor: "rgba(0, 123, 255, 1)",
						borderWidth: 1,
					},
					{
						label: "Comprobantes de egreso",
						data: response.data.ce,
						borderColor: "rgba(255, 99, 132, 1)",
						backgroundColor: "rgba(255, 99, 132, 0.2)",
						tension: 0.4,
					},
				],
			});
			setData3({
				labels: [
					"Enero",
					"Febrero",
					"Marzo",
					"Abril",
					"Mayo",
					"Junio",
					"Julio",
					"Agosto",
					"Septiembre",
					"Octubre",
					"Noviembre",
					"Diciembre",
				],
				datasets: [
					{
						label: "Contables",
						data: response.data.con,
						backgroundColor: "rgba(0, 123, 255, 0.6)",
						borderColor: "rgba(0, 123, 255, 1)",
						borderWidth: 1,
					},
					
				],
			});
			setData4({
				labels: response.data.nomComp,
				datasets: [
					{
						label: "Valor",
						data: response.data.valorComp,
						backgroundColor: "rgba(0, 123, 255, 0.6)",
						borderColor: "rgba(0, 123, 255, 1)",
						borderWidth: 1,
					},
					
				],
			});
			setData5({
				labels: response.data.nomCC,
				datasets: [
					{
						label: "Valor",
						data: response.data.valorCC,
						backgroundColor: "rgba(0, 123, 255, 0.6)",
						borderColor: "rgba(0, 123, 255, 1)",
						borderWidth: 1,
					},
					
				],
			});

			setInfo(response.data);
			setDataLoaded(true); // Se establece que los datos están cargados
		} catch (error) {
			// Manejo de errores
		}
	};

	return (
		<div className={styles.dashboardContainer}>
			{/* Título del Dashboard */}
			<div className={`${styles.dashboardTitle} text-center mb-4`}>
				<h1 className="display-4 text-primary">
					<i className="fas fa-tachometer-alt"></i> FinanceSoft 2024
				</h1>
				<p className="lead text-muted">
					Una visión clara de tu información financiera
				</p>
			</div>

			{/* Sección de tarjetas superiores */}
			{/*  <div className="row mb-4">
        {["Ingresos", "Gastos", "Contables"].map((title) => (
          <div className="col-md-4 mb-3" key={title}>
            <div className={`${styles.card} shadow-sm`}>
              <div className="card-body">
                <h5 className={`${styles.cardTitle} text-center`}>{title}</h5>
                <div className="row">
                  {/* Card 1 *
                  <div className="col-md-6">
                    <div className={`${styles.card} shadow-sm`}>
                      <div className="card-body">
                        <h6 className={`${styles.cardTitle} text-center`}>
                          Cuentas por Cobrar
                        </h6>
                        <table className={`${styles.table} table table-bordered`}>
                          <thead className="thead-light">
                            <tr>
                              <th>Débito</th>
                              <th>Crédito</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>0.00</td>
                              <td>0.00</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* Card 2 *
                  <div className="col-md-6">
                    <div className={`${styles.card} shadow-sm`}>
                      <div className="card-body">
                        <h6 className={`${styles.cardTitle} text-center`}>
                          Recibos de caja
                        </h6>
                        <table className={`${styles.table} table table-bordered`}>
                          <thead className="thead-light">
                            <tr>
                              <th>Débito</th>
                              <th>Crédito</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>0.00</td>
                              <td>0.00</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div> */}

			{/* Sección de tarjetas individuales */}
			<div className="row mb-4">
				{[
					{ title: "Ingresos Brutos", color: "primary", value: categorias.ingresos-categorias.costoDeVentas },
					{ title: "Gastos", color: "danger", value: categorias.gastos+categorias.otrosGastos+categorias.gastoNomina },
					{ title: "Utilidad Operacional", color: "success", value: ( categorias.ingresos-categorias.costoDeVentas)-categorias.gastos-categorias.otrosGastos-categorias.gastoNomina },
					{ title: "Ingresos No Operacionales", color: "info", value: categorias.otrosIngresos },
					{ title: "Gastos No Operacionales", color: "warning", value: categorias.otrosGastos },
					{
						title: "Utilidad Antes de Impuestos",
						color: "dark",
						value: categorias.ingresos+categorias.otrosIngresos- categorias.gastos-categorias.otrosGastos
					},
				].map((card, index) => (
					<div className="col-md-2" key={index}>
						<div className={`${styles.customCard} shadow border-${card.color}`}>
							<div className={styles.customCardBody}>
								<div>
									<h5
										className={`text-${card.color} ${styles.customCardTitle}`}
									>
										{card.title}
									</h5>
									<p className={styles.customCardText}>Valor: {card.value}</p>
								</div>
								<i className={`fas fa-chart-line fa-2x text-${card.color}`}></i>
							</div>
						</div>
					</div>
				))}
			</div>

			{/* Sección de gráficas */}
			{dataLoaded ? (
				<div className="row mb-4">
					{["Ingresos", "Gastos", "Contables"].map((title, index) => (
						<div className="col-md-4 mb-3" key={title}>
							<div className={`${styles.card} shadow-sm`}>
								<div className="card-body">
									<h5 className={`${styles.cardTitle} text-center`}>{title}</h5>
									<div className={styles.chartContainer}>
										{index === 0 && <Line data={data1} />}
										{index === 1 && <Line data={data2} />}
										{index === 2 && <Line data={data3} />}
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			) : (
				/* spinner carga */
				<button className="btn btn-primary" type="button" disabled>
					<span
						className="spinner-border spinner-border-sm"
						aria-hidden="true"
					></span>
					<span role="status"> Cargando...</span>
				</button>
				/*--------------- */
			)}

			{/* Sección de gráficas2 */}
			{dataLoaded ? (
				<div className="row mb-4">
					{["Comprobantes", "Centros de costo"].map((title, index) => (
						<div className="col-md-6 mb-3" key={title}>
							<div className={`${styles.card} shadow-sm`}>
								<div className="card-body">
									<h5 className={`${styles.cardTitle} text-center`}>{title}</h5>
									<div className={styles.chartContainer}>
										{index === 0 && <Bar data={data4} />}
										{index === 1 && <Bar data={data5} />}
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			) : (
				/* spinner carga */
				<button className="btn btn-primary" type="button" disabled>
					<span
						className="spinner-border spinner-border-sm"
						aria-hidden="true"
					></span>
					<span role="status"> Cargando...</span>
				</button>
				/*--------------- */
			)}

			{/* Sección de tablas */}
			{/* <div className="row">
				{["Centros de Costo", "Comprobantes", "Descuentos"].map((title) => (
					<div className="col-md-4 mb-3" key={title}>
						<div className={`${styles.card} shadow-sm`}>
							<div className="card-body">
								<h5 className={`${styles.cardTitle} text-center`}>{title}</h5>
								<table className={`${styles.table} table table-bordered`}>
									<thead className="thead-light">
										<tr>
											<th>#</th>
											<th>Detalle</th>
											<th>Valor</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>1</td>
											<td>Ejemplo</td>
											<td>0.00</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				))}
			</div> */}
		</div>
	);
};

export default Dashboard;
