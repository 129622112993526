import React from "react";
import { FcRatings,FcRules } from "react-icons/fc";import Dashboard from "../../componentsBase/tables/Intro"; // Asegúrate de que la ruta sea correcta
const ParentComponent = () => {
  const cards = [
    {
      id: 1,
      icon: <FcRatings />,
      title: "Informes",
      description: "Consulta las principales actividades realizadas en la adminstracion del sistema. En esta sección tienes los siguientes informes:",
     // to: "/menu/hefestoAdmin/register-establishment", 
      detalles: [
        {
          id: 1,
          icon: <FcRules />,
          title: "LOGS",
          description: "Revisa las acciones ejecutadas por cada usuario",
          to: "/menu/hefestoAdmin/info-logs", 
        },
       
      ],// Ruta correcta
    },
   
  ];

  return <Dashboard cards={cards} />;
};

export default ParentComponent;
