import React, { useState, useEffect, useContext, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import { AuthContext } from "../../context/LogContext";
import { route_contabconf_base } from "../../../api-routes/RoutesLogin";
import Swal from "sweetalert2";
import * as Yup from "yup";

const Estructuras = () => {
	const { getToken } = useContext(AuthContext);
	const { establishment, user, inputStyles } = useContext(AuthContext);
	const [nombres, setNombres] = useState([]);
	const btnCancel = useRef(null);
	const formikRef = useRef(null);
	const [btnCarga, setBtnCarga] = useState(0);
	const [banCarga, setBanCarga] = useState(0);
	const [checkboxStates, setCheckboxStates] = useState([]);

	const handleSubmit = (values) => {
	
		//setBtnCarga(1);
		saveNombre(values, 0);
	};
	const saveNombre = async (json, ban) => {
		json.user = parseInt(localStorage.getItem("idUser"));
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			const response = await axios.post(
				route_contabconf_base + "/saveCentrosC",
				json,
				config
			);
			let successMessage = "";
			if (ban === 0) {
				successMessage = "Centro creado";
			} else {
				successMessage = "Estado modificado";
			}

			Swal.fire({
				icon: "success",
				title: successMessage,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				btnCancel.current.click();
				getCentrosCosto();
				setBtnCarga(0);
			});
		} catch (error) {
			if (error.response && error.response.status === 403) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Verifique los datos",
					showConfirmButton: true,
				}).then(() => {});
			} else {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error de red, contactese con su proveedor",
					showConfirmButton: true,
				}).then(() => {});
			}
		}
	};
	const getCentrosCosto = async () => {
		setBanCarga(1);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_contabconf_base}/getCentrosC/${establishment.id}`,
			config
		);
		const sortedData = result.data.sort((a, b) => {
			if (a.codigo < b.codigo) return -1;
			if (a.codigo > b.codigo) return 1;
			return 0;
		});

		// Asignar el array ordenado al estado
		setNombres(sortedData);
		setBanCarga(0);
	};

	const handleEdit = (serviceId) => {
		const nombreDet = nombres.find((p) => p.id === serviceId);
		if (formikRef.current) {
			formikRef.current.setValues(nombreDet);
		}
	};

	const handleDelete = (servicioId) => {
		Swal.fire({
			title: "Eliminar centro?",
			text: "El centro de costo no debe estar en uso!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Eliminando...",
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					didOpen: () => {
						deleteServicio(servicioId, false)
							.then((response) => {
								Swal.fire({
									icon: "success",
									title: "Centro de costo eliminado",

									showConfirmButton: true,
								}).then(() => {
									getCentrosCosto();
								});
							})
							.catch((error) => {
								console.error(error);
								Swal.fire({
									icon: "error",
									title: "Hubo un error al eliminar la estructura",
									text: error.message,
									showConfirmButton: true,
								});
							});
					},
				});
			}
		});
	};

	const deleteServicio = async (servicioId, status) => {
		let user = localStorage.getItem("idUser")
		try {
			const response = await axios.delete(
				route_contabconf_base + "/deleteCentroC/" + servicioId+","+user,

				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
		
			return response.data;
		} catch (error) {
			
			throw error;
		}
	};
	useEffect(() => {
		getCentrosCosto();
	}, []);

	const validationSchema = Yup.object().shape({
		codigo: Yup.string().required("Ingrese el codigo"),
		nombre: Yup.string().required("Ingrese el nombre "),
	});

	const initialValues = {
		id: "",
		establishment: establishment.id,
		//user: localStorage.getItem("idUser"),
		nombre: "",
		codigo: "",
		status: true,
	};

	const handleCheckboxChange = (id, checked) => {
		let centro = nombres.find((c) => (c.id = id));
	
		centro.status = checked;

		saveNombre(centro, 1);
	};

	const editStatus = async (id, checked) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			let user = localStorage.getItem("idUser")
			const response = await axios.get(
				`${route_contabconf_base}/statusCentroC/${id},${checked},${user}`,
				config
			);

			Swal.fire({
				icon: "success",
				title: "Estado modificado",
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				getCentrosCosto();
				setBtnCarga(0);
			});
		} catch (error) {
	
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Verifique los datos",
				showConfirmButton: true,
			}).then(() => {});
		}
	};
	return (
		<div className="containerFormUser">
			<h1>Centros de costo</h1>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
				innerRef={formikRef}
			>
				{(formik, props) => (
					<Form>
						<div className="row">
							{/* BLOQUE1 */}
							<div className="col-md-5  ">
								<div className="form-floating mb-2  ">
									<Field
										style={inputStyles}
										type="text"
										name="codigo"
										id="codigo"
										className="form-control"
										placeholder="Código"
									/>
									<label htmlFor="forma" className="form-label">
										Código
									</label>
									<ErrorMessage
										name="codigo"
										component="div"
										className="error"
									/>
								</div>
							</div>
							<div className="col-md-5  ">
								<div className="form-floating mb-2  ">
									<Field
										style={inputStyles}
										type="text"
										name="nombre"
										id="estructura"
										className="form-control"
										placeholder="Estructura"
									/>
									<label htmlFor="forma" className="form-label">
										Nombre
									</label>
									<ErrorMessage
										name="nombre"
										component="div"
										className="error"
									/>
								</div>
							</div>
							<div
								className="col-md-2"
								style={{
									textAlign: "left",
									padding: "10px",
									paddingRight: "30px",
								}}
							>
								{btnCarga === 0 ? (
									<button type="submit" className="btn btn-primary">
										Guardar
									</button>
								) : (
									/* spinner carga */
									<button className="btn btn-primary" type="button" disabled>
										<span
											className="spinner-border spinner-border-sm"
											aria-hidden="true"
										></span>
										<span role="status"> Guardando...</span>
									</button>
									/*--------------- */
								)}
								&nbsp;
								<button type="reset" ref={btnCancel} className="btn btn-danger">
									Cancelar
								</button>
							</div>
						</div>
					</Form>
				)}
			</Formik>
			<br></br>
			<h1>Lista de centros</h1>
			{banCarga == 0 ? (
				<table
					style={{ width: "100%" }}
					id="tableUsers"
					className="table table-striped table-hover"
				>
					<thead>
						<tr>
							<th>Código</th>
							<th>Nombre</th>
							<th style={{ width: "20%", textAlign: "right" }}>Opciones</th>
						</tr>
					</thead>
					<tbody>
						{nombres.map((servicio) => (
							<tr key={servicio.id}>
								<td>{servicio.codigo}</td>
								<td>{servicio.nombre}</td>
								<td style={{ width: "20%", textAlign: "right" }}>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "flex-end",
											gap: "10px",
											width: "100%",
										}}
									>
										<div className="form-check form-switch">
											<input
												className="form-check-input"
												type="checkbox"
												role="switch"
												checked={servicio.status}
												onChange={(e) => {
													editStatus(servicio.id, e.target.checked);
												}}
											></input>
										</div>
										<button
											className="btnEdit"
											type="button"
											onClick={(e) => handleEdit(servicio.id)}
										>
											<i className="fas fa-pen"></i>
										</button>
										<button
											className="btnDelete"
											type="button"
											onClick={() => handleDelete(servicio.id)}
										>
											<i className="fas fa-trash-alt"></i>
										</button>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			) : (
				/* spinner carga */
				<button className="btn btn-primary" type="button" disabled>
					<span
						className="spinner-border spinner-border-sm"
						aria-hidden="true"
					></span>
					<span role="status"> Cargando...</span>
				</button>
				/*--------------- */
			)}
		</div>
	);
};

export default Estructuras;
