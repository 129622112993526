import React, { useState, useContext } from "react";
import {
	useTable,
	useSortBy,
	useGroupBy,
	usePagination,
	useFilters,
} from "react-table";
import { BsFiletypePdf } from "react-icons/bs";
import { AuthContext } from "../../context/LogContext";
import { FaFileExcel } from "react-icons/fa";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver"; // Importa saveAs desde file-saver
import { FaFileSignature } from "react-icons/fa";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const MyTable = ({ data, columns, encabezado }) => {
	const exportToExcel = async () => {
		const headers = columns.map((col) => col.Header);
		const dataRows = data.map((row) => columns.map((col) => row[col.accessor]));

		const result = data.map((item) => {
			const row = {};
			columns.forEach((header) => {
				const fieldName = header.accessor;
				const headerName = header.Header;
				row[headerName] = item[fieldName];
			});
			return row;
		});

		// Crear un nuevo libro de Excel
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet("Sheet 1");

		// Establecer el título en la celda A1
		const titleCell = worksheet.getCell("D2");
		titleCell.value = encabezado.empresa;
		titleCell.font = { size: 12, bold: true };
		titleCell.alignment = { vertical: "middle", horizontal: "left" };

		// Establecer otras celdas de encabezado
		const nitCell = worksheet.getCell("D3");
		nitCell.value = encabezado.nit;
		nitCell.font = { size: 9, bold: false };

		const informeCell = worksheet.getCell("D4");
		informeCell.value = encabezado.titulo;
		informeCell.font = { size: 12, bold: true };

		const fechasCell = worksheet.getCell("D5");
		fechasCell.value = encabezado.fechas;
		fechasCell.font = { size: 9, bold: false };

		const generadoCell = worksheet.getCell("D6");
		generadoCell.value = encabezado.generado;
		generadoCell.font = { size: 9, bold: false };

		// Insertar la imagen (si informe.imagen es una URL válida)
		if (encabezado.imagen) {
			const imageId = workbook.addImage({
				base64: encabezado.imagen,
				extension: "png",
			});

			worksheet.addImage(imageId, "A1:B6"); // Ajusta el rango según tu necesidad
		}
		// generamos dos filas de espaio en blanco
		const espacioCell = worksheet.getCell("D7");
		//generadoCell.value = "";
		const espacioCell2 = worksheet.getCell("D8");
		//generadoCell.value = "";

		columns.forEach((column, index) => {
			worksheet.getColumn(index + 1).width = 12; // Index + 1 porque el índice de las columnas comienza en 1
		});

		// Insertar el encabezado de las columnas en la fila 8 (después de las celdas de encabezado)
		const headerRow = worksheet.addRow(headers);
		// Establecer bordes delgados y fondo de color azul claro para las celdas de encabezado
		headerRow.eachCell((cell) => {
			cell.width = 50;
			cell.font = { size: 10, bold: true };
			cell.alignment = { vertical: "middle", horizontal: "center" };
			cell.border = {
				top: { style: "thin" },
				left: { style: "thin" },
				bottom: { style: "thin" },
				right: { style: "thin" },
			};
			cell.fill = {
				type: "pattern",
				pattern: "solid",
				fgColor: { argb: "83a9f0" }, // Código de color para azul claro
			};
		});

		// Insertar los datos a partir de la fila 9
		result.forEach((row) => worksheet.addRow(Object.values(row)));

		// Guardar el libro de Excel como un archivo
		const buffer = await workbook.xlsx.writeBuffer();
		const blob = new Blob([buffer], {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		});
		saveAs(blob, encabezado.titulo + ".xlsx");
	};
	const { inputStyles } = useContext(AuthContext);



	const exportToPDF = () => {
		const unit = "pt";
		const size = "legal";
		const orientation = "landscape";
	
		const marginLeft = 40;
		const doc = new jsPDF(orientation, unit, size);
	
		doc.setFontSize(10);
	
		// Agregar la imagen al encabezado
		if (encabezado.imagen) {
			doc.addImage(encabezado.imagen, "png", marginLeft, 30, 100, 100); // Ajusta las coordenadas y dimensiones según tu necesidad
		}
	
		const headers = columns.map((col) => col.Header);
		const dataRows = data.map((row) =>
			columns.map((col) => {
				// Formatear datos numéricos con indicadores de mil
				const value = row[col.accessor];
				return typeof value === "number" ? numberWithCommas(value) : value;
			})
		);
	
		// Agregar el título al encabezado
		doc.text(encabezado.empresa, marginLeft + 120, 40);
		doc.text(encabezado.nit, marginLeft + 120, 60);
		doc.text(encabezado.titulo, marginLeft + 120, 80);
		doc.text(encabezado.fechas, marginLeft + 120, 100);
		doc.text(encabezado.generado, marginLeft + 120, 120);
	
		// Configurar estilos de columna
		const columnStyles = {};
		columns.forEach((col, index) => {
			columnStyles[index] = { halign: typeof data[0][col.accessor] === "number" ? "right" : "left" };
		});
	
		doc.autoTable({
			startY: 140,
			head: [headers],
			body: dataRows,
			styles: {
				fontSize: 8,
				cellPadding: 2,
				cellWidth: "auto",
				valign: "middle",
			},
			columnStyles: columnStyles,
		});
	
		// Agregar columna de firmas
		const firmasData = firmas.map((firma, index) => {
			const columnWidth = (doc.internal.pageSize.width - (2 * marginLeft)) / 2;
			const rowHeight = 120;
			const columnIndex = index % 2;
			const rowIndex = Math.floor(index / 2);
			const x = marginLeft + (columnIndex * columnWidth);
			const y = doc.autoTable.previous.finalY + 80 + (rowIndex * rowHeight);
	
			doc.text("_________________________", x, y);
			doc.text(firma.nombre, x, y + 15);
			doc.text(firma.cargo, x, y + 30);
			doc.text(firma.identificacion, x, y + 45);
			doc.text(firma.tarjetaProfesional, x, y + 60);
	
			return null; // No necesitamos retornar nada para firmasData
		});
	
		doc.save(encabezado.titulo + ".pdf");
	};
	
	
	const numberWithCommas = (x) => {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};
	const [firmas, setFirmas] = useState([]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setFilter,
		state: { pageIndex },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0, pageSize: 20 },
		},
		useFilters,
		useGroupBy,
		useSortBy,
		usePagination
	);

	const handleFilterChange = (columnId, event) => {
		const value = event.target.value || undefined;
		setFilter(columnId, value);
	};

	const isValidBase64 = (base64String) => {
		try {
			atob(base64String);
			return true;
		} catch (e) {
			return false;
		}
	};
	function formatNumber(number) {
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
	const handleSubmit = (values, { resetForm }) => {
		setFirmas([...firmas, values]);
		resetForm();
	};
	const validationSchema = Yup.object().shape({
		nombre: Yup.string().required("El nombre es requerido"),
		identificacion: Yup.string().required("La identificación es requerida"),
		cargo: Yup.string(),
		tarjetaProfesional: Yup.string(),
	});
	const handleEliminarEmpleado = (index) => {
		setFirmas(firmas.filter((_, i) => i !== index));
	};

	return (
		<>
			<div style={{ display: "flex", justifyContent: "flex-start" }}>
				<button
					className="btnInfo"
					onClick={exportToExcel}
					title="Generar Excel"
				>
					<FaFileExcel size={20} />
				</button>
				&nbsp;
				<button className="btnInfo" onClick={exportToPDF} title="Generar PDF">
					<BsFiletypePdf size={20} />
				</button>
				&nbsp;
				<button
					type="button"
					className="btnInfo"
					data-bs-toggle="modal"
					data-bs-target="#exampleModal"
					title="Configurar firmas"
					onClick={() => {}}
				>
					<FaFileSignature />
				</button>
			</div>
			<div>
				<table className="reactTables" {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps(column.getSortByToggleProps())}>
										{column.render("Header")}
										<span>
											{column.isSorted
												? column.isSortedDesc
													? " 🔽"
													: " 🔼"
												: ""}
										</span>
										<div>
											{column.canFilter ? (
												<input
													type="text"
													value={column.filterValue || ""}
													onChange={(e) => handleFilterChange(column.id, e)}
												/>
											) : null}
										</div>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell) => (
										<>
										<td
											style={{
												backgroundColor: row.original.color,
												textAlign:
													typeof cell.value === "number" ? "right" : "left", // Alineación derecha para números, izquierda para otros tipos
											}}
											{...cell.getCellProps()}
										>
											{typeof cell.value === "number"
												? formatNumber(cell.value)
												: cell.render("Cell")}
										</td>
										
										</>
									))}
									
								</tr>
							);
						})}
					</tbody>
				</table>
				<div>
					<button
						className="btn btn-primary"
						onClick={() => gotoPage(0)}
						disabled={!canPreviousPage}
					>
						{"<<"}
					</button>
					&nbsp;
					<button
						className="btn btn-primary"
						onClick={() => previousPage()}
						disabled={!canPreviousPage}
					>
						{"<"}
					</button>
					&nbsp;
					<button
						className="btn btn-primary"
						onClick={() => nextPage()}
						disabled={!canNextPage}
					>
						{">"}
					</button>
					&nbsp;
					<button
						className="btn btn-primary"
						onClick={() => gotoPage(pageCount - 1)}
						disabled={!canNextPage}
					>
						{">>"}
					</button>
					&nbsp;
					<span>
						Página{" "}
						<strong>
							{pageIndex + 1} de {pageOptions.length}
						</strong>{" "}
					</span>
				</div>
			</div>
			{/* modal para configurar cunetas por banco */}
			<div
				class="modal fade"
				id="exampleModal"
				tabindex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div class="modal-dialog modal-lg modal-dialog-scrollable">
					<div class="modal-content">
						<div class="modal-header">
							<h1 class="modal-title fs-5" id="exampleModalLabel">
								Configuración de firmas
							</h1>
							<button
								type="button"
								class="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div class="modal-body">
							<Formik
								initialValues={{
									nombre: "",
									identificacion: "",
									cargo: "",
									tarjetaProfesional: "",
								}}
								validationSchema={validationSchema}
								onSubmit={handleSubmit}
							>
								{({ isSubmitting }) => (
									<Form>
										<div className="row">
											<div className="col-md-6  ">
												<div className="form-floating mb-2  ">
													<Field
														style={inputStyles}
														type="text"
														name="nombre"
														id="nombre"
														className="form-control"
														placeholder="Nombre"
													/>
													<label htmlFor="forma" className="form-label">
														Nombre
													</label>
													<ErrorMessage
														name="nombre"
														component="div"
														className="error"
													/>
												</div>
											</div>

											<div className="col-md-6  ">
												<div className="form-floating mb-2  ">
													<Field
														style={inputStyles}
														type="text"
														name="identificacion"
														id="identificacion"
														className="form-control"
														placeholder="Identificacion"
													/>
													<label htmlFor="forma" className="form-label">
														Identificación
													</label>
													<ErrorMessage
														name="identificacion"
														component="div"
														className="error"
													/>
												</div>
											</div>
										</div>
										{/* ----- */}
										<div className="row">
											<div className="col-md-6  ">
												<div className="form-floating mb-2  ">
													<Field
														style={inputStyles}
														type="text"
														name="cargo"
														id="cargo"
														className="form-control"
														placeholder="Cargo"
													/>
													<label htmlFor="forma" className="form-label">
														Cargo
													</label>
													<ErrorMessage
														name="cargo"
														component="div"
														className="error"
													/>
												</div>
											</div>

											<div className="col-md-6  ">
												<div className="form-floating mb-2  ">
													<Field
														style={inputStyles}
														type="text"
														name="tarjetaProfesional"
														id="tarjetaProfesional"
														className="form-control"
														placeholder="Tarjeta Profesional"
													/>
													<label htmlFor="forma" className="form-label">
														Tarjeta Profesional
													</label>
													<ErrorMessage
														name="tarjetaProfesional"
														component="div"
														className="error"
													/>
												</div>
											</div>
										</div>

										<button
											className="btn btn-primary "
											type="submit"
											disabled={isSubmitting}
										>
											Guardar
										</button>
									</Form>
								)}
							</Formik>
						</div>
						<div
							class="modal-footer"
							//style={{ height: "200px", overflowY: "auto" }}
						>
							<table
								style={{ width: "100%" }}
								id="tableUsers"
								className="table table-striped table-hover"
							>
								<thead>
									<tr>
										<th style={{ textAlign: "center" }}>Nombre</th>
										<th style={{ textAlign: "center" }}>Identificación</th>
										<th style={{ textAlign: "center" }}>Cargo</th>
										<th style={{ textAlign: "center" }}>Tarjeta profesional</th>
										<th style={{ width: "10%", textAlign: "right" }}>
											Opciones
										</th>
									</tr>
								</thead>
								<tbody>
									{firmas.map((empleado, index) => (
										<tr key={index}>
											<td>{empleado.nombre}</td>
											<td>{empleado.identificacion}</td>
											<td>{empleado.cargo}</td>
											<td>{empleado.tarjetaProfesional}</td>
											<td>
												<button
													className="btnDelete"
													type="button"
													onClick={() => handleEliminarEmpleado(index)}
												>
													<i className="fas fa-trash-alt"></i>
												</button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

			{/* --------------------------------------- */}
		</>
	);
};

export default MyTable;
