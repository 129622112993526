import React, { useState, useEffect, useContext } from "react";
import { NavLink, useParams } from "react-router-dom";
import { AuthContext } from "../../context/LogContext";
import { Formik, Form, Field } from "formik";
import Select from "react-select";
import axios from "axios";
import {
	route_procesosConta_base,
	route_person_searchPerson,
	route_contabconf_base,
	route_informesConta_base,
} from "../../../api-routes/RoutesLogin";
import TableIngresos from "../../componentsBase/tables/TableConciliaciones";

const Conciliacion = () => {
	useEffect(() => {
		loadFiltros();
		getDetalles();
	}, []);
	const columns = [
		{ Header: "Fecha", accessor: "fecha" },
		{ Header: "Cuenta", accessor: "cuenta" },
		{ Header: "No registros ", accessor: "noRegistros" },
		{ Header: "Ingresos", accessor: "totalIngresos" },
		{ Header: "Gastos", accessor: "totalGastos" },
	];
	const sumFields = ["totalIngresos", "totalGastos"];

	const {
		establishment,
		getToken,
		customStyles,
		inputStyles,
		dateStyles,
		FechaYMDHMS,
	} = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(false);
	const [optionsPersons, setOptionsPersons] = useState([]);
	const [personSelected, setPersonSelected] = useState(null);
	const [banCarga, setBanCarga] = useState(0);
	const [options, setOptions] = useState({});
	const [data, setData] = useState([]);

	const onInputChange = (inputValue) => {
		if (
			typeof inputValue === "string" &&
			inputValue != "" &&
			inputValue.length >= 3
		) {
			loadPersons(inputValue);
		}
	};
	const loadPersons = async (inputValue) => {
		setIsLoading(true);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		try {
			const response = await axios.get(
				route_person_searchPerson +
					establishment.id +
					"," +
					inputValue +
					",true",
				config
			);
			const data = response.data;
			const formattedOptions = data.map((item) => ({
				value: item.id,
				label:
					item.identification +
					" - " +
					item.name1 +
					" " +
					item.name2 +
					" " +
					item.lastName1 +
					" " +
					item.lastName2 +
					" - " +
					item.businessName,
			}));

			setOptionsPersons(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
			setIsLoading(false);
		}
	};

	function customSort(a, b) {
		// Ordenar por 'codigo'
		if (a.codigo < b.codigo) return -1;
		if (a.codigo > b.codigo) return 1;

		// Si 'codigo' es igual, ordenar por 'tipo'
		if (a.tipo < b.tipo) return -1;
		if (a.tipo > b.tipo) return 1;

		// Si 'codigo' y 'tipo' son iguales, ordenar por 'naturaleza'
		if (a.naturaleza < b.naturaleza) return -1;
		if (a.naturaleza > b.naturaleza) return 1;

		return 0;
	}
	const loadFiltros = async (inputValue) => {
		setIsLoading(true);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		try {
			const response = await axios.get(
				route_contabconf_base + "/getOptions/" + establishment.id,
				config
			);
			const data = response.data;
			//let order = response.data.cuentas.sort(customSort);
			//data.cuentas=order;
			data.tiposC = [
				{ value: 1, label: "Ingresos" },
				{ value: 2, label: "Gastos" },
				{ value: 3, label: "Contables" },
			];
			/* data.categoriasC = [
				{ value: 1, label: "Ingresos" },
				{ value: 2, label: "Gastos" },
				{ value: 3, label: "Contables" },
			]; */
			setOptions(data);
			console.log(data);

			//setOptionsPersons(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
			setIsLoading(false);
		}
	};
	const getDetalles = async (values) => {
		setBanCarga(1);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_procesosConta_base}/getConciliacionesEjecutadas/${establishment.id}`,
			 config
		);
		setData(result.data);
		setBanCarga(0);
	};
	const stylePlan = {
		control: (base) => ({
			...base,
			height: 80,
			minHeight: 35,
			fontSize: "14px",
			height: "45px",
		}),
		option: (base, { data, isDisabled }) => ({
			...base,
			textAlign: `left`,
			backgroundColor: data.tipo === 1 ? "#5fb7eb" : "#3879db",
			color: "white",
		}),
	};
	return (
		<>
			<div className="containerFormUser">
				<h1>Conciliaciones</h1>
				<div className="row justify-content-center">
					<div className="col-auto">
						<NavLink
							className="navInfo"
							to={{
								pathname:
									"/menu/hefestoContabilidad/nuevaConciliacion-contabilidad/",
							}}
						>
							<button className="btn btn-info" type="button">
								Nueva Conciliación
							</button>
						</NavLink>
					</div>
				</div>
				<div className="table-container-reacTable">
					{banCarga === 0 ? (
						<TableIngresos
							data={data}
							columns={columns}
							encabezado={{
								empresa: establishment.name,
								nit: establishment.identification,
								titulo: "Informe Detallado",
								imagen: establishment.image,
								fechas: "Informacion corte: ",
								generado: "Informe generado: " + FechaYMDHMS,
							}}
							sumFields={sumFields}
						/>
					) : (
						/* spinner carga */
						<button className="btn btn-primary" type="button" disabled>
							<span
								className="spinner-border spinner-border-sm"
								aria-hidden="true"
							></span>
							<span role="status"> Cargando información...</span>
						</button>
						/*--------------- */
					)}
				</div>
			</div>
		</>
	);
};

export default Conciliacion;
