import React, { useState, useEffect, useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
	route_establishments_getAll,
	route_roles_getByEstablishment,
	route_user_register,
	route_user_id,
} from "../../../api-routes/RoutesLogin";
import { AuthContext } from "../../context/LogContext";
import axios from "axios";
import { show_alert } from "../../../functions";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

export default function RegisterUser(props) {
	const { establishment, getToken, inputStyles } = useContext(AuthContext);
	//creamos el estado loading que controla la carga de info de roles y establecimientos
	const [isLoading, setIsLoading] = useState(true);
	// Creamos el estado para almacenar los roles seleccionados
	const [roles, setRoles] = useState([]);
	const [estabs, setEstabs] = useState([]);
	// Creamos el estado para almacenar el valor seleccionado en el select
	const [selectedRole, setSelectedRole] = useState("");
	const [selectedEstab, setSelectedEstab] = useState("");
	// Array de opciones para el select
	const [optionsRoles, setOptionsRoles] = useState([]);
	const [optionsEstabs, setOptionsEstabs] = useState([]);
	// Array de respaldo de options para reestablecer al cancelar
	const [optionsRolesBackup, setOptionsRolesBackup] = useState([]);
	const [optionsEstabsBackup, setOptionsEstabsBackup] = useState([]);
	//estado para edicion
	const [userToEdit, setUserToEdit] = useState(null);
	const [showForm, setShowForm] = useState(false);
	//estado check admin
	// estado para cambiar email a mayus
	//------------------------------------
	const { userId } = useParams();
	const navigate = useNavigate();
	const [btnCarga, setBtnCarga] = useState(0);

	// Función para manejar el evento cuando se agrega un rol
	function handleAddRole() {
		if (selectedRole) {
			var array = [];
			var array2 = [];
			array = optionsRoles.filter((rol) => rol.id == selectedRole);
			setRoles([...roles, array[0]]);
			array2 = optionsRoles.filter((rol) => rol.id != selectedRole);
			setOptionsRoles(array2);
			setSelectedRole("");
		}
	}
	// Función para manejar el evento cuando se elimina un rol
	function handleRemoveRole(index) {
		var array = [];
		array = roles.filter((rol) => rol.id == index);
		setOptionsRoles([...optionsRoles, array[0]]);
		array = roles.filter((rol) => rol.id != index);
		setRoles(array);
	}
	function handleAddEstab() {
		if (selectedEstab) {
			var array = [];
			var array2 = [];
			array = optionsEstabs.filter((estab) => estab.id == selectedEstab);
			setEstabs([...estabs, array[0]]);
			array2 = optionsEstabs.filter((estab) => estab.id != selectedEstab);
			setOptionsEstabs(array2);
			setSelectedEstab("");
		}
	}
	// Función para manejar el evento cuando se elimina un rol
	function handleRemoveEstab(index) {
		var array = [];
		array = estabs.filter((estab) => estab.id == index);
		setOptionsEstabs([...optionsEstabs, array[0]]);
		array = estabs.filter((estab) => estab.id != index);
		setEstabs(array);
	}
	// funcion almacenamiento de usuario, permisos y roles
	const registrarUsuario = async (json) => {
		setBtnCarga(1)
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		return axios
			.post(route_user_register, json, config)
			.then((response) => {
				if (response.data.errorMessage != null) {
					show_alert(response.data.errorMessage, "error");
					return false;
				} else {
					Swal.fire({
						icon: "success",
						title: "Usuario creado exitosamente",
						showConfirmButton: false,
						timer: 1500,
					}).then(() => {
						setBtnCarga(0)
						navigate("/menu/hefestoAdmin/search-users");
					});
					//show_alert("Usuario creado exitosamente", "success");
				}
			})
			.catch((error) => {
				console.error(error);
				throw error;
			});
	};
	//funcion cancelar
	const cancelRegister = () => {
		setRoles([]);
		setEstabs([]);
		setOptionsRoles(optionsRolesBackup);
		setOptionsEstabs(optionsEstabsBackup);
	};
	//funcin verificar opciones de listado en edicion

	useEffect(() => {
		var arrayEstablishments = [];
		var arrayRoles = [];

		axios
			.get(route_establishments_getAll, {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			})
			.then((response) => {
				setOptionsEstabs(response.data);
				setOptionsEstabsBackup(response.data);
				arrayEstablishments = response.data;
			})
			.catch((error) => {
			});

		axios
			.get(route_roles_getByEstablishment + establishment.id, {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			})
			.then((response) => {
				setOptionsRoles(response.data);
				setOptionsRolesBackup(response.data);
				arrayRoles = response.data;
				setIsLoading(false);
			})
			.catch((error) => {
			});
		if (userId) {
			axios
				.get(route_user_id + userId, {
					headers: {
						Authorization: `Bearer ${getToken()}`,
					},
				})
				.then((response) => {
					var array = [];
					var array2 = [];
					setUserToEdit(response.data);

					setShowForm(true);
					//asignar establecimientos asignados
					response.data.estabs.map((esta) => {
						array.push(esta.establishmentxuser);
					});
					setEstabs(array);
					const resultEstablishments = arrayEstablishments.filter(
						(userA) => !array.find((userB) => userB.id == userA.id)
					);
					setOptionsEstabs(resultEstablishments);
					//asignar roles asignados
					response.data.roles.map((rol) => {
						array2.push(rol.rolesxrol);
						return null;
					});
					setRoles(array2);
					const resultRoles = arrayRoles.filter(
						(userA) => !array2.find((userB) => userB.id == userA.id)
					);
					setOptionsRoles(resultRoles);
				})
				.catch((error) => {
				});
		} else {
			setShowForm(true);
		}
	}, []);

	const handleKeyPress = (event) => {
		if (event.charCode === 32) {
			event.preventDefault();
		}
	};

	return (
		<div className="containerFormUser">
			<h1>Registro Usuarios</h1>
			{showForm && (
				<Formik
					initialValues={{
						id: userToEdit ? userToEdit.id : null,
						name: userToEdit ? userToEdit.name : "",
						last_name: userToEdit ? userToEdit.lastName : "",
						email: userToEdit ? userToEdit.email : "",
						password: "",
						confirm_password: "",
						image: null,
						phone: userToEdit ? userToEdit.phoneNumber : "",
						admin: userToEdit ? userToEdit.admin : false,
						//user:localStorage.getItem("idUser")
					}}
					validate={(values) => {
						const errors = {};
						if (!values.name) {
							errors.name = "El nombre es requerido";
						}
						if (!values.last_name) {
							errors.last_name = "El apellido es requerido";
						}
						if (!values.email) {
							errors.email = "El email es requerido";
						} else if (
							!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
						) {
							errors.email = "Ingrese un email valido y activo";
						}
						if (!values.password && !userToEdit) {
							errors.password = "Password requerido";
						}

						if (!values.confirm_password && !userToEdit) {
							errors.confirm_password = "Verificación requerida";
						} else if (
							values.confirm_password != values.password &&
							!userToEdit
						) {
							errors.confirm_password = "Verifique la contraseña";
						}
						if (!values.phone) {
							errors.phone = "Telefono requerido";
						} else if (!/^\d+$/.test(values.phone)) {
							errors.phone = "Número invalido";
						}

						return errors;
					}}
					onSubmit={(values, { setSubmitting }) => {
						if (roles.length == 0) {
							show_alert("Configure los roles", "error");
						} else if (estabs.length == 0) {
							show_alert("Configure los establecimientos", "error");
						} else {
							values.user = parseInt(localStorage.getItem("idUser"));
						
							let data = { values, roles, estabs };
							let dataJson = JSON.stringify(data);
						
							setSubmitting(false);

							registrarUsuario(dataJson);
						}
					}}
				>
					{({ values, isSubmitting, setFieldValue }) => (
						<Form id="formUser">
							<div className="row">
								<div className="col-md-6">
									<div className="form-floating mb-2">
										<Field
											style={inputStyles}
											type="text"
											name="name"
											id="name"
											className="form-control"
											placeholder="Nombre"
										/>
										<label htmlFor="name" className="form-label">
											Nombre
										</label>
										<ErrorMessage
											name="name"
											component="div"
											className="error"
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-floating mb-2">
										<Field type="hidden" name="id" id="id" />
										<Field
											style={inputStyles}
											type="text"
											name="last_name"
											id="last_name"
											className="form-control"
											placeholder="Apellido"
										/>
										<label htmlFor="last_name" className="form-label">
											Apellido
										</label>
										<ErrorMessage
											name="last_name"
											component="div"
											className="error"
										/>
									</div>
								</div>

								<div className="col-md-6">
									<div className="form-floating mb-2">
										<Field
											style={inputStyles}
											type="email"
											name="email"
											id="email"
											className="form-control"
											placeholder="Email"
											//value={mail}
											onKeyPress={handleKeyPress}
										/>
										<label htmlFor="email" className="form-label">
											Email
										</label>
										<ErrorMessage
											name="email"
											component="div"
											className="error"
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-floating mb-2">
										<Field
											style={inputStyles}
											type="text"
											name="phone"
											id="phone"
											className="form-control"
											placeholder="Número de contacto"
										/>
										<label htmlFor="phone" className="form-label">
											Número telefónico
										</label>
										<ErrorMessage
											name="phone"
											component="div"
											className="error"
										/>
									</div>
								</div>
								{!userToEdit ? (
									<>
										<div className="col-md-6">
											<div className="form-floating mb-2">
												<Field
													type="password"
													name="password"
													id="password"
													className="form-control"
													placeholder="Password"
												/>
												<label htmlFor="name" className="form-label">
													Password
												</label>
												<ErrorMessage
													name="password"
													component="div"
													className="error"
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-floating mb-2">
												<Field
													type="password"
													name="confirm_password"
													id="confirm_password"
													className="form-control"
													placeholder="confirm_password"
												/>
												<label
													htmlFor="confirm_password"
													className="form-label"
												>
													Confirmar Password
												</label>
												<ErrorMessage
													name="confirm_password"
													component="div"
													className="error"
												/>
											</div>
										</div>
									</>
								) : (
									<></>
								)}
								{/* <div className="col-md-3 divAdmin">
									<div class="form-check form-switch">
										<Field
											className="form-check-input"
											type="checkbox"
											role="switch"
											id="admin"
											name="admin"
										></Field>
										<label className="form-check-label" for="admin">
											Administrador
										</label>
									</div>
								</div> */}
								{/* <div className="col-md-6">
									<div className="form-floating mb-2">
										<div>
											<input
												className="form-control form-control-lg"
												id="image"
												type="file"
												onChange={(e) => {
													setFieldValue("image", e.target.files[0]);
												}}
											/>
										</div>
									</div>
								</div> */}

								{/* <div className="col-md-6">
									<div className="form-check form-switch">
										<input
											className="form-check-input"
											type="checkbox"
											role="switch"
											id="flexSwitchCheckDefault"
										/>
										<label className="form-check-label" for="flexSwitchCheckDefault">
											Administrador
										</label>
									</div>

								</div> */}
							</div>
							<div className="row">
								{!isLoading ? (
									<>
										{/* Formulario de roles */}
										<div id="containerRoles" className="col-md-6">
											<h2>Roles</h2>
											<div className="input-group">
												<select
													className="form-select"
													id="selectRoles"
													value={selectedRole}
													onChange={(event) =>
														setSelectedRole(event.target.value)
													}
												>
													<option>Selecciona un rol</option>
													{optionsRoles.map((rol) => (
														<option key={rol.id} value={rol.id}>
															{rol.name}
														</option>
													))}
												</select>
												<button
													className="btn btn-primary"
													type="button"
													onClick={handleAddRole}
												>
													Agregar
												</button>
											</div>
											<table className="table table-striped table-hover">
												<thead>
													<tr>
														<th>Rol</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													{roles.map((rol) => (
														<tr key={rol.id}>
															<td>{rol.name}</td>
															<td style={{ textAlign: "right" }}>
																<button
																	className="btnDelete"
																	type="button"
																	onClick={() => handleRemoveRole(rol.id)}
																>
																	<i className="fas fa-trash-alt"></i>
																</button>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
										{/* formulario de establecimientos */}
										<div id="containerRoles" className="col-md-6  ">
											<h2>Establecimientos</h2>
											<div className="input-group">
												<select
													className="form-select"
													id="inputGroupSelect05"
													value={selectedEstab}
													onChange={(event) =>
														setSelectedEstab(event.target.value)
													}
												>
													<option value="">
														Selecciona un establecimiento
													</option>
													{optionsEstabs.map((estab) => (
														<option key={estab.id} value={estab.id}>
															{estab.name}
														</option>
													))}
												</select>
												<button
													className="btn btn-primary"
													type="button"
													onClick={handleAddEstab}
												>
													Agregar
												</button>
											</div>
											<table className="table table-striped table-hover">
												<thead>
													<tr>
														<th>Establecimiento</th>
														<th>Opciones</th>
													</tr>
												</thead>
												<tbody>
													{estabs.map((estab) => (
														<tr key={estab.id}>
															<td>{estab.name}</td>
															<td style={{ textAlign: "right" }}>
																<button
																	className="btnDelete"
																	type="button"
																	onClick={() => handleRemoveEstab(estab.id)}
																>
																	<i className="fas fa-trash-alt"></i>
																</button>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</>
								) : (
									<h1>Cargando</h1>
								)}
							</div>

							{!userToEdit ? (
								<>
									{btnCarga === 0 ? (
										<button type="submit" className="btn btn-primary">
											Guardar
										</button>
									) : (
										/* spinner carga */
										<button className="btn btn-primary" type="button" disabled>
											<span
												className="spinner-border spinner-border-sm"
												aria-hidden="true"
											></span>
											<span role="status"> Guardando...</span>
										</button>
										/*--------------- */
									)}
									&nbsp;
									<button
										type="reset"
										onClick={cancelRegister}
										className="btn btn-danger"
									>
										Cancelar
									</button>
								</>
							) : (
								<>
									{btnCarga === 0 ? (
										<button type="submit" className="btn btn-primary">
											Actualizar
										</button>
									) : (
										/* spinner carga */
										<button className="btn btn-primary" type="button" disabled>
											<span
												className="spinner-border spinner-border-sm"
												aria-hidden="true"
											></span>
											<span role="status"> Guardando...</span>
										</button>
										/*--------------- */
									)}
									&nbsp;
									<NavLink
										to={"/menu/hefestoAdmin/search-users"}
										className="btn btn-danger"
									>
										Cancelar
									</NavLink>
								</>
							)}
						</Form>
					)}
				</Formik>
			)}
		</div>
	);
}
