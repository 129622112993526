import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { RiFileUserFill } from "react-icons/ri";
import { MdDashboard } from "react-icons/md";
import { TbReportAnalytics } from "react-icons/tb";
export default function BaseInformesPre() {
	const navigate = useNavigate();
	const [usuariosOptionsVisible, setUsuariosOptionsVisible] = useState(false);
	const [financierosOptionsVisible, setFinancierosOptionsVisible] =
		useState(false);
	const [sifseOptionsVisible, setSifseOptionsVisible] = useState(false);

	const toggleUsuariosOptions = () => {
		setUsuariosOptionsVisible(!usuariosOptionsVisible);
	};
	
	const toggleUsuariosOptionsSifse = () => {
		setFinancierosOptionsVisible(!financierosOptionsVisible);
	};
	const toggleSifseOptions = () => {
		setSifseOptionsVisible(!sifseOptionsVisible);
	};

	useEffect(() => {
		navigate("/menu/hefestoContabilidad/intro-infoconta");
	}, []);
	return (
		<>
			<div id="space">
				<div className="container1 container-fluid">
					<div className="menu">
						<div className="divMenuLog">
						{/* 	<ul>
								<NavLink className="navInfo" to="#">
									<li className="navInfoAdmin" onClick={toggleUsuariosOptions}>
										<MdOutlineTableChart />{" "}
										<span>
											Ejecuciones&nbsp;
											{usuariosOptionsVisible === false ? (
												<i class="fas fa-chevron-down"></i>
											) : (
												<i class="fas fa-chevron-up"></i>
											)}{" "}
										</span>
									</li>
								</NavLink>
								{usuariosOptionsVisible && (
									<ul>
										<NavLink
											className="navInfo"
											to="/menu/hefestoPresupuesto/infoejecuciones-Pre/1"
										>
											<li className="navSubmenu">
												<i class="fas fa-file-alt"></i> <span> Ingresos</span>
											</li>
										</NavLink>

										<NavLink
											className="navInfo"
											to="/menu/hefestoPresupuesto/infoejecuciones-Pre/2"
										>
											<li className="navSubmenu">
												<i class="fas fa-file-alt"></i> <span> Gastos</span>
											</li>
										</NavLink>
									</ul>
								)}
							</ul> */}
							<ul>
								<NavLink className="navInfo" to="dashboard-contabilidad">
									<li className="navInfoAdmin">
									<MdDashboard /> <span> Dashboard</span>
									</li>
								</NavLink>
							</ul>
							<ul>
								<NavLink className="navInfo" to="informe-detalle">
									<li className="navInfoAdmin">
									<TbReportAnalytics /> <span> Informe Detalles</span>
									</li>
								</NavLink>
							</ul>
							<ul>
								<NavLink className="navInfo" to="conciliaciones-contabilidad">
									<li className="navInfoAdmin">
										<RiFileUserFill /> <span> Conciliación</span>
									</li>
								</NavLink>
							</ul>
							<ul>
								<NavLink className="navInfo" to="estado-resultados">
									<li className="navInfoAdmin">
										<RiFileUserFill /> <span> Estado de Resultados</span>
									</li>
								</NavLink>
							</ul>
							<ul>
								<NavLink className="navInfo" to="informe-situacion">
									<li className="navInfoAdmin">
										<RiFileUserFill /> <span> Situación Financiera</span>
									</li>
								</NavLink>
							</ul>
							
						
						</div>
					</div>
					<div className="workspace">
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}
