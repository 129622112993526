import React from "react";
import {
	FcCalendar,FcReading,FcEditImage
} from "react-icons/fc";
import Dashboard from "../../componentsBase/tables/Intro"; // Asegúrate de que la ruta sea correcta

const ParentComponent = () => {
	const cards = [
		{
			id: 1,
			icon: <FcCalendar />,
			title: "Años",
			description: "Gestiona la apertura y cierre de cada año",
			to: "/menu/hefestoPresupuesto/anios_planp/4", // Ruta correcta
		},
		{
			id: 2,
			icon: <FcCalendar />,
			title: "Meses",
			description: "Gestiona la apertura y cierre mensual",
			to: "/menu/hefestoPresupuesto/meses_planp/4", // Ruta correcta
		},
		{
			id: 3,
			icon: <FcReading />,
			title: "Aprobar plan ",
			description: "Revisa, verifica, aprueba o rechaza el plan presupuestal consolidado. ",
			to: "/menu/hefestoPresupuesto/aprobar_planp", // Ruta correcta
		},
		{
			id: 4,
			icon: <FcEditImage />,
			title: "Editar plan ",
			description: "Autoriza la edición del plan presupuestal. ",
			to: "/menu/hefestoPresupuesto/aprobar_planp/1", // Ruta correcta
		},
		
	];

	return <Dashboard cards={cards} />;
};

export default ParentComponent;
