import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import "../../../styles/administration/Menu1.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { Outlet, NavLink } from "react-router-dom";
import { AuthContext } from "../../context/LogContext";
import { route_modules_list } from "../../../api-routes/RoutesLogin";
import { HiOutlineBarsArrowDown } from "react-icons/hi2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Swal from "sweetalert2";
import { show_alert } from "../../../functions";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import Dashboard from "../home/Dashboard";
import ReactDOM from "react-dom";
import FooterEstab from "../home/FooteEstab";
import BtnMenu from "./../../../images/logo.png";

import { route_user_id } from "../../../api-routes/RoutesLogin";

export default function Menu1Container() {
	const [showArea, setShowArea] = useState(null);
	const { getToken, isAuthenticated, establishment } = useContext(AuthContext);
	const [loading, setLoading] = useState(true);
	const [modules, setModules] = useState(null);
	const [permission, setPermission] = useState(null);
	const [userToEdit, setUserToEdit] = useState(null);
	const [showForm, setShowForm] = useState(false);
	const closeB = useRef(null);
	const [selectedImage, setSelectedImage] = useState(null);

	const navigate = useNavigate();
	const {
		logout,
		getUserByMail,
		user,
		userAct,
		getPermissions,
		getEstablishment,
		setEstablishment,
		setEstablishmentLS,
		banVisualizar,
		setBanVisualizar,
	} = useContext(AuthContext);

	function close() {
		var btn = document.getElementById("btnClose1");
		btn.click();
	}

	var getArrayModules = () => {
		return localStorage.getItem("modules");
	};

	const functionLogout = () => {
		logout();
		navigate("/");
	};

	const registrarUsuario = async (json) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		if (selectedImage != "") {
			json.image = selectedImage;
		}
		return axios
			.post(route_user_id + "updateUsu", json, config)
			.then((response) => {
				if (response.data.errorMessage != null) {
					Swal.fire({
						icon: "warning",
						title: "Error",
						showConfirmButton: false,
						timer: 1500,
					}).then(() => {});
				} else {
					Swal.fire({
						icon: "success",
						title: "Usuario actualizado exitosamente",
						showConfirmButton: false,
						timer: 1500,
					}).then(() => {
						closeB.current.click();
						functionLogout();
					});
					//show_alert("Usuario creado exitosamente", "success");
				}
			})
			.catch((error) => {
				console.error(error);
				throw error;
			});
	};
	const handleKeyPress = (event) => {
		if (event.charCode === 32) {
			event.preventDefault();
		}
	};

	const editUser = async (id) => {
		axios
			.get(route_user_id + id, {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			})
			.then((response) => {
				setUserToEdit(response.data);
				setShowForm(true);

				//asignar establecimientos asignados
			})
			.catch((error) => {
				Swal.fire({
					icon: "warning",
					title: "ERROR",
					showConfirmButton: false,
					timer: 1500,
				});
			});
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required("Ingrese su nombre"),
		lastName: Yup.string().required("Ingrese su apellido"),
		email: Yup.string()
			.email("Ingrese un correo electrónico válido")
			.required("Ingrese su correo electrónico"),
		password: Yup.string().required("Ingrese su contraseña"),
		confirm_password: Yup.string()
			.oneOf([Yup.ref("password"), null], "Las contraseñas deben coincidir")
			.required("Confirme su contraseña"),
		//image: Yup.mixed(), // Puedes ajustar esta validación según tus necesidades específicas
		phoneNumber: Yup.string().required("Ingrese su número de teléfono"),
	});

	const initialValues = {
		id: userToEdit ? userToEdit.id : null,
		name: userToEdit ? userToEdit.name : "",
		lastName: userToEdit ? userToEdit.lastName : "",
		email: userToEdit ? userToEdit.email : "",
		password: null,
		confirm_password: null,
		image: null,
		phoneNumber: userToEdit ? userToEdit.phoneNumber : "",
		active: userToEdit ? userToEdit.active : "",
		admin: userToEdit ? userToEdit.admin : false,
		user: localStorage.getItem("idUser"),
	};

	const handleImageChange = async (event) => {
		const file = event.currentTarget.files[0];
		try {
			const base64Image = await fileToBase64(file);
			setSelectedImage(base64Image);
		} catch (error) {
			console.error("Error converting file to base64:", error);
		}
	};
	const fileToBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {
				// Cuando la lectura ha sido completada con éxito, resolvemos la Promesa
				resolve(reader.result);
			};
			reader.onerror = (error) => {
				// En caso de error, rechazamos la Promesa con el error
				reject(error);
			};
			reader.onabort = () => {
				// En caso de abortar la lectura, rechazamos la Promesa con un error
				reject(new Error("File reading aborted"));
			};

			reader.readAsDataURL(file);
			// Manejar el cierre del FileReader cuando ya no es necesario
			return () => {
				reader.abort(); // Abortar la lectura si la Promesa se cancela antes de ser resuelta
			};
		});
	};

	useEffect(() => {
		getPermisos();
		var estab = getEstablishment();
		setEstablishment(JSON.parse(estab));
		if (!getArrayModules()) {
			axios
				.get(route_modules_list, {
					headers: {
						Authorization: `Bearer ${getToken()}`,
					},
				})
				.then((response) => {
					setModules(response.data);
					localStorage.setItem("modules", JSON.stringify(response.data));
					setLoading(false);
				})
				.catch((error) => {
				});
		} else {
			setModules(JSON.parse(getArrayModules()));
			setLoading(false);
		}
	}, []);

	async function getPermisos() {
		setPermission(JSON.parse(localStorage.getItem("permissions")));
	}

	return (
		<>
			<div id="containerBar1" className="d-flex">
				<img
					src={BtnMenu}
					alt="User"
					id="btnMenu1"
					data-bs-toggle="offcanvas"
					data-bs-target="#offcanvasWithBothOptions"
					aria-controls="offcanvasWithBothOptions"
					onClick={() => setBanVisualizar(0)}
					style={{
						border: "none",
						background: "none",
						cursor: "pointer",
					}}
				>
					{/* <img
						src={BtnMenu} // Reemplaza 'image/png' con el tipo de imagen correcto
						alt="User"
						style={{
							width: "60px", // Ajusta el tamaño según tus preferencias
							height: "60px",
							borderRadius: "50%",
						}}
					/> */}
				</img>
				<Outlet />
				<div id="divLogUser">
					{establishment ? (
						<>
							<div id="divLogUser">
								{!isAuthenticated ? (
									<button
										className="btnLogs"
										data-bs-toggle="modal"
										data-bs-target="#loginModal"
									>
										<i className="fas fa-person-booth"></i>
									</button>
								) : (
									<>
										{userAct && userAct.image ? (
											<button
												className="btnLogs "
												type="button"
												data-bs-toggle="offcanvas"
												data-bs-target="#offcanvasRight"
												aria-controls="offcanvasRight"
												onClick={() => editUser(localStorage.getItem("idUser"))}
											>
												<img
													src={userAct.image} // Reemplaza 'image/png' con el tipo de imagen correcto
													alt="User"
													style={{
														width: "40px", // Ajusta el tamaño según tus preferencias
														height: "40px",
														borderRadius: "50%",
													}}
												/>
											</button>
										) : (
											<button
												className="btnLogs "
												type="button"
												data-bs-toggle="offcanvas"
												data-bs-target="#offcanvasRight"
												aria-controls="offcanvasRight"
												onClick={() => editUser(localStorage.getItem("idUser"))}
											>
												<i className="fas fa-user-check"></i>
											</button>
										)}
									</>
								)}
							</div>
						</>
					) : (
						<div id="divLogEstab">
							<button
								className="btnLogs"
								data-bs-toggle="modal"
								data-bs-target="#loginEstabModal"
							>
								<i className="fas fa-laptop-house"></i>
							</button>
						</div>
					)}
				</div>
			</div>

			<div
				className="offcanvas offcanvas-start"
				data-bs-scroll="true"
				id="offcanvasWithBothOptions"
				aria-labelledby="offcanvasWithBothOptionsLabel"
			>
				<div
					className="offcanvas-header"
					style={{ flexDirection: "column", alignItems: "center" }}
				>
					<img
						src={establishment.image} // Reemplaza 'image/png' con el tipo de imagen correcto
						alt="User"
						style={{
							width: "40px", // Ajusta el tamaño según tus preferencias
							height: "40px",
							borderRadius: "50%",
							marginBottom: "10px", // Espacio entre la imagen y el título
						}}
					/>
					<label style={{textAlign:"center", fontSize:"20"}} className="offcanvas-title" id="offcanvasWithBothOptionsLabel">
						{establishment.name}
					</label>
					<button
						id="btnClose1"
						type="button"
						className="btn-close"
						data-bs-dismiss="offcanvas"
						aria-label="Close"
						style={{ position: "absolute", top: "10px", right: "10px" }} // Ajuste para el botón de cerrar
					></button>
				</div>
				<div className="offcanvas-body">
					{!loading && permission ? (
						modules.map((module) => (
							<>
								<div key={"div" + module.id} className="divMenuLog">
									<ul key={"ul" + module.id}>
										{(permission.modules.includes(module.id) ||
											permission.admin == true) && (
											<NavLink
												key={module.id}
												className="navInfo"
												onClick={() => {
													close();
												}}
												to={module.component}
											>
												<li key={"Li" + module.id}>
													<center>{module.module}</center>
												</li>
											</NavLink>
										)}
									</ul>
								</div>
							</>
						))
					) : (
						<h3>Cargando</h3>
					)}
				</div>
			</div>

			<div
				className="modal fade"
				id="staticBackdrop"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabindex="-1"
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog ">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="staticBackdropLabel">
								Administración de usuario
							</h1>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								ref={closeB}
							></button>
						</div>
						<div className="modal-body" style={{ height: "400px" }}>
							<div className="containerFormUser">
								{showForm && (
									<Formik
										initialValues={initialValues}
										validationSchema={validationSchema}
										onSubmit={registrarUsuario}
									>
										{(formik, props) => (
											<Form id="formUser">
												<div className="row">
													<div className="col-md-6">
														<div className="form-floating mb-2">
															<Field
																type="text"
																name="name"
																id="name"
																className="form-control"
																placeholder="Nombre"
															/>
															<label htmlFor="name" className="form-label">
																Nombre
															</label>
															<ErrorMessage
																name="name"
																component="div"
																className="error"
															/>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-floating mb-2">
															<Field type="hidden" name="id" id="id" />
															<Field
																type="text"
																name="lastName"
																id="lastName"
																className="form-control"
																placeholder="Apellido"
															/>
															<label htmlFor="lastName" className="form-label">
																Apellido
															</label>
															<ErrorMessage
																name="lastName"
																component="div"
																className="error"
															/>
														</div>
													</div>

													<div className="col-md-6">
														<div className="form-floating mb-2">
															<Field
																type="email"
																name="email"
																id="email"
																className="form-control"
																placeholder="Email"
																//value={mail}
																onKeyPress={handleKeyPress}
															/>
															<label htmlFor="email" className="form-label">
																Email
															</label>
															<ErrorMessage
																name="email"
																component="div"
																className="error"
															/>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-floating mb-2">
															<Field
																type="text"
																name="phoneNumber"
																id="phoneNumber"
																className="form-control"
																placeholder="Número de contacto"
															/>
															<label
																htmlFor="phoneNumber"
																className="form-label"
															>
																Número telefónico
															</label>
															<ErrorMessage
																name="phoneNumber"
																component="div"
																className="error"
															/>
														</div>
													</div>

													<div className="col-12  ">
														<div className="form-floating mb-2  ">
															<Field
																className="form-control"
																type="file"
																id="b64"
																name="b64"
																accept="image/*"
																onChange={handleImageChange}
															/>
															{/* <label htmlFor="logo" className="form-label">
																Logo
															</label> */}
															<ErrorMessage
																name="b64"
																component="div"
																className="error"
															/>
														</div>
													</div>

													<div className="col-md-6">
														<div className="form-floating mb-2">
															<Field
																type="password"
																name="password"
																id="password"
																className="form-control"
																placeholder="Password"
															/>
															<label htmlFor="name" className="form-label">
																Password
															</label>
															<ErrorMessage
																name="password"
																component="div"
																className="error"
															/>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-floating mb-2">
															<Field
																type="password"
																name="confirm_password"
																id="confirm_password"
																className="form-control"
																placeholder="confirm_password"
															/>
															<label
																htmlFor="confirm_password"
																className="form-label"
															>
																Confirmar Password
															</label>
															<ErrorMessage
																name="confirm_password"
																component="div"
																className="error"
															/>
														</div>
													</div>
												</div>

												<>
													<button type="submit" className="btn btn-primary">
														Actualizar
													</button>
													&nbsp;
													<button
														type="button"
														class="btn btn-danger"
														data-bs-dismiss="modal"
													>
														Cancelar
													</button>
												</>
											</Form>
										)}
									</Formik>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="workArea">{banVisualizar == 1 ? <Dashboard /> : null}</div>
			<FooterEstab />
		</>
	);
}
