import React, { useEffect, useState } from "react";
import {
	PDFViewer,
	Document,
	Page,
	Text,
	View,
	StyleSheet,
	Font,
	Image,
} from "@react-pdf/renderer";

function ComprobantePagoPDF({ movimiento, establishment }) {
	console.log(movimiento);
	const [sumDctos, setSumDctos] = useState(0);
	const [arrayDctos, setArrayDctos] = useState([]);
	useEffect(() => {
		console.log(movimiento);
		const sumaValorTotal = movimiento.listDctos
			? movimiento.listDctos.reduce((total, row) => total + row.valorTotal, 0)
			: 0;
		setSumDctos(sumaValorTotal);

		if (movimiento.listDctos && movimiento.listDctos.length > 0) {
			const dctosAgrup = agruparDescuentos(movimiento.listDctos);
			setArrayDctos(Object.values(dctosAgrup));
		}
	}, [movimiento]);

	function agruparDescuentos(descuentos) {
		return descuentos.reduce((acc, curr) => {
			if (!acc[curr.dcto]) {
				acc[curr.dcto] = {
					...curr,
					valorAplicado: 0,
					valorTotal: 0,
				};
			}

			acc[curr.dcto].valorAplicado += curr.valorAplicado;
			acc[curr.dcto].valorTotal += curr.valorTotal;

			return acc;
		}, {});
	}

	const styles = StyleSheet.create({
		/* ------------- USADOS EN FORMATO CONTABILIDAD ---------------- */

		containerDatos: {
			position: "absolute",
			top: 130, // Ajusta este valor según sea necesario
			left: 40,
			width: "100%",
		},
		tableRow: {
			display: "flex",
			flexDirection: "row",
		},
		page: {
			padding: 30,
		},
		logo: {
			position: "absolute",
			top: 20, // Ajusta este valor según sea necesario
			left: 30, // Ajusta este valor según sea necesario
			width: 100, // Ajusta el ancho de la imagen según sea necesario
			height: 100, // Ajusta la altura de la imagen según sea necesario
		},
		title: {
			fontSize: 18,
			marginBottom: 5,
			textAlign: "center",
			fontFamily: "Helvetica",
		},
		header: {
			textAlign: "center",
			fontSize: 8,
			//marginBottom: 5,
			fontFamily: "Helvetica",
		},
		tableNo: {
			position: "absolute",
			top: 62, // Ajusta este valor según sea necesario
			right: 26, // Ajusta este valor según sea necesario
			width: "150px",
			textAlign: "center",
			fontSize: 8,
			padding: 2,
			//backgroundColor: "#E5E5E5",
			borderWidth: 1,
			borderColor: "#000000",
			fontFamily: "Helvetica",
		},
		tableFecha: {
			position: "absolute",
			top: 85, // Ajusta este valor según sea necesario
			right: 26, // Ajusta este valor según sea necesario
			width: "150px",
			textAlign: "left",
			fontSize: 8,
			padding: 5,
			//backgroundColor: "#E5E5E5",
			borderWidth: 1,
			borderColor: "#000000",
			fontFamily: "Helvetica",
		},
		tableTitle100: {
			textAlign: "center",
			flex: 1,
			padding: 5,
			fontSize: 10,
			fontWeight: "bold",
			width: "100%",
			maxWidth: "100%",
			borderWidth: 1,
			borderColor: "#000000",
			fontFamily: "Helvetica",
		},
		tableRow50: {
			textAlign: "left",
			flex: 1,
			padding: 5,
			fontSize: 10,
			fontWeight: "bold",
			width: "50%",
			maxWidth: "50%",
			borderWidth: 1,
			borderColor: "#000000",
			fontFamily: "Helvetica",
		},
		tableRow33: {
			textAlign: "left",
			flex: 1,
			padding: 5,
			fontSize: 10,
			fontWeight: "bold",
			width: "33.33%",
			maxWidth: "33.33%",
			borderWidth: 1,
			borderColor: "#000000",
			fontFamily: "Helvetica",
		},
		espacio: {
			height: "10px",
		},
		tableCellDetalle: {
			flex: 1,
			fontSize: 9,
			padding: 5,
			width: "100%",
			borderWidth: 0.8,
			borderColor: "#000000",
			fontFamily: "Helvetica",
		},
		cellText: {
			flexWrap: "wrap",
			overflow: "hidden", // Asegura que el texto no se desborde
			maxWidth: "100%", // Asegura que el texto no se desborde de la celda
		},
		tableTitle50: {
			textAlign: "center",
			flex: 1,
			padding: 5,
			fontSize: 10,
			fontWeight: "bold",
			width: "50%",
			maxWidth: "50%",
			borderWidth: 1,
			borderColor: "#000000",
			fontFamily: "Helvetica",
		},
		tableTitle15: {
			textAlign: "center",
			flex: 1,
			padding: 5,
			fontSize: 10,
			fontWeight: "bold",
			width: "15%",
			maxWidth: "15%",
			borderWidth: 1,
			borderColor: "#000000",
			fontFamily: "Helvetica",
		},
		tableTitle20: {
			textAlign: "center",
			flex: 1,
			padding: 5,
			fontSize: 10,
			fontWeight: "bold",
			width: "20%",
			maxWidth: "20%",
			borderWidth: 1,
			borderColor: "#000000",
			fontFamily: "Helvetica",
		},
		tableTitle25: {
			textAlign: "center",
			flex: 1,
			padding: 5,
			fontSize: 10,
			fontWeight: "bold",
			width: "25%",
			maxWidth: "25%",
			borderWidth: 1,
			borderColor: "#000000",
			fontFamily: "Helvetica",
		},
		tableCell50: {
			flex: 1,
			fontSize: 9,
			padding: 5,
			width: "50%",
			maxWidth: "50%",
			borderWidth: 1,
			borderColor: "#000000",
			fontFamily: "Helvetica",
		},
		tableCell20: {
			flex: 1,
			fontSize: 9,
			padding: 5,
			width: "20%",
			maxWidth: "20%",
			borderWidth: 1,
			borderColor: "#000000",
			fontFamily: "Helvetica",
		},
		tableCellNumber15: {
			flex: 1,
			textAlign: "right",
			fontSize: 9,
			padding: 5,
			width: "15%",
			maxWidth: "15%",
			borderWidth: 1,
			borderColor: "#000000",
			fontFamily: "Helvetica",
		},
		tableCellNumber25: {
			flex: 1,
			textAlign: "right",
			fontSize: 9,
			padding: 5,
			width: "25%",
			maxWidth: "25%",
			borderWidth: 1,
			borderColor: "#000000",
			fontFamily: "Helvetica",
		},
		tableCellTotales70: {
			flex: 1,
			textAlign: "right",
			fontSize: 9,
			padding: 5,
			width: "70%",
			maxWidth: "70%",
			borderWidth: 0,
			fontFamily: "Helvetica",
		},
		firmas: {
			flex: 1,
			alignItems: "center",
			marginLeft: 30,
			marginTop: 50,
			fontSize: 10,
			paddingTop: 10,
			paddingBottom: 10,
			fontFamily: "Helvetica",
		},

		/* ----------------------------------------------------------- */
		tableTitle25: {
			flex: 1,
			padding: 5,
			fontSize: 12,
			fontWeight: "bold",
			width: "25%",
			maxWidth: "25%",
			fontSize: "10",
			borderWidth: 0.5,
			borderColor: "#000000",
			backgroundColor: "#E5E5E5",
			fontFamily: "Helvetica",
		},

		containerDetalle: {
			/* position: "absolute",
			top: 290, // Ajusta este valor según sea necesario
			left: 10, */
			width: "100%",
		},

		tableTitle: {
			flex: 1,
			padding: 5,
			fontSize: 12,
			fontWeight: "bold",
			width: "100%",
			maxWidth: "100%",
			fontSize: "10",
			borderWidth: 0.5,
			borderColor: "#000000",
			backgroundColor: "#E5E5E5",
			fontFamily: "Helvetica",
		},

		tableTitleNumber: {
			flex: 1,
			padding: 5,
			fontSize: 12,
			fontWeight: "bold",
			width: "50px",
			maxWidth: "80px",
			fontSize: "10",
			borderWidth: 0.5,
			borderColor: "#000000",
			backgroundColor: "#E5E5E5",
			fontFamily: "Helvetica",
		},
		tableTitle1: {
			flex: 1,
			padding: 5,
			fontSize: 12,
			fontWeight: "bold",
			width: "25%",
			maxWidth: "25%",
			borderWidth: 1,
			borderColor: "#000000",
			backgroundColor: "#E5E5E5",
			fontFamily: "Helvetica",
		},
		tableTitle2: {
			flex: 1,
			padding: 5,
			fontSize: 12,
			fontWeight: "bold",
			width: "90%",
			maxWidth: "90%",
			borderWidth: 1,
			borderColor: "#000000",
			backgroundColor: "#E5E5E5",
			fontFamily: "Helvetica",
		},
		title2: {
			textAlign: "center",
			flex: 2,
			padding: 5,
			fontSize: 10,
			fontWeight: "bold",
			width: "100%",
			maxWidth: "100%",
			borderWidth: 1,
			borderColor: "#000000",
			backgroundColor: "#E5E5E5",
			fontFamily: "Helvetica",
		},

		tableCellSmall: {
			flex: 1,
			fontSize: 9,
			padding: 5,
			width: "50px",
			maxWidth: "80px",
			borderWidth: 0.8,
			borderColor: "#000000",
			fontFamily: "Helvetica",
		},

		tableCellNumber: {
			flex: 1,
			textAlign: "right",
			fontSize: 9,
			padding: 5,
			width: "50px",
			maxWidth: "80px",
			//backgroundColor: "#E5E5E5",
			borderWidth: 1,
			borderColor: "#000000",
			fontFamily: "Helvetica",
		},
		tableCellNumberTotal: {
			flex: 1,
			textAlign: "right",
			fontSize: 9,
			padding: 5,
			width: "100%",
			maxWidth: "100%",
			//backgroundColor: "#E5E5E5",
			borderWidth: 1,
			borderColor: "#000000",
			fontFamily: "Helvetica",
		},
		footer: {
			position: "absolute",
			bottom: -2,
			left: 20,
			right: 0,
			textAlign: "left",
			fontSize: 8,
			paddingTop: 10,
			paddingBottom: 10,
			fontFamily: "Helvetica",
		},

		anulado: {
			position: "absolute",
			top: "20%",
			textAlign: "center",
			display: "inline-block",
			color: "rgba(255,0,0,0.5)",
			transform: "rotate(-45deg)",
			fontSize: "100",
		},
		txtAnula: {
			position: "absolute",
			bottom: 20,
			left: 40,
			textAlign: "center",
			display: "inline-block",
			color: "rgba(255,0,0,0.5)",

			fontSize: "20",
		},

		cellSinBordes: {
			flex: 1,
			padding: 3,
			fontSize: 12,
			fontWeight: "bold",
			width: "20%",
			maxWidth: "20%",
			borderWidth: 0,
			borderColor: "White",
			backgroundColor: "#ffffff",
			fontFamily: "Helvetica",
			textAlign: "center",
		},
		cellSinBordes25: {
			flex: 1,
			padding: 3,
			fontSize: 12,
			fontWeight: "bold",
			width: "100%",
			maxWidth: "100%",
			borderWidth: 0.8,
			borderColor: "White",
			backgroundColor: "#ffffff",
			fontFamily: "Helvetica",
			textAlign: "center",
		},
	});

	return (
		<>
			<PDFViewer
				style={{ width: "100%", height: "100vh" }}
				className="pdf-viewer"
			>
				<Document>
					<Page size="A4" style={styles.page}>
						{/* TITULO Y TEXTO BAJO TITULO */}
						<Image
							style={styles.logo}
							//src={`data:image/png;base64,${establishment.image}`}
							src={establishment.image ? establishment.image : ""}
						/>
						<Text style={styles.title}>{establishment.name}</Text>
						<Text style={styles.header}>
							{establishment.identificationTypeEst.code} -{" "}
							{establishment.identification}
						</Text>
						<Text style={styles.header}>
							{`${establishment.address} - ${establishment.phone}`}
						</Text>
						<Text style={styles.header}>{establishment.email}</Text>
						{/* -------------------------------------------- */}

						{/* CUADRO FECHA TIPO Y NUMERO DE COMPROBANTE */}
						<View style={styles.tableNo}>
							<Text>{movimiento.comprobante}</Text>
							<Text>No. {movimiento.numero}</Text>
						</View>
						<View style={styles.tableFecha}>
							<Text>Fecha. {movimiento.fecha}</Text>
							{/* <Text>Fecha Crea. {movimiento.fecha}</Text> */}
						</View>
						{/* --------------------------------------------- */}

						{/* --------- CONTENEDOR DATOS PERSONALES --------- */}
						<View style={styles.containerDatos}>
							{/* encabezado tercero */}
							<View style={styles.tableRow}>
								<View style={styles.tableTitle100}>
									<Text>TERCERO</Text>
								</View>
							</View>
							{/* linea identificacion */}
							<View style={styles.tableRow}>
								<View style={styles.tableRow50}>
									<Text>
										{movimiento.tipoid} : {movimiento.identificaciont}
									</Text>
								</View>
								<View style={styles.tableRow50}>
									<Text>{movimiento.nombret}</Text>
								</View>
							</View>
							{/* linea ubicación */}
							<View style={styles.tableRow}>
								<View style={styles.tableRow33}>
									<Text>País: {movimiento.pais}</Text>
								</View>
								<View style={styles.tableRow33}>
									<Text>Departamento: {movimiento.depto}</Text>
								</View>
								<View style={styles.tableRow33}>
									<Text>Municipio: {movimiento.mupio}</Text>
								</View>
							</View>
							{/* linea info contacto */}
							<View style={styles.tableRow}>
								<View style={styles.tableRow33}>
									<Text>Direccion: {movimiento.direccion}</Text>
								</View>
								<View style={styles.tableRow33}>
									<Text>Teléfono: {movimiento.telefono}</Text>
								</View>
								<View style={styles.tableRow33}>
									<Text>Correo: {movimiento.correo}</Text>
								</View>
							</View>
							{/* espacio */}
							<View style={styles.espacio}></View>
							{/* titulo comprobante */}
							<View style={styles.tableRow}>
								<View style={styles.tableTitle100}>
									<Text>COMPROBANTE</Text>
								</View>
							</View>
							{/* ------------detalle comprobante  ------------- */}
							<View style={styles.tableCellDetalle}>
								<Text style={styles.cellText}>
									{" "}
									DETALLE: {movimiento.detalle}
								</Text>
							</View>
							{/* ENCABEZADO TABLA DETALLES */}
							<View style={styles.tableRow}>
								<View style={styles.tableTitle50}>
									<Text>Cuenta</Text>
								</View>
								<View style={styles.tableTitle20}>
									<Text>Documento</Text>
								</View>
								<View style={styles.tableTitle15}>
									<Text>Débitos</Text>
								</View>
								<View style={styles.tableTitle15}>
									<Text>Créditos</Text>
								</View>
							</View>
							{/* lista de detalles */}
							{movimiento.detalles &&
								movimiento.detalles.map((row, rowIndex) => (
									<View style={styles.tableRow} key={rowIndex}>
										<View style={styles.tableCell50} key={rowIndex}>
											<Text>
												{row.codigocta} - {row.nombrecta}
											</Text>
										</View>
										<View style={styles.tableCell20} key={rowIndex}>
											<Text>{row.docafectado}</Text>
										</View>

										<View style={styles.tableCellNumber15} key={rowIndex}>
											<Text>{row.debito.toLocaleString()}</Text>
										</View>
										<View style={styles.tableCellNumber15} key={rowIndex}>
											<Text>{row.credito.toLocaleString()}</Text>
										</View>
									</View>
								))}
							{/* espacio */}
							<View style={styles.espacio}></View>
							{/* linea de totales */}
							<View style={styles.tableRow}>
								<View style={styles.tableCellTotales70}>
									<Text>Totales:</Text>
								</View>
								<View style={styles.tableCellNumber15}>
									<Text>{movimiento.debitos.toLocaleString()}</Text>
								</View>
								<View style={styles.tableCellNumber15}>
									<Text>{movimiento.creditos.toLocaleString()}</Text>
								</View>
							</View>

							{/* ------------------------ AFECTACION PRESUPUESTAL ----------- */}
							{movimiento.presupuesto != null ? (
								<>
									<View style={styles.tableRow}>
										<View style={styles.tableTitle100}>
											<Text>AFECTACIÓN PRESUPUESTAL No : {movimiento.presupuesto.numero}</Text>
										</View>
									</View>
									<View style={styles.tableCellDetalle}>
										<Text style={styles.cellText}>
											{" "}
											DETALLE: {movimiento.presupuesto.detalle}
										</Text>
										
									</View>
									{/* ENCABEZADO TABLA DETALLES */}
									<View style={styles.tableRow}>
										<View style={styles.tableTitle50}>
											<Text>Cuenta</Text>
										</View>
										<View style={styles.tableTitle25}>
											<Text>Contra crédito</Text>
										</View>
										<View style={styles.tableTitle25}>
											<Text>Créditos</Text>
										</View>
									</View>
									{/* lista de detalles */}
									{movimiento.presupuesto &&
										movimiento.presupuesto.detalles &&
										(() => {
											// Calculamos los totales
											const totDeb = movimiento.presupuesto.detalles.reduce(
												(sum, row) => sum + (row.debito || 0),
												0
											);
											const totCre = movimiento.presupuesto.detalles.reduce(
												(sum, row) => sum + (row.credito || 0),
												0
											);

											return (
												<>
													{movimiento.presupuesto.detalles.map(
														(row, rowIndex) => (
															<View style={styles.tableRow} key={rowIndex}>
																<View style={styles.tableCell50}>
																	<Text>{row.cuentad}</Text>
																</View>
																<View style={styles.tableCellNumber25}>
																	<Text>{row.debito}</Text>
																</View>
																<View style={styles.tableCellNumber25}>
																	<Text>{row.credito}</Text>
																</View>
															</View>
														)
													)}
													<View style={styles.espacio}></View>
													{/* linea de totales */}
													<View style={styles.tableRow}>
														<View style={styles.tableCellTotales70}>
															<Text>Totales:</Text>
														</View>
														<View style={styles.tableCellNumber15}>
															<Text>{totDeb.toLocaleString()}</Text>
														</View>
														<View style={styles.tableCellNumber15}>
															<Text>{totCre.toLocaleString()}</Text>
														</View>
													</View>
												</>
											);
										})()}

									{/* espacio */}
								</>
							) : null}

							{/* -------------- firmas ------------------------ */}
							<View
								style={{
									flexDirection: "row",
									justifyContent: "space-between",
								}}
							>
								<View style={styles.firmas}>
									<Text>{`________________________`}</Text>
									<Text>{`Elaborado por:`}</Text>
									<Text>{movimiento.nombreCrea}</Text>
								</View>
								<View style={styles.firmas}>
									<Text>{`________________________`}</Text>
									<Text>{`Aprobó`}</Text>
								</View>
							</View>
							{(movimiento.tipo == 1 || movimiento.tipo == 2) &&
							movimiento.categoria == 2 ? (
								<View
									style={{
										flexDirection: "row",
										justifyContent: "space-between",
									}}
								>
									<View style={styles.firmas}>
										<Text>{`______________________________________`}</Text>
										<Text>Beneficiario</Text>
										<Text>{movimiento.nombret}</Text>
										<Text>No. {movimiento.identificaciont}</Text>
									</View>
								</View>
							) : null}
						</View>

						{/* ---------------------------------------------------- */}

						{/* detalles de descuentos si es tipo 6 y tiene conceptos pago */}

						{/* ------------------------------------------------------------ */}

						<View style={styles.footer}>
							<Text>
								{`Fecha y hora de expedición: ${new Date().toLocaleString()} - Elaborado por: ${
									movimiento.nombreCrea
								}`}
							</Text>
						</View>
						<View style={styles.anulado}>
							<Text>{movimiento.estado == 2 ? "ANULADO" : ""} </Text>
						</View>
						<View style={styles.txtAnula}>
							<Text>
								{movimiento.estado == 2 ? "Motivo: " + movimiento.motivo : ""}{" "}
							</Text>
						</View>
					</Page>
				</Document>
			</PDFViewer>
		</>
	);
}

export default ComprobantePagoPDF;
