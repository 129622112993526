import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/LogContext";
import axios from "axios";
import Select from "react-select";
import {
	route_informesPresupuesto,
	route_confiPresupuesto_base,
	route_configuration_base,
	route_informesConta_base,
} from "../../../api-routes/RoutesLogin";
import { MdContentPasteSearch } from "react-icons/md";
import Swal from "sweetalert2";
import TableIngresos from "../../componentsBase/tables/TableBase1";

export default function InformacionUsuarios() {
	const { naturaleza } = useParams();
	const [cabecerasPlanP, setCabecerasPlanP] = useState([]);
	const [planp, setPlanp] = useState(null);
	const [tipo, setTipo] = useState(null);
	const [year, setYear] = useState(null);
	const { establishment, getToken, customStyles, dateStyles, FechaYMDHMS } =
		useContext(AuthContext);
	const [data, setData] = useState([]);
	const [valoresCategoria, setValoresCategoria] = useState([]);
	const [years, setYears] = useState([]);
	const [desde, setDesde] = useState("");
	const [hasta, setHasta] = useState("");
	const [titulo, setTitulo] = useState("");
	const [estado, setEstado] = useState(null);
	const [banCarga, setBanCarga] = useState(0);

	const sumFields = ["creditos", "debitos"];
	useEffect(() => {
		getCurrentMonthDates();
		getCabecerasP(0);
		getYears();
	}, [naturaleza]);

	const columns = [
		{ Header: "Codigo", accessor: "codigo" },
		{ Header: "Nombre", accessor: "nombre" },
		{ Header: "Total", accessor: "total" },
	];
	const visualizarInforme = async () => {
		setBanCarga(1);
		if (hasta != "") {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};
			const result = await axios.get(
				`${route_informesConta_base}/getEstadoResultados/${hasta},${establishment.id}`,
				config
			);
			console.log(result.data);
			setData(result.data.situacionFinanciera);
			setValoresCategoria(result.data.valoresPorCategoria);
			setBanCarga(0);
			setTitulo("Estado de resultados");
		} else {
			Swal.fire({
				icon: "warning",
				title: "Verifique los valores del filtro",
				showConfirmButton: false,
				timer: 1500,
			});
			setBanCarga(0);
		}
	};
	const getCabecerasP = async (idP) => {
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};

			const result = await axios.get(
				`${route_confiPresupuesto_base}/getplanesp/${establishment.id}`,
				config
			);
			const formattedOptions = result.data.map((item) => ({
				value: item.id,
				label: item.nombre + " - " + item.yearObject.year,
				tipo: item.tipo,
			}));

			setCabecerasPlanP(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		}
	};
	const getYears = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_configuration_base}/getYears/${establishment.id},4`,
			config
		);
		result.data.sort((a, b) => a.year - b.year);
		setYears(result.data);
	};
	const getCurrentMonthDates = () => {
		const currentDate = new Date();
		const firstDayOfMonth = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			1
		);
		const lastDayOfMonth = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth() + 1,
			0
		);

		setDesde(firstDayOfMonth.toISOString().slice(0, 10));
		setHasta(lastDayOfMonth.toISOString().slice(0, 10));
	};
	return (
		<>
			<div className="containerFormUser">
				<h1>Estado de resultados</h1>

				{/* seccion de filtros */}

				<div style={{ marginTop: "10px" }} className="row">
					{/* <div className="col-md-4  ">
						<div className="form-floating mb-2  ">
							<input
								style={dateStyles}
								type="date"
								name="desde"
								id="desde"
								value={desde}
								className="form-control"
								placeholder="Desde "
								onChange={(e) => {
									if (e) {
										setDesde(e.target.value);
									}
								}}
							/>
							<label style={{zIndex:"0"}} htmlFor="desde" className="form-label">
								Desde
							</label>
						</div>
					</div> */}
					<div className="col-md-6  ">
						<div className="form-floating mb-2  ">
							<input
								style={dateStyles}
								type="date"
								name="hasta"
								id="hasta"
								value={hasta}
								className="form-control"
								placeholder="Corte "
								onChange={(e) => {
									if (e) {
										setHasta(e.target.value);
									}
								}}
							/>
							<label
								style={{ zIndex: "0" }}
								htmlFor="hasta"
								className="form-label"
							>
								Corte
							</label>
						</div>
					</div>
					<div className="col-md-1">
						<button
							className="btn btn-primary"
							title="Visualizar Informe"
							onClick={visualizarInforme}
						>
							<MdContentPasteSearch size={20} />
						</button>
					</div>
					<div className="col-md-1">
						<button
							type="button"
							class="btn btn-info"
							data-bs-toggle="modal"
							data-bs-target="#modalConsolidados"
						>
							Consolidados
						</button>
					</div>
				</div>
				<div className="table-container-reacTable">
					{banCarga === 0 ? (
						<TableIngresos
							data={data}
							columns={columns}
							encabezado={{
								empresa: establishment.name,
								nit: establishment.identification,
								titulo: titulo,
								imagen: establishment.image,
								fechas: "Informacion corte: " + hasta,
								generado: "Informe generado: " + FechaYMDHMS,
							}}
							sumFields={sumFields}
						/>
					) : (
						/* spinner carga */
						<button className="btn btn-primary" type="button" disabled>
							<span
								className="spinner-border spinner-border-sm"
								aria-hidden="true"
							></span>
							<span role="status"> Cargando información...</span>
						</button>
						/*--------------- */
					)}
				</div>
				{/* modal de consolidados */}
				<div
					class="modal fade"
					id="modalConsolidados"
					tabindex="-1"
					aria-labelledby="exampleModalLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog modal-lg modal-dialog-scrollable">
						<div class="modal-content">
							<div class="modal-header">
								<h1 class="modal-title fs-5" id="exampleModalLabel">
									Estado de resultados
								</h1>
								<button
									type="button"
									class="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
								></button>
							</div>
							<div class="modal-body">
								{/* ----listado */}
								<table
									id="tableUsers"
									className="table table-bordered border-primary table-striped table-hover"
								>
									<thead>
										<tr>
											<th>código</th>
											<th>Categoria</th>
											<th>Débitos</th>
											<th>Créditos</th>
											<th>Saldo</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>1</td>
											<td>Caja - Bancos</td>
											<td>{valoresCategoria.cajaBancosDebito}</td>
											<td>{valoresCategoria.cajaBancosCredito}</td>
											<td>{valoresCategoria.cajaBancos}</td>
										</tr>
										<tr>
											<td>2</td>
											<td>Cuentas por Cobrar</td>
											<td>{valoresCategoria.cuentasPorCobrarDebito}</td>
											<td>{valoresCategoria.cuentasPorCobrarCredito}</td>
											<td>{valoresCategoria.cuentasPorCobrar}</td>
										</tr>
										<tr>
											<td>3</td>
											<td>Otros Activos Corrientes</td>
											<td>{valoresCategoria.otrosActivosCorrientesDebito}</td>
											<td>{valoresCategoria.otrosActivosCorrientesCredito}</td>
											<td>{valoresCategoria.otrosActivosCorrientes}</td>
										</tr>
										<tr>
											<td>4</td>
											<td>Inventarios</td>
											<td>{valoresCategoria.inventariosDebito}</td>
											<td>{valoresCategoria.inventariosCredito}</td>
											<td>{valoresCategoria.inventarios}</td>
										</tr>
										<tr>
											<td>5</td>
											<td>Activos Fijos</td>
											<td>{valoresCategoria.activosFijosDebito}</td>
											<td>{valoresCategoria.activosFijosCredito}</td>
											<td>{valoresCategoria.activosFijos}</td>
										</tr>
										<tr>
											<td>6</td>
											<td>Otros Activos</td>
											<td>{valoresCategoria.otrosActivosDebito}</td>
											<td>{valoresCategoria.otrosActivosCredito}</td>
											<td>{valoresCategoria.otrosActivos}</td>
										</tr>
										<tr>
											<td>7</td>
											<td>Cuentas por Pagar</td>
											<td>{valoresCategoria.cuentasPorPagarDebito}</td>
											<td>{valoresCategoria.cuentasPorPagarCredito}</td>
											<td>{valoresCategoria.cuentasPorPagar}</td>
										</tr>
										<tr>
											<td>8</td>
											<td>Otros Pasivos Corrientes</td>
											<td>{valoresCategoria.otrosPasivosCorrientesDebito}</td>
											<td>{valoresCategoria.otrosPasivosCorrientesCredito}</td>
											<td>{valoresCategoria.otrosPasivosCorrientes}</td>
										</tr>
										<tr>
											<td>9</td>
											<td>Pasivos Corto Plazo</td>
											<td>{valoresCategoria.pasivosCortoPlazoDebito}</td>
											<td>{valoresCategoria.pasivosCortoPlazoCredito}</td>
											<td>{valoresCategoria.pasivosCortoPlazo}</td>
										</tr>
										<tr>
											<td>10</td>
											<td>Pasivos Largo Plazo</td>
											<td>{valoresCategoria.pasivosLargoPlazoDebito}</td>
											<td>{valoresCategoria.pasivosLargoPlazoCredito}</td>
											<td>{valoresCategoria.pasivosLargoPlazo}</td>
										</tr>
										<tr>
											<td>11</td>
											<td>Otros Pasivos</td>
											<td>{valoresCategoria.otrosPasivosDebito}</td>
											<td>{valoresCategoria.otrosPasivosCredito}</td>
											<td>{valoresCategoria.otrosPasivos}</td>
										</tr>
										<tr>
											<td>12</td>
											<td>Patrimonio</td>
											<td>{valoresCategoria.patrimonioDebito}</td>
											<td>{valoresCategoria.patrimonioCredito}</td>
											<td>{valoresCategoria.patrimonio}</td>
										</tr>
										<tr>
											<td>13</td>
											<td>Ingresos</td>
											<td>{valoresCategoria.ingresosDebito}</td>
											<td>{valoresCategoria.ingresosCredito}</td>
											<td>{valoresCategoria.ingresos}</td>
										</tr>
										<tr>
											<td>14</td>
											<td>Otros Ingresos</td>
											<td>{valoresCategoria.otrosIngresosDebito}</td>
											<td>{valoresCategoria.otrosIngresosCredito}</td>
											<td>{valoresCategoria.otrosIngresos}</td>
										</tr>
										<tr>
											<td>15</td>
											<td>Costo de Ventas</td>
											<td>{valoresCategoria.costoDeVentasDebito}</td>
											<td>{valoresCategoria.costoDeVentasCredito}</td>
											<td>{valoresCategoria.costoDeVentas}</td>
										</tr>
										<tr>
											<td>16</td>
											<td>Gastos</td>
											<td>{valoresCategoria.gastosDebito}</td>
											<td>{valoresCategoria.gastosCredito}</td>
											<td>{valoresCategoria.gastos}</td>
										</tr>
										<tr>
											<td>17</td>
											<td>Otros Gastos</td>
											<td>{valoresCategoria.otrosGastosDebito}</td>
											<td>{valoresCategoria.otrosGastosCredito}</td>
											<td>{valoresCategoria.otrosGastos}</td>
										</tr>
										<tr>
											<td>18</td>
											<td>Orden</td>
											<td>{valoresCategoria.ordenDebito}</td>
											<td>{valoresCategoria.ordenCredito}</td>
											<td>{valoresCategoria.orden}</td>
										</tr>
										<tr>
											<td>19</td>
											<td>Gasto Nómina</td>
											<td>{valoresCategoria.gastoNominaDebito}</td>
											<td>{valoresCategoria.gastoNominaCredito}</td>
											<td>{valoresCategoria.gastoNomina}</td>
										</tr>
									</tbody>
								</table>
								{/*  */}{" "}
							</div>
							<div class="modal-footer">
								<button
									type="button"
									class="btn btn-primary"
									data-bs-dismiss="modal"
								>
									Aceptar
								</button>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
