import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../context/LogContext";
import "../../../styles/administration/Dashboard.css";
import { route_user_id } from "../../../api-routes/RoutesLogin";
import Swal from "sweetalert2";

export default function Dashboard() {
	const { establishment, getToken } = useContext(AuthContext);
	const [userAct, setUserAct] = useState(null);

	useEffect(() => {
	
		getUser(localStorage.getItem("idUser"));
	}, []);

	const getUser = async (id) => {
		axios
			.get(route_user_id + id, {
				headers: {
					Authorization: `Bearer ${getToken()}`,
				},
			})
			.then((response) => {
				setUserAct(response.data);

				//asignar establecimientos asignados
			})
			.catch((error) => {
				Swal.fire({
					icon: "warning",
					title: "ERROR",
					showConfirmButton: false,
					timer: 1500,
				});
			});
	};

	return (
		<div className="containerFormUser">
			<div
				className="row"
				style={{ display: "flex", justifyContent: "center" }}
			>
				<div className="col-5 containerDashboard">
					{userAct != null ? (
						<>
							<div className="profile">
								<center>
									{userAct.image ? (
										<div class="avatarDash">
											<img src={userAct.image} alt="Avatar" />
										</div>
									) : (
										<i
											className="fas fa-user"
											style={{ fontSize: "100px", color: "#729fcf" }}
										></i>
									)}
								</center>
							</div>
							<label className="titleDash">
								Usuario Actual: {userAct.name + " " + userAct.lastName}{" "}
							</label>
							<div className="Admin">
								<h5>Email: {userAct.email} </h5>
								<h5>Telefono: {userAct.phoneNumber}</h5>
								<h5>Roles:</h5>
								<ul>
									{userAct.roles.map((rol) => {
										return <li>{rol.rolesxrol.name}</li>;
									})}
								</ul>
							</div>
						</>
					) : (
						/*spinner carga*/
						<button class="btn btn-primary" type="button" disabled>
							<span
								class="spinner-border spinner-border-sm"
								aria-hidden="true"
							></span>
							<span role="status"> Cargando...</span>
						</button>
						/*--------------*/
					)}
				</div>
				<div className="col-5 containerDashboard">
					<div className="profilfe">
						<center>
							{establishment.image ? (
								<div class="avatarDash">
									<img src={establishment.image} alt="Avatar" />
								</div>
							) : (
								<i
									className="fas fa-user"
									style={{ fontSize: "100px", color: "#729fcf" }}
								></i>
							)}
						</center>
					</div>
					<label className="titleDash">Entidad: {establishment.name} </label>

					{establishment != null ? (
						<div className="Admin">
							<h5>Nit: {establishment.identification} </h5>
							<h5>Dirección: {establishment.address} </h5>
							<h5>Email: {establishment.email} </h5>
							<h5>
								Telefonos: {establishment.phone} - {establishment.phone2}
							</h5>
							<h5>Vencimiento: {establishment.vencimiento} </h5>
						</div>
					) : (
						/*spinner carga*/
						<button class="btn btn-primary" type="button" disabled>
							<span
								class="spinner-border spinner-border-sm"
								aria-hidden="true"
							></span>
							<span role="status"> Cargando...</span>
						</button>
						/*--------------*/
					)}
				</div>
			</div>
		</div>
	);
}
