import React from "react";
import {
	FcAnswers,
	FcBullish,
	FcBearish,
	FcFinePrint,
	FcComboChart,
	FcElectricalThreshold,
} from "react-icons/fc";
import Dashboard from "../../componentsBase/tables/Intro"; // Asegúrate de que la ruta sea correcta

const ParentComponent = () => {
	const cards = [
		{
			id: 1,
			icon: <FcAnswers />,
			title: "Ejecuciones",
			description: "Accede a tus informes de ejecuciones presupuestales",
			detalles: [
				{
					id: 1,
					icon: <FcBullish />,
					title: "Ingresos",
					description: "Informe de ejecución de ingresos",
					to: "/menu/hefestoPresupuesto/infoejecuciones-Pre/1",
				},
				{
					id: 2,
					icon: <FcBearish />,
					title: "Gastos",
					description: "Informe de eje3cución de gastos",
					to: "/menu/hefestoPresupuesto/infoejecuciones-Pre/2",
				},
			], // Ruta correcta
		},
		{
			id: 2,
			icon: <FcFinePrint />,
			title: "Movimientos",
			description: "Consula el detalle de tus movimientos presupuestales",
			to: "/menu/hefestoPresupuesto/movimientos-Pre",
		},
		{
			id: 3,
			icon: <FcComboChart />,
			title: "SIA",
			description:
				"Encuentra los informes SIA requeridos por las entidades de control",
			to: "/menu/hefestoPresupuesto/infoejecucionesSia-Pre/1",
		},
		{
			id: 4,
			icon: <FcElectricalThreshold />,
			title: "SIFSE",
			description: "Genera los informes SIFSE de igresos y gastos",
			detalles: [
				{
					id: 1,
					icon: <FcBullish />,
					title: "Ingresos",
					description: "Informe sifse de ingresos",
					to: "/menu/hefestoPresupuesto/infoejecucionesSifse-Pre/1",
				},
				{
					id: 2,
					icon: <FcBearish />,
					title: "Gastos",
					description: "Informe sifse de gastos",
					to: "/menu/hefestoPresupuesto/infoejecucionesSifse-Pre/3",
				},
			], // Ruta correcta
		},
	];

	return <Dashboard cards={cards} />;
};

export default ParentComponent;
