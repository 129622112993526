import React, { useState, useEffect, useContext, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import { AuthContext } from "../../context/LogContext";
import { route_contabconf_base } from "../../../api-routes/RoutesLogin";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { NumericFormat } from "react-number-format";
import Select from "react-select";

const ConceptosPago = () => {
	const { getToken, inputStyles, establishment, customStyles } =
		useContext(AuthContext);
	const [conceptos, setConceptos] = useState([]);
	const btnCancel = useRef(null);
	const formikRef = useRef(null);
	const refCuenta = useRef(null);
	const refTipo = useRef(null);
	const inputServicio = useRef(null);
	const [porcentajeVal, setPorcentajeVal] = useState(null);
	const [tiposDcto, setTiposDcto] = useState([]);
	const [conceptoSelected, setConceptoSelected] = useState(null);
	const [btnCarga, setBtnCarga] = useState(0);
	const [banCarga, setBanCarga] = useState(0);
	const [cuentaO, setCuentaO] = useState(null);
	const [Puc, setPuc] = useState([]);

	const handleSubmit = (values) => {
		setBtnCarga(1);
		saveConcepto(values);
	
	};
	const saveConcepto = async (json) => {
		json.user = parseInt(localStorage.getItem("idUser"));
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			const response = await axios.post(
				route_contabconf_base + "/saveDescuento",
				json,
				config
			);
			const successMessage = "Descuento creado exitosamente";

			Swal.fire({
				icon: "success",
				title: successMessage,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				setBtnCarga(0);
				btnCancel.current.click();
				setPorcentajeVal(0);
				getConceptos();
				setConceptoSelected(null);
			});
		} catch (error) {
			if (error.response && error.response.status === 403) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Verifique los datos",
					showConfirmButton: true,
				}).then(() => {});
			} else {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error de red, contactese con su proveedor",
					showConfirmButton: true,
				}).then(() => {
					setBtnCarga(0);
				});
			}
		}
	};
	const getConceptos = async () => {
		setBanCarga(1);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_contabconf_base}/getDescuentos/${establishment.id}`,
			config
		);
		setConceptos(result.data);
		setBanCarga(0);
	};
	const handleServiceEdit = (conceptoId) => {
		const conceptoDet = conceptos.find((p) => p.id === conceptoId);
		if (formikRef.current) {
			formikRef.current.setValues(conceptoDet);
			setPorcentajeVal(conceptoDet.porcentaje);
			var concepto = tiposDcto.find(
				(objeto) => objeto.value === conceptoDet.tipo
			);
			setConceptoSelected(concepto != null ? concepto : null);
			var cuenta = Puc.find((p) => p.value == conceptoDet.cuenta);
			setCuentaO(cuenta);
		}
	};
	const handleDelete = (conceptoId) => {
		Swal.fire({
			title: "Eliminar Descuento?",
			text: "El descuento no debe estar aplicado a ningun movimiento!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				deleteServicio(conceptoId);
			}
		});
	};
	const deleteServicio = async (servicioId) => {
		try {
			const response = await axios.delete(
				route_contabconf_base + "/deleteDescuento/" + servicioId,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			if (response.data == true) {
				Swal.fire({
					icon: "success",
					title: "El descuento se ha eliminado correctamente",
					showConfirmButton: false,
					timer: 1500,
				}).then(() => {
					getConceptos();
				});
			} else {
				Swal.fire({
					icon: "warning",
					title: "Error, Verifique si el concepto no ha sido aplicado",
					showConfirmButton: false,
					timer: 1500,
				});
			}
		} catch (error) {
			throw error;
		}
	};
	useEffect(() => {
		getConceptos();
		getPuc();
		setTiposDcto([
			{ label: "Descuentos por seguridad social", value: 1 },
			{ label: "Descuentos por retenciones", value: 2 },
			{ label: "Otros descuentos", value: 3 },
		]);
	}, []);
	const validationSchema = Yup.object().shape({
		nombre: Yup.string().required("Ingrese el nombre del concepto"),
		porcentaje: Yup.number()
			.required("Ingrese el porcentaje")
			.typeError("Por favor, ingrese un valor numérico")
			.max(100, "El porcentaje no puede ser mayor a 100"),
		tipo: Yup.number()
			.required("Ingrese el tipo de deducción")
			.typeError("Por favor, seleccione un tipo de descuento"),
		cuenta: Yup.number()
			.required("Ingrese la cuenta")
			.typeError("Por favor, seleccione una cuenta"),
	});
	const initialValues = {
		id: "",
		nombre: "",
		porcentaje: null,
		tipo: null,
		cuenta: null,
		establishment: establishment.id,
	};
	const stylePlan = {
		control: (base) => ({
			...base,
			height: 80,
			minHeight: 35,
			fontSize: "14px",
			height: "45px",
		}),
		option: (base, { data, isDisabled }) => ({
			...base,
			textAlign: `left`,
			backgroundColor: data.tipo === 1 ? "#5fb7eb" : "#3879db",
			color: "white",
		}),
	};
	const getPuc = async () => {
		//setBanCarga(1)
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_contabconf_base}/getpuc/${establishment.id}`,
			config
		);
		//let newPuc = reemplazarMadreConCodigo(result.data.pucDTOList);
		let order = result.data.pucDTOList.sort(customSort);
		const formattedOptions = order.map((item) => ({
			value: item.id,
			label: item.codigo + " - " + item.nombre,
			tipo: item.tipo,
			naturaleza: item.naturaleza,
			isDisabled: item.tipo == 1 ? true : false,
			//saldo: item.saldo,
		}));
		setPuc(formattedOptions);
	};
	function customSort(a, b) {
		// Ordenar por 'codigo'
		if (a.codigo < b.codigo) return -1;
		if (a.codigo > b.codigo) return 1;

		// Si 'codigo' es igual, ordenar por 'tipo'
		if (a.tipo < b.tipo) return -1;
		if (a.tipo > b.tipo) return 1;

		// Si 'codigo' y 'tipo' son iguales, ordenar por 'naturaleza'
		if (a.naturaleza < b.naturaleza) return -1;
		if (a.naturaleza > b.naturaleza) return 1;

		return 0;
	}
	function resetForm() {
		setPorcentajeVal(0);
		if (refCuenta.current) {
			refCuenta.current.clearValue();
		}
		if (refTipo.current) {
			refTipo.current.clearValue();
		}
	}

	return (
		<div className="containerFormUser">
			<h1>Descuentos</h1>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
				innerRef={formikRef}
			>
				{(formik, props) => (
					<Form>
						<div className="row">
							{/* BLOQUE1 */}
							<div className="col-md-3  ">
								<div className="form-floating mb-2  ">
									<Field
										style={inputStyles}
										innerRef={inputServicio}
										type="text"
										name="nombre"
										id="nombre"
										className="form-control"
										placeholder="Nombre de concepto"
									/>
									<label htmlFor="forma" className="form-label">
										Concepto
									</label>
									<ErrorMessage
										name="nombre"
										component="div"
										className="error"
									/>
								</div>
							</div>
							{/* ------tCuenta---------------- */}
							<div className="col-md-3  ">
								<div className="form-floating mb-2  ">
									<Select
										ref={refCuenta}
										styles={stylePlan}
										isSearchable={true}
										placeholder="Cuenta"
										isClearable={true}
										id="selectCta"
										value={cuentaO}
										options={Puc}
										filterOption={(option, inputValue) => {
											const label = option.label.toLowerCase();
											const input = inputValue.toLowerCase();

											// Primero, prioriza las coincidencias que comienzan con el input
											if (label.startsWith(input)) {
												return true;
											}

											// Como segunda opción, busca cualquier coincidencia
											return label.includes(input);
										}}
										onChange={(e) => {
											if (e) {
												formik.setFieldValue("cuenta", e.value);
												//setCuenta(e.value);
												setCuentaO(e);
											} else {
												formik.setFieldValue("cuenta", null);
												//setCuenta(null);
												setCuentaO(null);
											}
										}}
									></Select>
									<ErrorMessage
										id="cuenta"
										name="cuenta"
										component="div"
										className="error"
									/>
								</div>
							</div>

							{/* ------- tipo--------------- */}
							<div className="col-md-2  ">
								<div className="form-floating mb-2  ">
									<Select
										ref={refTipo}
										isClearable={true}
										styles={customStyles}
										id="tipo"
										name="tipo"
										value={conceptoSelected}
										placeholder="Tipo Descuento"
										onChange={(e) => {
											if (e) {
												formik.setFieldValue("tipo", e.value);
												setConceptoSelected(e);
											} else {
												formik.setFieldValue("tipo", null);
												setConceptoSelected(null);
											}
										}}
										options={tiposDcto}
									/>
									<ErrorMessage
										id="tipo"
										name="tipo"
										component="div"
										className="error"
									/>
								</div>
							</div>
							{/* ---------------------- */}
							<div className="col-md-2  ">
								<div className="form-floating mb-2  ">
									<NumericFormat
										style={{ ...inputStyles, textAlign: "right" }}
										value={porcentajeVal}
										className="form-control"
										thousandSeparator="."
										decimalSeparator=","
										onChange={(e) => {
											setPorcentajeVal(
												parseFloat(
													e.target.value.replace(/\./g, "").replace(",", ".")
												)
											);
											formik.setFieldValue(
												"porcentaje",
												e
													? e.target.value.replace(/\./g, "").replace(",", ".")
													: ""
											);
										}}
									/>
									<label htmlFor="valorPlan" className="form-label">
										Porcentaje
									</label>
									<ErrorMessage
										name="porcentaje"
										component="div"
										className="error"
									/>
								</div>
							</div>

							<div className="col-md-2  ">
								{btnCarga === 0 ? (
									<button type="submit" className="btn btn-primary">
										Guardar
									</button>
								) : (
									/* spinner carga */
									<button className="btn btn-primary" type="button" disabled>
										<span
											className="spinner-border spinner-border-sm"
											aria-hidden="true"
										></span>
										<span role="status"> Guardando...</span>
									</button>
									/*--------------- */
								)}
								&nbsp;
								<button
									type="reset"
									ref={btnCancel}
									className="btn btn-danger"
									onClick={() => resetForm()}
								>
									Cancelar
								</button>
							</div>
						</div>
					</Form>
				)}
			</Formik>
			<br></br>
			<h1>Lista de descuentos</h1>
			{banCarga === 0 ? (
				<table
					style={{ width: "100%" }}
					id="tableUsers"
					className="table table-striped table-hover"
				>
					<thead>
						<tr>
							<th style={{ width: "30%", textAlign: "center" }}>Nombre</th>
							<th style={{ width: "30%", textAlign: "center" }}>Cuenta</th>
							<th style={{ width: "20%", textAlign: "center" }}>Tipo</th>
							<th style={{ width: "10%", textAlign: "right" }}>Porcentaje</th>
							<th style={{ width: "10%", textAlign: "right" }}>Opciones</th>
						</tr>
					</thead>
					<tbody>
						{conceptos.map((concepto) => {
							const cuenta = Puc.find((p) => p.value === concepto.cuenta);
							return (
								<tr key={concepto.id}>
									<td style={{ textAlign: "left" }}>{concepto.nombre}</td>
									<td style={{ textAlign: "left" }}>
										{cuenta ? cuenta.label : ""}
									</td>
									<td style={{ textAlign: "left" }}>
										{concepto.tipo === 1
											? "Descuentos por seguridad social"
											: concepto.tipo === 2
											? "Descuentos por retenciones"
											: concepto.tipo === 3
											? "Otros descuentos"
											: null}
									</td>
									<td style={{ textAlign: "right" }}>{concepto.porcentaje}</td>
									<td style={{ width: "20%", textAlign: "right" }}>
										<>
											<button
												className="btnEdit"
												type="button"
												onClick={(e) => handleServiceEdit(concepto.id)}
											>
												<i className="fas fa-pen"></i>
											</button>
											&nbsp;&nbsp;
											<button
												className="btnDelete"
												type="button"
												onClick={() => handleDelete(concepto.id)}
											>
												<i className="fas fa-trash-alt"></i>
											</button>
										</>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			) : (
				/* spinner carga */
				<button className="btn btn-primary" type="button" disabled>
					<span
						className="spinner-border spinner-border-sm"
						aria-hidden="true"
					></span>
					<span role="status"> Guardando...</span>
				</button>
				/*--------------- */
			)}
		</div>
	);
};

export default ConceptosPago;
