import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { BsClipboard2Check } from "react-icons/bs";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { FaEdit } from "react-icons/fa";

export default function ProcesosPreMov() {
	const navigate = useNavigate();

	useEffect(() => {
		navigate("/menu/hefestoPresupuesto/intro-procpre");
	}, []); // Agrega una dependencia vacía para ejecutar useEffect solo una vez

	return (
		<>
			<div id="space">
				<div className="container1 container-fluid">
					<div className="menu">
						<div className="divMenuLog">
							<ul>
								<NavLink className="navInfo" to="anios_planp/4">
									<li className="navInfoAdmin">
										<IoCalendarNumberOutline /> <span> Años. </span>
									</li>
								</NavLink>
							</ul>
							<ul>
								<NavLink className="navInfo" to="meses_planp/4">
									<li className="navInfoAdmin">
										<IoCalendarNumberOutline /> <span> Meses. </span>
									</li>
								</NavLink>
							</ul>
							<ul>
								<NavLink className="navInfo" to="aprobar_planp">
									<li className="navInfoAdmin">
										<BsClipboard2Check /> <span> Aprobar Plan P. </span>
									</li>
								</NavLink>
							</ul>
							<ul>
								<NavLink className="navInfo" to="aprobar_planp/1">
									<li className="navInfoAdmin">
										<FaEdit /> <span> Editar Plan P. </span>
									</li>
								</NavLink>
							</ul>
						</div>
					</div>
					<div className="workspace">
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}
