import React, { useEffect } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { IoCalendarNumberOutline } from "react-icons/io5";

export default function ConfPresupuesto() {
	const navigate = useNavigate();

	useEffect(() => {
		navigate("/menu/hefestoContabilidad/intro-proconta");
	}, []);

	
	return (
		<>
			<div id="space">
				<div className="container1 container-fluid">
					<div className="menu">
						<div className="divMenuLog">
						<ul>
								<NavLink className="navInfo" to="anios_planp/5">
									<li className="navInfoAdmin">
										<IoCalendarNumberOutline /> <span> Años. </span>
									</li>
								</NavLink>
							</ul>
							<ul>
								<NavLink className="navInfo" to="meses_planp/5">
									<li className="navInfoAdmin">
										<IoCalendarNumberOutline /> <span> Meses. </span>
									</li>
								</NavLink>
							</ul>
						</div>
					</div>
					<div className="workspace">
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}
