import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/LogContext";
import axios from "axios";
import Select from "react-select";
import {
	route_informesPresupuesto,
	route_confiPresupuesto_base,
	route_configuration_base,
} from "../../../api-routes/RoutesLogin";
import { MdContentPasteSearch } from "react-icons/md";
import Swal from "sweetalert2";
import TableIngresos from "../../componentsBase/tables/TableBaseSum1";

export default function InformacionUsuarios() {
	const { naturaleza } = useParams();
	const [cabecerasPlanP, setCabecerasPlanP] = useState([]);
	const [planp, setPlanp] = useState(null);
	const [tipo, setTipo] = useState(null);
	const [year, setYear] = useState(null);
	const { establishment, getToken, customStyles, dateStyles,FechaYMDHMS } = useContext(
		AuthContext
	);
	const [data, setData] = useState([]);
	const [years, setYears] = useState([]);
	const [desde, setDesde] = useState("");
	const [hasta, setHasta] = useState("");
	const [titulo, setTitulo] = useState("");
	const [estado, setEstado] = useState(null);
	const [banCarga, setBanCarga] = useState(0);

	const sumFields = ["creditos", "debitos"];
	useEffect(() => {
		getCurrentMonthDates();
		getCabecerasP(0);
		getYears();
	}, [naturaleza]);

	const columns = [
		{ Header: "Identificación", accessor: "identificacion" },
		{ Header: "Tercero", accessor: "tercero" },
		{ Header: "Número", accessor: "numero" },
		{ Header: "Créditos", accessor: "creditos" },
		{ Header: "Dèbitos ", accessor: "debitos" },
		{ Header: "Fecha", accessor: "fecha" },
		{ Header: "Detalle", accessor: "detalle" },
		{ Header: "Motivo", accessor: "motivo" },
	];
	const visualizarInforme = async () => {
		setBanCarga(1)
		if (
			planp != null &&
			tipo != null &&
			desde != "" &&
			hasta != "" &&
			estado != null
		) {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};
			const result = await axios.get(
				`${route_informesPresupuesto}/movimientos/${establishment.id},${planp},${tipo},${desde},${hasta},${estado}`,
				config
			);
			setData(result.data);
			setBanCarga(0)

			if (tipo == 1) {
				setTitulo("Reconocimiento Presupuestal");
			} else if (tipo == 2) {
				setTitulo("Ingreso Presupuestal");
			} else if (tipo == 3) {
				setTitulo("Disponibilidad Presupuestal");
			} else if (tipo == 4) {
				setTitulo("Registro Presupuestal");
			} else if (tipo == 5) {
				setTitulo("Obligación Presupuestal");
			} else if (tipo == 6) {
				setTitulo("Pago");
			} else if (tipo == 15) {
				setTitulo("Adición Presupuestal");
			} else if (tipo == 16) {
				setTitulo("Reducción Presupuestal");
			} else if (tipo == 17) {
				setTitulo("Traslado Presupuestal");
			}
		} else {
			Swal.fire({
				icon: "warning",
				title: "Verifique los valores del filtro",
				showConfirmButton: false,
				timer: 1500,
			});
			setBanCarga(0)
		}
	};
	const getCabecerasP = async (idP) => {
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};

			const result = await axios.get(
				`${route_confiPresupuesto_base}/getplanesp/${establishment.id}`,
				config
			);
			const formattedOptions = result.data.map((item) => ({
				value: item.id,
				label: item.nombre + " - " + item.yearObject.year,
				tipo: item.tipo,
			}));

			setCabecerasPlanP(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		}
	};
	const getYears = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_configuration_base}/getYears/${establishment.id},4`,
			config
		);
		result.data.sort((a, b) => a.year - b.year);
		setYears(result.data);
	};
	const getCurrentMonthDates = () => {
		const currentDate = new Date();
		const firstDayOfMonth = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			1
		);
		const lastDayOfMonth = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth() + 1,
			0
		);

		setDesde(firstDayOfMonth.toISOString().slice(0, 10));
		setHasta(lastDayOfMonth.toISOString().slice(0, 10));
	};
	return (
		<>
			<div className="containerFormUser">
				<h1>Informe de movimientos</h1>

				{/* seccion de filtros */}
				<div className="row">
					<div className="col-md-4">
						<Select
							styles={customStyles}
							isSearchable="true"
							placeholder="Plan presupuestal"
							isClearable="true"
							options={cabecerasPlanP}
							onChange={(e) => {
								if (e) {
									setPlanp(e.value);
								}
							}}
						></Select>
					</div>
					<div className="col-md-4">
						<Select
							styles={customStyles}
							isSearchable={true}
							placeholder="Movimiento"
							isClearable={true}
							options={[
								{
									label: "Ingresos",
									options: [
										{ label: "Reconocimiento", value: 1 },
										{ label: "Ingreso", value: 2 },
									],
								},
								{
									label: "Gastos",
									options: [
										{ label: "Disponibilidad", value: 3 },
										{ label: "Registro", value: 4 },
										{ label: "Obligación", value: 5 },
										{ label: "Pago", value: 6 },
									],
								},
								{
									label: "Modificaciones",
									options: [
										{ label: "Adición", value: 15 },
										{ label: "Disminucion", value: 16 },
										{ label: "Traslado", value: 17 },
									],
								},
								// Puedes agregar más grupos de opciones si es necesario
							]}
							onChange={(e) => {
								if (e) {
									setTipo(e.value);
								}
							}}
						/>
					</div>
					<div className="col-md-4">
						<Select
							styles={customStyles}
							placeholder="Estado"
							onChange={(e) => {
								if (e) {
									setEstado(e.value);
								}
							}}
							options={[
								{ label: "Activos", value: 1 },
								{ label: "Anulados", value: 2 },
							]}
							classNamePrefix="select"
						/>
					</div>
				</div>
				<div style={{ marginTop: "10px" }} className="row">
					<div className="col-md-4  ">
						<div className="form-floating mb-2  ">
							<input
								style={dateStyles}
								type="date"
								name="desde"
								id="desde"
								value={desde}
								className="form-control"
								placeholder="Desde "
								onChange={(e) => {
									if (e) {
										setDesde(e.target.value);
									}
								}}
							/>
							<label style={{zIndex:"0"}} htmlFor="desde" className="form-label">
								Desde
							</label>
						</div>
					</div>
					<div className="col-md-4  ">
						<div className="form-floating mb-2  ">
							<input
								style={dateStyles}
								type="date"
								name="hasta"
								id="hasta"
								value={hasta}
								className="form-control"
								placeholder="Hasta "
								onChange={(e) => {
									if (e) {
										setHasta(e.target.value);
									}
								}}
							/>
							<label style={{zIndex:"0"}} htmlFor="hasta" className="form-label">
								Hasta
							</label>
						</div>
					</div>
					<div className="col-md-4">
						<button
							className="btn btn-primary"
							title="Visualizar Informe"
							onClick={visualizarInforme}
						>
							<MdContentPasteSearch size={20} />
						</button>
					</div>
				</div>
				<div className="table-container-reacTable">
				{banCarga === 0 ? (
					<TableIngresos
						data={data}
						columns={columns}
						encabezado={{
							empresa: establishment.name,
							nit: establishment.identification,
							titulo: titulo,
							imagen: establishment.image,
							fechas: "Informacion desde: "+desde+" hasta: "+hasta,
							generado: "Informe generado: "+FechaYMDHMS

						}}
						sumFields={sumFields}
					/>
					) : (
						/* spinner carga */
						<button className="btn btn-primary" type="button" disabled>
							<span
								className="spinner-border spinner-border-sm"
								aria-hidden="true"
							></span>
							<span role="status"> Cargando información...</span>
						</button>
						/*--------------- */
					)}
				</div>
			</div>
		</>
	);
}
