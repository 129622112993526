//const domain="http://localhost:8090/hefesto/api";
const domain="https://hefestoback-production.up.railway.app/hefesto/api";


export const route_base=domain;

export const route_users_getByEmail=domain+"/users/email/";
export const route_users_login=domain+"/auth/login";
export const route_login_getEstablishmentByIdentification=domain+"/establishments/login"; 
export const route_login_existUserxEstab=domain+"/UsersxEstab/exist/"; 

//ROUTES ROLES
export const route_roles_save= domain+"/roles/save";
export const route_roles_getByEstablishment= domain+"/roles/getbyidE/";
export const route_roles_delete= domain+"/roles/delete/";

//ROUTES ESTABLISHMENTS
export const route_establishments_getAll=domain+"/establishments/all";
export const route_establishments_save=domain+"/establishments/save";
export const route_establishments_update=domain+"/establishments/update";
export const route_establishments_getEstabsxUser=domain+"/establishments/estabxuser";
export const route_establishments_license=domain+"/license/obtenerDatosLicencia"

// RUTAS USERS
export const route_user_register=domain+"/users/register";
export const route_user_list=domain+"/users/all/";
export const route_user_id=domain+"/users/";
export const route_user_deactive = domain+"/users/getActive";
export const route_user_byEstab = domain+"/users/usersE";


//ROUTES TYPE ID
export const route_typeid_list = domain+"/identification/all";

//ROUTES MODULES
export const route_modules_list = domain+"/modules/all";

//ROUTES OPTIONS
export const route_options_list = domain+"/options/all";
export const route_options_listByModule = domain+"/options/module_all";

//ROUTES PERMISSION
export const route_permission_save = domain+"/permission/save";
export const route_permission_permissionsByRole = domain+"/permission/getByRole/";

//ROUTES PERSONS
export const route_persons_typesPersons = domain+"/persons/getTypes";
export const route_persons_registerPersons = domain+"/persons"
export const route_persons_listPersons = domain+"/persons/allPaging/";
export const route_persons_getById = domain+"/persons/";
export const route_persons_getListById = domain+"/persons/searchById/";
export const route_person_deactive = domain+"/persons/updateActive";
export const route_person_listPais = domain+"/configuration/searchC/";
export const route_person_listDepto = domain+"/configuration/searchD/";
export const route_person_listMupios = domain+"/configuration/searchM/";
export const route_person_listCiiu = domain+"/configuration/searchCiiu/";
export const route_person_listCiuo = domain+"/configuration/searchCiuo/";
export const route_configuration_base = domain+"/configuration";
export const route_person_searchPerson = domain+"/persons/search/";

//ROUTES  GYM
export const route_confiGym_base = domain+"/configym";
export const route_confiGym_servicios = domain+"/configym/servicios";
export const route_confiGym_planes = domain+"/configym/planes";
export const route_gym_suscripciones = domain+"/suscripciones";

// ROUTES PRESUPUESTO
export const route_confiPresupuesto_base = domain+"/configpresupuesto";
export const route_procesosPresupuesto = domain+"/procesospresupuesto"
export const route_movimientosPresupuesto = domain+"/movimientospresupuesto"
export const route_informesPresupuesto = domain+"/informespresupuesto"

// ROUTE INSTITUCIONES
export const route_instituciones_base = domain+"/instituciones";

// ROUTES CONTABILIDAD
export const route_contabconf_base = domain+"/configcontabilidad";
export const route_contamovs_base = domain+"/movsContabilidad";
export const route_informesConta_base = domain+"/informescontabilidad";
export const route_procesosConta_base = domain+"/ProcesosContabilidad";



























