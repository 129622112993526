import React from "react";
import {
	FcBullish,
	FcBarChart,
	FcDonate,
	FcBearish,
	FcMoneyTransfer,
	FcFinePrint,
	FcDebt,
	FcOk,
	FcRightUp2,
	FcRightDown2,
	FcLineChart,
	FcFeedIn
} from "react-icons/fc";
import Dashboard from "../../componentsBase/tables/Intro"; // Asegúrate de que la ruta sea correcta

const ParentComponent = () => {
	const cards = [
		{
			id: 1,
			icon: <FcBullish />,
			title: "Ingresos",
			description: "Ejecuta movimientos presupuestales de ingresos",
			detalles: [
				{
					id: 1,
					icon: <FcBarChart />,
					title: "Reconocimiento",
					description: "Reconocimientos presupuestales",
					to: "/menu/hefestoPresupuesto/reconocimiento-Presupuesto/1",
				},
				{
					id: 2,
					icon: <FcDonate />,
					title: "Ingreso",
					description: "Ingresos presupuestales",
					to: "/menu/hefestoPresupuesto/reconocimiento-Presupuesto/2",
				},
			], // Ruta correcta
		},
		{
			id: 2,
			icon: <FcBearish />,
			title: "Gastos",
			description: "Ejecuta movimientos presupuestales de gastos",
			detalles: [
				{
					id: 1,
					icon: <FcMoneyTransfer />,
					title: "Disponibilidad",
					description: "Disponibilidad presupuestal",
					to: "/menu/hefestoPresupuesto/reconocimiento-Presupuesto/3",
				},
				{
					id: 2,
					icon: <FcFinePrint />,
					title: "Registro",
					description: "Registros presupuestales",
					to: "/menu/hefestoPresupuesto/reconocimiento-Presupuesto/4",
				},
				{
					id: 3,
					icon: <FcDebt />,
					title: "Obligación",
					description: "Obligación presupuestal",
					to: "/menu/hefestoPresupuesto/reconocimiento-Presupuesto/5",
				},
				{
					id: 4,
					icon: <FcOk />,
					title: "Egreso",
					description: "Comprobante de egreso",
					to: "/menu/hefestoPresupuesto/reconocimiento-Presupuesto/6",
				},
			], // Ruta correcta
		},
		{
			id: 3,
			icon: <FcFeedIn />,
			title: "Modificaciones",
			description: "Ejecuta modificaciones presupuestales",
			detalles: [
				{
					id: 1,
					icon: <FcRightUp2 />,
					title: "Adiciones",
					description: "Adición presupuestal",
					to: "/menu/hefestoPresupuesto/reconocimiento-Presupuesto/15",
				},
				{
					id: 2,
					icon: <FcRightDown2 />,
					title: "Reducción",
					description: "Reducciones presupuestales",
					to: "/menu/hefestoPresupuesto/reconocimiento-Presupuesto/16",
				},
				{
					id: 3,
					icon: <FcLineChart />,
					title: "Traslados",
					description: "Traslados presupuestales",
					to: "/menu/hefestoPresupuesto/reconocimiento-Presupuesto/17",
				},
			
			], // Ruta correcta
		},
	];

	return <Dashboard cards={cards} />;
};

export default ParentComponent;
