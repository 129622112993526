import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { AuthContext } from "../../context/LogContext";
import { route_contabconf_base } from "../../../api-routes/RoutesLogin";
import TablePuc from "../../componentsBase/tables/TablePUC";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import Swal from "sweetalert2";

export default function PucManual() {
	const { getToken, establishment, inputStyles, customStyles } =
		useContext(AuthContext);
	const [pucSugerido, setPucSugerido] = useState([]);
	const [columnas, setColumnas] = useState([]);
	const [years, setYears] = useState([]);
	const [estructura, setEstructura] = useState([]);
	const [maxNivel, setMaxNivel] = useState(0);
	const btnCancel = useRef(null);
	const [maxCodigo, setMaxCodigo] = useState(0);
	const [nomCab, setNomCab] = useState("");
	const [yearCab, setYearCab] = useState(null);
	const [cabeceraPuc, setCabeceraPuc] = useState(0);
	const [banCarga, setBanCarga] = useState(0);

	useEffect(() => {
		getPucSugerido();
		getYears();
		getEstructura();

		var columns = [
			{ Header: "Código", accessor: "codigo" },
			{ Header: "Nombre", accessor: "nombre" },
			{ Header: "Naturaleza", accessor: "naturalezaN" },
			{ Header: "Tipo", accessor: "tipoN" },
			{ Header: "Opciones", accessor: "opciones" },
		];
		setColumnas(columns);
	}, []);
	function customSort(a, b) {
		// Ordenar por 'codigo'
		if (a.codigo < b.codigo) return -1;
		if (a.codigo > b.codigo) return 1;

		// Si 'codigo' es igual, ordenar por 'tipo'
		if (a.tipo < b.tipo) return -1;
		if (a.tipo > b.tipo) return 1;

		// Si 'codigo' y 'tipo' son iguales, ordenar por 'naturaleza'
		if (a.naturaleza < b.naturaleza) return -1;
		if (a.naturaleza > b.naturaleza) return 1;

		return 0;
	}
	function reemplazarMadreConCodigo(array) {
		// Crear un diccionario para buscar los códigos por id
		const diccionario = {};
		array.forEach((obj) => {
			diccionario[obj.id] = obj.codigo;
		});
		// Reemplazar madre con el código correspondiente
		array.forEach((obj) => {
			if (obj.madre !== null) {
				obj.madre = diccionario[obj.madre];
			}
			obj.id = diccionario[obj.id];
		});

		return array;
	}
	const getPucSugerido = async () => {
		setBanCarga(1)
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_contabconf_base}/getpuc/${establishment.id}`,
			config
		);
		let newPuc = reemplazarMadreConCodigo(result.data.pucDTOList);
		let order = newPuc.sort(customSort);
	
		setPucSugerido(order);

		/* obtenemos el codigo mas largo para validar las estructuras usadas */
		const diccionario = {};
		let maxCodigo = 0;
		order.forEach((obj) => {
			diccionario[obj.id] = obj.codigo;
			if (obj.codigo.length > maxCodigo) {
				maxCodigo = obj.codigo.length;
			}
		});
		setMaxCodigo(maxCodigo);
		/* ----------obtenemos cabecera si existen datos ---------------------*/
		var cab = result.data.cabecera;
		setCabeceraPuc(cab);
		if (cab.id) {
			setNomCab(cab.nombre);
			setYearCab(cab.year);
		}
		setBanCarga(0)
	};
	const getYears = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_contabconf_base}/getyears/${establishment.id}`,
			config
		);
		const formattedOptions = result.data.map((item) => ({
			value: item.id,
			label: item.year,
		}));
		setYears(formattedOptions);
	};
	function handleCrear(values, maxCodigo) {
		setMaxCodigo(maxCodigo);
		setPucSugerido(values);
	}
	const getEstructura = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_contabconf_base}/getestructura/${establishment.id}`,
			config
		);
		if (result.data.length === 0) {
			estructuraBasica();
		} else {
			let order = result.data.sort((a, b) => a.nivel - b.nivel);
			setEstructura(order);
			var max = result.data.reduce((max, estructura) => {
				return estructura.nivel > max ? estructura.nivel : max;
			}, result.data[0].nivel);
			setMaxNivel(max);
		}
	};
	const estructuraBasica = async () => {
		let estructura = JSON.stringify([
			{
				nivel: 1,
				nombre: "Clase",
				caracteres: 1,
				establishment: establishment.id,
			},
			{
				nivel: 2,
				nombre: "Grupo",
				caracteres: 1,
				establishment: establishment.id,
			},
			{
				nivel: 3,
				nombre: "Cuenta",
				caracteres: 2,
				establishment: establishment.id,
			},
			{
				nivel: 4,
				nombre: "Subcuenta",
				caracteres: 2,
				establishment: establishment.id,
			},
		]);
		/* almacenar estructura básica */
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			const response = await axios.post(
				`${route_contabconf_base}/save`,
				estructura,
				config
			);
			getEstructura();
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Error de red, contactese con su proveedor",
				showConfirmButton: true,
			}).then(() => {});
		}
		/* --------------------------- */
	};
	const initialValues = {
		id: "",
		nivel: "",
		nombre: "",
		caracteres: "",
		establishment: establishment.id,
	};
	const validationSchema = Yup.object().shape({
		nombre: Yup.string().required("Requerido"),
		caracteres: Yup.number()
			.required("Requerido")
			.positive("Debe ser positivo")
			.integer("Debe ser  entero")
			.moreThan(0, "Debe ser mayor que cero"),
	});
	const handleSubmit = async (values) => {
		values.nivel = maxNivel + 1;
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
		
			const response = await axios.post(
				`${route_contabconf_base}/saveEstructura`,
				values,
				config
			);
			Swal.fire({
				icon: "success",
				title: "Nivel Configurado correctamente",
				timer: 1500,
			}).then(() => {
				btnCancel.current.click();
				getEstructura();
			});
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Error de red, contactese con su proveedor",
				showConfirmButton: true,
			}).then(() => {});
		}
	};
	const deleteE = async (id) => {
		Swal.fire({
			title: "Eliminar estructura?",
			text: "El nivel no debe tener configuración activa!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				if (validarE(id)) {
					deleteServicio(id);
				} else {
					Swal.fire({
						icon: "error",
						title:
							"Verifique que el nivel no este en uso y no existan niveles superiores",
						showConfirmButton: true,
					});
				}
			}
		});
	};
	const deleteServicio = async (servicioId) => {
		try {
			const response = await axios.delete(
				route_contabconf_base + "/deleteEstructura/" + servicioId,

				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			Swal.fire({
				icon: "success",
				title: "Estructura Eliminada",
				showConfirmButton: true,
			}).then(() => {
				getEstructura();
			});
		} catch (error) {
			throw error;
		}
	};
	function validarE(id) {
		var estructuraS = estructura.find((item) => item.id == id);
		let numero = estructura.reduce((sum, estructura) => {
			if (estructura.nivel <= estructuraS.nivel) {
				return sum + estructura.caracteres;
			}
			return sum;
		}, 0);
	
		var estructuraS = estructura.find((item) => item.id == id);
		if (estructuraS.nivel === maxNivel && numero > maxCodigo) {
			return true;
		} else {
			return false;
		}
	}
	const handleGuardarPuc = async (pucFinal) => {
		if (nomCab !== "" && yearCab != null) {
			setBanCarga(1)
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};
			let cabeceraPuc = {};
			let cabecera = {
				nombre: nomCab,
				year: yearCab,
				activa: true,
				establishment: establishment.id,
				user:localStorage.getItem("idUser"),
			};
			cabeceraPuc.cabecera = cabecera;
			cabeceraPuc.pucList = pucFinal;

			const jsonString = JSON.stringify(cabeceraPuc);
			try {
				await axios.post(
					`${route_contabconf_base}/savePuc`,
					jsonString,
					config
				);
				Swal.fire({
					icon: "success",
					title: "PUC Actualizado correctamente",
					//text: "Error de red, contactese con su proveedor",
					showConfirmButton: true,
				}).then(() => {
					getPucSugerido();
					setBanCarga(0)

				});
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error de red, contactese con su proveedor",
					showConfirmButton: true,
				}).then(() => {
					setBanCarga(0)

				});
			}
		} else {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Configure el año y nombre del PUC",
				showConfirmButton: true,
			}).then(() => {});
		}

		/* --------------------------- */
	};
	const handlUpdateCta = async (cuenta) => {
		cuenta.cabecera = cabeceraPuc.id;
	
		cuenta.user=localStorage.getItem("idUser")
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			await axios.post(
				`${route_contabconf_base}/saveCta/${establishment.id}`,
				cuenta,
				config
			);
			Swal.fire({
				icon: "success",
				title: "Cuenta Actualizada correctamente",
				timer: 1500,
				//text: "Error de red, contactese con su proveedor",
				showConfirmButton: false,
			}).then(() => {
				getPucSugerido();
			});
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Error de red, contactese con su proveedor",
				showConfirmButton: true,
			}).then(() => {});
		}

		/* --------------------------- */
	};
	const handleDeleteCta = async (codigo) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			let response = await axios.delete(
				`${route_contabconf_base}/deleteCta/${codigo},${establishment.id}`,
				config
			);
			if (response.data == true) {
				Swal.fire({
					icon: "success",
					title: "Cuenta eliminada",
					//text: "Error de red, contactese con su proveedor",
					showConfirmButton: true,
				}).then(() => {
					getPucSugerido();
				});
			} else {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Verifique que la cuenta no tenga movimientos",
					showConfirmButton: true,
				}).then(() => {});
			}
		} catch (error) {
		
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Error de red, contactese con su proveedor",
				showConfirmButton: true,
			}).then(() => {});
		}

		/* --------------------------- */
	};

	return (
		<div className="containerFormUser">
			<h1>Configuracion PUC</h1>
			<div className="row">
				{/* BLOQUE1 */}
				<div className="col-md-6  ">
					<div className="form-floating mb-2  ">
						<input
							style={{ ...inputStyles }}
							//innerRef={inputServicio}
							type="text"
							name="nombre"
							id="nombre"
							className="form-control"
							placeholder="Nombre"
							value={nomCab}
							onChange={(e) => {
								setNomCab(e ? e.target.value : "");
							}}
						/>
						<label htmlFor="forma" className="form-label">
							Nombre
						</label>
					</div>
				</div>
				<div className="col-md-6  ">
					<div className="form-floating mb-2  ">
						<Select
							isClearable={true}
							styles={customStyles}
							onChange={(e) => {
								setYearCab(e ? e.value : null);
							}}
							//value={tipoS}
							placeholder="Año"
							options={years}
						/>
					</div>
				</div>
			</div>
			{/* BLOQUE ESTRUCTURA */}

			<div
				className="accordion accordion-flush mb-3"
				id="accordionFlushExample"
			>
				<div class="accordion-item">
					<h2 class="accordion-header">
						<button
							class="accordion-button collapsed"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#flush-collapseOne"
							aria-expanded="false"
							aria-controls="flush-collapseOne"
						>
							Estructura
						</button>
					</h2>
					<div
						id="flush-collapseOne"
						class="accordion-collapse collapse"
						data-bs-parent="#accordionFlushExample"
					>
						<div class="accordion-body mt-1 " style={{ paddingTop: "5px" }}>
							<button
								className="btn btn-primary"
								data-bs-toggle="modal"
								data-bs-target="#crearEstructura"
							>
								<i className="fas fa-plus"></i>
							</button>
							<table className="reactTables table table-striped">
								<thead>
									<tr>
										<th>Nivel</th>
										<th>Nombre</th>
										<th>Caracteres</th>
										<th>Opciones</th>
									</tr>
								</thead>
								<tbody>
									{estructura.map((e, index) => (
										<tr key={index}>
											<td>{e.nivel}</td>
											<td>{e.nombre}</td>
											<td>{e.caracteres}</td>
											<td>
												<button
													className="btnDelete"
													type="button"
													onClick={() => deleteE(e.id)}
												>
													<i className="fas fa-trash-alt"></i>
												</button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

			{/* ----------------- */}

			<h1>PUC Manual</h1>
			{banCarga == 0 ? (
				<TablePuc
					datos={pucSugerido}
					columns={columnas}
					estructura={estructura}
					origenPuc={cabeceraPuc.origen}
					encabezado={{
						empresa: establishment.name,
						nit: establishment.identification,
						titulo: "Plan único de cuentas",
						imagen: establishment.image,
						fechas: "",
						generado: "",
					}}
					onClickCrear={handleCrear}
					onClickGuardar={handleGuardarPuc}
					updateCta={handlUpdateCta}
					deleteCtaBD={handleDeleteCta}
					numPages={1000}
					numPagesO={1000}
				/>
			) : (
				<div className="spinner-overlay">
					<button className="btn btn-primary" type="button" disabled>
						<span
							className="spinner-border spinner-border-sm"
							aria-hidden="true"
						></span>
						<span role="status"> Cargando...</span>
					</button>
				</div>
				/*--------------- */
			)}
			{/* ---------Modal creacion estructura */}
			{/* modal creacion de cuentas */}
			<div
				class="modal fade"
				id="crearEstructura"
				tabindex="-1"
				aria-labelledby="crearEstructuraLabel"
				aria-hidden="true"
			>
				<div class="modal-dialog">
					<div class="modal-content">
						<div class="modal-header">
							<h1 class="modal-title fs-5" id="crearEstructuraLabel">
								Crear Cuenta
							</h1>
							<button
								type="button"
								class="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div class="modal-body">
							{/* ----------------- */}
							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={handleSubmit}
								//innerRef={formikRef}
							>
								{(formik, props) => (
									<Form>
										<div className="row">
											{/* BLOQUE1 */}

											<div className="col-md-3  ">
												<div className="form-floating mb-2  ">
													<Field
														style={{ ...inputStyles }}
														value={maxNivel + 1}
														type="text"
														name="nivel"
														id="nivel"
														disabled
														className="form-control"
														placeholder="Nivel"
													/>
													<label htmlFor="forma" className="form-label">
														Nivel
													</label>
													<ErrorMessage
														name="nivel"
														component="div"
														className="error"
													/>
												</div>
											</div>
											<div className="col-md-6  ">
												<div className="form-floating mb-2  ">
													<Field
														style={{ ...inputStyles }}
														//innerRef={inputServicio}
														type="text"
														name="nombre"
														id="nombre"
														className="form-control"
														placeholder="Nombre"
													/>
													<label htmlFor="forma" className="form-label">
														Nombre
													</label>
													<ErrorMessage
														name="nombre"
														component="div"
														className="error"
													/>
												</div>
											</div>
											<div className="col-md-3  ">
												<div className="form-floating mb-2  ">
													<Field
														style={{
															...inputStyles,
															textAlign: "right",
														}}
														type="text"
														name="caracteres"
														id="caracteres"
														//value={madreS.codigo}
														className="form-control"
														placeholder="Caracteres"
													/>
													<label
														style={{ zIndex: "0" }}
														htmlFor="forma"
														className="form-label"
													>
														Caracteres
													</label>
													<ErrorMessage
														name="caracteres"
														component="div"
														className="error"
													/>
												</div>
											</div>
										</div>
										<div class="modal-footer">
											<button type="submit" className="btn btn-primary">
												Guardar
											</button>
											&nbsp;
											<button
												type="reset"
												ref={btnCancel}
												className="btn btn-danger"
												data-bs-dismiss="modal"
												aria-label="Close"
											>
												Cancelar
											</button>
										</div>
									</Form>
								)}
							</Formik>
							{/* ----------------- */}
						</div>
					</div>
				</div>
			</div>
			{/* ---------------------------------- */}
		</div>
	);
}
