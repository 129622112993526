import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import {
	useTable,
	useSortBy,
	useGroupBy,
	usePagination,
	useFilters,
} from "react-table";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
	route_contabconf_base,
	route_confiPresupuesto_base,
} from "../../../api-routes/RoutesLogin";
import { BsFiletypePdf } from "react-icons/bs";
import { FaFileExcel } from "react-icons/fa";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver"; // Importa saveAs desde file-saver
import Swal from "sweetalert2";
import { FaFolderOpen } from "react-icons/fa";
import { FaFolder } from "react-icons/fa";
import { FaFile } from "react-icons/fa";

import Select from "react-select";
import { AuthContext } from "../../context/LogContext";
import * as Yup from "yup";

const MyTable = ({
	datos,
	columns,
	encabezado,
	origenPuc,
	onClickCrear,
	onClickGuardar,
	updateCta,
	deleteCtaBD,
	estructura,
	//onClickPagination,
	numPages,
	numPagesO,
	//disabled,
	//onClickDelete,
}) => {
	const [expandedRows, setExpandedRows] = useState([]);
	const [estadosM, setEstadosM] = useState([]);
	const [pucFinal, setPucFinal] = useState([]);
	const [data, setData] = useState([]);
	const [madreS, setMadreS] = useState({});
	const [tipoS, setTipoS] = useState({ value: null, label: "Tipo de cuenta" });
	const btnCancel = useRef(null);
	const closemod = useRef(null);
	const closemod1 = useRef(null);
	const [checkboxStates, setCheckboxStates] = useState([]);
	const [nomEdit, setNomEdit] = useState("");
	const [ctaEdit, setCtaEdit] = useState(null);
	const [naturalezaS, setNaturalezaS] = useState({});
	const [categorias, setCategorias] = useState([]);
	const [categoriaS, setCategoriaS] = useState(null);
	const { establishment, customStyles, inputStyles, getToken } =
		useContext(AuthContext);
	const [proyecciones, setProyecciones] = useState([]);
	const [cabSelect, setCabSelect] = useState(null);
	const [cabecerasPlanP, setCabecerasPlanP] = useState([]);
	const [cuentaPre, setCuentaPre] = useState({});

	const exportToExcel = async () => {
		const headers = columns.map((col) => col.Header);
		const dataRows = data.map((row) => columns.map((col) => row[col.accessor]));

		const result = data.map((item) => {
			const row = {};
			columns.forEach((header) => {
				const fieldName = header.accessor;
				const headerName = header.Header;
				row[headerName] = item[fieldName];
			});
			return row;
		});

		// Crear un nuevo libro de Excel
		const workbook = new ExcelJS.Workbook();
		const worksheet = workbook.addWorksheet("Sheet 1");

		// Establecer el título en la celda A1
		const titleCell = worksheet.getCell("D2");
		titleCell.value = encabezado.empresa;
		titleCell.font = { size: 12, bold: true };
		titleCell.alignment = { vertical: "middle", horizontal: "left" };

		// Establecer otras celdas de encabezado
		const nitCell = worksheet.getCell("D3");
		nitCell.value = encabezado.nit;
		nitCell.font = { size: 9, bold: false };

		const informeCell = worksheet.getCell("D4");
		informeCell.value = encabezado.titulo;
		informeCell.font = { size: 12, bold: true };

		const fechasCell = worksheet.getCell("D5");
		fechasCell.value = encabezado.fechas;
		fechasCell.font = { size: 9, bold: false };

		const generadoCell = worksheet.getCell("D6");
		generadoCell.value = encabezado.generado;
		generadoCell.font = { size: 9, bold: false };

		// Insertar la imagen (si informe.imagen es una URL válida)
		if (encabezado.imagen) {
			const imageId = workbook.addImage({
				base64: encabezado.imagen,
				extension: "png",
			});

			worksheet.addImage(imageId, "A1:B6"); // Ajusta el rango según tu necesidad
		}
		// generamos dos filas de espaio en blanco
		const espacioCell = worksheet.getCell("D7");
		//generadoCell.value = "";
		const espacioCell2 = worksheet.getCell("D8");
		//generadoCell.value = "";

		columns.forEach((column, index) => {
			worksheet.getColumn(index + 1).width = 12; // Index + 1 porque el índice de las columnas comienza en 1
		});

		// Insertar el encabezado de las columnas en la fila 8 (después de las celdas de encabezado)
		const headerRow = worksheet.addRow(headers);
		// Establecer bordes delgados y fondo de color azul claro para las celdas de encabezado
		headerRow.eachCell((cell) => {
			cell.width = 50;
			cell.font = { size: 10, bold: true };
			cell.alignment = { vertical: "middle", horizontal: "center" };
			cell.border = {
				top: { style: "thin" },
				left: { style: "thin" },
				bottom: { style: "thin" },
				right: { style: "thin" },
			};
			cell.fill = {
				type: "pattern",
				pattern: "solid",
				fgColor: { argb: "83a9f0" }, // Código de color para azul claro
			};
		});

		// Insertar los datos a partir de la fila 9
		result.forEach((row) => worksheet.addRow(Object.values(row)));

		// Guardar el libro de Excel como un archivo
		const buffer = await workbook.xlsx.writeBuffer();
		const blob = new Blob([buffer], {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		});
		saveAs(blob, encabezado.titulo + ".xlsx");
	};

	const exportToPDF = () => {
		const unit = "pt";
		const size = "legal";
		const orientation = "landscape";

		const marginLeft = 40;
		const doc = new jsPDF(orientation, unit, size);

		doc.setFontSize(12);

		// Agregar la imagen al encabezado
		if (encabezado.imagen) {
			doc.addImage(encabezado.imagen, "png", marginLeft, 30, 100, 100); // Ajusta las coordenadas y dimensiones según tu necesidad
		}

		const title = encabezado.titulo;
		const headers = columns.map((col) => col.Header);
		const dataRows = data.map((row) => columns.map((col) => row[col.accessor]));

		// Agregar el título al encabezado
		doc.text(encabezado.empresa, marginLeft + 120, 40);
		doc.text(encabezado.nit, marginLeft + 120, 60);
		doc.text(encabezado.titulo, marginLeft + 120, 80);
		doc.text(encabezado.fechas, marginLeft + 120, 100);
		doc.text(encabezado.generado, marginLeft + 120, 120);

		doc.autoTable({
			startY: 140,
			head: [headers],
			body: dataRows,
			styles: {
				fontSize: 10,
				cellPadding: 3,
				cellWidth: "auto",
				halign: "left",
				valign: "middle",
			},
		});

		doc.save(encabezado.titulo + ".pdf");
	};
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setFilter,
		state: { pageIndex },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0, pageSize: numPages },
		},
		useFilters,
		useGroupBy,
		useSortBy,
		usePagination
	);

	const handleFilterChange = (columnId, event) => {
		const value = event.target.value || undefined;
		setFilter(columnId, value);
	};

	const handleToggleRow = (rowId, ban) => {
		var status = "";
		if (ban == 1) {
			status = "none";
		} else {
			status = "";
		}
		const arrayHijos = obtenerIdsHijosDirectosYDescendientes(rowId, data);
		var cambiosEstado = estadosM;
		arrayHijos.forEach((h) => {
			const fila = document.getElementById(h);
			if (fila) {
				fila.style.display = status;
			}
		});
		var cambiosEstado = estadosM.map((objeto) =>
			objeto.id === rowId ? { ...objeto, status: !objeto.status } : objeto
		);
		setEstadosM(cambiosEstado);
	};

	const obtenerIdsHijosDirectosYDescendientes = (
		idPadre,
		datos,
		resultado = []
	) => {
		const hijosDirectos = datos.filter((item) => item.madre === idPadre);
		hijosDirectos.forEach((hijoDirecto) => {
			resultado.push(hijoDirecto.id);
			obtenerIdsHijosDirectosYDescendientes(hijoDirecto.id, datos, resultado);
		});
		return resultado;
	};

	function buscarPorId(id) {
		var obj = estadosM.find((item) => item.id == id);
		if (obj) {
			return obj.status;
		} else {
			return null;
		}
	}

	function habilitarHijos(status, id) {
		if (status == true) {
			const hijosDirectos = data.filter((item) => item.madre === id);
			hijosDirectos.forEach((h) => {
				const fila = document.getElementById("S" + h.id);
				if (fila) {
					fila.disabled = !status;
				}
			});
		}
		const BtnT = document.getElementById("T" + id);
		const BtnD = document.getElementById("D" + id);
		const BtnDel = document.getElementById("Del" + id);
		if (BtnT) {
			//BtnT.disabled = !status;
		}
		if (BtnD) {
			//BtnD.disabled = !status;
		}
		if (BtnDel) {
			if (status == false) {
				BtnDel.style.display = "block";
			} else {
				BtnDel.style.display = "none";
			}
		}

		crearPucDefinitivo(id, status);
	}

	function crearPucDefinitivo(id, status) {
		if (status == true) {
			const cuenta = data.find((obj) => obj.id === id);
			if (cuenta) {
				cuenta.activa = true;
				setPucFinal([...pucFinal, cuenta]);
			}
		} else {
			let newArray = pucFinal.filter((item) => item.id !== id);
			setPucFinal(newArray);
		}
	}
	useEffect(() => {
		getCabecerasP(0);
		setData(datos);
		getCategorias();
		if (origenPuc === 1) {
			setPucFinal(datos);
		}
		checks(datos);
		const filteredArray = datos.filter((item) => item.tipo == 1);
		const newArray = filteredArray.map((item) => {
			// Creamos un nuevo objeto con las propiedades del objeto original
			const newItem = {
				id: item.id, // Puedes asignar el valor que desees para el estado
				status: true, // Asignamos 1 por defecto para el status
			};

			return newItem;
		});
		setEstadosM(newArray);
		maxCodigo(datos);
	}, [datos]);
	const initialValues = {
		id: "",
		codigo: "",
		nombre: "",
		naturaleza: "",
		tipo: null,
		naturalezaN: "",
		tipoN: "",
		activa: true,
		//idEstablishment: establishment.id,
	};
	const validationSchema = Yup.object().shape({
		codigo: Yup.number().required("Este campo es requerido"),
		nombre: Yup.string().required("Este campo es requerido"),
		tipo: Yup.number().required("Este campo es requerido"),
	});
	function customSort(a, b) {
		// Ordenar por 'codigo'
		if (a.codigo < b.codigo) return -1;
		if (a.codigo > b.codigo) return 1;

		// Si 'codigo' es igual, ordenar por 'tipo'
		if (a.tipo < b.tipo) return -1;
		if (a.tipo > b.tipo) return 1;

		// Si 'codigo' y 'tipo' son iguales, ordenar por 'naturaleza'
		if (a.naturaleza < b.naturaleza) return -1;
		if (a.naturaleza > b.naturaleza) return 1;

		return 0;
	}
	function validar(codigo) {
		let response = true;
		let msj = "";
		var codT = madreS.codigo + codigo;
		//valida si el codigo existe en el array
		var found = data.find((obj) => obj.codigo == codT) !== undefined;
	
		if (found == true) {
			response = false;
			msj = "Codigo duplicado ";
		}
		//validar si el numero de caracteres corresponde a la estructura
		let numCaracteres = madreS.codigo.length;
		let ultNivel = null;
		estructura.map((n) => {
			numCaracteres = numCaracteres - n.caracteres;
			if (numCaracteres === 0) {
				ultNivel = n;
			}
		});
		var existNivel =
			estructura.find((obj) => obj.nivel == ultNivel.nivel + 1) !== undefined;
		if (existNivel) {
			var Nivel = estructura.find((obj) => obj.nivel == ultNivel.nivel + 1);
	
			if (codigo.length !== Nivel.caracteres) {
				response = false;
				msj = "El código no cumple con la configuracion de estructura";
			}
		} else {
			response = false;
			msj = "La estructura no tiene el nivel requerido";
		}

		return { response: response, msj: msj };
	}
	const handleSubmit = (values) => {
		let cod = madreS.codigo + values.codigo;
		let jsonValidar = validar(values.codigo);
		if (jsonValidar.response === true) {
			values.naturaleza = naturalezaS.value;
			values.naturalezaN = naturalezaS.label;
			values.madre = madreS.codigo;
			values.id = cod;
			values.codigo = cod;
			values.tipoN = values.tipo === 1 ? "Titulo" : "Detalle";
			if (origenPuc === 0) {
				let newPuc = [...data, values];
				let order = newPuc.sort(customSort);
				btnCancel.current.click();
				setTipoS({ value: null, label: "Tipo de cuenta" });
				let maxCodigov = maxCodigo(order);
				onClickCrear(order, maxCodigov);
			} else if (origenPuc === 1) {
				btnCancel.current.click();
				updateCta(values);
			}
		} else {
			Swal.fire({
				icon: "error",
				title: "Error en código",
				text: jsonValidar.msj,
				showConfirmButton: true,
			});
		}
	};
	function maxCodigo(array) {
		/* obtenemos el codigo mas largo para validar las estructuras usadas */
		const diccionario = {};
		let maxCodigo = 0;
		array.forEach((obj) => {
			diccionario[obj.id] = obj.codigo;
			if (obj.codigo.length > maxCodigo) {
				maxCodigo = obj.codigo.length;
			}
		});
		return maxCodigo;
		/* ----------------------------------------------------------------- */
	}
	function validarCta(codigo) {
		//valida si la cuenta a eliominar tiene hijas
		const cuenta = data.find((obj) => obj.madre === codigo) !== undefined;
		return !cuenta;
	}
	function deleteCta(codigo) {
		Swal.fire({
			title: "Eliminar cuenta?",
			text: "La cuenta no debe tener movimientos!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				if (validarCta(codigo)) {
					if (origenPuc === 0) {
						let newArray = data.filter(
							(estructura) => estructura.codigo !== codigo
						);
						let maxCodigov = maxCodigo(newArray);
						onClickCrear(newArray, maxCodigov);
						Swal.fire({
							title: "Cuenta eliminada",
							icon: "success",
							showConfirmButton: true,
						});
					} else {
						deleteCtaBD(codigo);
					}
				} else {
					Swal.fire({
						title: "No se puede eliminar",
						text: "La cuenta no debe tener movimientos ni cuentas hijas!",
						icon: "warning",
						showConfirmButton: true,
					});
				}
			}
		});
	}
	function validarPucFinal() {
		let resp = true;
		let msj = "";
		//validamos que exitan un puc
		if (pucFinal.length == 0) {
			resp = false;
			msj = "No existen cuentas activas";
		}
		//validamos que todas las cuentas tengan madre dentro del puc

		pucFinal.forEach((obj) => {
			if (obj.codigo.length > 1) {
				const madre =
					pucFinal.find((cta) => cta.codigo === obj.madre) !== undefined;
				if (madre == false) {
					resp = false;
					msj = "Código sin madre activa: " + obj.codigo;
				}
			}
		});

		if (resp === true) {
			if (origenPuc === 0) {
				onClickGuardar(pucFinal);
			} else if (origenPuc === 1) {
				return true;
			}
		} else {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: msj,
				showConfirmButton: true,
			});
		}
	}
	function updateStatusCta(id, status, nombre, categoria) {
		const cuenta = data.find((obj) => obj.id === id);
		if (status != null) {
			cuenta.activa = status;
		}
		if (nombre != null) {
			cuenta.nombre = nombre;
			if (origenPuc === 0) {
				let newData = data.map((item) =>
					item.id === cuenta.id ? cuenta : item
				);
				let maxCodigov = maxCodigo(newData);
				onClickCrear(newData, maxCodigov);
				closemod.current.click();
			}
		}
		if (categoria != null) {
			cuenta.categoria = categoriaS != null ? categoriaS.value : null;
			let newData = data.map((item) => (item.id === cuenta.id ? cuenta : item));
			let maxCodigov = maxCodigo(newData);
			onClickCrear(newData, maxCodigov);
			closemod1.current.click();
		}
		if (cuenta != null) {
			cuenta.presupuesto = cuentaPre != null ? cuentaPre.value : null;
			let newData = data.map((item) => (item.id === cuenta.id ? cuenta : item));
			let maxCodigov = maxCodigo(newData);
			onClickCrear(newData, maxCodigov);
			closemod1.current.click();
		}
		//setPucFinal([...pucFinal, cuenta]);
		if (origenPuc === 1 && validarPucFinal()) {
			updateCta(cuenta);
			closemod.current.click();
		}

		//updateCta(cuenta)
	}
	const handleCheckboxChange = (id, checked) => {
		setCheckboxStates((prevStates) => ({
			...prevStates,
			[id]: checked,
		}));
		updateStatusCta(id, checked, null, null);
	};
	function checks(datos) {
		const initialCheckboxStates =
			origenPuc === 1
				? datos.reduce((acc, row) => {
						acc[row.id] = row.activa;
						return acc;
				  }, {})
				: {};
		setCheckboxStates(initialCheckboxStates);
	}
	const getCategorias = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_contabconf_base}/getCategoriasD`,
			config
		);

		const formattedOptions = result.data.map((item) => ({
			value: item.id,
			label: item.nombre,
		}));
		setCategorias(formattedOptions);
	};
	const loadCuentas = async (idC) => {
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};
			const result = await axios.get(
				`${route_confiPresupuesto_base}/getproyecciones/${idC}`,
				config
			);
			let order = result.data.sort(customSort);
			let ctasxtipo = order;

			/* if (tipo == 1) {
				ctasxtipo = order.filter((cuenta) => cuenta.naturaleza == 1);
			} else if (tipo == 3 || tipo == 17) {
				ctasxtipo = order.filter((cuenta) => cuenta.naturaleza == 2);
			} */

				const formattedOptions = ctasxtipo
				.filter((item) => item.naturaleza === 1) // Filtra solo los que tienen naturaleza = 1
				.map((item) => ({
				  value: item.id,
				  label: item.codigo + " - " + item.nombre,
				  tipo: item.tipo,
				  naturaleza: item.naturaleza,
				  isDisabled: item.tipo === 1, // Simplificado
				  saldo: item.saldo,
				}));
			  
			setProyecciones(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
		}
	};
	const getCabecerasP = async (idP) => {
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};

			const result = await axios.get(
				`${route_confiPresupuesto_base}/getplanesp/${establishment.id}`,
				config
			);
			const formattedOptions = result.data.map((item) => ({
				value: item.id,
				label: item.nombre + " - " + item.yearObject.year,
				tipo: item.tipo,
			}));
			setCabSelect(formattedOptions.length > 0 ? formattedOptions[0] : null);
			loadCuentas(
				formattedOptions.length > 0 ? formattedOptions[0].value : null
			);
			setCabecerasPlanP(formattedOptions);
			if (idP != 0) {
				const plan = formattedOptions.find((opcion) => opcion.value === idP);
				setCabSelect(plan);
				loadCuentas(plan.value);
			}
		} catch (error) {
			console.error("Error while fetching options:", error);
		}
	};
	const stylePlan = {
		control: (base) => ({
			...base,
			height: 60,
			minHeight: 35,
			fontSize: "14px",
			height: "45px",
		}),
		option: (base, { data, isDisabled }) => ({
			...base,
			textAlign: `left`,
			backgroundColor: data.tipo === 1 ? "#5fb7eb" : "#3879db",
			color: "white",
		}),
	};

	return (
		<>
			<div style={{ display: "flex", justifyContent: "flex-start" }}>
				<button
					className="btnInfo"
					onClick={exportToExcel}
					title="Generar Excel"
				>
					<FaFileExcel size={20} />
				</button>
				&nbsp;
				<button className="btnInfo" onClick={exportToPDF} title="Generar PDF">
					<BsFiletypePdf size={20} />
				</button>
				&nbsp;
				{origenPuc === 0 && (
					<button
						className="btn btn-success"
						onClick={validarPucFinal}
						title="Guardar Cambios"
					>
						<i className="fa fa-save"></i>&nbsp; Guardar Cambios
					</button>
				)}
			</div>
			<div>
				<table className="reactTables table table-striped" {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps(column.getSortByToggleProps())}>
										{column.render("Header")}
										<span>
											{column.isSorted
												? column.isSortedDesc
													? " 🔽"
													: " 🔼"
												: ""}
										</span>
										<div>
											{column.canFilter ? (
												<input
													type="text"
													value={column.filterValue || ""}
													onChange={(e) => handleFilterChange(column.id, e)}
												/>
											) : null}
										</div>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row, index) => {
							prepareRow(row);
							const isExpanded = expandedRows.includes(row.id);
							let isDisabled = false;
							return (
								<React.Fragment key={row.id}>
									<tr {...row.getRowProps()} id={row.original.id}>
										{row.cells.map((cell) => {
											if (
												cell.column.id == "codigo" &&
												cell.value.length > 1 &&
												isDisabled == false
											) {
												isDisabled = true;
											}
											return (
												<td
													key={cell.column.id}
													style={{
														backgroundColor: row.original.color,
														paddingLeft:
															cell.column.id === row.cells[0].column.id
																? row.original.codigo.length * 5
																: 0,
														textAlign: "left",
													}}
													{...cell.getCellProps()}
												>
													{/* Icono folder o hoja */}
													{cell.column.id === row.cells[0].column.id && (
														<>
															{row.original.tipo === 1 &&
																buscarPorId(row.original.id) === true && (
																	<FaFolderOpen
																		className="text-primary"
																		onClick={() =>
																			handleToggleRow(row.original.id, 1)
																		}
																		style={{ cursor: "pointer" }}
																	/>
																)}
															{/* ----------------------------------------- */}
															{row.original.tipo === 1 &&
																buscarPorId(row.original.id) === false && (
																	<FaFolder
																		className="text-primary"
																		onClick={() =>
																			handleToggleRow(row.original.id, 2)
																		}
																		style={{ cursor: "pointer" }}
																	/>
																)}
															{/* -------------------------------------- */}
															{row.original.tipo === 2 && <FaFile />}
															&nbsp;&nbsp;
														</>
													)}
													{/* boton de edicion en columna de nombbre */}
													{cell.column.id === row.cells[1].column.id && (
														<>
															&nbsp;
															<i
																className="fas fa-edit"
																style={{ color: "blue", cursor: "pointer" }}
																data-bs-toggle="modal"
																data-bs-target="#editarNombreCta"
																onClick={() => {
																	let nom = data.find(
																		(obj) => obj.id === row.original.id
																	);
																	setNomEdit(nom.nombre);
																	setCtaEdit(nom);
																}}
															></i>
															&nbsp;
														</>
													)}
													{/* -------------------------------------- */}
													{cell.render("Cell")} {}
													{/* CONDICION QUE PERMITE QUE LOS BOTONES SE MUESREN SOLO EN OPCINES */}
													{cell.column.id == "opciones" ? (
														<>
															<div
																style={{
																	display: "flex",
																	alignItems: "center",
																	gap: "10px",
																}}
															>
																{/* switch habilitar cuentas */}
																<div class="form-check form-switch">
																	<input
																		class="form-check-input"
																		type="checkbox"
																		role="switch"
																		id={`S${row.original.id}`}
																		checked={checkboxStates[row.original.id]}
																		onChange={(e) => {
																			if (origenPuc === 1) {
																				handleCheckboxChange(
																					row.original.id,
																					e.target.checked
																				);
																			}

																			habilitarHijos(
																				e.target.checked,
																				row.original.id
																			);
																		}}
																	></input>
																</div>
																{/* ---------------Boton creacion ctas ---------------- */}
																{row.original.tipo === 1 && (
																	<button
																		type="button"
																		class="btn btn-primary btn-sm"
																		//disabled={isDisabled}
																		data-bs-toggle="modal"
																		data-bs-target="#crearCtaModal"
																		id={`T${row.original.id}`}
																		onClick={() => {
																			var m = data.find(
																				(obj) => obj.id === row.original.id
																			);
																			setMadreS(m);
																			setNaturalezaS({
																				value: m.naturaleza,
																				label: m.naturalezaN,
																			});
																		}}
																	>
																		<i className="fas fa-plus"></i>
																	</button>
																)}
																{/* ----- opciones cuentas detalle --------------- */}
																{row.original.tipo === 2 && (
																	<button
																		type="button"
																		class="btn btn-primary btn-sm"
																		//disabled={isDisabled}
																		data-bs-toggle="modal"
																		data-bs-target="#detalleCtaModal"
																		id={`T${row.original.id}`}
																		onClick={() => {
																			setCategoriaS({
																				value: null,
																				label: "Categoria",
																			});
																			setCuentaPre({
																				value: null,
																				label: "Cuenra Presupuesto",
																			});
																			let nom = data.find(
																				(obj) => obj.id === row.original.id
																			);
																			setCtaEdit(nom);
																			let cat = categorias.find(
																				(obj) => obj.value === nom.categoria
																			);
																			let pre = proyecciones.find(
																				(obj) => obj.value === nom.presupuesto
																			);
																		
																			setCuentaPre(pre);
																			setCategoriaS(cat);
																		}}
																	>
																		<i className="fas fa-list"></i>
																	</button>
																)}

																<button
																	className="btnDelete"
																	type="button"
																	id={`Del${row.original.id}`}
																	style={{
																		display: origenPuc === 0 ? "block" : "none",
																	}}
																	onClick={() => deleteCta(row.original.codigo)}
																>
																	<i className="fas fa-trash-alt"></i>
																</button>
															</div>
														</>
													) : null}
													{/* ----------------------------------------------------- */}
												</td>
											);
										})}
									</tr>
								</React.Fragment>
							);
						})}
					</tbody>
				</table>
				<div>
					<button
						className="btn btn-primary"
						onClick={() => gotoPage(0)}
						disabled={!canPreviousPage}
					>
						{"<<"}
					</button>
					&nbsp;
					<button
						className="btn btn-primary"
						onClick={() => previousPage()}
						disabled={!canPreviousPage}
					>
						{"<"}
					</button>
					&nbsp;
					<button
						className="btn btn-primary"
						onClick={() => nextPage()}
						disabled={!canNextPage}
					>
						{">"}
					</button>
					&nbsp;
					<button
						className="btn btn-primary"
						onClick={() => gotoPage(pageCount - 1)}
						disabled={!canNextPage}
					>
						{">>"}
					</button>
					&nbsp;
					<span>
						Página{" "}
						<strong>
							{pageIndex + 1} de {pageOptions.length}
						</strong>{" "}
					</span>
				</div>
				{/* modal creacion de cuentas */}
				<div
					class="modal fade"
					id="crearCtaModal"
					tabindex="-1"
					aria-labelledby="crearCtaModalLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog">
						<div class="modal-content">
							<div class="modal-header">
								<h1 class="modal-title fs-5" id="crearCtaModalLabel">
									Crear Cuenta
								</h1>
								<button
									type="button"
									class="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
								></button>
							</div>
							<div class="modal-body">
								{/* ----------------- */}
								<Formik
									initialValues={initialValues}
									validationSchema={validationSchema}
									onSubmit={handleSubmit}
									//innerRef={formikRef}
								>
									{(formik, props) => (
										<Form>
											<div className="row">
												{/* BLOQUE1 */}
												<div className="col-md-12  ">
													<div className="form-floating mb-2  ">
														<Field
															style={{ ...inputStyles }}
															//innerRef={inputServicio}
															type="text"
															name="nombre"
															id="nombre"
															className="form-control"
															placeholder="Nombre"
														/>
														<label htmlFor="forma" className="form-label">
															Nombre
														</label>
														<ErrorMessage
															name="nombre"
															component="div"
															className="error"
														/>
													</div>
												</div>
												<div className="col-md-6  ">
													<div className="form-floating mb-2  ">
														<Select
															isClearable={true}
															styles={customStyles}
															onChange={(e) => {
																const newValue = e ? e : null;
																formik.setFieldValue(
																	"tipo",
																	newValue != null ? newValue.value : null
																);
																setTipoS(newValue);
															}}
															value={tipoS}
															placeholder="Tipo de cuenta"
															options={[
																{ value: 1, label: "Titulo" },
																{ value: 2, label: "Detalle" },
															]}
														/>
														<ErrorMessage
															name="tipo"
															component="div"
															className="error"
														/>
													</div>
												</div>

												<div className="col-md-6  ">
													<div className="form-floating mb-2  ">
														<Select
															isClearable={true}
															styles={customStyles}
															onChange={(e) => {
																const newValue = e ? e : null;
																/* formik.setFieldValue(
																	"naturaleza",
																	newValue != null ? newValue.value : null
																); */
																setNaturalezaS(newValue);
															}}
															value={naturalezaS}
															placeholder="Naturaleza de cuenta"
															options={[
																{ value: 1, label: "Débito" },
																{ value: 2, label: "Crédito" },
															]}
														/>
														<ErrorMessage
															name="naturaleza"
															component="div"
															className="error"
														/>
													</div>
												</div>
												<div className="col-md-6  ">
													<div className="form-floating mb-2  ">
														<Field
															style={{
																...inputStyles,
																textAlign: "right",
																fontSize: "25px",
															}}
															type="text"
															value={madreS.codigo}
															className="form-control"
															placeholder="Código"
															disabled
														/>
														<label
															style={{ zIndex: "0" }}
															htmlFor="forma"
															className="form-label"
														>
															Cuenta madre
														</label>
													</div>
												</div>
												<div className="col-md-6  ">
													<div className="form-floating mb-2  ">
														<Field
															style={{ ...inputStyles }}
															type="text"
															name="codigo"
															id="codigo"
															className="form-control"
															placeholder="Código"
														/>
														<label
															style={{ zIndex: "0" }}
															htmlFor="forma"
															className="form-label"
														>
															Código cuenta
														</label>
														<ErrorMessage
															name="codigo"
															component="div"
															className="error"
														/>
													</div>
												</div>
											</div>
											<div class="modal-footer">
												<button type="submit" className="btn btn-primary">
													Guardar
												</button>
												&nbsp;
												<button
													type="reset"
													ref={btnCancel}
													className="btn btn-danger"
													data-bs-dismiss="modal"
													aria-label="Close"
												>
													Cancelar
												</button>
											</div>
										</Form>
									)}
								</Formik>
								{/* ----------------- */}
							</div>
						</div>
					</div>
				</div>
				{/* modal detalle de categoria */}
				<div
					class="modal fade"
					id="detalleCtaModal"
					tabindex="-1"
					aria-labelledby="detalleCtaModalLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog modal-lg">
						<div class="modal-content">
							<div class="modal-header">
								<h1 class="modal-title fs-5" id="detalleCtaModalLabel">
									Configuración
								</h1>
								<button
									type="button"
									class="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
									ref={closemod1}
								></button>
							</div>
							<div
								className="modal-body"
								style={{ display: "flex", alignItems: "center" }}
							>
								{/* ----------------- */}

								<div className="row">
									<h2>Categoria</h2>
									<div className="col-md-12  ">
										<div className="form-floating mb-2  ">
											<Select
												isClearable={true}
												styles={customStyles}
												value={categoriaS}
												onChange={(e) => {
													setCategoriaS(e ? e : null);
												}}
												placeholder="Categoría"
												options={categorias}
											/>
										</div>
									</div>

									{/* select cuenta presupuestal */}
									<h2>Presupuesto</h2>
									<div className="col-md-12  ">
										<div className="form-floating mb-2  ">
											<Select
												styles={customStyles}
												isSearchable="true"
												placeholder="Plan presupuestal"
												isClearable="true"
												value={cabSelect}
												options={cabecerasPlanP}
												onChange={(e) => {
													setCabSelect(e);
													loadCuentas(e ? e.value : 0);
												}}
											></Select>
										</div>
									</div>
									<div className="col-md-12  ">
										<div className="form-floating mb-2  ">
											<Select
												//ref={selectRef}
												styles={stylePlan}
												isSearchable="true"
												placeholder="Cuenta"
												isClearable="true"
												id="selectCta"
												value={cuentaPre}
												options={proyecciones}
												onChange={(e) => {
													if (e) {
														setCuentaPre(e);
													} else {
														setCuentaPre(e);
													}
												}}
											></Select>
										</div>
									</div>
									<div class="modal-footer">
										<div className="col-md-2  ">
											<button
												className="btn btn-primary"
												onClick={() => {
													updateStatusCta(
														ctaEdit ? ctaEdit.id : null,
														null,
														null,
														1
													);
												}}
											>
												<i className="fas fa-save"></i>&nbsp;Actualizar
											</button>
										</div>
									</div>
								</div>
								{/* ----------------- */}
							</div>
						</div>
					</div>
				</div>
				{/* modal edicion nombre de cuenta */}
				<div
					class="modal fade"
					id="editarNombreCta"
					tabindex="-1"
					aria-labelledby="editarNombreCtaLabel"
					aria-hidden="true"
				>
					<div class="modal-dialog">
						<div class="modal-content">
							<div class="modal-header">
								<h1 class="modal-title fs-5" id="editarNombreCtaLabel">
									Editar Nombre
								</h1>
								<button
									type="button"
									class="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
									ref={closemod}
								></button>
							</div>
							<div
								className="modal-body"
								style={{ display: "flex", alignItems: "center" }}
							>
								<input
									className="form-control"
									style={{ marginRight: "10px" }}
									onChange={(e) => {
										setNomEdit(e.target.value);
									}}
									value={nomEdit}
								></input>
								<button
									className="btn btn-primary"
									onClick={() => {
										updateStatusCta(
											ctaEdit ? ctaEdit.id : null,
											null,
											nomEdit,
											null
										);
									}}
								>
									<i className="fas fa-save"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default MyTable;
