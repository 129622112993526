import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AuthContext } from "../../context/LogContext";
import {
	route_confiPresupuesto_base,
	route_procesosPresupuesto,
} from "../../../api-routes/RoutesLogin";
import Swal from "sweetalert2";
import { BsCheckCircle, BsXCircle } from "react-icons/bs";
import { FaFileSignature } from "react-icons/fa";
import ProyeccionPDF from "../pdfs/ProyeccionPDF2";
import ActaPDF from "../pdfs/ActaPlanP";
import * as Yup from "yup";
import Select from "react-select";
import { useParams } from "react-router-dom";

const Estructuras = () => {
	const { edit } = useParams();
	const { getToken } = useContext(AuthContext);
	const { establishment, user } = useContext(AuthContext);
	const [cabeceras, setCabeceras] = useState([]);
	const [motivoR, setMotivoR] = useState("");
	const [estructura, setEstructura] = useState([]);
	const [proyecciones, setProyecciones] = useState([]);
	const [cabeceraObject, setCabeceraObject] = useState({});
	const [cabeceraSelected, setCabeceraSelected] = useState(null);
	const [firmas, setFirmas] = useState([]);
	const [ctasMadre, setCtasMadre] = useState([]);
	const [articulos, setArticulos] = useState([]);
	const [confActa, setConfActa] = useState({});
	const submitCab = useRef();
	const formikRef = useRef(null);
	const formikArt = useRef(null);
	const [selectedImage, setSelectedImage] = useState(null);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [pie, setPie] = useState("");
	const [modalVisible, setModalVisible] = useState(false);
	const [cuentas, setCuentas] = useState(null);

	function customSort(a, b) {
		// Ordenar por 'codigo'
		if (a.codigo < b.codigo) return -1;
		if (a.codigo > b.codigo) return 1;

		// Si 'codigo' es igual, ordenar por 'tipo'
		if (a.tipo < b.tipo) return -1;
		if (a.tipo > b.tipo) return 1;

		// Si 'codigo' y 'tipo' son iguales, ordenar por 'naturaleza'
		if (a.naturaleza < b.naturaleza) return -1;
		if (a.naturaleza > b.naturaleza) return 1;

		return 0;
	}
	const getCabeceras = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_confiPresupuesto_base}/getcabeceras/${establishment.id}`,
			config
		);
		if (edit == 1) {
			const filtrados = result.data.filter((objeto) => objeto.estado == 1);
			setCabeceras(filtrados);
		} else {
			setCabeceras(result.data);
		}
	};
	const handleAprueba = (id) => {
		Swal.fire({
			title: "Esta seguro de aprobar el plan presupuestal?",
			text: "Este proceso es irreversible!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				cambiarEstado(id, 1);
			}
		});
	};
	const handleRechaza = (id) => {
		let motivoR = "";
		Swal.fire({
			title: "Esta seguro de aprobar el plan presupuestal?",
			text: "Este proceso es irreversible!",
			icon: "warning",
			input: "text",
			inputLabel: "Motivo de rechazo",
			showCancelButton: true,
			inputValidator: (value) => {
				if (!value) {
					return "You need to write something!";
				} else {
					motivoR = value;
				}
			},
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				cambiarEstado(id, 2, motivoR);
			}
		});
	};
	const cambiarEstado = async (id, estado, motivo = "") => {
		let values = {
			id: id,
			estado: estado,
			motivo: motivo,
			user: localStorage.getItem("idUser"),
		};
		let dataJson = JSON.stringify(values);
		try {
			const response = await axios.post(
				route_confiPresupuesto_base + "/estadoPlan",
				dataJson,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			Swal.fire({
				icon: "success",
				title: "Respuesta registrada",
				showConfirmButton: false,
				timer: 1500,
			});
			getCabeceras();
		} catch (error) {
			Swal.fire({
				icon: "warning",
				title: "Hubo un error en el proceso",
				showConfirmButton: false,
				timer: 1500,
			});
		}
	};
	const getEstructura = async (idE) => {
		const cab = cabeceras.find((item) => item.id === idE);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_confiPresupuesto_base}/getconf/${idE}`,
			config
		);
		let estructuraOrder = result.data.slice().sort((a, b) => a.nivel - b.nivel);
		setCabeceraObject(cab);
		setCabeceraSelected(cab.id);
		let order = cab.proyeccion.sort(customSort);
		setProyecciones(order);
		setEstructura(estructuraOrder);
		getCtasMadre(cab.id);
		getFirmas(idE);
		findActaxplan(idE);
		getArticulos(idE);
		setCabeceraSelected(idE);
	};
	const initialValues = {
		nombre: "",
		cargo: "",
		plan: "",
		user: localStorage.getItem("idUser"),
	};
	const handleSubmit = (values, { resetForm }) => {
		values.plan = cabeceraSelected;
		saveFirma(values);
		resetForm();
	};
	const saveFirma = async (json) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			const response = await axios.post(
				route_procesosPresupuesto + "/saveFirma",
				json,
				config
			);

			Swal.fire({
				icon: "success",
				title: "Firma registrada",
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				getFirmas(cabeceraSelected);
			});
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Error al añadir firma",
				showConfirmButton: true,
			}).then(() => {});
		}
	};
	const getFirmas = async (idC) => {
		setFirmas([]);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_procesosPresupuesto}/findFirmas/${idC}`,
			config
		);
		//return result.data
		setFirmas(result.data);
	};
	const handleDelete = (id) => {
		Swal.fire({
			title: "Eliminar firma?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				deleteFirma(id);
			}
		});
	};
	const deleteFirma = async (id) => {
		try {
			const response = await axios.delete(
				route_procesosPresupuesto + "/deleteFirma/" + id,

				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			Swal.fire({
				icon: "success",
				title: "Firma eliminada",
				showConfirmButton: false,
				timer: 1500,
			});
			getFirmas(cabeceraSelected);
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error al eliminar firma",
				showConfirmButton: false,
				timer: 1500,
			});
			throw error;
		}
	};
	const validationSchema = Yup.object().shape({
		nombre: Yup.string().required("El nombre es obligatorio"),
		cargo: Yup.string().required("El cargo es obligatorio"),
	});
	// --------------- formulario configuracion acta ----------------------------
	const handleSubmitActa = (valuesActa) => {
		valuesActa.plan = cabeceraSelected;
		if (selectedImage) {
			valuesActa.logo = selectedImage;
		}
		valuesActa.pie = pie;
	
		saveActa(valuesActa);
		//resetFormActa();
	};
	const saveActa = async (json) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			const response = await axios.post(
				route_procesosPresupuesto + "/saveActa",
				json,
				config
			);

			Swal.fire({
				icon: "success",
				title: "Acta registrada",
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				getFirmas(cabeceraSelected);
				//setPie("");
			});
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Error al añadir firma",
				showConfirmButton: true,
			}).then(() => {});
		}
	};
	const initialValuesActa = {
		//id: null,
		plan: null,
		titulo1: "",
		b64: null,
		titulo2: "",
		titulo3: "",
		texto1: "",
		texto2: "",
		pie: "",
		fecha: null,
		user: localStorage.getItem("idUser"),

		//pie: "",
	};
	const validationSchemaActa = Yup.object().shape({
		titulo1: Yup.string(),
		//b64: Yup.string(),
		titulo2: Yup.string(),
		texto1: Yup.string(),
		texto2: Yup.string(),
		fecha: Yup.date(),
		//pie: Yup.string(),
	});
	const simulaSubmitActa = () => {
		submitCab.current.click();
	};
	// ----------------------------------------------------------------------------
	/*------------------- Formulario articulos x acta ----------------------------*/
	function customSort(a, b) {
		// Ordenar por 'codigo'
		if (a.codigo < b.codigo) return -1;
		if (a.codigo > b.codigo) return 1;

		// Si 'codigo' es igual, ordenar por 'tipo'
		if (a.tipo < b.tipo) return -1;
		if (a.tipo > b.tipo) return 1;

		// Si 'codigo' y 'tipo' son iguales, ordenar por 'naturaleza'
		if (a.naturaleza < b.naturaleza) return -1;
		if (a.naturaleza > b.naturaleza) return 1;

		return 0;
	}
	const getCtasMadre = async (idC) => {
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};
			const result = await axios.get(
				`${route_confiPresupuesto_base}/getproyecciones/${idC}`,
				config
			);
			setCuentas(result.data);
			let ctasP = result.data.filter(a => a.madre === null);
	
			//let order = result.data.sort(customSort);
			const formattedOptions = ctasP.map((item) => ({
				value: item.id,
				label: item.codigo + " - " + item.nombre,
				saldo: item.saldo,
				cuenta: item.id,
			}));

			setCtasMadre(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
		}
	};
	const getArticulos = async (idC) => {
		setArticulos([]);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_procesosPresupuesto}/findArticulos/${idC}`,
			config
		);
		//return result.data;
		setArticulos(result.data);
	};
	const handleSubmitArt = (valuesArt) => {
		valuesArt.plan = cabeceraSelected;
		saveArt(valuesArt);
		//resetFormActa();
	};
	const saveArt = async (json) => {
		if (selectedOptions.length > 0) {
			json.ctas = selectedOptions;
		}
	
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			const response = await axios.post(
				route_procesosPresupuesto + "/saveArticulo",
				json,
				config
			);

			Swal.fire({
				icon: "success",
				title: "Articulo registrado",
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				getArticulos(cabeceraSelected);
				if (formikArt.current) {
					formikArt.current.resetForm();
					setSelectedOptions([]);
				}
			});
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: "Error al añadir articulo",
				showConfirmButton: true,
			}).then(() => {});
		}
	};
	const handleDeleteArt = (id) => {
		Swal.fire({
			title: "Eliminar articulo?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				deleteArticulo(id);
			}
		});
	};
	const deleteArticulo = async (id) => {
		try {
			const response = await axios.delete(
				route_procesosPresupuesto + "/deleteArticulo/" + id,

				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			Swal.fire({
				icon: "success",
				title: "Articulo eliminado",
				showConfirmButton: false,
				timer: 1500,
			});
			getArticulos(cabeceraSelected);
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error al eliminar articulo",
				showConfirmButton: false,
				timer: 1500,
			});
			throw error;
		}
	};
	const initialValuesArt = {
		id: null,
		plan: null,
		titulo: "",
		texto: "",
		cuenta: null,
	};
	const validationSchemaArt = Yup.object().shape({
		titulo: Yup.string().required("El titulo es obligatorio"),
		texto: Yup.string().required("El contenido es obligatorio"),
	});
	const customStyles = {
		control: (base) => ({
			...base,
			height: 60,
			minHeight: 35,
		}),
		option: (base) => ({
			...base,
			border: `1px dotted blue`,
			textAlign: "left",
		}),
	};
	const findActaxplan = async (idP) => {
		setConfActa({});
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_procesosPresupuesto}/findActaxplan/${idP}`,
			config
		);
		var Mov = result.data;
		if (formikRef.current && Mov) {
			Mov.user = localStorage.getItem("idUser");
			//setPie(Mov.pie);
			formikRef.current.setValues(Mov);
		}
	
		//return result.data
		setConfActa(result.data);
	};
	const handleImageChange = async (event) => {
		const file = event.currentTarget.files[0];
		try {
			const base64Image = await fileToBase64(file);
			setSelectedImage(base64Image);
		} catch (error) {
			console.error("Error converting file to base64:", error);
		}
	};
	const fileToBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {
				// Cuando la lectura ha sido completada con éxito, resolvemos la Promesa
				resolve(reader.result);
			};
			reader.onerror = (error) => {
				// En caso de error, rechazamos la Promesa con el error
				reject(error);
			};
			reader.onabort = () => {
				// En caso de abortar la lectura, rechazamos la Promesa con un error
				reject(new Error("File reading aborted"));
			};

			reader.readAsDataURL(file);
			// Manejar el cierre del FileReader cuando ya no es necesario
			return () => {
				reader.abort(); // Abortar la lectura si la Promesa se cancela antes de ser resuelta
			};
		});
	};
	const handleChange1 = (selectedValues) => {

		setSelectedOptions(selectedValues);
	};
	const handleEdit = (mov) => {
		setSelectedOptions([]);
		var arrayCtas = [];
		if (formikArt.current) {
			formikArt.current.setValues(mov);
			mov.listCtas.map((cta) => {
				var c = {};
				c = {
					value: cta.cuenta,
					label: cta.cta.codigo + " - " + cta.cta.nombre,
					cuenta: cta.cuenta,
				};
				arrayCtas.push(c);
			});
			setSelectedOptions(arrayCtas);
		}
	};
	const generarActa = (idP) => {
		getFirmas(idP);
		findActaxplan(idP);
		getArticulos(idP);
		setCabeceraSelected(idP);
		setModalVisible(true);
	};
	const cerrarModal = () => {
		setModalVisible(false);
		setConfActa(null);
	};
	const handleEditPlan = (id) => {
		Swal.fire({
			title: "Habilitar edición?",
			text: "Esta opción permitirá la creación de nuevas cuentas en el plan presupuestal",
			icon: "success",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				updatePlan(id);
			}
		});
	};
	const updatePlan = async (id) => {
		try {
			const values = {
				id: id,
				user: localStorage.getItem("idUser"),
			};

			const response = await axios.post(
				`${route_procesosPresupuesto}/updateplan`,
				values,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);

			if (response.status == 200) {
				Swal.fire({
					icon: "success",
					title: "Edición habilitada",
					showConfirmButton: false,
					timer: 1500,
				}).then(() => {
					getCabeceras();
				});
			} else {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Hubo un problema al habilitar la edición.",
					showConfirmButton: true,
				});
			}
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error en la actualización",
				text: error.response ? error.response.data.message : error.message,
				showConfirmButton: true,
			});
		}
	};
	/* ---------------------------------------------------------------------------- */

	useEffect(() => {
		getCabeceras();
	}, []);

	return (
		<div className="containerFormUser">
			<h1>Lista de planes presupuestales</h1>
			<table
				style={{ width: "100%" }}
				id="tableUsers"
				className="table table-striped table-hover"
			>
				<thead>
					<tr>
						<th>Nombre</th>
						<th>Año</th>
						<th>Estado</th>
						<th>Opciones</th>
					</tr>
				</thead>
				<tbody>
					{cabeceras.map((cab) => (
						<tr key={cab.id}>
							<td>{cab.nombre}</td>
							<td>{cab.yearObject.year}</td>
							<td>
								{cab.estado === 0
									? "Proceso"
									: cab.estado === 1
									? "Aprobado"
									: cab.estado === 2
									? "Rechazado"
									: cab.estado === 3
									? "Pendiente"
									: "Estado Desconocido"}
							</td>
							<td style={{ textAlign: "right" }}>
								{cab.estado === 3 ? (
									<>
										<button
											className="btnSuccess"
											type="button"
											onClick={() => handleAprueba(cab.id)}
										>
											<BsCheckCircle /> Aprobar
										</button>
										&nbsp;
										<button
											className="btnDelete"
											type="button"
											onClick={() => handleRechaza(cab.id)}
										>
											<BsXCircle /> Rechazar
										</button>
										&nbsp;
										<button
											onClick={() => {
												formikRef.current.resetForm(); // Usar current para acceder al objeto de referencia
												setCabeceraSelected(cab.id);
												getEstructura(cab.id);
											}}
											className="btnInfo"
											type="button"
											data-bs-toggle="modal"
											data-bs-target="#modalFirmas"
										>
											<FaFileSignature /> Configurar acta
										</button>
									</>
								) : cab.estado === 1 ? (
									<button
										onClick={() => generarActa(cab.id)}
										className="btnSuccess"
										type="button"
										data-bs-toggle="modal"
										data-bs-target="#modalActa"
									>
										<i class="fas fa-file-invoice"></i> Acta
									</button>
								) : null}
								&nbsp;
								<button
									onClick={() => getEstructura(cab.id)}
									className="btnDetail"
									type="button"
									data-bs-toggle="modal"
									data-bs-target="#modalPdf"
								>
									<i class="fas fa-file-pdf"></i> Plan
								</button>
								{edit == 1 && (
									<>
										&nbsp;
										<button
											onClick={() => handleEditPlan(cab.id)}
											className="btnEdit"
											type="button"
										>
											<i className="fas fa-file-pdf"></i> Editar
										</button>
									</>
								)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
			{/* modal con proyeccion en pdf */}
			<div
				className="modal fade"
				id="modalPdf"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabindex="-1"
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div class="modal-dialog modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="staticBackdropLabel">
								Proyección plan presupuestal
							</h1>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>

						<div className="modal-body">
							<ProyeccionPDF
								style={{ width: "100%" }}
								establishment={establishment}
								plan={proyecciones}
								cabecera={cabeceraObject}
								estructura={estructura}
							/>
						</div>
					</div>
				</div>
			</div>
			{/* --------------------------------- */}

			{/* modal con configuiracion de acta */}
			<div
				className="modal fade"
				id="modalFirmas"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabindex="-1"
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div class="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="staticBackdropLabel">
								Configuración de acta
							</h1>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>

						<div className="modal-body">
							{/* ----------------------- inicio acordeon  ------------------- */}

							<div class="accordion" id="accordionExample">
								{/* ------Formulario acta------ */}
								<Formik
									initialValues={initialValuesActa}
									onSubmit={handleSubmitActa}
									validationSchema={validationSchemaActa}
									innerRef={formikRef}
								>
									{({ valuesActa, handleSubmitActa }) => (
										<Form>
											<div class="accordion-item">
												<h2 class="accordion-header">
													<button
														class="accordion-button"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseOne"
														aria-expanded="true"
														aria-controls="collapseOne"
													>
														ENCABEZADO - PIE
													</button>
												</h2>
												<div
													id="collapseOne"
													class="accordion-collapse collapse show"
													data-bs-parent="#accordionExample"
												>
													<div class="accordion-body">
														<div className="row">
															<div className="col-6  ">
																<div className="form-floating mb-2  ">
																	<Field
																		type="text"
																		name="titulo1"
																		id="titulo1"
																		className="form-control"
																		placeholder="titulo1"
																	/>
																	<label htmlFor="forma" className="form-label">
																		Titulo
																	</label>
																	<ErrorMessage
																		name="titulo1"
																		component="div"
																		className="error"
																	/>
																</div>
															</div>
															<div className="col-6  ">
																<div className="form-floating mb-2  ">
																	<Field
																		type="file"
																		id="b64"
																		name="b64"
																		accept="image/*"
																		onChange={handleImageChange}
																	/>
																	<label htmlFor="logo" className="form-label">
																		Logo
																	</label>
																	<ErrorMessage
																		name="b64"
																		component="div"
																		className="error"
																	/>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-6  ">
																<div className="form-floating mb-2  ">
																	<Field
																		as="textarea"
																		type="text"
																		name="titulo2"
																		id="titulo2"
																		className="form-control"
																		placeholder="titulo2"
																	/>
																	<label htmlFor="forma" className="form-label">
																		Titulo
																	</label>
																	<ErrorMessage
																		name="titulo2"
																		component="div"
																		className="error"
																	/>
																</div>
															</div>
															<div className="col-6  ">
																<div className="form-floating mb-2  ">
																	<Field
																		type="date"
																		name="fecha"
																		id="fecha"
																		className="form-control"
																		placeholder="fecha"
																	/>
																	<label htmlFor="forma" className="form-label">
																		Fecha
																	</label>
																	<ErrorMessage
																		name="fecha"
																		component="div"
																		className="error"
																	/>
																</div>
															</div>
															<div className="col-12  ">
																<div className="form-floating mb-2">
																	<Field
																		as="textarea"
																		name="texto1"
																		id="texto1"
																		className="form-control"
																		placeholder="Texto 1"
																		style={{ height: "100px" }}
																	/>
																	<label
																		htmlFor="texto1"
																		className="form-label"
																	>
																		Texto 1
																	</label>
																	<ErrorMessage
																		name="texto1"
																		component="div"
																		className="error"
																	/>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-12 ">
																<div className="form-floating mb-2  ">
																	<Field
																		as="textarea"
																		id="pie"
																		style={{ height: 150 }}
																		name="pie"
																		//value={pie}
																		 onKeyUp={(e) => {
																			setPie(e ? e.target.value : "");
																		}} 
																		className="form-control"
																		placeholder="pie"
																	/>
																	<label htmlFor="forma" className="form-label">
																		Pie
																	</label>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div class="accordion-item">
												<h2 class="accordion-header">
													<button
														class="accordion-button collapsed"
														type="button"
														data-bs-toggle="collapse"
														data-bs-target="#collapseTwo"
														aria-expanded="false"
														aria-controls="collapseTwo"
													>
														CONSIDERANDO
													</button>
												</h2>
												<div
													id="collapseTwo"
													class="accordion-collapse collapse"
													data-bs-parent="#accordionExample"
												>
													<div class="accordion-body">
														<div className="row">
															<div className="col-12  ">
																<div className="form-floating mb-2">
																	<Field
																		as="textarea"
																		name="titulo3"
																		id="titulo3"
																		className="form-control"
																		placeholder="Titulo"
																		style={{
																			height: "100px",
																			width: "100%",
																			overflowY: "auto", // Establece overflow-y en "auto" para que aparezca una barra de desplazamiento vertical si es necesario
																			wordWrap: "break-word", // Permite el corte de palabras para que el texto no se desborde del textarea
																		}}
																	/>
																	<label
																		htmlFor="titulo3"
																		className="form-label"
																	>
																		Titulo
																	</label>
																	<ErrorMessage
																		name="texto3"
																		component="div"
																		className="error"
																	/>
																</div>
															</div>
															<div className="col-12  ">
																<div className="form-floating mb-2">
																	<Field
																		as="textarea"
																		name="texto2"
																		id="texto2"
																		className="form-control"
																		placeholder="Considerando"
																		style={{
																			height: "100px",
																			width: "100%",
																			overflowY: "auto", // Establece overflow-y en "auto" para que aparezca una barra de desplazamiento vertical si es necesario
																			wordWrap: "break-word", // Permite el corte de palabras para que el texto no se desborde del textarea
																		}}
																	/>
																	<label
																		htmlFor="texto2"
																		className="form-label"
																	>
																		Considerando
																	</label>
																	<ErrorMessage
																		name="texto2"
																		component="div"
																		className="error"
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<button
												ref={submitCab}
												type="submit"
												style={{ display: "none" }}
											></button>{" "}
										</Form>
									)}
								</Formik>
								{/* fin formulario */}
								{/*------Formulario articulos  */}
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseThree"
											aria-expanded="false"
											aria-controls="collapseThree"
										>
											ACUERDA
										</button>
									</h2>
									<div
										id="collapseThree"
										class="accordion-collapse collapse"
										data-bs-parent="#accordionExample"
									>
										<div class="accordion-body">
											{/* ------------------- formulario articulos---------- */}
											<Formik
												initialValues={initialValuesArt}
												onSubmit={handleSubmitArt}
												validationSchema={validationSchemaArt}
												innerRef={formikArt}
											>
												{(formik, props) => (
													<Form>
														<div className="row">
															<div className="col-6 ">
																<div className="form-floating mb-2  ">
																	<Field
																		type="text"
																		name="titulo"
																		id="titulo"
																		className="form-control"
																		placeholder="Nombre"
																	/>
																	<label htmlFor="forma" className="form-label">
																		Titulo
																	</label>
																	<ErrorMessage
																		name="titulo"
																		component="div"
																		className="error"
																	/>
																</div>
															</div>

															<div className="col-6">
																<Select
																	styles={customStyles}
																	isMulti
																	placeholder="Cuenta"
																	options={ctasMadre}
																	value={selectedOptions}
																	onChange={handleChange1}
																	className="basic-multi-select"
																	classNamePrefix="select"
																/>

																<ErrorMessage
																	name="cuenta"
																	component="div"
																	className="error"
																/>
															</div>
															<div className="col-12  ">
																<div className="form-floating mb-2  ">
																	<Field
																		as="textarea"
																		name="texto"
																		id="texto"
																		className="form-control"
																		placeholder="Texto"
																		style={{
																			height: "100px",
																			width: "100%",
																			overflowY: "auto", // Establece overflow-y en "auto" para que aparezca una barra de desplazamiento vertical si es necesario
																			wordWrap: "break-word", // Permite el corte de palabras para que el texto no se desborde del textarea
																		}}
																	/>
																	<label htmlFor="forma" className="form-label">
																		Texto
																	</label>
																	<ErrorMessage
																		name="texto"
																		component="div"
																		className="error"
																	/>
																</div>
															</div>
															<div className="col-12  ">
																<button
																	type="submit"
																	className="btn btn-primary"
																>
																	Agregar
																</button>
															</div>
														</div>
													</Form>
												)}
											</Formik>

											<table
												style={{ width: "100%" }}
												id="tableUsers"
												className="table table-striped table-hover"
											>
												<thead>
													<tr>
														<th style={{ width: "20%" }}>Titulo</th>
														<th style={{ width: "50%" }}>Contenido</th>
														<th style={{ width: "20%" }}>Cuenta</th>
														<th style={{ textAlign: "right" }}>Opciones</th>
													</tr>
												</thead>
												<tbody>
													{articulos
														.sort((a, b) => a.titulo.localeCompare(b.titulo))
														.map((cab) => (
															<tr key={cab.id}>
																<td>{cab.titulo}</td>
																<td
																	style={{
																		maxWidth: "300px", // Ancho máximo
																		maxHeight: "100px", // Altura máxima
																		overflowY: "auto", // Barra de desplazamiento vertical si es necesario
																		whiteSpace: "normal", // Ajustar automáticamente el texto al ancho y la altura
																	}}
																>
																	{cab.texto}
																</td>

																<td>
																	{cab.cuentaObject
																		? cab.cuentaObject.codigo
																		: ""}{" "}
																	-{" "}
																	{cab.cuentaObject
																		? cab.cuentaObject.nombre
																		: ""}
																</td>
																<td style={{ textAlign: "right" }}>
																	<>
																		<button
																			className="btnEdit"
																			type="button"
																			onClick={(e) => handleEdit(cab)}
																		>
																			<i className="fas fa-pen"></i>
																		</button>
																		&nbsp;
																		<button
																			className="btnDelete"
																			type="button"
																			onClick={() => handleDeleteArt(cab.id)}
																		>
																			<i className="fas fa-trash-alt"></i>
																		</button>
																	</>
																</td>
															</tr>
														))}
												</tbody>
											</table>

											{/* -------------------------------------------------- */}
										</div>
									</div>
								</div>
								{/* --------------------------- */}

								{/* ----- Formulario Firmas---- */}
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseFour"
											aria-expanded="false"
											aria-controls="collapseFour"
										>
											FIRMAS
										</button>
									</h2>
									<div
										id="collapseFour"
										class="accordion-collapse collapse"
										data-bs-parent="#accordionExample"
									>
										<div class="accordion-body">
											{/* FORMULARIO FIRMAS */}
											<Formik
												initialValues={initialValues}
												onSubmit={handleSubmit}
												validationSchema={validationSchema}
											>
												{({ values }) => (
													<Form>
														<div className="row">
															<div className="col-5 ">
																<div className="form-floating mb-2  ">
																	<Field
																		type="text"
																		name="nombre"
																		id="nombre"
																		className="form-control"
																		placeholder="Nombre"
																	/>
																	<label htmlFor="forma" className="form-label">
																		Nombre
																	</label>
																	<ErrorMessage
																		name="nombre"
																		component="div"
																		className="error"
																	/>
																</div>
															</div>
															<div className="col-5  ">
																<div className="form-floating mb-2  ">
																	<Field
																		type="text"
																		name="cargo"
																		id="cargo"
																		className="form-control"
																		placeholder="Cargo"
																	/>
																	<label htmlFor="forma" className="form-label">
																		Cargo
																	</label>
																	<ErrorMessage
																		name="cargo"
																		component="div"
																		className="error"
																	/>
																</div>
															</div>
															<div className="col-2  ">
																<button
																	type="submit"
																	className="btn btn-primary"
																>
																	Agregar
																</button>
															</div>
														</div>
													</Form>
												)}
											</Formik>
											<table
												style={{ width: "100%" }}
												id="tableUsers"
												className="table table-striped table-hover"
											>
												<thead>
													<tr>
														<th>Nombre</th>
														<th>Cargo</th>
														<th style={{ textAlign: "right" }}>Opciones</th>
													</tr>
												</thead>
												<tbody>
													{firmas.map((cab) => (
														<tr key={cab.id}>
															<td>{cab.nombre}</td>
															<td>{cab.cargo}</td>
															<td style={{ textAlign: "right" }}>
																<>
																	<button
																		className="btnDelete"
																		type="button"
																		onClick={() => handleDelete(cab.id)}
																	>
																		<i className="fas fa-trash-alt"></i>
																	</button>
																</>
															</td>
														</tr>
													))}
												</tbody>
											</table>
											{/* -------------- */}
										</div>
									</div>
								</div>
								{/* --------------------------- */}
							</div>
							{/* -----------------------  fin acordeon ----------------------- */}
						</div>

						{/* pie */}
						<div class="modal-footer">
							<button
								type="button"
								class="btn btn-secondary"
								data-bs-dismiss="modal"
							>
								Cerrar
							</button>
							<button
								type="button"
								className="btn btn-primary"
								onClick={simulaSubmitActa}
							>
								Guardar
							</button>
							<button
								onClick={() => {
									setArticulos([]);
									generarActa(cabeceraSelected);
								}}
								className="btn btn-info"
								type="button"
								data-bs-toggle="modal"
								data-bs-target="#modalActa"
							>
								<i class="fas fa-file-invoice"></i> Vista previa
							</button>
						</div>

						{/* --- */}
					</div>
				</div>
			</div>
			{/* --------------------------------- */}

			{/* modal con acta en pdf */}
			<div
				className={`modal fade ${modalVisible ? "show" : ""}`}
				id="modalActa"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex="-1"
				aria-labelledby="staticBackdropLabel"
				aria-hidden={!modalVisible}
			>
				<div class="modal-dialog modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="staticBackdropLabel">
								Acta
							</h1>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								onClick={cerrarModal}
							></button>
						</div>

						<div className="modal-body">
							{confActa && confActa.id && (
								<ActaPDF
									style={{ width: "100%" }}
									firmas={firmas}
									acta={confActa}
									articulos={articulos}
									cuentas={cuentas} 
								/>
							)}
						</div>
					</div>
				</div>
			</div>
			{/* --------------------------------- */}
		</div>
	);
};

export default Estructuras;
