import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { GrOrderedList } from "react-icons/gr";
import { GiPayMoney } from "react-icons/gi";
import { GiReceiveMoney } from "react-icons/gi";

export default function ConfPresupuesto() {
	const navigate = useNavigate();
	const [usuariosOptionsVisible, setUsuariosOptionsVisible] = useState(false);
	const [usuariosOptionsVisible1, setUsuariosOptionsVisible1] = useState(false);

	useEffect(() => {
		navigate("/menu/hefestoContabilidad/intro-movsconta");
	}, []);
	const toggleUsuariosOptions = () => {
		setUsuariosOptionsVisible(!usuariosOptionsVisible);
	};
	const toggleUsuariosOptions1 = () => {
		setUsuariosOptionsVisible1(!usuariosOptionsVisible1);
	};

	return (
		<>
			<div id="space">
				<div className="container1 container-fluid">
					<div className="menu">
						<div className="divMenuLog">
							{/* bloque Ingresos */}
							<ul>
								<NavLink className="navInfo" to="#">
									<li className="navInfoAdmin" onClick={toggleUsuariosOptions}>
										<GiReceiveMoney />
										&nbsp;
										<span>
											Ingresos&nbsp;
											{usuariosOptionsVisible === false ? (
												<i class="fas fa-chevron-down"></i>
											) : (
												<i class="fas fa-chevron-up"></i>
											)}{" "}
										</span>
									</li>
								</NavLink>
								{usuariosOptionsVisible && (
									<ul>
										<NavLink
											className="navInfo"
											to={{
												pathname:
													"/menu/hefestoContabilidad/lista-movimientos/1/1",
											}}
										>
											<li className="navSubmenu">
												<i class="fas fa-file-invoice-dollar"></i>&nbsp;
												<span> Cuentas por cobrar</span>
											</li>
										</NavLink>

										<NavLink
											className="navInfo"
											to={{
												pathname:
													"/menu/hefestoContabilidad/lista-movimientos/1/2",
											}}
										>
											<li className="navSubmenu">
												<i class="fas fa-file-invoice-dollar"></i>&nbsp;
												<span> Recibos de caja</span>
											</li>
										</NavLink>
									</ul>
								)}
							</ul>
							{/* bloque Gastos */}
							<ul>
								<NavLink className="navInfo" to="#">
									<li className="navInfoAdmin" onClick={toggleUsuariosOptions1}>
										<GiPayMoney />
										&nbsp;
										<span>
											Gastos&nbsp;
											{usuariosOptionsVisible1 === false ? (
												<i class="fas fa-chevron-down"></i>
											) : (
												<i class="fas fa-chevron-up"></i>
											)}{" "}
										</span>
									</li>
								</NavLink>
								{usuariosOptionsVisible1 && (
									<ul>
										<NavLink
											className="navInfo"
											to={{
												pathname:
													"/menu/hefestoContabilidad/lista-movimientos/2/1",
											}}
										>
											<li className="navSubmenu">
												<i class="fas fa-file-invoice-dollar"></i>&nbsp;
												<span> Cuentas por pagar</span>
											</li>
										</NavLink>

										<NavLink
											className="navInfo"
											to={{
												pathname:
													"/menu/hefestoContabilidad/lista-movimientos/2/2",
											}}
										>
											<li className="navSubmenu">
												<i class="fas fa-file-invoice-dollar"></i>&nbsp;
												<span>Comp. de egreso</span>
											</li>
										</NavLink>
									</ul>
								)}
							</ul>
							<ul>
								<NavLink
									className="navInfo"
									to="/menu/hefestoContabilidad/lista-movimientos/3/1"
								>
									<li className="navInfoAdmin">
										<i class="fas fa-money-check-alt"></i>{" "}
										<span>Contables </span>
									</li>
								</NavLink>
							</ul>
						</div>
					</div>
					<div className="workspace">
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}
