import React from "react";
import {
	FcBullish,
	FcBearish,
	FcRules,
	FcSurvey,
	FcNews,
	FcInspection,
} from "react-icons/fc";
import Dashboard from "../../componentsBase/tables/Intro"; // Asegúrate de que la ruta sea correcta

const ParentComponent = () => {
	const cards = [
		{
			id: 1,
			icon: <FcBullish />,
			title: "Ingresos",
			description: "Gestiona movimientos contables de ingresos",
			detalles: [
				{
					id: 1,
					icon: <FcSurvey />,
					title: "Cuentas por cobrar",
					description: "Cuentas por cobrar ",
					to: "/menu/hefestoContabilidad/lista-movimientos/1/1",
				},
				{
					id: 2,
					icon: <FcNews />,
					title: "Recibos de caja",
					description: "Recibos de caja",
					to: "/menu/hefestoContabilidad/lista-movimientos/1/2",
				},
			],
		},
		{
			id: 2,
			icon: <FcBearish />,
			title: "Gastos",
			description: "Gestions movimientos contables de gastos",
			detalles: [
				{
					id: 1,
					icon: <FcSurvey />,
					title: "Cuentas por pagar",
					description: "Cuentas por pagar ",
					to: "/menu/hefestoContabilidad/lista-movimientos/2/1",
				},
				{
					id: 2,
					icon: <FcRules />,
					title: "Comprobantes de egreso",
					description: "Comprobantes de egreso",
					to: "/menu/hefestoContabilidad/lista-movimientos/2/2",
				},
			],
		},
		{
			id: 3,
			icon: <FcInspection />,
			title: "Contables",
			description: "Gestiona tus movimientos contables ",
			to:"/menu/hefestoContabilidad/lista-movimientos/3/1"
		},
	];

	return <Dashboard cards={cards} />;
};

export default ParentComponent;
