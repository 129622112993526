import React from "react";
import "./../../../styles/administration/Intro.css";
import { FcPlus } from "react-icons/fc";
import { FcSearch } from "react-icons/fc";
import Dashboard from "../../componentsBase/tables/Intro"; // Asegúrate de que la ruta sea correcta


const IntroUsers = () => {
  const cards = [
    {
      id: 1,
      icon: <FcSearch />,
      title: "Busqueda",
      description: "Filtro dinámico para encontrar los usuarios que usan el sistema, y sus caracteristicas",
      to: "/menu/hefestoAdmin/search-users", 
    },
    {
      id: 2,
      icon: <FcPlus />,
      title: "Registro",
      description: "Crea los usuarios requeridos, asigna roles y entidades autorizadas para el uso de tu sistema ",
      to: "/menu/hefestoAdmin/register-users", 
    },
   
   
  ];

  return <Dashboard cards={cards} />;

};

export default IntroUsers;
