import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/LogContext";
import axios from "axios";
import {
	route_persons_listPersons,
	route_person_deactive,
	route_person_searchPerson,
	route_persons_getListById,
} from "../../../api-routes/RoutesLogin";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";

import "moment/locale/es";

const SearchPersons = (props) => {
	const { establishment, getToken } = useContext(AuthContext);
	const [persons, setPersons] = useState([]);
	const [personsback, setPersonsBack] = useState([]);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(50);
	const [totalPages, setTotalPages] = useState(0);
	const [personDetail, setPersonDetail] = useState({});
	const [typeId, setTypeId] = useState({});
	const [typePerson, setTypePerson] = useState({});
	const [optionsPersons, setOptionsPersons] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const [btnCarga, setBtnCarga] = useState(0);

	const fetchData = async () => {
		setBtnCarga(1);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_persons_listPersons}${establishment.id}?page=${page}&size=${pageSize}&sort=id,asc&active=true`,
			config
		);
		setPersons(result.data.content);
		setPersonsBack(result.data.content);
		setTotalPages(result.data.totalPages);
		setBtnCarga(0);
	};

	const getPersonById = async (inputValue) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		try {
			const response = await axios.get(
				route_persons_getListById + inputValue,
				config
			);
		
			const data = response.data;
			setPersons(data);
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, [page, pageSize]);

	const handlePageChange = (newPage) => {
		setPage(newPage);
	};

	const handlePrevClick = () => {
		if (page > 0) {
			setPage(page - 1);
		}
	};

	const handleNextClick = () => {
		if (page < totalPages) {
			setPage(page + 1);
		}
	};

	const handlePersonDetail = (personId) => {
		const personDet = persons.find((p) => p.id === personId);
		setPersonDetail(personDet);
		setTypeId(personDet.identificationType);
		setTypePerson(personDet.typePerson);
	};

	const handleDelete = (personId) => {
		Swal.fire({
			title: "Eliminar Usuario?",
			text: "El tercero ne estará disponible, sin embargo sus movimientos permaneceran almacenados!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Eliminando...",
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					didOpen: () => {
						deletePerson(personId)
							.then((response) => {
								Swal.fire({
									icon: "success",
									title: "El tercero ha sido eliminado",
									showConfirmButton: false,
									timer: 1500,
								}).then(() => {
									fetchData();
								});
							})
							.catch((error) => {
								console.error(error);
								Swal.fire({
									icon: "error",
									title: "Hubo un error al eliminar el tercero",
									text: error.message,
									showConfirmButton: true,
								});
							});
					},
				});
			}
		});
	};

	const deletePerson = async (personId) => {
		let values = {
			id: personId,
			active: false,
			user: parseInt(localStorage.getItem("idUser")),
		};
		let dataJson = JSON.stringify(values);
		try {
			const response = await axios.post(route_person_deactive, dataJson, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			});
			return response;
		} catch (error) {
			throw error;
		}
	};

	const handleActive = (personId) => {
		Swal.fire({
			title: "Reactivar tercero?",
			text: "El tercero estará disponible nuevamente!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Activando...",
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					didOpen: () => {
						activePerson(personId)
							.then((response) => {
								Swal.fire({
									icon: "success",
									title: "El tercero ha sido reactivado",
									showConfirmButton: false,
									timer: 1500,
								}).then(() => {
									fetchData();
								});
							})
							.catch((error) => {
								console.error(error);
								Swal.fire({
									icon: "error",
									title: "Hubo un error al reactivar el tercero",
									text: error.message,
									showConfirmButton: true,
								});
							});
					},
				});
			}
		});
	};

	const activePerson = async (personId) => {
		let values = {
			id: personId,
			active: true,
		};
		let dataJson = JSON.stringify(values);
		try {
			const response = await axios.post(route_person_deactive, dataJson, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			});
			return response;
		} catch (error) {
	
			throw error;
		}
	};

	const handlePersonEdit = (userId) => {
		navigate("/menu/hefestoPersons/register-person/" + userId);
	};

	/* Busiqeda de terceros  */
	const onInputChange = (inputValue) => {
		if (
			typeof inputValue === "string" &&
			inputValue != "" &&
			inputValue.length >= 3
		) {
			loadPersons(inputValue);
		}
	};

	const loadPersons = async (inputValue) => {
		setIsLoading(true);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		try {
			const response = await axios.get(
				route_person_searchPerson +
					establishment.id +
					"," +
					inputValue +
					",false",
				config
			);
			const data = response.data;
			const formattedOptions = data.map((item) => ({
				value: item.id,
				label:
					item.identification +
					" - " +
					item.name1 +
					" " +
					item.name2 +
					" " +
					item.lastName1 +
					" " +
					item.lastName2 +
					" - " +
					item.businessName,
			}));

			setOptionsPersons(formattedOptions);
		} catch (error) {
			console.error("Error while fetching options:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const filterChange = (filterValue) => {
		if (filterValue) {
			setOptionsPersons([]);
			getPersonById(filterValue);
		} else {
			setPersons(personsback);
			setOptionsPersons([]);
		}
	};

	return (
		<>
			<div className="containerFormUser">
				<h1>Lista de terceros</h1>

				<Select
					isSearchable="true"
					placeholder="Búsqueda de terceros "
					isClearable="true"
					isLoading={isLoading}
					onInputChange={onInputChange}
					options={optionsPersons}
					onChange={(e) => {
						filterChange(e ? e.value : "");
					}}
				></Select>
				{btnCarga === 0 ? (
					<>
						<table
							style={{ width: "100%" }}
							id="tableUsers"
							className="table table-striped table-hover"
						>
							<thead>
								<tr>
									<th>Identificación</th>
									<th>Nombres</th>
									<th>Apellidos</th>
									<th>Razón social</th>
									<th>Teléfono</th>
									<th style={{ width: "20%", textAlign: "right" }}>Opciones</th>
								</tr>
							</thead>
							<tbody>
								{persons.map((person) => (
									<tr key={person.id}>
										<td>{person.identification}</td>
										<td>
											{person.name1} {person.name2}
										</td>
										<td>
											{person.lastName1} {person.lastName2}
										</td>
										<td>{person.businessName}</td>
										<td>{person.phone1}</td>
										<td style={{ width: "20%", textAlign: "right" }}>
											{person.active ? (
												<>
													<button
														className="btnDetail"
														type="button"
														data-bs-toggle="modal"
														data-bs-target="#staticBackdrop3"
														onClick={() => handlePersonDetail(person.id)}
													>
														<i className="fas fa-info-circle"></i>
													</button>
													&nbsp;&nbsp;
													<button
														className="btnEdit"
														type="button"
														onClick={() => handlePersonEdit(person.id)}
													>
														<i className="fas fa-pen"></i>
													</button>
													&nbsp;&nbsp;
													<button
														className="btnDelete"
														type="button"
														onClick={() => handleDelete(person.id)}
													>
														<i className="fas fa-trash-alt"></i>
													</button>
												</>
											) : (
												<>
													<button
														className="btn btn-success"
														type="button"
														onClick={() => handleActive(person.id)}
													>
														<i class="fas fa-check"></i>
													</button>
												</>
											)}
										</td>
									</tr>
								))}
							</tbody>
						</table>

						<Pagination
							totalPages={totalPages}
							currentPage={page}
							onPageChange={handlePageChange}
							onPrevClick={handlePrevClick}
							onNextClick={handleNextClick}
						/>
					</>
				) : (
					/* spinner carga */
					<button className="btn btn-primary" type="button" disabled>
						<span
							className="spinner-border spinner-border-sm"
							aria-hidden="true"
						></span>
						<span role="status"> Guardando...</span>
					</button>
					/*--------------- */
				)}
			</div>

			<div
				className="modal fade"
				id="staticBackdrop3"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabindex="-1"
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<center>Detalle de tercero</center>

							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>

						<div className=" modal-body">
							<center>
								<i
									className="fas fa-user"
									style={{
										textAlign: "center",
										fontSize: "60px",
										color: "#729fcf",
									}}
								></i>
								<h4 className="name">
									{personDetail.name1} {personDetail.name2}{" "}
									{personDetail.lastName1} {personDetail.lastName2}
								</h4>
								<h4 className="name">{personDetail.businessName}</h4>
							</center>

							<div class="accordion" id="accordionExample">
								<div class="accordion-item">
									<h2 class="accordion-header" id="headingOne">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseOne"
											aria-expanded="true"
											aria-controls="collapseOne"
										>
											IDENTIFICACIÓN
										</button>
									</h2>
									<div
										id="collapseOne"
										class="accordion-collapse collapse "
										aria-labelledby="headingOne"
										data-bs-parent="#accordionExample"
									>
										<div class="accordion-body">
											<table style={{ width: "100%" }} id="tableInfo">
												<tr>
													<th>Tipo de persona</th>
													<td>
														{typePerson.name} ({typePerson.code})
													</td>
													<th>Tipo de identificación</th>
													<td>
														{typeId.name} ({typeId.code})
													</td>
													<th>Tipo de identificación</th>
													<td>{personDetail.identification}</td>
													<th>Dv</th>
													<td>{personDetail.dv}</td>
												</tr>
												<tr>
													<th>Fecha expedición</th>
													<td>{personDetail.fechaExpedicion}</td>
													<th>País de expedición</th>
													<td>
														{personDetail && personDetail.paisExpedicion
															? personDetail.paisExpedicion.codigo +
															  " - " +
															  personDetail.paisExpedicion.descripcion
															: ""}
													</td>
													<th>Depto de expedición</th>
													<td>
														{personDetail && personDetail.departamentoExpedicion
															? personDetail.departamentoExpedicion.codigo +
															  " - " +
															  personDetail.departamentoExpedicion.descripcion
															: ""}
													</td>
													<th>Mupio de expedición</th>
													<td>
														{personDetail && personDetail.municipioExpedicion
															? personDetail.municipioExpedicion.codigo +
															  " - " +
															  personDetail.municipioExpedicion.descripcion
															: ""}
													</td>
												</tr>
												<tr>
													<th>Apellido 1</th>
													<td>{personDetail.lastName1}</td>
													<th>Apellido 2</th>
													<td>{personDetail.lastName2}</td>
													<th>Nombre 1</th>
													<td>{personDetail.name1}</td>
													<th>Otros Nombres</th>
													<td>{personDetail.name2}</td>
												</tr>
												<tr>
													<th>Razón social</th>
													<td>{personDetail.businessName}</td>
												</tr>
												<tr>
													<th>Nombre comercial</th>
													<td colSpan={3}>{personDetail.nombreComercial}</td>
													<th>Sigla</th>
													<td>{personDetail.sigla}</td>
												</tr>
											</table>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header" id="headingTwo">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseTwo"
											aria-expanded="false"
											aria-controls="collapseTwo"
										>
											UBICACIÓN
										</button>
									</h2>
									<div
										id="collapseTwo"
										class="accordion-collapse collapse"
										aria-labelledby="headingTwo"
										data-bs-parent="#accordionExample"
									>
										<div class="accordion-body">
											<table style={{ width: "100%" }} id="tableInfo">
												<tr>
													<th>País de ubicación</th>
													<td>
														{personDetail && personDetail.paisUbicacion
															? personDetail.paisUbicacion.codigo +
															  " - " +
															  personDetail.paisUbicacion.descripcion
															: ""}
													</td>
													<th>Depto de ubicación</th>
													<td>
														{personDetail && personDetail.departamentoUbicacion
															? personDetail.departamentoUbicacion.codigo +
															  " - " +
															  personDetail.departamentoUbicacion.descripcion
															: ""}
													</td>
													<th>Mupio de ubicación</th>
													<td>
														{personDetail && personDetail.municipioUbicacion
															? personDetail.municipioUbicacion.codigo +
															  " - " +
															  personDetail.municipioUbicacion.descripcion
															: ""}
													</td>
												</tr>
												<tr>
													<th>Dirección</th>
													<td colSpan={3}>{personDetail.address}</td>
													<th>Código postal</th>
													<td>{personDetail.codPostal}</td>
												</tr>
												<tr>
													<th>Email</th>
													<td>{personDetail.email1}</td>
													<th>Teléfono 1</th>
													<td>{personDetail.phone1}</td>
													<th>Teléfono 2</th>
													<td>{personDetail.phone2}</td>
												</tr>
											</table>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header" id="headingThree">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											data-bs-target="#collapseThree"
											aria-expanded="false"
											aria-controls="collapseThree"
										>
											CLASIFICACIÓN
										</button>
									</h2>
									<div
										id="collapseThree"
										class="accordion-collapse collapse"
										aria-labelledby="headingThree"
										data-bs-parent="#accordionExample"
									>
										<div class="accordion-body">
											<table id="tableInfo">
												<tr>
													<th>Actividad económica 1</th>
													<td>
														{personDetail && personDetail.actividad1
															? personDetail.actividad1.codigo +
															  " - " +
															  personDetail.actividad1.descripcion
															: ""}
													</td>
													<th>fecha inicio</th>
													<td>{personDetail.fechaIniAct1Formatted}</td>
												</tr>

												<tr>
													<th>Actividad económica 2</th>
													<td>
														{personDetail && personDetail.actividad2
															? personDetail.actividad2.codigo +
															  " - " +
															  personDetail.actividad2.descripcion
															: ""}
													</td>
													<th>fecha inicio</th>
													<td>{personDetail.fechaIniAct1Formatted}</td>
												</tr>
												<tr>
													<th>Actividad económica 3</th>
													<td colSpan={3}>
														{personDetail && personDetail.actividad3
															? personDetail.actividad3.codigo +
															  " - " +
															  personDetail.actividad3.descripcion
															: ""}
													</td>
												</tr>
												<tr>
													<th>Actividad económica 4</th>
													<td colSpan={3}>
														{personDetail && personDetail.actividad4
															? personDetail.actividad4.codigo +
															  " - " +
															  personDetail.actividad4.descripcion
															: ""}
													</td>
												</tr>
												<tr>
													<th>Ocupación</th>
													<td>
														{personDetail && personDetail.ocupacionTercero
															? personDetail.ocupacionTercero.codigo +
															  " - " +
															  personDetail.ocupacionTercero.descripcion
															: ""}
													</td>
													<th>Número de establecimientos</th>
													<td>{personDetail.numEstabs}</td>
												</tr>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const Pagination = ({
	totalPages,
	currentPage,
	onPageChange,
	onPrevClick,
	onNextClick,
}) => {
	const pageNumbers = [];

	for (let i = 0; i < totalPages; i++) {
		pageNumbers.push(i);
	}

	return (
		<nav>
			<ul className="pagination">
				<li className={`page-item ${currentPage === 0 ? "disabled" : ""}`}>
					<button onClick={onPrevClick} className="page-link">
						&laquo;
					</button>
				</li>
				{pageNumbers.map((number) => (
					<li
						key={number}
						className={`page-item ${number === currentPage ? "active" : ""}`}
					>
						<button onClick={() => onPageChange(number)} className="page-link">
							{number + 1}
						</button>
					</li>
				))}
				<li
					className={`page-item ${
						currentPage === totalPages ? "disabled" : ""
					}`}
				>
					<button onClick={onNextClick} className="page-link">
						&raquo;
					</button>
				</li>
			</ul>
		</nav>
	);
};

export default SearchPersons;
