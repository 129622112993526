import React, { useState, useEffect, useContext, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../context/LogContext";
import {
	route_procesosPresupuesto,
	route_configuration_base,
	route_confiPresupuesto_base,
} from "../../../api-routes/RoutesLogin";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { FaLock, FaLockOpen } from "react-icons/fa";

const FormasPago = () => {
	const { modulo } = useParams();
	const { getToken, inputStyles } = useContext(AuthContext);
	const { establishment } = useContext(AuthContext);
	const [years, setYears] = useState([]);
	const btnCancel = useRef(null);
	const formikRef = useRef(null);
	const [activeYears, setActiveYears] = useState(null);
	const [planxyear, setPlanxyear] = useState([]);
	const [btnCarga, setBtnCarga] = useState(0);
	const [banCarga, setBanCarga] = useState(0);

	const handleSubmit = (values) => {
		setBtnCarga(1);
		values.modulo = modulo;
		saveYear(values);
	};
	const saveYear = async (json) => {
		json.user = parseInt(localStorage.getItem("idUser"));
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			const response = await axios.post(
				route_configuration_base + "/saveYear",
				json,
				config
			);
			const successMessage = "Año creado correctamente";

			Swal.fire({
				icon: "success",
				title: successMessage,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				setBtnCarga(0);
				btnCancel.current.click();
				getYears();
			});
		} catch (error) {
			if (error.response && error.response.status === 403) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "El año ya existe",
					showConfirmButton: true,
				}).then(() => {setBtnCarga(0);});
			} else {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error de red, contactese con su proveedor",
					showConfirmButton: true,
				}).then(() => {
					setBtnCarga(0);
				});
			}
		}
	};

	const getYears = async () => {
		setBanCarga(1);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		const result = await axios.get(
			`${route_configuration_base}/getYears/${establishment.id},${modulo}`,
			config
		);
		result.data.sort((a, b) => a.year - b.year);
		setYears(result.data);
		var active = result.data.filter((a) => a.status == true);
		setActiveYears(active);
		setBanCarga(0);
	};

	const handleDelete = (servicioId) => {
		let years = activeYears.filter((y) => y.id != servicioId);
		let YearS = activeYears.find((y) => y.id == servicioId);
		//setActiveYears(years)
		Swal.fire({
			title: "Desea cerrar el año?",
			text: "No se permitirá ejecutar movimientos en este año",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
			footer: `
			<div class="row" >
			<div class="col-12" >
			<div class="input-group mb-3">
			  <span class="input-group-text" id="inputGroup-sizing-default">Plan origen:</span>
			  <select required class="form-control" id="select-plan">
				<option value="null">Seleccione el plan presupuestal origen</option>
				${planxyear
					.map((Y) =>
						`
					<option value="${Y.id}">${Y.nombre}</option>
				  `.trim()
					)
					.join("")}
			  </select>
			</div>
			</div>
			<div class="col-12" >
			<div class="input-group mb-3">
			  <span class="input-group-text" id="inputGroup-sizing-default">Año destino:</span>
			  <select required class="form-control" id="my-select">
				<option value="null">Seleccione año destino</option>
				${years
					.map((Y) =>
						`
					<option value="${Y.id}">${Y.year}</option>
				  `.trim()
					)
					.join("")}
			  </select>
			</div>
			</div>
			</div>
			`,
			inputAttributes: {
				autocapitalize: "off",
			},
			inputPlaceholder: "Seleccione una opción",
		}).then((result) => {
			if (result.isConfirmed) {
				const selectYear = document.getElementById("my-select");
				const selectPlan = document.getElementById("select-plan");
				let values = {
					id: servicioId,
					status: false,
					user: localStorage.getItem("idUser"),
					yearD: selectYear.value,
					planO: selectPlan.value,
					year: YearS.year,
				};
				cerrarPeriodo(values)
					.then((response) => {
						Swal.fire({
							icon: "success",
							title: "El año ha sido cerrado",
							showConfirmButton: false,
							timer: 1500,
						}).then(() => {
							getYears();
						});
					})
					.catch((error) => {
						console.error(error);
						Swal.fire({
							icon: "success",
							title: "El año ha sido cerrado",
							showConfirmButton: false,
							timer: 1500,
						});
					});

				/*
				// Ejecutar la primera función
				trasladarCuentas(selectElement.value,selectPlan.value)
					.then(() => {
						// Si trasladarCuentas tiene éxito, ejecutar la segunda función
						return deleteServicio(servicioId, false);
					})
					.then((response) => {
						// Manejar el resultado de la segunda función
						Swal.fire({
							icon: "success",
							title: "El año ha sido cerrado",
							showConfirmButton: false,
							timer: 1500,
						}).then(() => {
							getYears();
						});
					})
					.catch((error) => {
						// Manejar cualquier error que ocurra en cualquiera de las funciones
						console.error(error);
						Swal.fire({
							icon: "error",
							title: "Hubo un error al cerrar el año",
							text: error.message,
							showConfirmButton: true,
						});
					}); */
			}
		});
	};

	const handleActive = (servicioId) => {
		Swal.fire({
			title: "Abrir año?",
			text: "El año estará disponible para movimientos, Seleccione el año ",
			icon: "success",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Reactivando...",
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					didOpen: () => {
						deleteServicio(servicioId, true)
							.then((response) => {
								Swal.fire({
									icon: "success",
									title: "El año ha sido abierto",
									showConfirmButton: false,
									timer: 1500,
								}).then(() => {
									getYears();
								});
							})
							.catch((error) => {
								console.error(error);
								Swal.fire({
									icon: "error",
									title: "Hubo un error al abrir el año",
									text: error.message,
									showConfirmButton: true,
								});
							});
					},
				});
			}
		});
	};

	const deleteServicio = async (servicioId, status) => {
		let values = {
			id: servicioId,
			status: status,
			user: localStorage.getItem("idUser"),
		};
		let dataJson = JSON.stringify(values);
		try {
			const response = await axios.post(
				route_configuration_base + "/years/status",
				dataJson,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			return response;
		} catch (error) {
			throw error;
		}
	};
	const cerrarPeriodo = async (values) => {
		let dataJson = JSON.stringify(values);
		try {
			const response = await axios.post(
				route_procesosPresupuesto + "/cerrarPeriodo",
				dataJson,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			return response;
		} catch (error) {
			throw error;
		}
	};

	useEffect(() => {
		getCabeceras();
		getYears();
	}, []);

	const validationSchema = Yup.object().shape({
		year: Yup.number().required("Ingrese el año a crear"),
	});

	const initialValues = {
		id: "",
		year: "",
		status: true,
		establishmentId: establishment.id,
		user: localStorage.getItem("idUser"),
	};

	const getCabeceras = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_confiPresupuesto_base}/getplanesp/${establishment.id}`,
			config
		);
		setPlanxyear(result.data);
	};

	const trasladarCuentas = async (year, plan) => {
		//-----trasladar ejecuciones presupuestales -------------- ////
	
	};
	return (
		<div className="containerFormUser">
			<h1>Crear Año</h1>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
				innerRef={formikRef}
			>
				{(formik, props) => (
					<Form>
						<div className="row">
							{/* BLOQUE1 */}
							<div className="col-md-8 ">
								<div className="form-floating mb-2  ">
									<Field
										style={inputStyles}
										type="number"
										name="year"
										id="year"
										className="form-control"
										placeholder="Forma de pago"
									/>
									<label htmlFor="forma" className="form-label">
										Año
									</label>
									<ErrorMessage name="year" component="div" className="error" />
								</div>
							</div>
							<div
								className="col-md-4"
								style={{ textAlign: "left", padding: "10px" }}
							>
								{btnCarga === 0 ? (
									<button type="submit" className="btn btn-primary">
										Guardar
									</button>
								) : (
									/* spinner carga */
									<button className="btn btn-primary" type="button" disabled>
										<span
											className="spinner-border spinner-border-sm"
											aria-hidden="true"
										></span>
										<span role="status"> Guardando...</span>
									</button>
									/*--------------- */
								)}
								&nbsp;
								<button type="reset" ref={btnCancel} className="btn btn-danger">
									Cancelar
								</button>
							</div>
						</div>
					</Form>
				)}
			</Formik>
			<br></br>
			<h1>Años Creados</h1>
			{banCarga === 0 ? (
				<table
					style={{ width: "100%" }}
					id="tableUsers"
					className="table table-striped table-hover tableUsers"
				>
					<thead>
						<tr>
							<th>Año</th>
							<th>Estado</th>
							<th style={{ width: "20%", textAlign: "right" }}>Opciones</th>
						</tr>
					</thead>
					<tbody>
						{years.map((servicio) => (
							<tr key={servicio.id}>
								<td>{servicio.year}</td>
								<td>
									{servicio.status ? (
										<>
											<label style={{ color: "green" }}>Abierto</label>
										</>
									) : (
										<>
											<label style={{ color: "red" }}>Cerrado</label>
										</>
									)}
								</td>
								<td style={{ width: "20%", textAlign: "right" }}>
									{servicio.status ? (
										<>
											<button
												title="Cerrar Año"
												className="btnDelete"
												type="button"
												onClick={() => handleDelete(servicio.id)}
											>
												<FaLock />{" "}
											</button>
										</>
									) : (
										<>
											<button
												title="Abrir año"
												className="btnSuccess"
												type="button"
												onClick={() => handleActive(servicio.id)}
											>
												<FaLockOpen />
											</button>
										</>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			) : (
				/* spinner carga */
				<button className="btn btn-primary" type="button" disabled>
					<span
						className="spinner-border spinner-border-sm"
						aria-hidden="true"
					></span>
					<span role="status"> Cargando...</span>
				</button>
				/*--------------- */
			)}
		</div>
	);
};

export default FormasPago;
