import React, { useEffect, useContext, useState, useRef } from "react";
import axios from "axios";
import { AuthContext } from "../context/LogContext";
import "../../styles/administration/Dashboard.css";
import { route_contamovs_base } from "../../api-routes/RoutesLogin";
import Swal from "sweetalert2";
import Chart from "chart.js/auto";

export default function DashboardAdmin() {
	const { establishment, getToken } = useContext(AuthContext);
	const [info, setInfo] = useState([]);
	const [dataLoaded, setDataLoaded] = useState(false);
	const chartRef1 = useRef(null); // Referencia para el primer gráfico
	const chartRef2 = useRef(null); // Referencia para el segundo gráfico

	useEffect(() => {
		loadInfo();
	}, []);

	useEffect(() => {
		if (dataLoaded) {
		
			// Crear el primer gráfico
			var color1 = generateRandomColor();
			var color2 = generateRandomColor();
			var color3 = generateRandomColor();
			var color4 = generateRandomColor();
			var color5 = generateRandomColor();
			var color6 = generateRandomColor();
			createPieChart(
				chartRef1,
				"Estado Ingresos",
				["Cuentas por cobrar", "Recibos de caja"],
				[info.cxc, info.rc],
				[color1, color2]
			);

			// Crear el segundo gráfico
			createPieChart(
				chartRef2,
				"Estado gastos",
				["Cuentas por pagar", "Comprobantes de egreso"],
				[info.cxp, info.ce],
				[color3, color4]
			);
		}
	}, [dataLoaded]);

	const loadInfo = async () => {
	
		try {
		
			const response = await axios.get(
				route_contamovs_base + "/getDashBoard/"+establishment.id,
				{
					headers: {
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
		console.log(response.data)
			setInfo(response.data);
			setDataLoaded(true); // Se establece que los datos están cargados
		} catch (error) {
		
			// Manejo de errores
		}
	};
	function generateRandomColor() {
		// Generar un valor hexadecimal aleatorio
		const hue = Math.random() * 60 + 180; // Matiz entre 180° y 240° (tonos azulados)
		const saturation = Math.random() * 20 + 10; // Saturación baja (10%-30%) para tonos suaves
		const lightness = Math.random() * 40 + 60; // Claridad alta (60%-100%) para tonos claros

		// Convertir a formato HSL
		const hslColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;

		return hslColor;
	}

	// Función para crear un gráfico de torta
	const createPieChart = (chartRef, title, labels, data, backgroundColor) => {
		const ctx = chartRef.current.getContext("2d");

		new Chart(ctx, {
			type: "pie",
			data: {
				labels: labels,
				datasets: [
					{
						data: data,
						backgroundColor: backgroundColor,
					},
				],
			},
			options: {
				responsive: true,
				plugins: {
					legend: {
						position: "top",
					},
					title: {
						display: false,
						text: title,
					},
				},
			},
		});
	};

	return (
		<div className="containerFormUser">
			<div
				className="row"
				style={{ display: "flex", justifyContent: "center" }}
			>
				<div className="col-5 containerDashboard">
					<label className="titleDash">Ingresos</label>
					<div className="InfoDashboard">
						<div className="graphContainer">
							<canvas className="canvasContainer" ref={chartRef1} />
						</div>
						{dataLoaded ? (
							<table
								id="tableUsers"
								className="table table-striped table-hover"
							>
								<thead>
									<tr>
										<th>Movimiento</th>
										<th>Valor</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Cuentas por cobrar</td>
										<td>{info.cxc}</td>
									</tr>
									<tr>
										<td>Recibos de caja</td>
										<td>{info.rc}</td>
									</tr>
								</tbody>
							</table>
						) : (
							/* spinner carga */
							<button className="btn btn-primary" type="button" disabled>
								<span
									className="spinner-border spinner-border-sm"
									aria-hidden="true"
								></span>
								<span role="status"> Cargando...</span>
							</button>
							/*--------------- */
						)}
					</div>
				</div>
				<div className="col-5 containerDashboard">
					<label className="titleDash">Relación de gastos</label>
					<div className="InfoDashboard">
						<div style={{ width: "350px", height: "350px" }}>
							<canvas ref={chartRef2} />
						</div>
						{dataLoaded ? (
							<table
								id="tableUsers"
								className="table table-striped table-hover"
							>
								<thead>
									<tr>
										<th>Movimiento</th>
										<th>Valor</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Cuentas por pagar</td>
										<td>{info.cxp}</td>
									</tr>
									<tr>
										<td>Comprobante de egreso</td>
										<td>{info.ce}</td>
									</tr>
									
								</tbody>
							</table>
						) : (
							/* spinner carga */
							<button className="btn btn-primary" type="button" disabled>
								<span
									className="spinner-border spinner-border-sm"
									aria-hidden="true"
								></span>
								<span role="status"> Cargando...</span>
							</button>
							/*--------------- */
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
