import React from "react";
import {
	FcPackage,
	FcTimeline,
	FcTodoList,
	FcBullish,
	FcBearish,
	FcRules,
	FcSalesPerformance
} from "react-icons/fc";
import Dashboard from "../../componentsBase/tables/Intro"; // Asegúrate de que la ruta sea correcta

const ParentComponent = () => {
	const cards = [
		{
			id: 1,
			icon: <FcTimeline />,
			title: "Plan de Cuentas",
			description: "Crea, configura y revisa de froma àgil tu PUC",
			to: "/menu/hefestoContabilidad/crear-puc",
		},
		{
			id: 2,
			icon: <FcPackage />,
			title: "Centros de Costo",
			description: "Administra los centros de costo de tu sistema",
			to: "/menu/hefestoContabilidad/crear-centroscosto",
		},
		{
			id: 3,
			icon: <FcTodoList />,
			title: "Comprobantes",
			description: "Gestiona tus comprobantes contables",
			detalles: [
				{
					id: 1,
					icon: <FcBullish />,
					title: "Ingresos",
					description: "Cuentas por cobrar y recibos de caja",
					to: "/menu/hefestoContabilidad/crear-comprobantes/1",
				},
				{
					id: 2,
					icon: <FcBearish />,
					title: "Gastos",
					description: "Cuentas por pagar y comprobantes de egreso",
					to: "/menu/hefestoContabilidad/crear-comprobantes/2",
				},
				{
					id: 2,
					icon: <FcRules />,
					title: "Contables",
					description: "Comprobantes contables",
					to: "/menu/hefestoContabilidad/crear-comprobantes/3",
				},
			],
		},
		{
			id: 4,
			icon: <FcSalesPerformance />,
			title: "Descuentos",
			description: "Configura los descuentos requeridos para tus movimientos contables",
			to: "/menu/hefestoContabilidad/crear-descuentos",
		},
		
	];

	return <Dashboard cards={cards} />;
};

export default ParentComponent;
