import React, { useState, useEffect, useContext, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import axios from "axios";
import { route_confiPresupuesto_base } from "../../../api-routes/RoutesLogin";
import { AuthContext } from "../../context/LogContext";
import { GiCancel } from "react-icons/gi";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { GrTree } from "react-icons/gr";

const ConfigurarEstructura = () => {
	const { getToken, customStyles, inputStyles } = useContext(AuthContext);
	const { establishment } = useContext(AuthContext);
	const [instructors, setInstructors] = useState([]);
	const [instructorDetail, setInstructorDetail] = useState({});
	const buttonRef = useRef(null);
	const formikRef = useRef(null);
	const [selectedEstructura, setSelectedEstructura] = useState(null);
	const [estructuras, setEstructuras] = useState([]);
	const [idEstructura, setIdEstructura] = useState(null);
	const [jsonConfiguracion, setJsonConfiguracion] = useState([]);
	const [codigoE, setCodigoE] = useState(null);
	const [jsonEstructura, setJsonEstructura] = useState(null);
	const btnClose = useRef(null);

	const arrayColors = [
		"#999999",
		"#9d9d9d",
		"#a1a1a1",
		"#a5a5a5",
		"#a9a9a9",
		"#adadad",
		"#b1b1b1",
		"#b5b5b5",
		"#b9b9b9",
		"#bdbdbd",
		"#c1c1c1",
		"#c5c5c5",
		"#c9c9c9",
		"#cdcdcd",
		"#d1d1d1",
		"#d5d5d5",
		"#d9d9d9",
		"#dddddd",
		"#e1e1e1",
		"#e5e5e5",
		"#e9e9e9",
		"#ededed",
		"#f1f1f1",
		"#f5f5f5",
		"#f9f9f9",
		"#fdfdfd",
		"#ffffff",
	];
	const [btnCarga, setBtnCarga] = useState(0);
	const [banCarga, setBanCarga] = useState(0);

	const handleSubmit = (values) => {
		values.idEstructura = idEstructura;
		const nivelExistente = jsonConfiguracion.some(
			(objeto) => objeto.nivel === values.nivel
		);
		if (nivelExistente) {
			Swal.fire({
				icon: "warning",
				title: "Nivel existente",
				text: "Los niveles de la estructura son unicos",
				showConfirmButton: false,
				timer: 1500,
			});
		} else {
			values.color = arrayColors[values.nivel];
			setJsonConfiguracion([...jsonConfiguracion, values]);
			buttonRef.current.click();
		}
	};

	const saveConf = async (json) => {
		setBtnCarga(1);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			const response = await axios.post(
				route_confiPresupuesto_base + "/guardarNivel",
				json,
				config
			);
			const successMessage = "Configuración actualizada exitosamente";

			Swal.fire({
				icon: "success",
				title: successMessage,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				buttonRef.current.click();
				setBtnCarga(0);
			});
		} catch (error) {
			if (error.response && error.response.status === 403) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Verifique los datos",
					showConfirmButton: true,
				}).then(() => {});
			} else {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error de red, contactese con su proveedor",
					showConfirmButton: true,
				}).then(() => {});
			}
			setBtnCarga(0);
		}
	};

	useEffect(() => {
		getEstructuras();
	}, []);

	const handleSelectChange = (e) => {
		setSelectedEstructura(e);
		setIdEstructura(e.value);
		getConfiguracion(e.value);
	};

	const getEstructuras = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_confiPresupuesto_base}/getNombres/${establishment.id}`,
			config
		);
		setEstructuras(result.data);
	};

	const getConfiguracion = async (idE) => {
		setBanCarga(1);
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_confiPresupuesto_base}/getconf/${idE}`,
			config
		);
		setJsonConfiguracion(result.data);
		setBanCarga(0);
	};

	const eliminarEstructura = (id) => {
		setJsonConfiguracion((prevObjetos) =>
			prevObjetos.filter((objeto) => objeto.nivel !== id)
		);
	};

	const guardarConfiguracion = () => {
		var msg = "";
		if (selectedEstructura == null) {
			msg = "Seleccione una estructura";
		} else if (jsonConfiguracion.length == 0) {
			msg = "No existe una configuración valida";
		} else if (jsonConfiguracion.length > 0) {
			const resultadoVerificacion =
				verificarNivelesConsecutivos(jsonConfiguracion);
			if (resultadoVerificacion == false) {
				msg = "Los niveles no son consecutivos";
			}
		}

		if (msg == "") {
			saveConf(jsonConfiguracion);
		} else {
			Swal.fire({
				icon: "warning",
				title: msg,
				showConfirmButton: false,
				timer: 2500,
			});
		}
		//alert(jsonConfiguracion)
	};

	function verificarNivelesConsecutivos(jsonConfiguracion) {
		// Ordenar los objetos en el array por el campo "nivel" de forma ascendente
		const sortedConfiguracion = jsonConfiguracion.sort(
			(a, b) => a.nivel - b.nivel
		);

		// Verificar que los campos "nivel" sean enteros consecutivos y no falte ninguno
		for (let i = 0; i < sortedConfiguracion.length; i++) {
			if (sortedConfiguracion[i].nivel != i + 1) {
				// Si el campo "nivel" no es igual a i + 1, entonces falta un número o no es consecutivo
				return false;
			}
		}

		// Si todos los campos "nivel" son enteros consecutivos, retornar true
		return true;
	}

	const validationSchema = Yup.object().shape({
		//idEstructura: Yup.number().required("Seleccione una estructura"),
		nivel: Yup.number().required("El nivel es obligatorio"),
		caracteres: Yup.number().required("El numero de caracteres es obligatorio"),
		nombre: Yup.string().required("El nombre es obligatorio"),
		//	color: Yup.string().required("El color es obligatorio"),
	});

	const initialValues = {
		id: "",
		idEstructura: "",
		nivel: "",
		caracteres: "",
		nombre: "",
		color: "",
		user: localStorage.getItem("idUser"),
	};

	function generarEstructura() {
		const regex = /^[0-9.]*$/;
		if (codigoE) {
			var cod = codigoE.trim().toString();
			if (regex.test(cod) && cod) {
				var arrCods = cod.split(".");
				var jsonEstructura = [];

				arrCods.forEach((c, key) => {
					var arr = { nivel: key + 1, nombre: "", tam: c.length };
					jsonEstructura.push(arr);
				});
				setJsonEstructura(jsonEstructura);
			} else {
				Swal.fire({
					icon: "warning",
					title: "Código incorrecto",
					text: "El código debe ser numérico y separado por puntos(.)",
					showConfirmButton: true,
				});
			}
		} else {
			Swal.fire({
				icon: "warning",
				title: "Ingrese código",
				text: "El código debe ser numérico y separado por puntos(.)",
				showConfirmButton: true,
			});
		}
	}

	function DynamicForm({ jsonData }) {
		const [formData, setFormData] = useState({});

		// Manejar cambios en los campos del formulario
		const handleChange = (e) => {
			const { name, value } = e.target;
			setFormData({ ...formData, [name]: value });
		};

		// Manejar envío del formulario
		const handleSubmit = (e) => {
			e.preventDefault();
			var json = [];
			{
				jsonData.map((item) => {
					var i = {
						id: "",
						idEstructura: idEstructura,
						nivel: item.nivel,
						caracteres: item.tam,
						nombre: formData[item.nivel],
						color: arrayColors[item.nivel],
						user: localStorage.getItem("idUser"),
					};
					json.push(i);
				});
				setJsonConfiguracion(json);
				btnClose.current.click();
			}
		};

		return (
			<div className="containerFormUser">
				<h2>Estructura Sugerida</h2>
				<form onSubmit={handleSubmit}>
					{jsonData.map((item) => (
						<div className="row mb-2" key={item.nivel}>
							<div className="col-3 ">
								<label htmlFor={`${item.nivel}`}>Nivel {item.nivel}:</label>
							</div>
							<div className="col-5 ">
								<input
									placeholder="Nombre"
									className="form-control"
									type="text"
									id={`${item.nivel}`}
									name={`${item.nivel}`}
									value={formData[`${item.nivel}`] || ""}
									onChange={handleChange}
									required
								/>
							</div>
							<div className="col-4">
								<label htmlFor={`${item.nivel}`}>Caracteres: {item.tam}</label>
							</div>
						</div>
					))}
					<button className="btn btn-primary" type="submit">
						Aceptar
					</button>
				</form>
			</div>
		);
	}

	return (
		<>
			<div className="containerFormUser">
				<h1>Configuración de estructura</h1>
				<div className="row">
					<div className="col-md-12  ">
						<div className="form-floating mb-2  ">
							<Select
								styles={customStyles}
								placeholder="Estructuras"
								value={selectedEstructura}
								onChange={(e) => {
									handleSelectChange(e);
									//formik.setFieldValue("idEstructura", e ? e.value : "");
								}}
								options={estructuras.map((est) => ({
									label: est.nombre,
									value: est.id,
								}))}
								classNamePrefix="select"
							/>
						</div>
					</div>
				</div>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}
					innerRef={formikRef}
				>
					{(formik, props) => (
						<Form id="formInstrucor">
							<div className="row">
								{/* BLOQUE1 */}

								<div className="col-md-3  ">
									<div className="form-floating mb-2  ">
										<Field
											style={inputStyles}
											type="numeric"
											name="nivel"
											id="nivel"
											className="form-control"
											placeholder="Nivel"
										/>
										<label
											style={{ zIndex: "0" }}
											htmlFor="nivel"
											className="form-label"
										>
											Nivel
										</label>
										<ErrorMessage
											name="nivel"
											component="div"
											className="error"
										/>
									</div>
								</div>
								<div className="col-md-3  ">
									<div className="form-floating mb-2  ">
										<Field
											style={inputStyles}
											type="text"
											name="nombre"
											id="nombre"
											className="form-control"
											placeholder="Nombre"
										/>
										<label
											style={{ zIndex: "0" }}
											htmlFor="nombre"
											className="form-label"
										>
											Nombre
										</label>
										<ErrorMessage
											name="nombre"
											component="div"
											className="error"
										/>
									</div>
								</div>
								<div className="col-md-3  ">
									<div className="form-floating mb-2  ">
										<Field
											style={inputStyles}
											type="numeric"
											name="caracteres"
											id="caracteres"
											className="form-control"
											placeholder="No de caracteres"
										/>
										<label
											style={{ zIndex: "0" }}
											htmlFor="caracteres"
											className="form-label"
										>
											No.de caracteres
										</label>
										<ErrorMessage
											name="caracteres"
											component="div"
											className="error"
										/>
									</div>
								</div>
								{/* 	<div className="col-md-1  ">
									<div className="form-floating mb-2  ">
										<Field
											type="color"
											name="color"
											id="color"
											className="form-control"
											placeholder="Color de nivel"
										/>
										<label htmlFor="caracteres" className="form-label">
											Color
										</label>
										<ErrorMessage
											name="color"
											component="div"
											className="error"
										/>
									</div>
								</div> */}
								<div className="col-md-3  ">
									<button className="btn btn-primary" title="Agregar Nivel">
										<span className="fas fa-plus" />
									</button>
									&nbsp;
									<button
										type="reset"
										ref={buttonRef}
										className="btn btn-danger"
										title="Eliminar Nivel"
									>
										<GiCancel />
									</button>
									&nbsp;
									<button
										type="button"
										className="btn btn-primary"
										data-bs-toggle="modal"
										data-bs-target="#modalEstructura"
										title="Detectar estructura"
									>
										<GrTree />
									</button>
								</div>
							</div>

							<Field
								type="hidden"
								id="idEstructura"
								name="idEstructura"
								value={idEstructura}
							/>
						</Form>
					)}
				</Formik>
				<br></br>
				<h1>Estructura</h1>
				{banCarga == 0 ? (
					<table
						style={{ width: "100%" }}
						id="tableUsers"
						className="table table-striped table-hover"
					>
						<thead>
							<tr>
								<th>Nivel</th>
								<th>Nombre</th>
								<th>Caracteres</th>
								{/* <th>Color</th> */}
								<th style={{ width: "20%", textAlign: "right" }}>Opciones</th>
							</tr>
						</thead>
						<tbody>
							{jsonConfiguracion.map((instructor) => (
								<tr key={instructor.nivel}>
									<td>{instructor.nivel}</td>
									<td>{instructor.nombre}</td>
									<td>{instructor.caracteres}</td>
									{/* <td style={{ textAlign: "center" }}>
									<div
										style={{
											width: "30px",
											height: "30px",
											borderRadius: "50%",
											backgroundColor: instructor.color,
											display: "inline-flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									></div>
								</td>
 */}
									<td style={{ width: "20%", textAlign: "right" }}>
										<>
											<button
												className="btnDelete"
												type="button"
												onClick={() => eliminarEstructura(instructor.nivel)}
											>
												<i className="fas fa-trash-alt"></i>
											</button>
										</>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				) : (
					/* spinner carga */
					<button className="btn btn-primary" type="button" disabled>
						<span
							className="spinner-border spinner-border-sm"
							aria-hidden="true"
						></span>
						<span role="status"> Cargando...</span>
					</button>
					/*--------------- */
				)}
				{btnCarga === 0 ? (
					<button
						type="button"
						onClick={() => guardarConfiguracion()}
						className="btn btn-primary"
					>
						Guardar Cambios
					</button>
				) : (
					/* spinner carga */
					<button className="btn btn-primary" type="button" disabled>
						<span
							className="spinner-border spinner-border-sm"
							aria-hidden="true"
						></span>
						<span role="status"> Guardando...</span>
					</button>
					/*--------------- */
				)}
			</div>
			{/* modal detalle instructor */}
			<div
				className="modal fade"
				id="staticBackdrop"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabindex="-1"
				aria-labelledby="staticBackdropLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<center>Detalle de instructor</center>

							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>

						<div className=" modal-body">
							<center>
								<i
									className="fas fa-user"
									style={{
										textAlign: "center",
										fontSize: "60px",
										color: "#729fcf",
									}}
								></i>
								<h4 className="name">
									{instructorDetail.nombre1} {instructorDetail.nombre2}{" "}
									{instructorDetail.apellido1} {instructorDetail.apellido2}
								</h4>
							</center>

							<table style={{ width: "100%" }} id="tableInfo">
								<tr>
									<th>Identificación</th>
									<td>{instructorDetail.identificacion}</td>
								</tr>
								<tr>
									<th>Teléfono </th>
									<td>{instructorDetail.telefono}</td>
									<th>Email </th>
									<td>{instructorDetail.email}</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
			{/* ------- modal estructura */}
			<div
				className="modal fade"
				id="modalEstructura"
				tabindex="-1"
				aria-labelledby="modalEstructura"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="modalEstructura">
								Detectar Estructura
							</h1>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
								ref={btnClose}
							></button>
						</div>
						<div className="modal-body">
							<div class="input-group mb-3">
								<span class="input-group-text" id="inputGroup-sizing-default">
									Código
								</span>
								<input
									type="text"
									class="form-control"
									aria-label="Sizing example input"
									aria-describedby="inputGroup-sizing-default"
									onChange={(e) => {
										setCodigoE(e.target.value);
									}}
								></input>
								<button
									class="btn btn-outline-primary"
									onClick={generarEstructura}
									type="button"
								>
									<i className="fas fa-check"></i>
								</button>

								{/* --------------------------------- */}
								{jsonEstructura && <DynamicForm jsonData={jsonEstructura} />}

								{/* ---------------------------------- */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ConfigurarEstructura;
