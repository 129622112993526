import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { AuthContext } from "../../context/LogContext";
import { FaSearchDollar } from "react-icons/fa";
import { NumericFormat } from "react-number-format";
import { route_procesosConta_base } from "../../../api-routes/RoutesLogin";
import axios from "axios";
import Swal from "sweetalert2";
import { MdChecklistRtl } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export default function NuevaConciliacion() {
	const [mes, setMes] = useState(null);
	const [mesO, setMesO] = useState(null);
	const [year, setYear] = useState(null);
	const [puc, setPuc] = useState([]);
	const [cuenta, setCuenta] = useState(null);
	const [cuentaO, setCuentaO] = useState(null);
	const [detalles, setDetalles] = useState([]);
	const [valIngresos, setValIngresos] = useState(0);
	const [valGastos, setGastos] = useState(0);
	const [activeIds, setActiveIds] = useState([]);
	const [saldoIniCon, setSaldoIniCon] = useState(0);
	const [saldoIniBan, setSaldoIniBan] = useState(0);
	const [banCarga, setBanCarga] = useState(0);
	const navigate = useNavigate();

	const [switchStates, setSwitchStates] = useState(
		detalles.map(() => false) // Inicializa los switches en falso
	);

	const handleSwitchChange = (index) => {
		const updatedStates = [...switchStates];
		updatedStates[index] = !updatedStates[index];
		setSwitchStates(updatedStates);

		const updatedIds = [...activeIds];
		const detalleId = detalles[index].id; // Asume que cada detalle tiene un campo `id`

		if (updatedStates[index]) {
			updatedIds.push(detalleId); // Agrega el ID si el switch está activado
		} else {
			const idIndex = updatedIds.indexOf(detalleId);
			if (idIndex > -1) {
				updatedIds.splice(idIndex, 1); // Elimina el ID si el switch está desactivado
			}
		}
		setActiveIds(updatedIds);

		// Recalcular sumas de débitos y créditos
		const debitos = detalles
			.filter((_, i) => updatedStates[i])
			.reduce((sum, detalle) => sum + detalle.debitos, 0);
		const creditos = detalles
			.filter((_, i) => updatedStates[i])
			.reduce((sum, detalle) => sum + detalle.creditos, 0);

		setValIngresos(debitos);
		setGastos(creditos);
	};

	const meses = [
		{ value: 1, label: "Enero" },
		{ value: 2, label: "Febrero" },
		{ value: 3, label: "Marzo" },
		{ value: 4, label: "Abril" },
		{ value: 5, label: "Mayo" },
		{ value: 6, label: "Junio" },
		{ value: 7, label: "Julio" },
		{ value: 8, label: "Agosto" },
		{ value: 9, label: "Septiembre" },
		{ value: 10, label: "Octubre" },
		{ value: 11, label: "Noviembre" },
		{ value: 12, label: "Diciembre" },
	];
	const stylePlan = {
		control: (base) => ({
			...base,
			height: 80,
			minHeight: 35,
			fontSize: "14px",
			height: "45px",
		}),
		option: (base, { data, isDisabled }) => ({
			...base,
			textAlign: `left`,
			backgroundColor: "#5fb7eb",
			color: "white",
		}),
	};
	const { establishment, getToken, customStyles, inputStyles, FechaYMDHMS } =
		useContext(AuthContext);
	const visualizarInforme = async () => {
		if (mes != null && year != null && cuenta != null) {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};
			const result = await axios.get(
				`${route_procesosConta_base}/getCuentasxConciliar/${establishment.id},${cuenta},${mes},${year}`,
				config
			);
			console.log(result.data);
			setDetalles(result.data);
		} else {
			Swal.fire({
				icon: "warning",
				title: "Verifique los valores del filtro",
				showConfirmButton: false,
				timer: 1500,
			});
		}
	};
	function customSort(a, b) {
		// Ordenar por 'codigo'
		if (a.codigo < b.codigo) return -1;
		if (a.codigo > b.codigo) return 1;

		// Si 'codigo' es igual, ordenar por 'tipo'
		if (a.tipo < b.tipo) return -1;
		if (a.tipo > b.tipo) return 1;

		// Si 'codigo' y 'tipo' son iguales, ordenar por 'naturaleza'
		if (a.naturaleza < b.naturaleza) return -1;
		if (a.naturaleza > b.naturaleza) return 1;

		return 0;
	}

	const getPuc = async () => {
		//setBanCarga(1)
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		const result = await axios.get(
			`${route_procesosConta_base}/getCuentasBancos/${establishment.id}`,
			config
		);
		//let newPuc = reemplazarMadreConCodigo(result.data.pucDTOList);
		let order = result.data.sort(customSort);
		const formattedOptions = order.map((item) => ({
			value: item.id,
			label: item.cuenta,
		}));
		console.log(formattedOptions);
		setPuc(formattedOptions);
	};
	const Conciliar = async () => {
		setBanCarga(1);
		var conciliacion = {
			inicialBanco: saldoIniBan,
			inicialContable: saldoIniCon,
			noRegistros: activeIds.length,
			totalIngresos: valIngresos,
			totalGastos: valGastos,
			usuario: localStorage.getItem("idUser"),
			fecha: FechaYMDHMS,
			detalles: activeIds,
			establishment: establishment.id
		};
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		console.log(`${route_procesosConta_base}/saveConciliacon`);
		console.log(conciliacion);

		const result = await axios.post(
			`${route_procesosConta_base}/saveConciliacon`,
			conciliacion,
			config
		);
		Swal.fire({
			icon: "success",
			title: "Conciliación registrada ",
			showConfirmButton: false,
			timer: 1500,
		}).then(() => {
			setBanCarga(0);
			navigate(`/menu/hefestoContabilidad/conciliaciones-contabilidad`);
		});
	};

	useEffect(() => {
		getPuc();
	}, []);
	return (
		<div className="containerFormUser">
			<h1>Nueva Conciliación</h1>
			<div className="row">
				<div className="col-md-3  ">
					<div className="form-floating mb-2  ">
						<Select
							styles={customStyles}
							value={mesO}
							isSearchable="true"
							placeholder="Mes "
							isClearable="true"
							options={meses}
							onChange={(e) => {
								setMesO(e);
								setMes(e != null ? e.value : null);
							}}
						></Select>
					</div>
				</div>
				<div className="col-md-3  ">
					<div className="form-floating mb-2  ">
						<NumericFormat
							style={{ ...inputStyles, textAlign: "right" }}
							value={year}
							className="form-control"
							onChange={(e) => {
								setYear(
									(() => {
										const rawValue = e.target.value
											.replace(/\./g, "")
											.replace(",", ".");
										const parsedValue = parseFloat(rawValue);
										return isNaN(parsedValue) ? null : parsedValue;
									})()
								);
							}}
						/>
						<label style={{ zIndex: "0" }} className="form-label">
							Año
						</label>
					</div>
				</div>
				<div className="col-md-3  ">
					<div className="form-floating mb-2  ">
						<Select
							styles={stylePlan}
							isSearchable={true}
							placeholder="Cuenta"
							isClearable={true}
							id="selectCta"
							value={cuentaO}
							options={puc}
							filterOption={(option, inputValue) => {
								const label = option.label.toLowerCase();
								const input = inputValue.toLowerCase();

								// Primero, prioriza las coincidencias que comienzan con el input
								if (label.startsWith(input)) {
									return true;
								}

								// Como segunda opción, busca cualquier coincidencia
								return label.includes(input);
							}}
							onChange={(e) => {
								if (e) {
									setCuenta(e.value);
									setCuentaO(e);
									setDetalles([]);
								} else {
									setCuenta(null);
									setCuentaO(null);
									setDetalles([]);
								}
							}}
						></Select>
					</div>
				</div>
				<div className="col-3">
					<button
						className="btn btn-primary"
						title="Visualizar Informe"
						onClick={() => visualizarInforme()}
					>
						<FaSearchDollar size={20} />
					</button>
				</div>

				{/* card con lista de conciliaciones  */}
				<div
					style={{
						backgroundColor: "#f0f8ff", // Tono azul suave
						boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Sombra leve
						borderRadius: "8px",
						padding: "20px",
						width: "100%", // Ocupa el ancho completo del contenedor
						margin: "20px 0",
						boxSizing: "border-box", // Incluye el padding dentro del ancho
					}}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							marginBottom: "20px",
						}}
					>
						<div className="form-floating mb-2  ">
							<NumericFormat
								style={{ ...inputStyles, textAlign: "right" }}
								value={saldoIniCon}
								className="form-control"
								disabled={true}
								thousandSeparator="."
								decimalSeparator=","
							/>
							<label style={{ zIndex: "0" }} className="form-label">
								Saldo Contable
							</label>
						</div>
						<div className="form-floating mb-2  ">
							<NumericFormat
								style={{ ...inputStyles, textAlign: "right" }}
								value={saldoIniBan}
								className="form-control"
								thousandSeparator="."
								decimalSeparator=","
								onChange={(e) => {
									setSaldoIniBan(
										parseFloat(
											e.target.value.replace(/\./g, "").replace(",", ".")
										)
									);
								}}
							/>
							<label style={{ zIndex: "0" }} className="form-label">
								Saldo Bancario
							</label>
						</div>
						<div className="form-floating mb-2  ">
							<NumericFormat
								style={{ ...inputStyles, textAlign: "right" }}
								value={valIngresos}
								className="form-control"
								disabled={true}
								thousandSeparator="."
								decimalSeparator=","
							/>
							<label style={{ zIndex: "0" }} className="form-label">
								Ingresos
							</label>
						</div>
						<div className="form-floating mb-2  ">
							<NumericFormat
								style={{ ...inputStyles, textAlign: "right" }}
								value={valGastos}
								className="form-control"
								disabled={true}
								thousandSeparator="."
								decimalSeparator=","
							/>
							<label style={{ zIndex: "0" }} className="form-label">
								Gastos
							</label>
						</div>
						<button className="btn btn-primary" onClick={() => Conciliar()}>
							{" "}
							<MdChecklistRtl /> Conciliar
						</button>
					</div>
					{banCarga === 0 ? (
						<table style={{ width: "100%", borderCollapse: "collapse" }}>
							<thead>
								<tr style={{ backgroundColor: "#e6f7ff" }}>
									<th style={{ border: "1px solid #ccc", padding: "8px" }}>
										Tercero
									</th>
									<th style={{ border: "1px solid #ccc", padding: "8px" }}>
										Cuenta
									</th>
									<th style={{ border: "1px solid #ccc", padding: "8px" }}>
										Débito
									</th>
									<th style={{ border: "1px solid #ccc", padding: "8px" }}>
										Crédito
									</th>
									<th style={{ border: "1px solid #ccc", padding: "8px" }}>
										Conciliado
									</th>
								</tr>
							</thead>
							<tbody>
								{detalles.map((detalle, index) => (
									<tr key={index}>
										<td
											style={{
												border: "1px solid #ccc",
												padding: "8px",
												textAlign: "left",
											}}
										>
											{detalle.tercero}
										</td>
										<td
											style={{
												border: "1px solid #ccc",
												padding: "8px",
												textAlign: "left",
											}}
										>
											{detalle.cuenta}
										</td>
										<td
											style={{
												border: "1px solid #ccc",
												padding: "8px",
												textAlign: "right",
											}}
										>
											{detalle.debitos}
										</td>
										<td
											style={{
												border: "1px solid #ccc",
												padding: "8px",
												textAlign: "right",
											}}
										>
											{detalle.creditos}
										</td>
										<td
											style={{
												border: "1px solid #ccc",
												padding: "8px",
												textAlign: "center",
											}}
										>
											<div className="form-check form-switch">
												<input
													className="form-check-input"
													type="checkbox"
													id={`switch-${index}`}
													checked={switchStates[index]}
													onChange={() => handleSwitchChange(index)}
												/>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					) : (
						/* spinner carga */
						<button className="btn btn-primary" type="button" disabled>
							<span
								className="spinner-border spinner-border-sm"
								aria-hidden="true"
							></span>
							<span role="status"> Cargando información...</span>
						</button>
						/*--------------- */
					)}
					<div style={{ marginTop: "20px" }}>
						<button  className="btn btn-info" onClick={()=>{navigate(`/menu/hefestoContabilidad/conciliaciones-contabilidad`)}} >Regresar</button>
					</div>
				</div>
				{/* -------------------------------- */}
			</div>
		</div>
	);
}
