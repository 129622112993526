import React from "react";
import "./../../../styles/administration/Intro.css";
import { FcPlus } from "react-icons/fc";
import { FcSearch } from "react-icons/fc";
import Dashboard from "../../componentsBase/tables/Intro"; // Asegúrate de que la ruta sea correcta


const IntroUsers = () => {
  const cards = [
    {
      id: 1,
      icon: <FcSearch />,
      title: "Busqueda",
      description: "Filtro dinámico para encontrar los terceros que usan el sistema, y sus caracteristicas",
      to: "/menu/hefestoPersons/search-person", 
    },
    {
      id: 2,
      icon: <FcPlus />,
      title: "Registro",
      description: "Crea los terceros y toda la información legal requerida.  ",
      to: "/menu/hefestoPersons/register-person", 
    },
   
   
  ];

  return <Dashboard cards={cards} />;

};

export default IntroUsers;
