import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/LogContext";
import axios from "axios";
import Select from "react-select";
import {
	route_informesPresupuesto,
	route_confiPresupuesto_base,
	route_configuration_base,
	route_informesConta_base,
} from "../../../api-routes/RoutesLogin";
import { MdContentPasteSearch } from "react-icons/md";
import Swal from "sweetalert2";
import TableIngresos from "../../componentsBase/tables/TableBase1";

export default function InformacionUsuarios() {
	const { naturaleza } = useParams();
	const { establishment, getToken, customStyles, dateStyles, FechaYMDHMS } =
		useContext(AuthContext);
	const [data, setData] = useState([]);
	const [years, setYears] = useState([]);
	const [desde, setDesde] = useState("");
	const [hasta, setHasta] = useState("");
	const [titulo, setTitulo] = useState("");
	const [banCarga, setBanCarga] = useState(0);

	const sumFields = ["creditos", "debitos"];
	useEffect(() => {
	//	getCurrentMonthDates();
		//getYears();
	}, [naturaleza]);

	const columns = [
		{ Header: "Codigo", accessor: "codigo" },
		{ Header: "Nombre", accessor: "nombre" },
		{ Header: "Total", accessor: "total" },
		
	];
	const visualizarInforme = async () => {
		setBanCarga(1);
		if (hasta != "") {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${getToken()}`,
				},
			};
			const result = await axios.get(
				`${route_informesConta_base}/getSituacionFinanciera/${hasta},${establishment.id}`,
				config
			);
		
			setData(result.data);
			setBanCarga(0);
			setTitulo("Estado de situación financiera");
		} else {
			Swal.fire({
				icon: "warning",
				title: "Verifique los valores del filtro",
				showConfirmButton: false,
				timer: 1500,
			});
			setBanCarga(0);
		}
	};

	/* const getYears = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_configuration_base}/getYears/${establishment.id},4`,
			config
		);
		result.data.sort((a, b) => a.year - b.year);
		setYears(result.data);
	}; */
	/* const getCurrentMonthDates = () => {
		const currentDate = new Date();
		const firstDayOfMonth = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			1
		);
		const lastDayOfMonth = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth() + 1,
			0
		);

		setDesde(firstDayOfMonth.toISOString().slice(0, 10));
		setHasta(lastDayOfMonth.toISOString().slice(0, 10));
	}; */
	return (
		<>
			<div className="containerFormUser">
				<h1>Estado de situación financiera</h1>

				{/* seccion de filtros */}

				<div style={{ marginTop: "10px" }} className="row">
					{/* <div className="col-md-4  ">
						<div className="form-floating mb-2  ">
							<input
								style={dateStyles}
								type="date"
								name="desde"
								id="desde"
								value={desde}
								className="form-control"
								placeholder="Desde "
								onChange={(e) => {
									if (e) {
										setDesde(e.target.value);
									}
								}}
							/>
							<label style={{zIndex:"0"}} htmlFor="desde" className="form-label">
								Desde
							</label>
						</div>
					</div> */}
					<div className="col-md-6  ">
						<div className="form-floating mb-2  ">
							<input
								style={dateStyles}
								type="date"
								name="hasta"
								id="hasta"
								value={hasta}
								className="form-control"
								placeholder="Corte "
								onChange={(e) => {
									if (e) {
										setHasta(e.target.value);
									}
								}}
							/>
							<label
								style={{ zIndex: "0" }}
								htmlFor="hasta"
								className="form-label"
							>
								Corte
							</label>
						</div>
					</div>
					<div className="col-md-1">
						<button
							className="btn btn-primary"
							title="Visualizar Informe"
							onClick={visualizarInforme}
						>
							<MdContentPasteSearch size={20} />
						</button>
					</div>
				</div>
				<div className="table-container-reacTable">
					{banCarga === 0 ? (
						<TableIngresos
							data={data}
							columns={columns}
							encabezado={{
								empresa: establishment.name,
								nit: establishment.identification,
								titulo: titulo,
								imagen: establishment.image,
								fechas: "Informacion corte: "  + hasta,
								generado: "Informe generado: " + FechaYMDHMS,
							}}
							sumFields={sumFields}
						/>
					) : (
						/* spinner carga */
						<button className="btn btn-primary" type="button" disabled>
							<span
								className="spinner-border spinner-border-sm"
								aria-hidden="true"
							></span>
							<span role="status"> Cargando información...</span>
						</button>
						/*--------------- */
					)}
				</div>
			</div>
		</>
	);
}
