import React, { useState, useEffect, useContext, useRef } from "react";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import axios from "axios";
import { AuthContext } from "../../context/LogContext";
import { NumericFormat } from "react-number-format";
import Select from "react-select";
import {
	route_confiGym_planes,
	route_confiGym_base,
} from "../../../api-routes/RoutesLogin";
import Swal from "sweetalert2";
import * as Yup from "yup";

const RegisterPlan = () => {
	const { getToken, establishment, inputStyles, customStyles } = useContext(
		AuthContext
	);
	const [servicios, setServicios] = useState([]);
	const [planes, setPlanes] = useState([]);
	const btnCancel = useRef(null);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [inputValor, setInputValor] = useState(null);
	const formikRef = useRef(null);

	const handleSubmit = (values) => {
	
		savePlan(values);
	};
	const getServicios = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_confiGym_base}/getServicios/${establishment.id},true`,
			config
		);
		setServicios(result.data);
	};
	const getPlanes = async () => {
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};
		const result = await axios.get(
			`${route_confiGym_base}/getAllPlanes/${establishment.id}`,
			config
		);
		setPlanes(result.data);
	};

	const savePlan = async (json) => {
		const config = {
			headers: {
				//"Content-Type": "application/json",
				Authorization: `Bearer ${getToken()}`,
			},
		};

		try {
			const response = await axios.post(route_confiGym_planes, json, config);
			const successMessage = "Plan creado exitosamente";

			Swal.fire({
				icon: "success",
				title: successMessage,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				btnCancel.current.click();
				setSelectedOptions([]);
				setInputValor(null);
				getPlanes();
			});
		} catch (error) {
			if (error.response && error.response.status === 403) {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Verifique que el dato no se encuentre registrado",
					showConfirmButton: true,
				}).then(() => {});
			} else {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "Error de red, contactese con su proveedor",
					showConfirmButton: true,
				}).then(() => {});
			}
		}
	};
	const handleSelectChange = (options) => {
		setSelectedOptions(options);
	};
	const handleValorChange = (valor) => {
		setInputValor(valor);
	};
	const handlePlanEdit = (planId) => {
		const plantDet = planes.find((p) => p.id === planId);
		if (formikRef.current) {
			let optionJson = [];
			if (plantDet.serviciosxplan && plantDet.serviciosxplan.length > 0) {
				optionJson = plantDet.serviciosxplan.map((det) => ({
					value: det.servicoDet.id,
					label: det.servicoDet.servicio,
				}));
			}
			plantDet.servicios = optionJson;
			formikRef.current.setValues(plantDet);
			setSelectedOptions(optionJson);
			setInputValor(plantDet.valor);
		}
	};
	const handleDelete = (planId) => {
		Swal.fire({
			title: "Eliminar plan?",
			text: "El plan no estará disponible!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Eliminando...",
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					didOpen: () => {
						deletePlan(planId, false)
							.then((response) => {
								Swal.fire({
									icon: "success",
									title: "El plan ha sido eliminado",
									showConfirmButton: false,
									timer: 1500,
								}).then(() => {
									getPlanes();
								});
							})
							.catch((error) => {
								console.error(error);
								Swal.fire({
									icon: "error",
									title: "Hubo un error al eliminar el plan",
									text: error.message,
									showConfirmButton: true,
								});
							});
					},
				});
			}
		});
	};

	const handleActive = (planId) => {
		Swal.fire({
			title: "Reactivar plan?",
			text: "El plan  estará disponible!",
			icon: "success",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Aceptar",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire({
					title: "Reactivando...",
					allowOutsideClick: false,
					allowEscapeKey: false,
					showConfirmButton: false,
					didOpen: () => {
						deletePlan(planId, true)
							.then((response) => {
								Swal.fire({
									icon: "success",
									title: "El plan ha sido reactivado",
									showConfirmButton: false,
									timer: 1500,
								}).then(() => {
									getPlanes();
								});
							})
							.catch((error) => {
								console.error(error);
								Swal.fire({
									icon: "error",
									title: "Hubo un error al eliminar el plan",
									text: error.message,
									showConfirmButton: true,
								});
							});
					},
				});
			}
		});
	};

	const deletePlan = async (planId, status) => {
		let values = {
			id: planId,
			active: status,
		};
		let dataJson = JSON.stringify(values);
		try {
			const response = await axios.post(
				route_confiGym_planes + "/deactive",
				dataJson,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${getToken()}`,
					},
				}
			);
			return response;
		} catch (error) {
			throw error;
		}
	};

	const handleCancel = () => {
		setSelectedOptions([]);
		setInputValor("");
	};

	useEffect(() => {
		getServicios();
		getPlanes();
	}, []);

	const validationSchema = Yup.object().shape({
		plan: Yup.string().required("Ingrese el nombre del plan"),
		descripcion: Yup.string().required("Ingrese la descripción"),
		dias: Yup.string().required("Ingrese el numero de dias del plan"),
		valor: Yup.string().required("Ingrese el valor del plan"),
		//servicios: Yup.string().required("Ingrese los servicios del plan"),
	});

	const options = {
		useGrouping: true,
		maximumFractionDigits: 2,
	};

	const initialValues = {
		plan: "",
		descripcion: "",
		dias: "",
		valor: "",
		servicios: [],
		idEstablishment: establishment.id,
		active: true,
	};

	return (
		<div className="containerFormUser">
			<h1>Registro de planes</h1>
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
				innerRef={formikRef}
			>
				{(formik, props) => (
					<Form>
						<div className="row">
							{/* BLOQUE1 */}
							<div className="col-md-4  ">
								<div className="form-floating mb-2  ">
									<Field
										style={inputStyles}
										type="text"
										name="plan"
										id="plan"
										className="form-control"
										placeholder="Plan"
									/>
									<label htmlFor="plan" className="form-label">
										Plan
									</label>
									<ErrorMessage name="plan" component="div" className="error" />
								</div>
							</div>
							<div className="col-md-4  ">
								<div className="form-floating mb-2  ">
									<Field
										style={inputStyles}
										type="text"
										name="descripcion"
										id="descripcion"
										className="form-control"
										placeholder="Descripción"
									/>
									<label htmlFor="descripcion" className="form-label">
										Descripción
									</label>
									<ErrorMessage
										name="descripcion"
										component="div"
										className="error"
									/>
								</div>
							</div>
							<div className="col-md-4  ">
								<div className="form-floating mb-2  ">
									<Field
										style={inputStyles}
										type="number"
										name="dias"
										id="dias"
										className="form-control"
										placeholder="Dias"
									/>
									<label htmlFor="dias" className="form-label">
										Dias
									</label>
									<ErrorMessage name="dias" component="div" className="error" />
								</div>
							</div>
							<div className="col-md-6  ">
								<div className="form-floating mb-2  ">
									<Select
										styles={customStyles}
										placeholder="Servicios"
										isMulti
										value={selectedOptions}
										onChange={(e) => {
											handleSelectChange();
											formik.setFieldValue("servicios", e ? e : "");
										}}
										options={servicios.map((rol) => ({
											label: rol.servicio,
											value: rol.id,
										}))}
										className="basic-multi-select"
										classNamePrefix="select"
									/>

									<ErrorMessage
										name="servicios"
										component="div"
										className="error"
									/>
								</div>
							</div>

							<div className="col-md-6  ">
								<div className="form-floating mb-2  ">
									<NumericFormat
										value={inputValor}
										style={{ ...inputStyles, textAlign: "right" }}
										className="form-control"
										thousandSeparator="."
										decimalSeparator=","
										onChange={(e) => {
											handleValorChange(
												parseFloat(
													e.currentTarget.value
														.replace(/\./g, "")
														.replace(/,/g, ".")
												)
											);
											formik.setFieldValue(
												"valor",
												e
													? parseFloat(
															e.currentTarget.value
																.replace(/\./g, "")
																.replace(/,/g, ".")
													  )
													: ""
											);
										}}
									/>
									<label htmlFor="dias" className="form-label">
										Valor
									</label>
									<ErrorMessage
										name="valor"
										component="div"
										className="error"
									/>
								</div>
							</div>
						</div>
						<button type="submit" className="btn btn-primary">
							Guardar
						</button>
						&nbsp;
						<button
							ref={btnCancel}
							type="reset"
							onClick={() => {
								handleCancel();
							}}
							className="btn btn-danger"
						>
							Cancelar
						</button>
					</Form>
				)}
			</Formik>
			<br></br>
			<h1>Lista de planes</h1>
			<table
				style={{ width: "100%" }}
				id="tableUsers"
				className="table table-striped table-hover"
			>
				<thead>
					<tr>
						<th>Plan</th>
						<th>Descripciòn</th>
						<th>Dias</th>
						<th>Servicios</th>
						<th>Valor</th>
						<th style={{ width: "20%", textAlign: "right" }}>Opciones</th>
					</tr>
				</thead>
				<tbody>
					{planes.map((plan) => (
						<tr key={plan.id}>
							<td>{plan.plan}</td>
							<td>{plan.descripcion}</td>
							<td>{plan.dias}</td>
							<td>
								{plan.serviciosxplan && plan.serviciosxplan.length > 0 ? (
									<ul>
										{plan.serviciosxplan.map((det) => (
											<li key={det.id} style={{ margin: "2px 0" }}>
												{det.servicoDet.servicio}
											</li>
										))}
									</ul>
								) : (
									<span>No hay servicios disponibles</span>
								)}
							</td>{" "}
							<td>{plan.valor.toLocaleString(undefined, options)}</td>
							<td style={{ width: "20%", textAlign: "right" }}>
								{plan.active ? (
									<>
										<button
											className="btnEdit"
											type="button"
											onClick={(e) => handlePlanEdit(plan.id)}
										>
											<i className="fas fa-pen"></i>
										</button>
										&nbsp;&nbsp;
										<button
											className="btnDelete"
											type="button"
											onClick={() => handleDelete(plan.id)}
										>
											<i className="fas fa-trash-alt"></i>
										</button>
									</>
								) : (
									<>
										<button
											className="btn btn-success"
											type="button"
											onClick={() => handleActive(plan.id)}
										>
											<i class="fas fa-check"></i>
										</button>
									</>
								)}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default RegisterPlan;
